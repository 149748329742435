import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';
import Frame from '../Frame/Frame';
import CloseIcon from '@assets/images/cyber-academy/close.svg';

const StyledModal = styled(AntModal)`
  & .ant-modal-content {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.08) !important;
    max-height: 95vh;
    overflow-y: auto;
    background-color: transparent;
  }
  & .ant-modal-body {
    padding: 0;
    position: relative;
  }
`;

const Modal = ({ children, closable, footer, onCancel, ...rest }) => {
  return (
    <StyledModal onCancel={onCancel} closable={false} footer={footer} {...rest}>
      <Frame>{children}</Frame>
      {closable && (
        <img
          alt='close-icon'
          onClick={onCancel}
          height={70}
          width={70}
          src={CloseIcon}
          style={{ width: 70, right: 0, top: 0, position: 'absolute', cursor: 'pointer' }}
        />
      )}
    </StyledModal>
  );
};

export default Modal;

Modal.defaultProps = {
  width: 510,
  footer: null,
  closable: true,
  centered: true,
  onCancel: undefined
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  /** Width of the modal */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Footer content to show at the bottom */
  footer: PropTypes.node,
  closable: PropTypes.bool,
  centered: PropTypes.bool,
  onCancel: PropTypes.func
};
