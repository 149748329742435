import * as Yup from 'yup';
import i18n from '@services/i18n';

export default Yup.object().shape({
  name: Yup.string().required(i18n.t('input_school_name', { ns: 'schoolAdmin' })),
  address: Yup.string().required(i18n.t('input_school_address', { ns: 'schoolAdmin' })),
  city: Yup.string().required(i18n.t('input_school_city', { ns: 'schoolAdmin' })),
  state: Yup.string().required(i18n.t('input_school_province', { ns: 'schoolAdmin' })),
  country: Yup.string().required(i18n.t('input_school_country', { ns: 'schoolAdmin' })),
  school_board: Yup.string().required(i18n.t('input_school_board', { ns: 'schoolAdmin' })),
  phone_number: Yup.string().required(i18n.t('input_school_phone_number', { ns: 'schoolAdmin' })),
  principal_fname: Yup.string().required(i18n.t('input_principal_first_name', { ns: 'schoolAdmin' })),
  principal_lname: Yup.string().required(i18n.t('input_principal_last_name', { ns: 'schoolAdmin' })),
  principal_email: Yup.string().required(i18n.t('input_principal_email', { ns: 'schoolAdmin' }))
});
