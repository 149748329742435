import React from 'react';
import PropTypes from 'prop-types';
import { EducatorButton, EducatorModal } from '../../../commonComponents';

export const AddChildModal = ({ show, handleOnCancel, handleCreateChild, handleExistingChild }) => {
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">Add a Child</div>
          <div className="fixCardtextStyle mt-2">
            Would you like to create a new account for the child you’re adding or are you looking to link an account
            that already exists?
          </div>

          <div className="mt-3">
            <EducatorButton
              key="button"
              label="I’d like to create a new account"
              type="button"
              width="100%"
              onClick={handleCreateChild}
            />
          </div>
          <div className="mt-3">
            <EducatorButton
              key="button"
              label="I’d like to connect an existing account"
              type="button"
              width="100%"
              onClick={handleExistingChild}
            />
          </div>
        </div>
      }
    />
  );
};

export default AddChildModal;

AddChildModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleCreateChild: PropTypes.func.isRequired,
  handleExistingChild: PropTypes.func.isRequired
};
