import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { Card, Divider, Form, Select } from 'antd';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import {
  API_URL,
  getApiWithAuth,
  postApiWithAuth,
  deleteApiWithAuth,
  patchApiWithAuth,
  getApiWithoutAuth,
  getUserID
} from '@utils';
import {
  AssignmentCardWithColor,
  EducatorButton,
  EducatorModal,
  EducatorInputField
} from '@components/commonComponents';
import { useGrades } from '@hooks';

import AddCard from './AddCard';
import UpdateCard from './UpdateCard';

import masterCard from '@assets/images/masterCard.svg';
import discover from '@assets/images/Discover.svg';
import americanExpress from '@assets/images/AmericanExpress.svg';
import jcb from '@assets/images/Jcb.svg';
import visa from '@assets/images/Visa.svg';
import unionPay from '@assets/images/UnionPay.svg';
import rightArrow from '@assets/images/rightArrow.svg';
import deleteImage from '@assets/images/deleteImage.svg';
import deleteFrame from '@assets/images/Frame.svg';

import './MembershipAndBilling.scss';

const { Option } = Select;

export const MembershipAndBilling = () => {
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const [subscriptionDetail, setSubscriptionDetail] = useState({ subscribed_children: [] });
  const [cardDetail, setCardDetail] = useState(null);
  const [showDeleteClassModal, setShowDeleteClassModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const [showCreateChildModal, setShowCreateChildModal] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [showAddCurriculumModal, setShowAddCurriculumModal] = useState(false);
  const [linkExistingModal, setLinkExistingModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailDta, setDetailData] = useState({});
  const [childData, setChildData] = useState({});
  const [childCurriculum, setChildCurriculum] = useState({});
  const [selectedProvince, setSelectedProvince] = useState('');
  const [dataCountry, setDataCountry] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [parentOverView, setParentOverView] = useState({});
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [showCopyMessage, setShowCopyMessage] = useState({ value: false });

  const getUserSubscriptionDetail = async () => {
    const parent_id = getUserID();
    const { success, data } = await getApiWithAuth(`payments/parent/${parent_id}/subscription-details`);
    if (success) {
      setSubscriptionDetail(data);
    }
  };
  const getCardDetails = async () => {
    const { success, data } = await getApiWithAuth(API_URL.FETCH_CARDS);
    if (success) {
      setCardDetail(data);
    }
  };

  useEffect(() => {
    getUserSubscriptionDetail();
    getCardDetails();
    getCountries();
    getParentOverView();
  }, []);
  const getParentOverView = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PARENT_OVERVIEW);
    if (success) {
      setParentOverView(data);
    }
  };
  const editAccountDetail = data => {
    navigate('/edit-account', { state: { userInfo: data } });
  };

  const showDeleteModal = item => {
    setSingleUser(item);
    setShowDeleteClassModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteClassModal(false);
  };

  const deleteUser = async data => {
    setShowDeleteSpinner(true);
    const { success } = await postApiWithAuth(API_URL.DELETE_SUBSCRIPTION, {
      sub_id: data.subscription_id,
      child_id: data.student?.student_id
    });
    if (success) {
      setShowDeleteSpinner(false);
      setSubscriptionDetail({ subscribed_children: [] });
      getUserSubscriptionDetail();
      handleDeleteCancel();
    } else {
      setShowDeleteSpinner(false);
    }
  };

  const showDeleteCard = item => {
    setSingleUser(item);
    setShowDeleteCardModal(true);
  };

  const handleDeleteCard = () => {
    setShowDeleteCardModal(false);
  };

  const cardDelete = async id => {
    setShowDeleteSpinner(true);
    const { status } = await deleteApiWithAuth(`${API_URL.DELETE_CARD}/${id}`);
    if (status === 204) {
      setShowDeleteSpinner(false);
      handleDeleteCard();
      getUserSubscriptionDetail();
      getCardDetails();
    } else {
      setShowDeleteSpinner(false);
    }
  };

  const showAddCard = () => {
    setShowAddCardModal(true);
  };

  const handleAddCard = () => {
    setShowAddCardModal(false);
    getUserSubscriptionDetail();
    getCardDetails();
  };
  const showEditCard = item => {
    setSingleUser(item);
    setShowEditCardModal(true);
  };

  const handleEditCard = () => {
    setShowEditCardModal(false);
    getUserSubscriptionDetail();
    getCardDetails();
  };

  const showAddChild = () => {
    setShowAddChildModal(true);
  };
  const hideAddChild = () => {
    setShowAddChildModal(false);
  };
  const showCreateChild = () => {
    setShowAddChildModal(false);
    setShowCreateChildModal(true);
  };
  const hideCreateChild = () => {
    setShowCreateChildModal(false);
  };
  const showCurriculumChild = () => {
    setShowCreateChildModal(false);
    setShowAddCurriculumModal(true);
  };
  const hideCurriculumChild = () => {
    setShowAddCurriculumModal(false);
  };

  const showDetail = () => {
    setShowAddCurriculumModal(false);
    setShowDetailModal(true);
  };
  const hideDetail = () => {
    setShowDetailModal(false);
    getUserSubscriptionDetail();
    getCardDetails();
  };
  const showExistingCode = () => {
    setShowAddChildModal(false);
    setLinkExistingModal(true);
  };
  const hideExistingCode = () => {
    setLinkExistingModal(false);
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setChildData({ ...childData, [name]: value });
  };
  const curriculumChild = async () => {
    setButtonSpinner(true);
    const { success } = await patchApiWithAuth(API_URL.PARENT_CURRICULUM_SETUP, childCurriculum);
    if (success) {
      showDetail();
      getUserSubscriptionDetail();
      getCardDetails();
      getParentOverView();
    }
    setButtonSpinner(false);
  };
  const createChild = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_CREATE_CHILD_ACCOUNT, childData);
    if (success) {
      setDetailData(data);
      setChildCurriculum({ ...childCurriculum, student_id: data.student_link_id });
      showCurriculumChild();
    }
    setButtonSpinner(false);
  };
  const getCountries = async () => {
    const { success, data } = await getApiWithoutAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
      setDataCountry(data[0]?.name);
    }
  };
  const selectCountry = countryName => {
    setChildCurriculum({ ...childCurriculum, country: countryName });
  };
  const selectProvince = provinceId => {
    const temp = provinces.filter(item => item.id == provinceId);
    setSelectedProvince(temp[0].name);
    setChildCurriculum({ ...childCurriculum, province_id: provinceId });
  };

  const selectGrade = gradeName => {
    setChildCurriculum({ ...childCurriculum, child_grade: gradeName });
  };

  useEffect(() => {
    if (dataCountry !== null) {
      setChildCurriculum({ ...childCurriculum, country: dataCountry });
    }
  }, [dataCountry]);

  useEffect(() => {
    if (selectedProvince !== null) {
      setChildCurriculum({ ...childCurriculum, province: selectedProvince });
    }
  }, [selectedProvince]);

  useEffect(() => {
    getProvinces(childCurriculum.country);
  }, [childCurriculum.country]);
  const getProvinces = async country => {
    const { success, data } = await getApiWithoutAuth(`${API_URL.GET_PROVINCES}?name=${country}`);
    if (success) {
      setProvinces(data);
      setSelectedProvince(data[0]?.name);
      setSelectedProvinceId(data[0]?.id);
    }
  };
  useEffect(() => {
    if (selectedProvinceId !== null) {
      setChildCurriculum({ ...childCurriculum, province_id: selectedProvinceId });
    }
  }, [selectedProvinceId]);

  useEffect(() => {
    if (grades.length > 0) {
      setChildCurriculum({ ...childCurriculum, child_grade: grades[0]?.name });
    }
  }, [grades]);

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle py-5">
        <AuthenticatedNavbar title="Account Management" />
        <div className="unitPlanTextStyle row">
          <div className="col-9">
            <div>
              This is where you can update all of your billing details and details for your children’s accounts -
              including resetting their password.{' '}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-sm-7  pb-3 ">
            <div>
              <div className="fixCardHeadingStyle">Your Users</div>
              <div className="pb-3">
                {subscriptionDetail.subscribed_children?.map(item => {
                  return (
                    <div key={item.subscription_id}>
                      <AssignmentCardWithColor
                        membership
                        title={`${item.student.first_name} ${item.student.last_name.substring(0, 1).toUpperCase()}`}
                        userInfo={item}
                        color={item.plan_type.plan === 'Premium' ? '#F8CA42' : '#4B3AD3'}
                        isFree={item.plan_type.plan === 'Premium' ? false : true}
                        viewReports={() => editAccountDetail(item)}
                        deleteAssignment={() => showDeleteModal(item)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="pt-3 textStyle400Cabin18Black">
                Don&apos;t see your child listed?
                <span className="parentOverviewHadding cursorPointer addLineBottom" onClick={showAddChild}>
                  &nbsp;Add a Child &gt;
                </span>
              </div>
            </div>
          </div>
          <div className="col col-sm-5 pb-3 ">
            <div>
              <div className="pb-1">
                <div className="fixCardHeadingStyle pb-2">Card on File</div>
                {cardDetail?.map(item => {
                  return (
                    <div className="pb-3" key={item.id}>
                      <Card bordered={false} className="cardsStyling cardHight px-sm-3 p-4 px-xxl-5 ">
                        <div className="cardBillingStyle">
                          {item.card_type === 'visa' ? (
                            <img src={visa} alt="visa" className="cardImageStyle" />
                          ) : item.card_type === 'masterCard' ? (
                            <img src={masterCard} alt="masterCard" className="cardImageStyle" />
                          ) : item.card_type === 'discover' ? (
                            <img src={discover} alt="discover" className="cardImageStyle" />
                          ) : item.card_type === 'americanExpress' ? (
                            <img src={americanExpress} alt="americanExpress" className="cardImageStyle" />
                          ) : item.card_type === 'jcb' ? (
                            <img src={jcb} alt="jcb" className="cardImageStyle" />
                          ) : (
                            <img src={unionPay} alt="unionPay" className="cardImageStyle" />
                          )}
                          <div className="cardNumberStyle">**** {item.last_digits}</div>
                          <div className="expStyle">EXP {item.expiry_date}</div>
                          <div className="editDetailStyle">
                            <div
                              className="assignmentCardSecondInnerDiv me-2 cursorPointer"
                              onClick={() => showEditCard(item)}>
                              Edit &nbsp;
                              <img src={rightArrow} alt="deleteImage" className="cursorPointer" />
                            </div>
                            <div onClick={() => showDeleteCard(item)}>
                              &nbsp;
                              <img src={deleteImage} alt="deleteImage" className="cursorPointer" />
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <div className="addNewCardText">
                Get a new Credit Card?
                <span className="parentOverviewHadding cursorPointer addLineBottom" onClick={showAddCard}>
                  &nbsp;Add a Card &gt;
                </span>
              </div>
            </div>
            <div>
              <div className="fixCardHeadingStyle mt-4">Manage Subscriptions</div>
              <div className="py-3">
                <Card bordered={false} className="cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 ">
                  {subscriptionDetail.subscribed_children?.map(item => {
                    return (
                      <div key={item.subscription_id}>
                        <div className="spaceBetweenDiv">
                          <div className="expStyle">
                            {item.student.first_name} {item.student.last_name.substring(0, 1).toUpperCase()}
                          </div>
                          <div className="expStyle">
                            {item.plan_type.plan} {item.plan_type.status}
                          </div>
                        </div>
                        <Divider className="dividerBilling" />
                      </div>
                    );
                  })}
                  <div className="fixCradCenter">
                    <EducatorButton
                      key="button"
                      disabled={subscriptionDetail?.subscribed_children?.length === 0}
                      label="Manage Subscriptions"
                      type="button"
                      width="95%"
                      onClick={() => navigate('/manage-subscription')}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EducatorModal
        visible={showDeleteClassModal}
        onCancel={handleDeleteCancel}
        bodyContent={
          <div className="fixCradCenter mt-4 p-3">
            <div>
              <img src={deleteFrame} alt="deleteFrame" className="cursorPointer" />

              <div className="fixCardHeadingStyle mt-3 px-5">
                Are you sure you want to delete {singleUser?.student?.first_name}{' '}
                {singleUser?.student?.last_name.substring(0, 1).toUpperCase()} Account?
              </div>
              <div className="unitPlanTextStyle px-2">
                When you delete your account you will lose all progress that your child has made in the game. If you
                want to keep your progress, you can always downgrade to a freemium membership.
              </div>
              <div className="pt-3">
                <EducatorButton
                  key="button"
                  label="Delete Account"
                  type="button"
                  width="100%"
                  onClick={() => deleteUser(singleUser)}
                  showSpinner={showDeleteSpinner}
                />
              </div>
            </div>
          </div>
        }
      />

      <EducatorModal
        visible={showDeleteCardModal}
        onCancel={handleDeleteCard}
        bodyContent={
          <div className="fixCradCenter mt-4 p-3">
            <div>
              <img src={deleteFrame} alt="deleteFrame" className="cursorPointer" />

              <div className="fixCardHeadingStyle mt-3 px-5">You’re about to delete your credit card!</div>
              <div className="unitPlanTextStyle px-2">
                This means that all future scheduled payments will be declined until you add a new payment method. Are
                you sure this is what you’d like to do?
              </div>
              <div className="pt-3">
                <EducatorButton
                  key="button"
                  label="Delete Credit Card"
                  type="button"
                  width="100%"
                  onClick={() => cardDelete(singleUser?.id)}
                  showSpinner={showDeleteSpinner}
                />
              </div>
            </div>
          </div>
        }
      />

      <EducatorModal
        visible={showAddCardModal}
        onCancel={handleAddCard}
        bodyContent={
          <div className="mt-4 p-3 ">
            <AddCard closeModal={handleAddCard} />
          </div>
        }
      />
      <EducatorModal
        visible={showEditCardModal}
        onCancel={handleEditCard}
        bodyContent={
          <div className="mt-4 p-3 ">
            <UpdateCard closeModal={handleEditCard} userInfo={singleUser} setSingleUser={setSingleUser} />
          </div>
        }
      />

      <EducatorModal
        visible={showAddChildModal}
        onCancel={hideAddChild}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">Add a Child</div>
            <div className="fixCardtextStyle mt-2">
              Would you like to create a new account for the child you’re adding or are you looking to link an account
              that already exists?
            </div>

            <div className="mt-3">
              <EducatorButton
                key="button"
                label="I’d like to create a new account"
                type="button"
                width="100%"
                onClick={showCreateChild}
              />
            </div>
            <div className="mt-3">
              <EducatorButton
                key="button"
                label="I’d like to connect an existing account"
                type="button"
                width="100%"
                onClick={showExistingCode}
              />
            </div>
          </div>
        }
      />

      <EducatorModal
        visible={showCreateChildModal}
        onCancel={hideCreateChild}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">Create Your Child’s Account</div>
            <div className="fixCardtextStyle mt-2 mb-3">
              Fill out the form below to sign your child up for Cyber Legends. Educational details allows us to ensure
              the content they’re seeing reflects that they learn about in school.
            </div>
            <Form onFinish={createChild}>
              <Form.Item
                name="first_name"
                rules={[
                  { required: true, message: 'Please input your First Name!' },
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message: 'Must contain Alphabets'
                  }
                ]}>
                <EducatorInputField
                  label="First Name:"
                  type="text"
                  inputValue={childData.first_name}
                  headerColor="#000000"
                  name="first_name"
                  onChange={onChangeHandle}
                  width="100%"
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z]{1}$/,
                    message: 'First Letter of Last Name'
                  }
                ]}>
                <EducatorInputField
                  label="First Letter of Last Name:"
                  headerColor="#000000"
                  inputValue={childData.last_name}
                  type="text"
                  name="last_name"
                  onChange={onChangeHandle}
                  width="100%"
                />
              </Form.Item>
              <div className="pt-3">
                <EducatorButton label="Create Account" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        }
      />

      <EducatorModal
        visible={showAddCurriculumModal}
        onCancel={hideCurriculumChild}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">Add Education Details</div>
            <div className="fixCardtextStyle mt-2">
              Entering your child’s education details allows us to match our content with what they learn about in
              school.
            </div>
            <Form onFinish={curriculumChild}>
              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">
                      What country does{' '}
                      {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to
                      school in?
                    </label>
                    <div className="mt-3">
                      <Select
                        className="fixCardInputField"
                        value={childCurriculum?.country}
                        onChange={selectCountry}
                        name="country">
                        {countries.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">
                      What province does{' '}
                      {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to
                      school in?
                    </label>
                    <div className="mt-3">
                      <Select
                        className="fixCardInputField"
                        value={selectedProvince}
                        onChange={selectProvince}
                        name="country">
                        {provinces.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">
                      What grade is{' '}
                      {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} in?
                    </label>
                    <div className="mt-3">
                      <Select
                        onChange={selectGrade}
                        value={childCurriculum?.child_grade}
                        className="fixCardInputField"
                        name="child_grade">
                        {grades.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="pt-3">
                <EducatorButton label="Add Curriculum" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        }
      />
      <EducatorModal
        visible={showDetailModal}
        onCancel={hideDetail}
        bodyContent={
          <div>
            {showCopyMessage.value ? (
              <div className="yellowTitle container copyMessage">
                <p className="copyMessage__text">{showCopyMessage.text} Copied to Clipboard!</p>
              </div>
            ) : null}
            <div className="fixCardHeadingStyle">Your child’s account has been created!</div>
            <div className="fixCardtextStyle mt-2">
              This new account has been successfully created! Below are the login details. Write them down or store them
              in a secure password manager
            </div>

            <Form onFinish={hideDetail}>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{detailDta?.user_name ? detailDta?.user_name : 'Your UserName'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(detailDta?.user_name);
                    setShowCopyMessage({ value: true, text: 'Username' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="mt-3 childSecondText">Your Password: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{detailDta?.password ? detailDta?.password : 'Your Password'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(detailDta?.password);
                    setShowCopyMessage({ value: true, text: 'Password' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="py-3">
                <EducatorButton label="That’s it for now!" type="submit" width="100%" />
              </div>
            </Form>
          </div>
        }
      />
      <EducatorModal
        visible={linkExistingModal}
        onCancel={hideExistingCode}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle fixCradCenter">Link an Existing Account</div>
            <div className="linkExistingModalTextStyling fixCradCenter pt-2">
              You’ve indicated that your child already has a Cyber Legends account. To add them to your class, share the
              classcode below and have them enter it in the game.
            </div>
            <div className="linkExistingModalCodeStyling pt-3">{parentOverView.Household_code}</div>
            <div className="linkExistingModalTextStyling">Your Family Code</div>
          </div>
        }
      />
    </>
  );
};
