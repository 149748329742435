import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '../../index';

export const DeleteStudentModal = ({ show, onCancel, handleOnFinish, showSpinner }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  return (
    <>
      <EducatorModal
        visible={show}
        onCancel={onCancel}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">{t('are_you_sure_you_want_to_delete_this_student')}</div>
            <div className="pt-3">
              <Form onFinish={handleOnFinish}>
                <Form.Item className="mt-3">
                  <div className="pt-3">
                    <EducatorButton
                      key="submit"
                      label={t('yes_remove_this_student')}
                      type="submit"
                      width="100%"
                      showSpinner={showSpinner}
                    />
                  </div>
                  <div className="pt-2">
                    <EducatorButton
                      key="button"
                      label={t('oops_nevermind')}
                      type="button"
                      width="100%"
                      onClick={onCancel}
                    />
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        }
      />
    </>
  );
};

DeleteStudentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleOnFinish: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired
};
