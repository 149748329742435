import useSWR from 'swr';
import { API_URL } from '@utils';

export const useGrades = () => {
  const url = API_URL.GET_SCHOOL_GRADES;

  const { data, mutate, error, isLoading } = useSWR(url);
  const response = data?.data ?? [];
  const sortedGrades = response?.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error: error,
    data: sortedGrades,
    revalidate
  };
};
