import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import {
  API_URL,
  clearStorage,
  getApiWithAuth,
  getIsGoogle,
  getRefereshToken,
  getToken,
  GOOGLE_SSO_LOGOUT_URL,
  setUserID
} from '@utils';
import { LanguageSwitcher } from '@components/commonComponents';

import studentImage from '@assets/images/studentHome.svg';
import teacherImage from '@assets/images/teacherHome.svg';

const ChildWherePlay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const language = i18n.language;

  const logoutUser = async checkValue => {
    const Google = getIsGoogle();
    const token = getToken();
    const refreshToken = getRefereshToken();
    if (Google === '1') {
      clearStorage();

      window.location.href = GOOGLE_SSO_LOGOUT_URL;
      playGame({ token, checkValue, language });
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
          playGame({ token, checkValue, refreshToken, language });
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };
  const checkUserInfo = async value => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      setUserID(data.id);
      if (value === 1) {
        if (data.class_joined) {
          logoutUser(value);
        }
      } else {
        if (data.student_parent !== '') {
          logoutUser(value);
        } else {
          navigate('/child-create-account/parent-consent');
        }
      }
    } else {
      message.error(`${data.message}`);
    }
  };

  const playGame = ({ token, checkValue, refreshToken, language }) => {
    const serverLink = new URL(import.meta.env.REACT_APP_LINK_GAME_SERVER);
    const params = new URLSearchParams({
      token: token,
      mode: checkValue === 1 ? 'school' : 'home',
      refresh: refreshToken,
      locale: language
    });
    serverLink.search = params.toString();

    window.open(serverLink.toString(), '_self');
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { status } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
      if (status && status === 401) {
        navigate('/');
      }
    };
    fetchUser();
  }, [location.pathname === 'child-where-play']);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <nav className="top-nav">
        <LanguageSwitcher />
      </nav>
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childSecondHaddingStyle">Where are you playing?</div>
          <div className="displayFlex mt-2">
            <div className="childBox me-3" onClick={() => checkUserInfo(1)}>
              <div className="centerImage cursorPointer">
                <img src={teacherImage} alt="teacherImage" />
                <div className="childTextStyleForgetPassword mt-2">At School</div>
              </div>
            </div>
            <div className="childBox" onClick={() => checkUserInfo(2)}>
              <div className="centerImage cursorPointer">
                <img src={studentImage} alt="studentImage" />
                <div className="childTextStyleForgetPassword mt-2">At Home</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildWherePlay;
