import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Form, Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField, EducatorModal } from '@components/commonComponents';
import { API_URL, postApiWithAuth } from '@utils';
import { useAppState } from '@context';

import editIcon from '@assets/images/editIcon.svg';
import deleteImage from '@assets/images/deleteImage.svg';

export const CreateStudentsModal = ({
  show,
  onCancel,
  studentList,
  showSpinner,
  handleOnFinish,
  handleEditButton,
  handleDeleteButton,
  handleNext,
  getStudentsOfClass,
  setNewStudents
}) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { t: commonT } = useTranslation('common');
  const [form] = Form.useForm();
  const { state } = useAppState();

  const [addStudent, setAddStudent] = useState('');
  const [readyToUpload, setReadyToUpload] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleOnChange = e => {
    const { value } = e.target;
    setAddStudent(value);
  };

  const handleUpload = useCallback(async file => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await postApiWithAuth(`${API_URL.ADD_STUDENTS}${state.classId}/create-students-csv`, formData);
    setUploading(false);

    if (data.success) {
      message.success(t('students_added_success'));
      getStudentsOfClass();
      setNewStudents(data.new_students);
    } else {
      message.error(data.message);
    }
  }, []);

  const props = {
    beforeUpload: file => {
      setReadyToUpload(false);
      const isCsv = file.type === 'text/csv';
      if (!isCsv) {
        message.error(t('only_csv_upload'));
      } else {
        setReadyToUpload(true);
      }
      return false;
    },
    onChange: async info => {
      if (readyToUpload) {
        await handleUpload(info.file);
      }
    },
    maxCount: 1,
    showUploadList: false
  };

  useEffect(() => {
    if (studentList && studentList.length > 0) {
      setAddStudent('');
      form.resetFields();
    }
  }, [studentList]);

  return (
    <EducatorModal
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <h3 className="fixCardHeadingStyle ">{t('add_students')}</h3>
          <p className="fixCardtext mt-2">{t('add_students_info')}</p>
          <Form form={form} onFinish={handleOnFinish}>
            <h4 className="pt-1 labelStyleCard">
              {t('bulk_add_students')}
              <br />
              <a
                href="https://s3.amazonaws.com/resources.cyberlegends.com/Dashboard+Static+Pdf+files/students_sample_csv.csv"
                className="download-link"
                download="students_sample_csv.csv">
                {t('download_sample_template')} &gt;
              </a>
            </h4>
            <Upload {...props} className="upload-button">
              <EducatorButton
                label={t('upload_button')}
                type="button"
                key="button"
                width="100%"
                showSpinner={uploading}
              />
            </Upload>
            <div className="or-text">{commonT('or')}</div>
            <h4 className="labelStyleCard">{t('manual_add_students')}</h4>
            <p className="fixCardtext mt-2">{t('manual_add_note')}</p>
            <Form.Item name="names">
              <EducatorInputField
                placeholder="i.e. Arlo B, Avery E, Matthew H, Chris V"
                type="text"
                name="names"
                inputValue={addStudent}
                className="fixCardInputField"
                onChange={handleOnChange}
                suffix={
                  <EducatorButton
                    key="button"
                    label={t('add_button')}
                    type="submit"
                    className="addButton"
                    showSpinner={showSpinner}
                  />
                }
              />
            </Form.Item>
            <div className="scorlingCardSecond mt-3">
              {studentList?.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className="spaceBetweenDisplay">
                      <div className="cardlistNameText">
                        <span className="pe-3">{index + 1}.</span>
                        <span>
                          {item.first_name} {item.last_name.substring(0, 1).toUpperCase()}.
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="pe-3" onClick={() => handleEditButton(item.id)}>
                          <img src={editIcon} alt="editIcon" />
                        </span>
                        <span onClick={() => handleDeleteButton(item.id)}>
                          <img src={deleteImage} alt="deleteImage" />
                        </span>
                      </div>
                    </div>
                    <Divider className="dividerStyle" />
                  </div>
                );
              })}
            </div>
            <div className="endButton mt-3">
              <EducatorButton key="button" label={t('next_step')} type="button" width={'auto'} onClick={handleNext} />
            </div>
          </Form>
        </div>
      }
    />
  );
};

CreateStudentsModal.defaultProps = {
  studentList: []
};

CreateStudentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  studentList: PropTypes.array,
  showSpinner: PropTypes.bool.isRequired,
  handleOnFinish: PropTypes.func.isRequired,
  handleEditButton: PropTypes.func.isRequired,
  handleDeleteButton: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  getStudentsOfClass: PropTypes.func.isRequired,
  setNewStudents: PropTypes.func.isRequired
};
