import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, HelperBot } from '@components/commonComponents';
import { Navbar, Multistep } from '@components/layoutComponents';
import {
  API_URL,
  getApiWithAuth,
  patchApiWithAuth,
  setUserID,
  getUserID,
  getStateIdByName,
  postApiWithAuth,
  putApiWithAuth,
  getLocalStorageItem
} from '@utils';
import { useCountries, useCurrentUser, useSchools, useStates } from '@hooks';
import './CurriculumSetup.scss';

const { Option } = Select;

export const CurriculumSetup = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'curriculum_setup' });
  const { t: schoolSetupT } = useTranslation('translation', { keyPrefix: 'school_setup' });
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const { data: countries, loading: countryLoading } = useCountries();
  const { data: states, loading: stateLoading } = useStates(selectedCountry);
  const [schoolName, setSchoolName] = useState('');
  const [schoolId, setSchoolId] = useState(null);
  const [isHomeschoolChecked, setIsHomeschoolChecked] = useState(false);

  const { data: currentUser, revalidate } = useCurrentUser();

  const countryId = getStateIdByName(countries, selectedCountry);
  const provinceId = getStateIdByName(states, selectedProvince);
  const { data: schools, loading } = useSchools(countryId, provinceId, schoolName);

  const isHomeschoolTeacherFlow = getLocalStorageItem('isHomeschoolTeacher');

  const selectCountry = value => {
    setSelectedCountry(value);
  };

  const selectProvince = value => {
    setSelectedProvince(value);
  };

  const getCurrentUser = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      revalidate();
      setUserID(data.id);
    }
  };

  const addCurriculum = async () => {
    if (!selectedCountry || !selectedProvince) {
      message.error(t('select_country_and_province'));
      return;
    }

    setButtonSpinner(true);
    const id = getUserID();
    const { success, data } = await patchApiWithAuth(`${API_URL.PATCH_CURRICULUM_SETUP}${id}/`, {
      province_id: getStateIdByName(states, selectedProvince),
      country: selectedCountry
    });

    if (success) {
      if (isHomeschoolTeacherFlow || isHomeschoolChecked) {
        await addHomeshool(isHomeschoolTeacherFlow || isHomeschoolChecked);

        setButtonSpinner(false);
        navigate('/teacher-create-account/select-licence');
        return;
      }

      setButtonSpinner(false);
      await addSchool();
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };

  const handleSearch = value => {
    setSchoolName(value);
  };

  const handleSelect = value => {

    if (value === 'new_school') {
      const id = getUserID();
      patchApiWithAuth(`${API_URL.PATCH_CURRICULUM_SETUP}${id}/`, {
        province_id: getStateIdByName(states, selectedProvince),
        country: selectedCountry
      }).then(({ success, data }) => {
        if (success) {
          navigate('/teacher-create-account/create-school');
        } else {
          message.error(data.message);
        }
      });
    } else {
      setSchoolId(value);
    }
  };

  const addSchool = async () => {
    if (!schoolId) {
      message.error(schoolSetupT('input_school_name'));
      return;
    }

    const URL = `school/${schoolId}/user/${currentUser.id}/add`;

    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(URL);

    if (success) {
      setButtonSpinner(false);
      navigate('/teacher-create-account/select-licence');
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };

  const addHomeshool = async isHomeschool => {
    const { success, data } = await putApiWithAuth(API_URL.UPDATE_HOME_SCHOOL, { is_homeschool: isHomeschool });

    if (!success) {
      message.error(data.message);
    }
  };

  const handleOnChange = event => {
    const { checked } = event.target;
    setIsHomeschoolChecked(checked);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (!selectedCountry && countries?.length > 0) {
      setSelectedCountry(countries[0]?.name);
    }

    if (!selectedProvince && states?.length > 0) {
      setSelectedProvince(states[0]?.name);
    }
  }, [countries, states]);

  return (
    <div className="mainDivStyle container-fluid curriculum-setup-container">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <Multistep select="Curriculum Setup" check={[1, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex flex-column">
            <div className="CurriculumSetupStyleHeading">{t('curriculum_setup')}</div>
            <div className="pt-2 textStyle">{t('letting_us_know_province')}</div>

            <div className="pt-3 mt-3">
              <div>
                <label className="labelCurriculumStyle">{t('what_country_do_you_teach')}</label>
                <div className="mt-2">
                  <Select
                    className="inputFieldStyle"
                    defaultValue={countries[0]?.name}
                    onSelect={selectCountry}
                    value={selectedCountry}
                    loading={countryLoading}>
                    {countries.map(item => (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div>
                <label className="labelCurriculumStyle">{t('what_state_do_you_teach')}</label>
                <div className="mt-2">
                  <Select
                    className="inputFieldStyle"
                    defaultValue={states[0]?.name}
                    onSelect={selectProvince}
                    value={selectedProvince}
                    loading={stateLoading}>
                    {states.map(item => (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            {!isHomeschoolTeacherFlow && (
              <>
                <div className="pt-4">
                  <div>
                    <label className="schoolSetUpLabel">{t('which_school_teach_in')}</label>
                    <div className="mt-2">
                      <Select
                        disabled={!selectedCountry || !selectedProvince || isHomeschoolChecked}
                        loading={loading}
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearch}
                        onSelect={handleSelect}
                        notFoundContent={null}
                        className="inputFieldStyle"
                        allowClear
                        popupClassName="school-name__search">
                        {schools?.map(item => (
                          <Option key={item.id} value={item.id}>
                            <div className="d-flex flex-column">
                              <div className="school-name">{item.name}</div>
                              <div className="school-details">
                                {item.district}, {item.city}
                              </div>
                            </div>
                          </Option>
                        ))}
                        <Option key="new_school" data-testid="dontSeeSchoolButton" value="new_school">
                          <div className="d-flex flex-column">
                            <div className="school-name fw-bold">{schoolSetupT('dont_see_your_school')}</div>
                          </div>
                        </Option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="pt-4 pb-2">
                  <Checkbox className="checkboxStyle" onChange={handleOnChange}>
                    {t('im_homeschool_teacher')}
                  </Checkbox>
                </div>
              </>
            )}

            <div className="pt-4">
              <EducatorButton
                label={t('add_curriculum')}
                onClick={addCurriculum}
                showSpinner={buttonSpinner}
                dataTestId="addCurriculumButton"
              />
            </div>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end">
          <HelperBot>{t('more_alignments_coming_soon')}</HelperBot>
        </div>
      </div>
    </div>
  );
};
export default CurriculumSetup;
