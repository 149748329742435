import React from 'react';
import PropTypes from 'prop-types';
import { Paperclip, Check, AlertTriangle } from 'react-feather';
import { Modal, Stack, Text, Title, Colors, Badge } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';

const TeachingResourceModal = ({ isOpen, onClose, resources, hasSensitiveContent }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });

  return (
    <Modal width={491} closable centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" alignItems='flex-start' spacing={12}>
        <Stack alignItems='flex-start' spacing={12}>
          <Paperclip color={Colors.GREY_50} size={30} />
          <Title level={3}>{t('teaching_resources')}</Title>
        </Stack>
        {hasSensitiveContent &&<Badge type='danger' block icon={<AlertTriangle />}>{t('sensitive_content')}</Badge>}
        <Text size='small' style={{ color: Colors.GREY_50 }}>{t('teaching_resources_modal_text')}</Text>
        <Stack alignItems="flex-start" direction="column" style={{ paddingTop: 10 }}>
          {resources.map((resource, index) => (
            <Stack gap={5} key={index}>
              <Check color={Colors.SHAMROCK_100} />
              <Text bold size='small'> {resource}</Text>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default TeachingResourceModal;

TeachingResourceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resources: PropTypes.array.isRequired,
  hasSensitiveContent: PropTypes.bool.isRequired,
};
