import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button as AntButton, Spin } from 'antd';
import colors from '../../theme/colors';

const StyledButton = styled(AntButton)`
  &.ant-btn {
    font-family: Catamaran, sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 21.6px;
    text-align: center;
    padding: 15px 25px !important;
    height: 52px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    > span[role='img'] {
      font-size: 16px;
      > svg {
        width: 16px;
        height: 16px;
        font-size: 10px;
      }
    }
  }
  &.ant-btn > .ant-btn-loading-icon {
    display: none;
  }
  &.ant-btn > .ant-btn-loading {
    cursor: not-allowed;
  }
  &.ant-btn-icon {
    height: 49px;
    width: 49px;
    padding: 15px !important;
    > span[role='img'] > svg {
      height: 19px;
      width: 19px;
      font-size: 19px;
      line-height: 19px;
    }
  }
  &.ant-btn-primary {
    color: ${colors.GREY_200};
    background-color: ${colors.DAFFODIL};
    &:focus,
    &:hover {
      background-color: ${colors.GOLD};
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: ${colors.WHITE_200};
      color: ${colors.GREY_50};
    }
  }
  &.ant-btn-ghost {
    border: 2px solid ${colors.DAFFODIL};
    line-height: 17px;
    &:focus,
    &:hover {
      background-color: ${colors.BANANA};
      color: ${colors.GREY_200};
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      border-color: ${colors.WHITE_200};
      color: ${colors.WHITE_200};
      background: none;
    }
  }
  &.ant-btn-link {
    color: ${colors.GOLD};
    &:focus,
    &:hover {
      background-color: ${colors.BANANA};
      color: ${colors.GREY_200};
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      border-color: ${colors.WHITE_200};
      color: ${colors.WHITE_200};
      background: none;
    }
  }
  &.ant-btn-text {
    color: #0a0a0a;
    &:focus,
    &:hover {
      background: none;
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      color: ${colors.WHITE_200};
    }
  }
  &.ant-btn-sm {
    font-size: 14px;
    line-height: 16.8px;
    height: 47px;
    > span[role='img'] {
      font-size: 14px;
      > svg {
        width: 14px;
        height: 14px;
        font-size: 10px;
      }
    }
    &.ant-btn-icon {
      height: 46px;
      width: 46px;
      padding: 14px !important;
      > span[role='img'] {
        font-size: 14px;
        > svg {
          height: 18px;
          width: 18px;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
`;

const Button = ({ children, loading, size, type, ...rest }) => {
  return (
    <StyledButton
      loading={loading}
      className={type === 'icon' ? 'ant-btn-icon' : ''}
      size={size === 'regular' ? 'middle' : 'small'}
      type={type === 'secondary' ? 'ghost' : type === 'icon' ? 'primary' : type}
      {...rest}>
      {loading ? <Spin size={size === 'small' ? 'small' : 'default'} /> : children}
    </StyledButton>
  );
};
export default Button;

Button.defaultProps = {
  type: 'primary',
  block: false,
  disabled: false,
  htmlType: 'button',
  loading: false,
  size: 'regular',
  icon: null,
  target: null,
  onClick: null
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  /** Option to fit button width to its parent width */
  block: PropTypes.bool,
  /**  Disabled state of button */
  disabled: PropTypes.bool,
  /**  Set the original html type of button, see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type */
  htmlType: PropTypes.string,
  /**  Set the icon component of button */
  icon: PropTypes.node,
  /**  Set the loading status of button */
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      delay: PropTypes.number
    })
  ]),
  /** Set the size of button */
  size: PropTypes.oneOf(['regular', 'small']),
  /**  Same as target attribute of a, works when href is specified */
  target: PropTypes.string,
  /**  Can be set to primary, secondary, link, text */
  type: PropTypes.oneOf(['primary', 'secondary', 'icon', 'text', 'link']),
  /**  Set the handler to handle click event */
  onClick: PropTypes.func
};
