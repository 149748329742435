/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
export const HomeSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 22V12H15V22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const StudentSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TeacherSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M11.3417 2.22927L1.61641 7.20818C0.915083 7.56723 0.885134 8.55858 1.5635 8.95931L11.2897 14.7048C11.5987 14.8873 11.9819 14.8902 12.2937 14.7124L22.3664 8.96745C23.0623 8.57055 23.0322 7.55735 22.3141 7.20235L12.2405 2.22294C11.9568 2.08269 11.6234 2.08503 11.3417 2.22927Z"
        stroke="#C2CEED"
        strokeWidth="2"
      />
      <path
        d="M3 10V18.7583C3 19.0797 3.15052 19.3823 3.42054 19.5566C6.48455 21.5343 13.4956 23.8863 20.5416 19.561C20.8289 19.3847 21 19.067 21 18.7299V10"
        stroke="#C2CEED"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CalendarSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
        stroke="#c2ceed"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 2V6" stroke="#c2ceed" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2V6" stroke="#c2ceed" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 10H21" stroke="#c2ceed" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const AssignmentSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ResourcesSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReportingSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="M18 20V10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 20V4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 20V14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const StartSvg = ({ className }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GiftSvg = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="M20 12V22H4V12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 7H2V12H22V7Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 22V7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CyberSecureSvg = ({ color }) => {
  return (
    <svg width="47" height="49" viewBox="0 0 47 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0423 22.2516L27.2609 20.0872L29.3645 16.3968L36.4824 20.4843L39.3233 15.4547L32.1666 11.375L33.719 8.63438L43.6702 14.3024L46.5732 9.27281L30.2105 0L20.7795 16.5914L19.4987 18.8571C12.2877 16.716 4.7506 19.1373 1.52929 25.1324C-2.28194 32.1395 1.26538 41.4824 9.46224 45.967C17.6591 50.4516 27.3851 48.3027 31.1963 41.2955C34.5496 35.0747 32.2132 27.1177 26.0423 22.2516ZM12.5128 40.3301C7.93309 37.8465 5.94597 32.63 8.08057 28.6904C10.2152 24.7509 15.6487 23.5752 20.2206 26.0666C24.7925 28.5581 26.7797 33.7667 24.6528 37.7453C22.526 41.7238 17.0769 42.8215 12.5128 40.3301Z"
        fill={color}
      />
    </svg>
  );
};

export const CyberSafeSvg = ({ color }) => {
  return (
    <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0671 14.2715L17.0389 0H30.4005L31.7698 14.2275L43.8174 9.37844L49.0516 17.0487L40.5929 25.6778L49.7473 34.4501L44.3474 42.1754L32.609 36.4227L31.5048 47.5755H17.0389L15.9677 37.8334L6.45997 42.2526L1.23678 34.5823L10.7556 25.7218L0 18.8671L4.18518 9.58783L16.0671 14.2715Z"
        fill={color}
      />
    </svg>
  );
};

export const CyberPostiveSvg = ({ color }) => {
  return (
    <svg width="56" height="37" viewBox="0 0 56 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 36.8941H18.9618C18.9618 36.8941 19.8198 13.608 50.7078 20.4548L55.178 4.92503C53.3059 3.82975 51.3318 2.91885 49.2836 2.20516C25.74 -6.37485 0.37752 11.1541 0 36.2421V36.8941Z"
        fill={color}
      />
    </svg>
  );
};

export const CyberKindSvg = ({ color }) => {
  return (
    <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.6812 5.52017C24.7055 4.07974 26.018 2.86792 27.5355 1.96159C29.0529 1.05527 30.7422 0.474232 32.4961 0.255337C34.7818 -0.160049 37.1382 0.071658 39.2992 0.924313C41.4603 1.77697 43.34 3.21663 44.7264 5.08085C46.1304 6.78322 47.0265 8.84659 47.3123 11.0347C47.598 13.2227 47.2617 15.447 46.3419 17.4529C44.1527 22.2909 40.8738 26.5568 36.7618 29.917C32.9425 33.3041 28.8256 36.2943 24.8008 39.4688C24.5711 39.743 24.2463 39.9201 23.8914 39.9648C23.5366 40.0095 23.178 39.9184 22.8876 39.7097C16.2156 35.584 10.2251 30.4469 5.13025 24.4821C2.60162 21.6163 0.888633 18.1242 0.170107 14.3705C-0.310898 11.089 0.528916 7.7507 2.50542 5.08747C4.48193 2.42423 7.43384 0.653397 10.714 0.16322C10.9478 0.12779 11.1816 0.0994463 11.4225 0.0781886C13.7646 -0.179867 16.1332 0.18787 18.2867 1.14389C20.4402 2.09992 22.3017 3.61002 23.6812 5.52017Z"
        fill={color}
      />
    </svg>
  );
};

export const CyberInformedSvg = ({ color }) => {
  return (
    <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.9177 31.6298C40.7362 28.4276 41.7139 24.8169 41.7595 21.1346C41.7595 17.0247 40.5408 13.0071 38.2574 9.58981C35.9741 6.17253 32.7286 3.50909 28.9316 1.93629C25.1345 0.363496 20.9563 -0.04802 16.9254 0.753786C12.8944 1.55559 9.19174 3.53471 6.28559 6.44086C3.37943 9.34701 1.40032 13.0497 0.598513 17.0806C-0.203293 21.1116 0.208222 25.2898 1.78102 29.0868C3.35382 32.8839 6.01726 36.1293 9.43454 38.4127C12.8518 40.696 16.8694 41.9148 20.9794 41.9148C24.5662 41.8547 28.0848 40.9249 31.2332 39.2053L31.8015 39.7737C33.1134 41.1092 34.5499 42.3163 36.0915 43.3785L39.0111 45.3638C39.1269 45.4512 39.2713 45.4918 39.4157 45.4774C39.56 45.4629 39.6936 45.3946 39.7897 45.286L46.0183 38.092C46.0691 38.0319 46.1074 37.9624 46.131 37.8874C46.1546 37.8124 46.163 37.7335 46.1558 37.6552C46.1485 37.5769 46.1258 37.5009 46.0888 37.4315C46.0518 37.3621 46.0014 37.3008 45.9404 37.2511L45.7147 37.002C43.5612 35.0737 41.2913 33.2795 38.9177 31.6298ZM21.0105 33.3816C18.5861 33.3831 16.2157 32.6655 14.1992 31.3195C12.1827 29.9736 10.6108 28.0597 9.6823 25.8201C8.7538 23.5806 8.51046 21.1159 8.98306 18.738C9.45567 16.3601 10.623 14.1758 12.3373 12.4614C14.0516 10.7471 16.2359 9.57979 18.6138 9.10719C20.9918 8.63459 23.4564 8.87793 25.696 9.80643C27.9356 10.7349 29.8494 12.3069 31.1954 14.3233C32.5414 16.3398 33.259 18.7102 33.2575 21.1346C33.2565 22.7453 32.9379 24.3399 32.3202 25.8273C31.7024 27.3148 30.7975 28.6658 29.6571 29.8033C28.5168 30.9407 27.1634 31.8422 25.6744 32.4562C24.1854 33.0702 22.59 33.3847 20.9794 33.3816H21.0105Z"
        fill={color}
      />
    </svg>
  );
};

export const CyberHealthySvg = ({ color }) => {
  return (
    <svg width="58" height="50" viewBox="0 0 58 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6080_5480)">
        <path
          d="M47.1194 0H14.8549C13.6508 0.00662579 12.4985 0.49015 11.6502 1.34467C10.8019 2.19919 10.3269 3.35506 10.3291 4.55912V45.4325C10.3247 46.638 10.7987 47.796 11.6473 48.6524C12.4958 49.5087 13.6494 49.9934 14.8549 50H47.1194C47.717 49.9978 48.3083 49.8779 48.8596 49.6472C49.4109 49.4165 49.9114 49.0794 50.3324 48.6553C50.7534 48.2312 51.0868 47.7283 51.3134 47.1753C51.5401 46.6223 51.6556 46.0301 51.6535 45.4325V4.55912C51.6557 3.35362 51.1795 2.1965 50.3294 1.34174C49.4794 0.486974 48.3249 0.0044218 47.1194 0V0Z"
          fill={color}
        />
        <path
          d="M45.5412 7.67537e-06H14.8549C13.6508 0.00663346 12.4985 0.490157 11.6502 1.34468C10.8019 2.1992 10.3269 3.35507 10.3291 4.55913V43.2448C10.3269 43.8414 10.4423 44.4325 10.6685 44.9844C10.8948 45.5364 11.2275 46.0383 11.6478 46.4617C12.068 46.8851 12.5675 47.2215 13.1178 47.4518C13.6681 47.6821 14.2583 47.8018 14.8549 47.8039H45.5412C46.1406 47.8062 46.7344 47.6897 47.2885 47.4614C47.8427 47.2331 48.3461 46.8973 48.7699 46.4735C49.1937 46.0497 49.5295 45.5463 49.7578 44.9921C49.9861 44.438 50.1025 43.8442 50.1003 43.2448V4.55913C50.1014 3.9601 49.9843 3.36676 49.7555 2.81313C49.5268 2.25949 49.191 1.75646 48.7675 1.33289C48.3439 0.909317 47.8409 0.573537 47.2872 0.344809C46.7336 0.116082 46.1402 -0.00109246 45.5412 7.67537e-06Z"
          fill={color}
        />
        <path d="M45.5409 4.00781H14.8545V39.7627H45.5409V4.00781Z" fill="#F3F7FF" />
        <path
          d="M30.2022 45.8083C30.6009 45.8117 30.9916 45.6964 31.3247 45.4773C31.6579 45.2582 31.9184 44.9451 32.0733 44.5777C32.2281 44.2103 32.2704 43.8052 32.1946 43.4137C32.1189 43.0223 31.9285 42.6621 31.6478 42.379C31.367 42.0959 31.0085 41.9026 30.6177 41.8236C30.2269 41.7446 29.8214 41.7835 29.4527 41.9353C29.084 42.0871 28.7688 42.345 28.5469 42.6763C28.325 43.0075 28.2066 43.3973 28.2066 43.796C28.2055 44.0592 28.2562 44.32 28.3559 44.5635C28.4556 44.8071 28.6023 45.0286 28.7876 45.2155C28.9729 45.4023 29.1932 45.5509 29.436 45.6526C29.6787 45.7543 29.939 45.8072 30.2022 45.8083Z"
          fill="#F3F7FF"
        />
        <path
          d="M30.1769 16.9087C30.5238 16.4083 30.9701 15.9848 31.4879 15.6645C32.0888 15.3108 32.7605 15.0944 33.4549 15.0309C34.1493 14.9674 34.8491 15.0583 35.5042 15.2971C36.24 15.5884 36.8833 16.0731 37.3663 16.6999C37.8445 17.2888 38.1481 18 38.2425 18.7528C38.337 19.5055 38.2186 20.2696 37.9007 20.9584C37.1585 22.6197 36.0409 24.086 34.6358 25.242C33.3416 26.386 31.9304 27.4297 30.5694 28.5236C30.4862 28.6084 30.3778 28.664 30.2604 28.6822C30.1389 28.6943 30.0172 28.6617 29.9181 28.5904C27.6534 27.1793 25.6246 25.4211 23.906 23.38C23.0362 22.4069 22.4347 21.2242 22.1609 19.9481C22.0114 18.792 22.3267 17.6238 23.0376 16.6999C23.4003 16.2446 23.8485 15.8645 24.3569 15.581C24.8743 15.3049 25.4425 15.1372 26.0269 15.0884C26.8244 14.9931 27.6328 15.1141 28.3674 15.4388C29.102 15.7635 29.7356 16.2798 30.202 16.9337L30.1769 16.9087Z"
          fill={color}
        />
        <path d="M4.54255 40.4976L7.04756 43.5119L3.20654 44.5223L4.54255 40.4976Z" fill={color} />
        <path
          d="M3.09786 36.7734C4.80876 36.7734 6.19572 35.3864 6.19572 33.6755C6.19572 31.9646 4.80876 30.5776 3.09786 30.5776C1.38696 30.5776 0 31.9646 0 33.6755C0 35.3864 1.38696 36.7734 3.09786 36.7734Z"
          fill={color}
        />
        <path
          d="M55.7783 4.12493C56.4608 4.12493 57.0141 3.57164 57.0141 2.88913C57.0141 2.20661 56.4608 1.65332 55.7783 1.65332C55.0958 1.65332 54.5425 2.20661 54.5425 2.88913C54.5425 3.57164 55.0958 4.12493 55.7783 4.12493Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6080_5480">
          <rect width="57.0224" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Card = ({ color = '#C2CEED', ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 10H23" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SupportSvg = ({ color = 'inherit', className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M3 18V12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V18"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H18C17.4696 21 16.9609 20.7893 16.5858 20.4142C16.2107 20.0391 16 19.5304 16 19V16C16 15.4696 16.2107 14.9609 16.5858 14.5858C16.9609 14.2107 17.4696 14 18 14H21V19ZM3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H6C6.53043 21 7.03914 20.7893 7.41421 20.4142C7.78929 20.0391 8 19.5304 8 19V16C8 15.4696 7.78929 14.9609 7.41421 14.5858C7.03914 14.2107 6.53043 14 6 14H3V19Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GameSvg = ({ color = 'inherit', className }) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
      <path d="m7.5 4.21 4.5 2.6 4.5-2.6M7.5 19.79V14.6L3 12M21 12l-4.5 2.6v5.19M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12" />
    </g>
  </svg>
);

export const ClassSvg = ({ color = 'inherit', className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M11.3417 2.22927L1.61641 7.20818C0.915083 7.56723 0.885134 8.55858 1.5635 8.95931L11.2897 14.7048C11.5987 14.8873 11.9819 14.8902 12.2937 14.7124L22.3664 8.96745C23.0623 8.57055 23.0322 7.55735 22.3141 7.20235L12.2405 2.22294C11.9568 2.08269 11.6234 2.08503 11.3417 2.22927Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M3 10V18.7583C3 19.0797 3.15052 19.3823 3.42054 19.5566C6.48455 21.5343 13.4956 23.8863 20.5416 19.561C20.8289 19.3847 21 19.067 21 18.7299V10"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);
