import React from 'react';
import PropTypes from 'prop-types';
import { UpdateCard } from '@pages/MembershipAndBilling';
import { EducatorModal } from '@components/commonComponents';

export const EditCardModal = ({ show, handleOnCancel, handleEditCard, singleUser, setSingleUser }) => {
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div className="">
          <UpdateCard closeModal={handleEditCard} userInfo={singleUser} setSingleUser={setSingleUser} />
        </div>
      }
    />
  );
};

export default EditCardModal;

EditCardModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleEditCard: PropTypes.func.isRequired,
  singleUser: PropTypes.object.isRequired,
  setSingleUser: PropTypes.func.isRequired
};
