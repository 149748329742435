import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { Navbar } from '@components/layoutComponents';
import { EducatorButton, EducatorInputField } from '@components/commonComponents';

import './ForgetPasswordStyle.scss';

const ForgetPassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'reset_password' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const forgotPasswordFunction = () => {
    setButtonSpinner(true);
    Auth.forgotPassword(email)
      .then(() => {
        setButtonSpinner(false);
        navigate('/reset-password', { state: email });
      })
      .catch(err => {
        setButtonSpinner(false);
        message.error(`${err.message}`);
      });
  };

  const onChangeHandle = e => {
    const { value } = e.target;
    setEmail(value);
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5 w-100">
        <div className="mt-2">
          <div className="textStyleHeading">{t('password_reset')}</div>
          <div className="mt-1 forgetPasswordTextStyle mb-3">{t('password_recovery_instructions')}</div>
          <Form onFinish={forgotPasswordFunction}>
            <Form.Item className="pt-3" name="email" rules={[{ required: true, message: commonT('input_email') }]}>
              <EducatorInputField
                label={t('email_address')}
                type="email"
                name="email"
                inputValue={email}
                onChange={onChangeHandle}
              />
            </Form.Item>
            <div className="pt-3">
              <EducatorButton
                label={t('send_reset_email')}
                type="submit"
                showSpinner={buttonSpinner}
                dataTestId="sendResetEmailButton"
              />
            </div>
          </Form>

          <div className=" mt-3 textStyleForgetPassword">
            <span className="textStyleOrStyle">{commonT('or')}</span>
            <span
              className="cursorPointer"
              onClick={() => {
                navigate(-1);
              }}>
              {t('return_to_login')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgetPassword;
