import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL, sendGaEvent } from '@utils';

import './RelatedEdubytes.scss';
import { EdubyteCard } from './EdubyteCard';

export const RelatedEdubytes = ({ itemId }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const navigate = useNavigate();

  const { data: suggestedActivitiesResponse } = useSWR(`${API_URL.SCHOOL_EDUBYTES}/${itemId}/related`);
  let suggestedActivities = suggestedActivitiesResponse?.data || [];

  if (suggestedActivities.length > 4) {
    suggestedActivities = suggestedActivities.slice(0, 4);
  }

  return (
    <div className="edubyte-related__container">
      <div className="wrapper">
        <h3>{t('other_playlist')}:</h3>
        <div className="edubyte-related-card__container row">
          {suggestedActivities?.map(item => (
            <div key={item.id} className="col-12 col-sm-6 col-md-4 col-xl-3">
              <EdubyteCard
                image={item.thumbnail}
                title={item.name}
                videos={item.videos}
                onClick={() => {
                  sendGaEvent('other_resource_card_click', 'edubyte__other_resource_card_click', item.name);
                  navigate(`/edubytes/${item.id}`, {
                    state: {
                      itemId: item.id,
                      title: item.name
                    }
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RelatedEdubytes.propTypes = {
  itemId: PropTypes.string.isRequired
};
