import React, { useEffect, useRef, useState } from 'react';
import { Card, Spin } from 'antd';
import HTMLFlipBook from 'react-pageflip';
import { Home } from 'react-feather';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL, getApiWithAuth } from '@utils';

import { RankPage } from './RankPage';
import { BadgePage } from './BadgePage';
import { MissionBadgePage } from './MissionBadgePage';
import { LOADING_STATE } from './Common';

import BeSafe from '@assets/images/cyber-academy/Be Cyber Safe.svg';
import BePositive from '@assets/images/cyber-academy/Be Cyber Positive.svg';
import BeKind from '@assets/images/cyber-academy/Be Cyber Kind.svg';
import BeSecure from '@assets/images/cyber-academy/Be Cyber Secure.svg';
import BeInformed from '@assets/images/cyber-academy/Be Cyber Informed.svg';
import BeHealthy from '@assets/images/cyber-academy/Be Cyber Healthy.svg';

const getBookmarkImage = badgeName => {
  const bookmarkMap = {
    'Be Cyber Safe': BeSafe,
    'Be Cyber Positive': BePositive,
    'Be Cyber Kind': BeKind,
    'Be Cyber Secure': BeSecure,
    'Be Cyber Informed': BeInformed,
    'Be Cyber Healthy': BeHealthy
  };

  const frenchBookmarkMap = {
    'Soyez en sécurité sur Internet': 'Be Cyber Safe',
    'Soyez cyber-positif': 'Be Cyber Positive',
    'Soyez cyber-gentils': 'Be Cyber Kind',
    'La cybersécurité': 'Be Cyber Secure',
    'Soyez cyber-informés': 'Be Cyber Informed',
    'Soyez en bonne santé sur Internet': 'Be Cyber Healthy'
  };

  return bookmarkMap[badgeName] || bookmarkMap[frenchBookmarkMap[badgeName]];
};

const BadgeLoadingPage = () => (
  <div className="badge_booklet__container">
    <div className="badge_booklet__content">
      <div className="emptyMessageStudent">
        <Spin size="large" />
      </div>
    </div>
  </div>
);

const NoProfilePage = () => {
  const { t } = useTranslation('student', { keyPrefix: 'badge_booklet' });

  return (
    <div className="badge_booklet__container">
      <div className="badge_booklet__content">
        <div className="emptyMessageStudent">
          <h3>{t('no_profile')}</h3>
        </div>
      </div>
    </div>
  );
};

export const BadgeBooklet = () => {
  const { t } = useTranslation('student', { keyPrefix: 'badge_booklet' });
  const [missionsMap, setMissionsMap] = useState(new Map());
  const [studentGrade, setStudentGrade] = useState(null);
  const [activeGrade, setActiveGrade] = useState(null);
  const [activeBadge, setActiveBadge] = useState(null);
  const [bookPages, setBookPages] = useState([]);
  const flipBookRef = useRef(null);
  const [availableMissions, setAvailableMissions] = useState({});

  const { data: badgesData } = useSWR(`${API_URL.GET_STUDENT_BADGES}`);
  const { data: profileData, isLoading: isProfileLoading } = useSWR(`${API_URL.GET_STUDENT_PROFILE}`);
  const { data: gradesData, isLoading: isBadgeDataLoading } = useSWR(`${API_URL.GET_STUDENT_GRADES}`);
  const { data: badgeAchievementsData } = useSWR(`${API_URL.GET_STUDENT_BADGES_ACHIEVEMENTS}`);
  const badges = badgesData?.data;
  const profile = profileData?.data;
  const grades = gradesData?.data;
  const badgeAchievements = badgeAchievementsData?.data;

  const getMissions = async () => {
    if (!activeGrade || !activeBadge?.id) return;

    const missionFound = availableMissions[activeBadge.id]?.find(item => item.grade === activeGrade);
    const nextAvailableMission = availableMissions[activeBadge.id]?.[0];
    if (!missionFound && !nextAvailableMission) {
      const updatedMissionsMap = new Map(missionsMap);
      updatedMissionsMap.set(activeGrade, {
        missions: [],
        state: LOADING_STATE.ERROR
      });

      setMissionsMap(updatedMissionsMap);
      return;
    }

    const gradeValue = missionFound?.grade ?? nextAvailableMission?.grade;

    const { success, data } = await getApiWithAuth(
      `${API_URL.GET_STUDENT_MISSIONS_ACHIEVEMENTS}?grade=${gradeValue}&badge_id=${activeBadge.id}`
    );
    const updatedMissionsMap = new Map(missionsMap);

    if (success) {
      updatedMissionsMap.set(gradeValue, {
        missions: data,
        state: LOADING_STATE.SUCCESS,
        badgeImage: missionFound?.image || nextAvailableMission?.image
      });
    } else {
      updatedMissionsMap.set(gradeValue, {
        missions: [],
        state: LOADING_STATE.ERROR
      });
    }
    setActiveGrade(gradeValue);
    setMissionsMap(updatedMissionsMap);
  };

  const handleBookmarkClick = item => {
    setActiveBadge(item);
    flipBookRef.current.pageFlip().turnToPage(2);
  };

  const handleNextPage = () => {
    const nextGradeIndex = grades.findIndex(grade => grade.name === activeGrade) + 1;
    if (nextGradeIndex < grades.length) {
      setActiveGrade(grades[nextGradeIndex].name);
    }
  };

  const handlePreviousPage = () => {
    const previousGradeIndex = grades.findIndex(grade => grade.name === activeGrade) - 1;
    if (previousGradeIndex >= 0) {
      setActiveGrade(grades[previousGradeIndex].name);
    }
  };

  const resetMissionsMap = () => {
    const updatedMissionsMap = grades.reduce((map, item) => {
      map.set(item.name, {
        missions: [],
        state: LOADING_STATE.LOADING
      });
      return map;
    }, new Map());

    setMissionsMap(updatedMissionsMap);
  };

  useEffect(() => {
    if (!badgeAchievements) return;

    const missions = badgeAchievements.reduce((acc, achievement) => {
      const badgeId = achievement.sub_heading.id;
      const badge = achievement.sub_heading.name;
      const grade = achievement.sub_heading_grade.grade.name;
      const cyberName = achievement.sub_heading_grade.grade.cyber_name;
      const image = achievement.achievement.image;

      if (!acc[badgeId]) {
        acc[badgeId] = [];
      }

      acc[badgeId].push({
        badgeId,
        badge,
        grade,
        image,
        cyberName
      });

      acc[badgeId] = acc[badgeId].sort((a, b) => a.grade.localeCompare(b.grade));

      return acc;
    }, {});

    setAvailableMissions(missions);
  }, [badgeAchievements]);

  useEffect(() => {
    if (profileData) {
      const userGrade = profileData.data['grade'];
      setStudentGrade(userGrade);

      if (activeGrade === null) {
        setActiveGrade(userGrade);
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (gradesData) {
      const grades = gradesData.data;
      const updatedMissionsMap = grades.reduce((map, item) => {
        map.set(item.name, {
          missions: [],
          state: LOADING_STATE.LOADING
        });
        return map;
      }, new Map());

      setMissionsMap(updatedMissionsMap);
    }
  }, [gradesData]);

  useEffect(() => {
    if (grades && activeBadge) {
      resetMissionsMap();
      getMissions();
    }
  }, [activeBadge, activeGrade]);

  useEffect(() => {
    if (!grades || !activeGrade) return;

    const updatedBookPages = [];

    updatedBookPages.push(<RankPage key={0} />, <BadgePage onBadgeClick={handleBookmarkClick} key={1} />);

    if (grades.length > 0 && missionsMap.size > 0) {
      const grade = grades.find(grade => grade.name === activeGrade);
      const { state, missions, badgeImage } = missionsMap.get(grade.name);
      const missionsCompleted = missions?.filter(mission => mission.user_achievement_id).length || 0;

      const previousGradeMission = availableMissions[activeBadge?.id]?.find(
        item => item.grade === grades[grades.findIndex(grade => grade.name === activeGrade) - 1]?.name
      );
      const nextGradeMission = availableMissions[activeBadge?.id]?.find(
        item => item.grade === grades[grades.findIndex(grade => grade.name === activeGrade) + 1]?.name
      );

      updatedBookPages.push(
        <MissionBadgePage
          grade={grade.cyber_name}
          missions={missions.slice(0, 6)}
          showHeader
          state={state}
          key={2}
          activeGrade={grades.find(grade => grade.name === studentGrade)?.cyber_name}
          previousButton={previousGradeMission}
          handlePreviousPage={handlePreviousPage}
          progress={{ completed: missionsCompleted, total: missions.length }}
          badgeImage={badgeImage}
        />
      );

      updatedBookPages.push(
        <MissionBadgePage
          grade={grade.name}
          missions={missions.slice(6)}
          showHeader={false}
          state={state}
          key={3}
          activeGrade={activeGrade}
          nextButton={nextGradeMission}
          handleNextPage={handleNextPage}
        />
      );

      setBookPages(updatedBookPages);
    }
  }, [missionsMap, activeGrade]);

  if (isBadgeDataLoading || isProfileLoading) {
    return <BadgeLoadingPage />;
  }

  if (!profile) {
    return <NoProfilePage />;
  }

  return (
    <div className="badge_booklet__container">
      <div className="badge_booklet__content">
        {
          <div className="d-flex flex-row">
            <div className="flex-grow-1" style={{ overflow: 'hidden' }}>
              <HTMLFlipBook
                width={800}
                minWidth={400}
                height={1200}
                minHeight={700}
                size="stretch"
                mobileScrollSupport
                disableFlipByClick
                maxShadowOpacity={0}
                className="badge_booklet__flipbook"
                ref={flipBookRef}
                drawShadow={false}
                useMouseEvents={false}>
                {bookPages}
              </HTMLFlipBook>
            </div>
            {badges && (
              <div className="badge_booklet__bookmarks">
                <div className="bookmarks__top">
                  {badges.map(badge => (
                    <Card
                      key={badge.id}
                      className={`bookmark__item ${activeBadge?.id === badge.id ? 'bookmark__item--active' : ''}`}
                      onClick={() => handleBookmarkClick(badge)}>
                      <img src={getBookmarkImage(badge.name)} alt={badge.name} />
                      <span>{badge.name}</span>
                    </Card>
                  ))}
                </div>
                <div className="bookmarks__bottom">
                  <Card
                    className={`bookmark__item bookmark__item--progress ${
                      activeBadge === null ? 'bookmark__item--active' : ''
                    }`}
                    onClick={() => {
                      flipBookRef.current.pageFlip().turnToPage(0);
                      setActiveBadge(null);
                    }}>
                    <Home color={'#A96300'} />
                    <span>{t('progress')}</span>
                  </Card>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};
