/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Navbar } from '../../layoutComponents';
import { EducatorButton } from '../../commonComponents';
import { useNavigate } from 'react-router-dom';
import award from '../../../assets/images/award.svg';
import { patchApiWithAuth, getApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import { setFlowComplete, getUserID } from '@utils';
import { useAppState } from '../../../context';
import './parentSignIn.css';
const ParentLastStep = () => {
  const { fetchCurrentUser } = useAppState();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [paidChilds, setPaidChilds] = useState([]);

  const navigate = useNavigate();
  const parentFlowComplete = async () => {
    setButtonSpinner(true);
    const { success } = await patchApiWithAuth(API_URL.UPDATE_USER_AUTH, { is_auth: true });
    if (success) {
      fetchCurrentUser();
      setFlowComplete(true);
      setButtonSpinner(false);
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    getPaidUsers();
  }, []);

  const getPaidUsers = async () => {
    const parent_id = getUserID();
    const { success, data } = await getApiWithAuth(`payments/parent/${parent_id}/subscribed-children`);
    if (success) {
      setPaidChilds(data.subscribed_children);
    }
  };
  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5">
        <div className="mt-2 w-50">
          <div className="d-flex justify-content-center">
            <img src={award} alt="award" width="110px" height="101px" />
          </div>
          <div className="textStyleHeading mt-3">You’re ready to start using premium!</div>
          <div className="mt-2 textStyle400Cabin18">
            You just signed{' '}
            {paidChilds.map(item => {
              return `${item.first_name.toUpperCase()}. ${item.last_name.toUpperCase()} , `;
            })}{' '}
            up for a premium membership. We’ve sent a receipt to your email. Enjoy the game!
          </div>
          <div className="pt-4 d-flex justify-content-center">
            <EducatorButton
              label="Sign Into the Dashboard"
              onClick={parentFlowComplete}
              type="button"
              width="276px"
              showSpinner={buttonSpinner}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentLastStep;
