import React from 'react';
import PropTypes from 'prop-types';

import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import catamaranRegular from '@assets/fonts/Catamaran-Regular.ttf';
import catamaranBold from '@assets/fonts/Catamaran-Bold.ttf';
import lexendRegular from '@assets/fonts/Lexend-Regular.ttf';
import lexendBold from '@assets/fonts/Lexend-Bold.ttf';

Font.register({
  family: 'Catamaran',
  fonts: [
    {
      src: catamaranRegular,
      fontWeight: 'normal'
    },
    {
      src: catamaranBold,
      fontWeight: 'bold'
    }
  ]
});

Font.register({
  family: 'Lexend',
  fonts: [
    {
      src: lexendRegular,
      fontWeight: 'normal'
    },
    {
      src: lexendBold,
      fontWeight: 'bold'
    }
  ]
});

export const resultPdfStyles = StyleSheet.create({
  page: {
    padding: 50
  },
  header: {
    marginBottom: 20
  },
  classRow: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    marginBottom: 10,
    width: '100%',
    justifyContent: 'space-between'
  },
  className: {
    fontSize: '16px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '100%',
    color: '#222'
  },
  todayDate: {
    fontSize: '16px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#222'
  },
  assignmentName: {
    marginBottom: 5,
    fontSize: '28px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#222'
  },
  table: {
    display: 'table',
    width: '100%',
    margin: '0 auto'
  },
  tableRow: {
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  tableHeaderCell: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#CCC8C7',
    padding: 5
  },
  overallRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  overallHeaderCell: {
    padding: 5
  },
  tableHeaderText: {
    fontSize: '16px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#222'
  },
  tableCell: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#CCC8C7',
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10
  },
  studentCell: {
    width: '45%'
  },
  learningGoalCell: {
    width: '60%'
  },
  studentCellText: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    color: '#222'
  },
  outcomeCell: {
    width: '40%'
  },
  learningGoalOutcomeCell: {
    width: '25%',
    textAlign: 'center'
  },
  resultCell: {
    width: '15%'
  },
  resultCellRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  resultCellText: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    width: 'auto',
    paddingRight: 7,
    paddingLeft: 7,
    borderRadius: 14
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
});

export const listItemStyles = StyleSheet.create({
  list: {
    marginBottom: 10
  },
  listItem: {
    flexDirection: 'row'
  },
  bulletPoint: {
    width: 10,
    marginLeft: 10
  },
  itemContent: {
    flex: 1,
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#3B3D3C'
  }
});

export const ResultPdfListItem = ({ children }) => (
  <View style={listItemStyles.listItem}>
    <Text style={listItemStyles.bulletPoint}>•</Text>
    <Text style={listItemStyles.itemContent}>{children}</Text>
  </View>
);

ResultPdfListItem.propTypes = {
  children: PropTypes.node.isRequired
};
