import Stack from '../Stack/Stack';
import Title from '../Typography/Title';
import Text from '../Typography/Text';
import Colors from '../../theme/colors';
import PropTypes from 'prop-types';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Calendar as CalendarIcon,
  List as ListIcon
} from 'react-feather';
import { Views } from 'react-big-calendar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledButton = styled.div`
  cursor: pointer;
  padding: 10px;
  height: 44px;
  border: 2px solid ${Colors.LAVENDER_50};
  border-radius: 30px;
  border-color: ${props => (props.focused ? Colors.VIOLET : Colors.LAVENDER_50)};
  svg {
    vertical-align: top;
  }
  :hover {
    border-color: ${Colors.VIOLET};
  }
`;
const CommonIconProps = { size: 20, color: Colors.VIOLET, verticalAlign: 'top' };

const CustomToolbar = ({ label, onView, view, onNavigate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });
  return (
    <Stack justifyContent="space-between" style={{ paddingBottom: 20 }}>
      <Stack>
        <StyledButton
          onClick={() => onNavigate('TODAY')}>
          <Text size="x-small" ellipsis>
            {t('today')}
          </Text>
        </StyledButton>
        <StyledButton
          onClick={() => onNavigate('PREV')}>
          <ChevronLeftIcon {...CommonIconProps} />
        </StyledButton>
        <StyledButton
          onClick={() => onNavigate('NEXT')}>
          <ChevronRightIcon {...CommonIconProps} />
        </StyledButton>
      </Stack>
      <Title level={3} style={{ whiteSpace: 'nowrap' }}>
        {label}
      </Title>
      <Stack justifyContent="flex-end">
        <StyledButton focused={view === Views.MONTH} onClick={() => onView(Views.MONTH)}>
          <CalendarIcon {...CommonIconProps} />
        </StyledButton>
        <StyledButton focused={view === Views.AGENDA} onClick={() => onView(Views.AGENDA)}>
          <ListIcon {...CommonIconProps} />
        </StyledButton>
      </Stack>
    </Stack>
  );
};

export default CustomToolbar;
CustomToolbar.propTypes = {
  label: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired
};
