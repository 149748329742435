import React, { useState } from 'react';
import { Card, Divider } from 'antd';
import useSWR from 'swr';
import { PasswordModal } from './modals/PasswordModal';
import { API_URL, fetcher } from '../../../utils';

export const LoginDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedLoginDetails, setSelectedLoginDetails] = useState({});

  const { data, isLoading } = useSWR(`${API_URL.PARENT_CHILDREN_PAGE}?page=${currentPage}`, fetcher);

  const { count, results } = data?.data || {};

  if (isLoading) {
    return <Card className="login-details cardsStyling p-4" loading={isLoading} />;
  }

  const totalPage = Array(Math.ceil(count / 5)).fill(1);

  const handleOnClick = student => {
    setSelectedLoginDetails(student);
    setShowPasswordModal(true);
  };

  const handlePage = page => {
    setCurrentPage(page);
  };

  return (
    <>
      <Card bordered={false} className="login-details cardsStyling p-4">
        <h3 className="fixCardHeadingStyle">Login Details</h3>
        <p className="mb-4">Click your children’s name below to see their login credentials.</p>
        <Divider className="dividerBilling" />
        <div className="results">
          {results?.map(item => {
            return (
              <div key={`${item.first_name}_${item.id}`}>
                <div className="spaceBetweenDiv" role="button" onClick={() => handleOnClick(item)}>
                  <span className="expStyle">
                    {item.first_name} {item.last_name}
                  </span>
                </div>
                <Divider className="dividerBilling" />
              </div>
            );
          })}
        </div>
        <div className="pagination">
          {totalPage.map((item, index) => {
            return (
              <button
                key={`${index + 1}`}
                className={`pagination-item ${index + 1 === currentPage ? 'current' : ''}`}
                onClick={() => handlePage(index + 1)}>
                {item}
              </button>
            );
          })}
        </div>
      </Card>

      <PasswordModal
        show={showPasswordModal}
        handleOnCancel={() => setShowPasswordModal(false)}
        selectedLoginDetails={selectedLoginDetails}
      />
    </>
  );
};
