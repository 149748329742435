import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '../../../commonComponents';
import deleteFrame from '../../../../assets/images/Frame.svg';

export const DeleteCardModal = ({ show, handleOnCancel, singleUser, handleDelete, showSpinner }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div className="fixCradCenter mt-4 p-3">
          <div>
            <img src={deleteFrame} alt="deleteFrame" className="cursorPointer" />

            <div className="fixCardHeadingStyle mt-3 px-5">{t('delete_credit_card_confirmation')}</div>
            <div className="unitPlanTextStyle px-2">{t('future_payments_declined')}</div>
            <div className="pt-3">
              <EducatorButton
                key="button"
                label={t('delete_credit_card')}
                type="button"
                width="100%"
                onClick={() => handleDelete(singleUser?.id)}
                showSpinner={showSpinner}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DeleteCardModal;

DeleteCardModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  singleUser: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired
};
