import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { API_URL, getApiWithAuth, patchApiWithAuth, postApiWithAuth, logoutTeacher } from '@utils';
import { useStudents } from '@hooks';
import { useAppState } from '@context';

import { StepOneModal } from './StepOneModal';
import { ExistingModal } from './ExistingModal';
import { CreateStudentsModal } from './CreateStudentsModal';
import { DoneSetupModal } from './DoneSetupModal';
import { EditStudentModal } from './EditStudentModal';
import { DeleteStudentModal } from './DeleteStudentModal';

export const StudentModalManager = ({ modal, setModal, closeFn, selectedStudent }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { state } = useAppState();

  const [studentDataById, setStudentDataById] = useState({});
  const [deleteStudent, setDeleteStudent] = useState('');
  const [studentId, setStudentId] = useState(0);

  const [createStudentsModalLoading, setCreateStudentsModalLoading] = useState(false);
  const [editStudentSpinner, setEditStudentSpinner] = useState(false);
  const [showSpinnerDeleteStudent, setShowSpinnerDeleteStudent] = useState(false);

  const [newStudents, setNewStudents] = useState([]);

  const { data: students, revalidate: getStudents } = useStudents(state.classId);

  const showExistingModal = () => {
    closeFn();
    setModal('existingStudent');
  };

  const showCreateStudentModal = () => {
    closeFn();
    setModal('createStudents');
  };
  const showAllSetup = () => {
    closeFn();
    setModal('doneSetup');
  };

  const showDeleteModal = id => {
    closeFn();
    setDeleteStudent(id);
    setModal('deleteStudent');
  };

  const showEditStudent = async id => {
    setStudentId(id);
    await getStudentById(id);
    closeFn();
    setModal('editStudent');
  };

  const getStudentById = async id => {
    const { success, data, status } = await getApiWithAuth(`${API_URL.GET_STUDENT_BY_ID}${id}/`);

    if (status === 401) {
      await logoutTeacher();
      return;
    }

    if (success) {
      setStudentDataById(data);
    }
  };

  const addStudentList = async values => {
    if (!values.names) {
      return message.error(t('enter_student_name'));
    }

    setCreateStudentsModalLoading(true);
    const { data } = await postApiWithAuth(`${API_URL.ADD_STUDENTS}${state.classId}/create-students`, values);
    if (data.success) {
      message.success(t('student_added_successfully'));
      setNewStudents([...newStudents, ...data.new_students]);
      await getStudents();
    } else {
      message.error(data.message);
    }
    setCreateStudentsModalLoading(false);
  };

  const downloadLoginCredentials = async () => {
    const { success, data, status } = await postApiWithAuth(
      `users/class/${state.classId}/new-students-account-credentials`,
      {
        new_students: newStudents
      }
    );

    if (status === 401) {
      await logoutTeacher();
      return;
    }

    if (success) {
      const newData = data.link;
      window.open(newData, '_self');
    } else {
      message.error(data.message);
    }
  };

  const printLoginInstructions = async () => {
    if (!state.classId) {
      return message.error(t('class_id_required'));
    }

    const { success, data, status } = await postApiWithAuth(
      `users/class/${state.classId}/new-students-login-instructions`,
      {
        new_students: newStudents
      }
    );

    if (status === 401) {
      await logoutTeacher();
      return;
    }

    if (success) {
      const newData = data.link;
      window.open(newData, '_self');
    } else {
      message.error(data.message);
    }
  };

  const editStudentSaveChanges = async () => {
    setEditStudentSpinner(true);
    const { success } = await patchApiWithAuth(`${API_URL.UPDATE_STUDENT_BY_ID}${studentId}`, studentDataById);
    if (success) {
      setEditStudentSpinner(false);
      message.success(
        t('student_connected_to_parent', {
          studentName: `${studentDataById.first_name} ${studentDataById.last_name}`,
          parent: studentDataById.student_parent
        })
      );
      await getStudents();
      closeFn();
    } else {
      setEditStudentSpinner(false);
      message.error(t('student_update_error'));
      closeFn();
    }
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setStudentDataById({ ...studentDataById, [name]: value });
  };

  const removeStudentFromClass = async () => {
    setShowSpinnerDeleteStudent(true);
    const { success } = await patchApiWithAuth(
      `${API_URL.DELETE_STUDENT_FROM_CLASS}${state.classId}/${deleteStudent}/`
    );
    if (success) {
      setShowSpinnerDeleteStudent(false);
      message.success(
        t('student_removed_from_class', {
          studentName: `${studentDataById.first_name} ${studentDataById.last_name}`
        })
      );
      await getStudents();
      closeFn();
    } else {
      setShowSpinnerDeleteStudent(false);
      message.error(t('student_delete_error'));
      closeFn();
    }
  };

  useEffect(() => {
    if (selectedStudent) {
      showEditStudent(selectedStudent.studentId);
    }
  }, [selectedStudent]);

  return (
    <>
      <StepOneModal
        show={modal === 'stepOne'}
        onCancel={closeFn}
        onLinkStudent={showExistingModal}
        onCreateNewStudent={showCreateStudentModal}
      />

      <ExistingModal show={modal === 'existingStudent'} onCancel={closeFn} />

      <CreateStudentsModal
        show={modal === 'createStudents'}
        onCancel={closeFn}
        handleOnFinish={addStudentList}
        showSpinner={createStudentsModalLoading}
        studentList={students}
        handleEditButton={showEditStudent}
        handleDeleteButton={showDeleteModal}
        handleNext={showAllSetup}
        getStudentsOfClass={getStudents}
        setNewStudents={setNewStudents}
      />

      <DoneSetupModal
        show={modal === 'doneSetup'}
        onCancel={closeFn}
        handleDownloadPdf={downloadLoginCredentials}
        handlePrintLoginInstruction={printLoginInstructions}
      />

      <EditStudentModal
        show={modal === 'editStudent'}
        onCancel={closeFn}
        handleOnFinish={editStudentSaveChanges}
        student={studentDataById}
        handleOnChange={onChangeHandle}
        handleDeleteClick={showDeleteModal}
        showSpinner={editStudentSpinner}
      />

      <DeleteStudentModal
        show={modal === 'deleteStudent'}
        onCancel={closeFn}
        handleOnFinish={removeStudentFromClass}
        showSpinner={showSpinnerDeleteStudent}
      />
    </>
  );
};

StudentModalManager.defaultProps = {
  setModal: () => {},
  selectedStudent: null
};

StudentModalManager.propTypes = {
  modal: PropTypes.string.isRequired,
  setModal: PropTypes.func,
  closeFn: PropTypes.func.isRequired,
  selectedStudent: PropTypes.object
};
