import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { API_URL, getApiWithAuth, postApiWithAuth, patchApiWithAuth } from '../../../utils';
import { AssignmentCardWithColor } from '../../commonComponents';
import { useSubscriptionDetails } from '../../../hooks/useSubscriptionDetails';
import * as Modal from './modals';
import { CardOnFile } from './CardOnFile';
import { AvailableCoupons } from './AvailableCoupons';

import './Subscriptions.scss';

export const Subscriptions = () => {
  const navigate = useNavigate();

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const [showCreateChildModal, setShowCreateChildModal] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [showAddCurriculumModal, setShowAddCurriculumModal] = useState(false);
  const [linkExistingModal, setLinkExistingModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [childData, setChildData] = useState({});
  const [childCurriculum, setChildCurriculum] = useState({});
  const [parentOverView, setParentOverView] = useState({});

  const getParentOverView = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PARENT_OVERVIEW);
    if (success) {
      setParentOverView(data);
    }
  };

  const editMembershipDetail = useCallback(data => {
    navigate(`/edit-membership/${data.student?.student_id}`, { state: { userInfo: data } });
  }, []);

  const showDeleteModal = useCallback(item => {
    setSingleUser(item);
    setShowDeleteAccountModal(true);
  }, []);

  const handleDeleteAccountCancel = useCallback(() => {
    setShowDeleteAccountModal(false);
  }, []);

  const deleteUser = async data => {
    setShowDeleteSpinner(true);
    const { success } = await postApiWithAuth(API_URL.DELETE_SUBSCRIPTION, {
      sub_id: data.subscription_id,
      child_id: data.student?.student_id
    });
    if (success) {
      setShowDeleteSpinner(false);
      // setSubscriptionDetail({ subscribed_children: [] });

      handleDeleteAccountCancel();
      revalidate();
    } else {
      setShowDeleteSpinner(false);
    }
  };

  const showAddChild = useCallback(() => {
    setShowAddChildModal(true);
  }, []);

  const hideAddChild = useCallback(() => {
    setShowAddChildModal(false);
  }, []);
  const showCreateChild = useCallback(() => {
    setShowAddChildModal(false);
    setShowCreateChildModal(true);
  }, []);
  const hideCreateChild = useCallback(() => {
    setShowCreateChildModal(false);
  }, []);
  const showCurriculumChild = useCallback(() => {
    setShowCreateChildModal(false);
    setShowAddCurriculumModal(true);
  }, []);
  const hideCurriculumChild = useCallback(() => {
    setShowAddCurriculumModal(false);
  }, []);

  const showDetail = useCallback(() => {
    setShowAddCurriculumModal(false);
    setShowDetailModal(true);
  }, []);
  const hideDetail = () => {
    setShowDetailModal(false);

    revalidate();
  };
  const showExistingCode = useCallback(() => {
    setShowAddChildModal(false);
    setLinkExistingModal(true);
  }, []);
  const hideExistingCode = useCallback(() => {
    setLinkExistingModal(false);
  }, []);

  const curriculumChild = async values => {
    setButtonSpinner(true);
    const body = {
      ...childCurriculum,
      country: values.country.label,
      province: values.province.label,
      province_id: values.province.value,
      child_grade: values.child_grade.value
    };

    const { success } = await patchApiWithAuth(API_URL.PARENT_CURRICULUM_SETUP, body);
    if (success) {
      showDetail();
      await getParentOverView();

      revalidate();
    }
    setButtonSpinner(false);
  };

  const createChild = async values => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_CREATE_CHILD_ACCOUNT, values);
    if (success) {
      setDetailData(data);
      setChildCurriculum({ ...childCurriculum, student_id: data.student_link_id });
      setChildData({ ...childData, ...values });
      showCurriculumChild();
    }
    setButtonSpinner(false);
  };

  useEffect(() => {
    getParentOverView();
  }, []);

  const { data: subscriptionDetail, isLoading, revalidate, isValidating } = useSubscriptionDetails();

  if (isLoading) {
    return (
      <>
        <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle py-5">
          <Skeleton active />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="subscriptions__container container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle py-5">
        <AuthenticatedNavbar title="Subscriptions" playButton />
        <div className="unitPlanTextStyle row">
          <div className="col-7">
            <div>
              This is where you can update all of your billing details and details for your children’s accounts -
              including resetting their password.
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-sm-7  pb-3 ">
            <div>
              <div className="fixCardHeadingStyle">Your Users:</div>
              <div className="pb-3">
                {subscriptionDetail?.subscribed_children?.map(item => {
                  return (
                    <div key={item.subscription_id}>
                      <AssignmentCardWithColor
                        membership
                        title={`${item.student.first_name} ${item.student.last_name.substring(0, 1).toUpperCase()}`}
                        color={item.plan_type.plan === 'Premium' ? '#F8CA42' : '#4B3AD3'}
                        isFree={item.plan_type.plan !== 'Premium'}
                        viewReports={() => editMembershipDetail(item)}
                        deleteAssignment={() => showDeleteModal(item)}
                        userInfo={item}
                        loading={isValidating}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="add-membership__button">
                <button onClick={showAddChild}>+ Add Another Membership</button>
              </div>
            </div>
          </div>
          <div className="col col-sm-5 pb-3">
            <CardOnFile />
            <AvailableCoupons />
          </div>
        </div>
      </div>
      <Modal.DeleteAccountModal
        show={showDeleteAccountModal}
        handleOnCancel={handleDeleteAccountCancel}
        singleUser={singleUser}
        handleDeleteUser={deleteUser}
        showSpinner={showDeleteSpinner}
      />

      <Modal.AddChildModal
        show={showAddChildModal}
        handleOnCancel={hideAddChild}
        handleCreateChild={showCreateChild}
        handleExistingChild={showExistingCode}
      />

      <Modal.CreateChildModal
        show={showCreateChildModal}
        handleOnCancel={hideCreateChild}
        handleOnSubmit={createChild}
        showSpinner={buttonSpinner}
      />

      <Modal.AddCurriculumModal
        show={showAddCurriculumModal}
        handleOnCancel={hideCurriculumChild}
        handleOnSubmit={curriculumChild}
        childData={childData}
        showSpinner={buttonSpinner}
      />

      <Modal.ShowDetailsModal
        show={showDetailModal}
        handleOnCancel={hideDetail}
        handleOnSubmit={hideDetail}
        childData={detailData}
      />

      <Modal.LinkExistingModal
        show={linkExistingModal}
        parentOverView={parentOverView}
        handleOnCancel={hideExistingCode}
      />
    </>
  );
};
