/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Navbar } from '../../layoutComponents';
import { EducatorButton } from '../../commonComponents';
import { useNavigate } from 'react-router-dom';
import { patchApiWithAuth, getApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import { getUserID } from '../../../utils/sessionStorage';
import { Checkbox } from 'antd';

const ParentCancelMembership = () => {
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [paidChilds, setPaidChilds] = useState([]);
  const [checkboxData, setCheckboxData] = useState([]);

  const navigate = useNavigate();

  const updateMembership = async () => {
    setButtonSpinner(true);
    const { success, data } = await patchApiWithAuth(API_URL.DOWNGRADE_SUBSCRIPTION, {
      plan: 'monthly',
      child_list: checkboxData
    });
    if (success) {
      setButtonSpinner(false);
      navigate('/downgrade-subscription-confirm', { state: { date: data.ending_date } });
    }
  };

  useEffect(() => {
    getUserSubscriptionDetail();
  }, []);

  const getUserSubscriptionDetail = async () => {
    const parent_id = getUserID();
    const { success, data } = await getApiWithAuth(`payments/parent/${parent_id}/subscription-details`);
    if (success) {
      setPaidChilds(data.subscribed_children);
    }
  };

  const onChange = event => {
    const value = event.target.id;
    if (!checkboxData.some(item => item === value)) {
      setCheckboxData([...checkboxData, value]);
    } else {
      const filteredArray = checkboxData.filter(item => item !== value);
      setCheckboxData(filteredArray);
    }
  };
  return (
    <div className="mainDivStyle">
      <Navbar />
      <div className="innerDivSignin py-5">
        <div>
          <div className="textStyleHeading mt-3">Cancel Subscription</div>
          <div className="textStyle400Cabin18 widthFix fixCradCenter">
            Before you’re ready to go we need to know if your child will be playing with a free or premium membership.
          </div>
          <div className="mt-4 labelClassSetUpStyle fixCradCenter">
            Which children would you like to downgrade to free?
          </div>
          <div className="mt-4 row justify-content-center fixCradCenter widthFix">
            {paidChilds?.map(item => {
              if (item.plan_type?.plan !== 'Fremium') {
                return (
                  <div key={item.student.student_id} className="textStylePayment col-3">
                    <Checkbox id={item.student.student_id} className="me-3" onChange={onChange} />
                    {item.student.first_name} {item.student.last_name}
                  </div>
                );
              }
            })}
          </div>
          <div className="mt-4 fixCradCenter widthFix">
            <EducatorButton
              label="Cancel Premium Membership"
              onClick={updateMembership}
              type="button"
              width="300px"
              showSpinner={buttonSpinner}
              disabled={checkboxData.length == 0 ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentCancelMembership;
