import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '@components/commonComponents';

export const DeleteAssignment = ({ show, onCancel, assignmentName, onFinish, loading, onCancelClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  return (
    <EducatorModal
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle fixCradCenter">
            {t('confirm_delete_assignment')} {assignmentName}?
          </div>
          <div className="pt-3">
            <Form onFinish={onFinish}>
              <Form.Item className="mt-3">
                <div className="pt-3">
                  <EducatorButton
                    key="submit"
                    label={t('delete_it')}
                    type="submit"
                    width="100%"
                    showSpinner={loading}
                  />
                </div>
                <div className="pt-4">
                  <EducatorButton
                    key="button"
                    label={t('cancel_delete')}
                    type="button"
                    width="100%"
                    onClick={onCancelClick}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      }
    />
  );
};

DeleteAssignment.defaultProps = {
  assignmentName: ''
};

DeleteAssignment.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  assignmentName: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired
};
