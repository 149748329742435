/**
 * Safely set an item to local storage.
 * @param {string} key - Key under which data should be stored.
 * @param {any} value - Value to be stored. It will be stringified before storing.
 * @param {boolean} stringify - Indicates whether or not to stringify the value.
 * @returns {boolean} - Indicates success or failure.
 */
export const setLocalStorageItem = (key, value, stringify = false) => {
  try {
    window.localStorage.setItem(`cyberlegends_${key}`, stringify ? JSON.stringify(value) : value);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Safely get an item from local storage.
 * @param {string} key - Key of the stored data.
 * @returns {any|null} - Parsed value or null if retrieval failed.
 */
export const getLocalStorageItem = key => {
  try {
    const value = window.localStorage.getItem(`cyberlegends_${key}`);
    return value ? JSON.parse(value) : null;
  } catch (e) {
    return null;
  }
};

/**
 * Safely remove an item from local storage.
 * @param {string} key - Key of the data to remove.
 * @returns {boolean} - Indicates success or failure.
 */
export const removeLocalStorageItem = key => {
  try {
    window.localStorage.removeItem(`cyberlegends_${key}`);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Safely clear all items from local storage.
 * @returns {boolean} - Indicates success or failure.
 */
export const clearLocalStorage = () => {
  try {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('cyberlegends_')) {
        localStorage.removeItem(key);
      }
    });
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Clear all Cognito storage from local storage.
 * @returns {void}
 */
export const clearCognitoStorage = () => {
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(key);
    }
  });
};
