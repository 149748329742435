import React, { useEffect, useState } from 'react';
import { Card, Popover, Select, Skeleton } from 'antd';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { EducatorButton } from '@components/commonComponents';
import { API_URL, getApiWithAuth } from '@utils';
import { useAppState } from '@context';
import questionIcon from '@assets/images/questionIcon.svg';
import './StudentComprehensionReportStyle.scss';

const { Option } = Select;

const ReportSkeleton = () => (
  <div className="py-4">
    <Skeleton.Input active size={'large'} block />
    <br />
    <br />
    <Skeleton.Input active size={'large'} block />
    <Skeleton.Input active size={'large'} block />
    <Skeleton.Input active size={'large'} block />
  </div>
);

export const StudentComprehensionReport = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'reporting' });
  const { state } = useAppState();
  const { classId } = state;

  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const filterData = [];

  const [isGetStudentLoading, setIsGetStudentLoading] = useState(false);

  const [selectedStrand, setSelectedStrand] = useState(null);

  const [csvData, setCsvData] = useState([]);

  const csvReport = {
    data: csvData,
    headers: [
      { label: 'Topic', key: 'topic' },
      { label: 'Completed Activities', key: 'completedActivities' },
      { label: 'Skill Level', key: 'skillLevel' }
    ],
    filename: 'Student_Comprehension_Report.csv'
  };

  useEffect(() => {
    const csvData = filterData.map(item => {
      return {
        topic: item.topic,
        completedActivities: item.completed_activities,
        skillLevel: item.skillLevel
      };
    });

    setCsvData(csvData);
  }, [filterData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsGetStudentLoading(true);

      const { success, data } = await getApiWithAuth(`${API_URL.GET_CLASS_STUDENTS}${classId}/`);
      if (success) {
        setStudents(data);
        setSelectedStudent(data[0]?.id);
      }

      setIsGetStudentLoading(false);
    };

    fetchData();
  }, [classId]);

  const resetData = () => {
    setSelectedStrand(null);
  };

  return (
    <>
      <div className="report__container container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar
          smallHeading1={t('reports')}
          smallHeading2={t('student_comprehension_report')}
          bodyButton={
            <CSVLink {...csvReport}>
              <EducatorButton label={t('print_report')} type="button" />
            </CSVLink>
          }
        />
        <div className="row mt-3 cardResourceMainDiv">
          <div className="authenticatedNavbarHeadingStyle">{t('student_comprehension_report')}</div>
          <div className="pt-1 textGradedAssignmentsStyle">{t('student_comprehension_report_description')}</div>
          {isGetStudentLoading ? (
            <ReportSkeleton />
          ) : (
            <>
              <Card className=" col-8 cardStyleFirstStudentComprehension p-md-3 p-4 mt-2">
                <div className="cardBodyStyleStudentComprehension mx-2">
                  <div className="studentReportOptionsDivStyle w-50">
                    <Select
                      className="fixCardInputField"
                      placeholder={t('select_student')}
                      value={selectedStudent}
                      onChange={student => setSelectedStudent(student)}
                      name="student_name">
                      {students.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.first_name} {item.last_name.substring(0, 1).toUpperCase()}.
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="studentReportOptionsDivStyle w-50 ms-3">
                    <Select
                      onChange={strandId => setSelectedStrand(strandId)}
                      placeholder={t('select_strand')}
                      value={selectedStrand}
                      className="fixCardInputField"
                      name="strand"
                    />
                  </div>
                  <div className="ms-3 col-2">
                    <EducatorButton
                      className="reset__button"
                      key="button"
                      label={t('reset')}
                      type="button"
                      onClick={resetData}
                      height={'50px'}
                    />
                  </div>
                </div>
              </Card>
              <Card
                bordered={false}
                className=" col-8 cardStyleSecondStudentComprehension cardsStyling p-md-3 p-3 mt-3">
                <div className="headerReportStyle mt-2">
                  <div className="itemStyle">{t('topic')}</div>
                  <div style={{ display: 'flex', justifyContent: 'space-around', width: '60%' }}>
                    {' '}
                    <div>
                      {t('completed_activities')}{' '}
                      <Popover content={t('completed_activities_description')} trigger="hover">
                        {' '}
                        <img src={questionIcon} alt="question" width="17px" height="17px" />
                      </Popover>
                    </div>
                    <div>
                      {' '}
                      {t('skill_level')}{' '}
                      <Popover content={t('skill_level_description')} trigger="hover">
                        {' '}
                        <img src={questionIcon} alt="question" width="17px" height="17px" />
                      </Popover>
                    </div>
                  </div>
                </div>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  );
};
