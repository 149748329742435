export const series = [
  {
    name: '',
    data: [0, 1, 2, 4, 0, 5, 2]
  }
];

export const option = {
  grid: {
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
    padding: {
      left: 20
    }
  },
  title: {
    text: 'Time Played At School',
    align: 'center'
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    colors: ['#4B3AD3']
  },
  chart: {
    toolbar: { show: false },
    type: 'scatter',
    zoom: {
      enabled: false
    }
  },
  xaxis: {
    colors: ['transparent'],
    title: {
      text: 'Day of Week',
      align: 'center'
    },
    categories: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    tickPlacement: 'between',
    labels: {
      style: {
        fontWeight: 700
      }
    }
  },
  yaxis: {
    title: {
      text: 'Hours',
      align: 'center'
    }
  },
  labels: {
    formatter: value => {
      return value;
    },
    offsetY: -3
  },
  tooltip: {
    enabled: true,
    formatter: undefined,
    offsetY: 0
  }
};

export const option2 = {
  grid: {
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
    padding: {
      left: 20
    }
  },
  title: {
    text: 'Time Played At Home',
    align: 'center'
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    colors: ['#F8CA42']
  },
  chart: {
    toolbar: { show: false },
    type: 'scatter',
    zoom: {
      enabled: false
    }
  },
  xaxis: {
    colors: ['transparent'],
    title: {
      text: 'Day of Week',
      align: 'center'
    },
    categories: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    tickPlacement: 'between',
    labels: {
      style: {
        fontWeight: 700
      }
    }
  },
  yaxis: {
    title: {
      text: 'Hours',
      align: 'center'
    }
  },
  labels: {
    formatter: value => {
      return value;
    },
    offsetY: -3
  },
  tooltip: {
    enabled: true,
    formatter: undefined,
    offsetY: 0
  }
};
