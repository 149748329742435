import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, DatePicker, Divider, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField, EducatorModal } from '@components/commonComponents';

const { Option } = Select;
export const CreateAssignment = ({
  show,
  onCancel,
  onFinish,
  createAssignmentData,
  onChange,
  onChangeStartDate,
  onChangeEndDate,
  disabledDate,
  assignCheckbox,
  onSelected,
  onPreviousClick,
  studentData,
  loading,
  form
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  return (
    <EducatorModal
      width="600"
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">{t('create_your_assignment')}</div>
          <div className="fixCardtext mt-2 mb-3">{t('enter_assignment_details')}</div>
          <Form onFinish={onFinish} form={form}>
            <Form.Item className="pt-3">
              <EducatorInputField
                label={t('name_assignment')}
                type="text"
                name="name"
                className="fixCardInputField"
                inputValue={createAssignmentData.name}
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item className="pt-3">
              <div className="d-flex justify-content-between">
                <div>
                  <label className="selectCreateAssignment mb-2 ">{t('select_start_date')}</label>
                  <DatePicker
                    placeholder=""
                    suffixIcon={false}
                    className="datePickerAssignmentStyle"
                    name="start_date"
                    onChange={onChangeStartDate}
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                  />
                </div>
                <div>
                  <label className="selectCreateAssignment mb-2">{t('select_end_date')}</label>
                  <DatePicker
                    placeholder=""
                    suffixIcon={false}
                    className="datePickerAssignmentStyle"
                    name="end_date"
                    onChange={onChangeEndDate}
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                  />
                </div>
              </div>
            </Form.Item>
            <div className="fixCardtext d-flex mt-3 mb-3">
              <Checkbox name="assign_all_students" onChange={assignCheckbox} />
              &nbsp;&nbsp;
              <span style={{ alignSelf: 'center', textAlign: 'center' }}>{t('assign_entire_class')}</span>
            </div>
            <Divider plain>{t('or')}</Divider>
            <label className="selectCreateAssignment mb-2 mt-2">{t('select_students')}</label>
            <Select
              className="selectStudentInputField"
              mode="multiple"
              value={createAssignmentData.assigned_students}
              optionLabelProp="label"
              onChange={onSelected}>
              {studentData.map(item => {
                return (
                  <Option
                    key={item.id}
                    value={item.id}
                    label={`${item.first_name} ${item.last_name.substring(0, 1).toUpperCase()}`}
                    name="assigned_students">
                    {item.first_name} {item.last_name.substring(0, 1).toUpperCase()}.
                  </Option>
                );
              })}
            </Select>
            <Form.Item className="pt-3">
              <div className="d-flex justify-content-between">
                <EducatorButton
                  label={t('previous')}
                  type="button"
                  className="previousButton"
                  width="49%"
                  onClick={onPreviousClick}
                />
                <EducatorButton label={t('create_assignment')} type="submit" width="50%" showSpinner={loading} />
              </div>
            </Form.Item>
          </Form>
        </div>
      }
    />
  );
};

CreateAssignment.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  createAssignmentData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  disabledDate: PropTypes.func.isRequired,
  assignCheckbox: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  studentData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
};
