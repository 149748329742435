import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { BASE_URL, getAuthToken } from '@utils';
import i18n from '@services/i18n';

export const UnityView = ({ unityTemplate, mission, assignment, userType, unloadUnity, onClose, useBackHack }) => {
  const finishStatusRef = useRef(false);
  const setfinishStatus = data => {
    finishStatusRef.current = data;
  };

  const [gameReady, setGameReady] = useState(false);
  const [gameComplete, setGameComplete] = useState(false);

  const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, unload } =
    useUnityContext({
      loaderUrl: `${unityTemplate.server_url}/${unityTemplate.name}/${unityTemplate.template_id}/${unityTemplate.build}/${unityTemplate.build}.loader.js`,
      dataUrl: `${unityTemplate.server_url}/${unityTemplate.name}/${unityTemplate.template_id}/${unityTemplate.build}/${unityTemplate.build}.data`,
      frameworkUrl: `${unityTemplate.server_url}/${unityTemplate.name}/${unityTemplate.template_id}/${unityTemplate.build}/${unityTemplate.build}.framework.js`,
      codeUrl: `${unityTemplate.server_url}/${unityTemplate.name}/${unityTemplate.template_id}/${unityTemplate.build}/${unityTemplate.build}.wasm`,
      streamingAssetsUrl: `${unityTemplate.server_url}/${unityTemplate.name}/${unityTemplate.template_id}/StreamingAssets`
    });

  const loadingPercentage = Math.round(loadingProgression * 100);

  const handleGameReady = useCallback(() => {
    setGameReady(true);
  }, []);

  const handleGameComplete = useCallback(() => {
    setGameComplete(true);
  }, []);

  useEffect(async () => {
    if (!gameReady) return;

    const language = i18n.language || 'en-CA';

    sendMessage(
      'GameManager',
      'SetData',
      JSON.stringify({
        jwt: await getAuthToken(),
        type: mission ? 'Mission' : assignment ? 'Assignment' : 'Unknown',
        user_type: userType,
        language: language.toLowerCase(),
        host: `${BASE_URL}${userType}/`,
        id: mission ? mission.mission_id : assignment ? assignment.assignment_id : 'Unknown'
      })
    );
  }, [gameReady]);

  useEffect(async () => {
    if (gameComplete || unloadUnity) {
      await unload();
      onClose();
    }
  }, [gameComplete, unloadUnity]);

  const onBackButtonEvent = async e => {
    if (gameComplete || unloadUnity) return;

    e.preventDefault();
    if (!finishStatusRef.current) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you want to go back ?')) {
        setfinishStatus(true);
        await unload();
        onClose();
      } else {
        window.history.pushState(window.history.state, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    if (useBackHack) {
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }
  }, [gameComplete, unloadUnity, isLoaded]);

  useEffect(() => {
    if (isLoaded && useBackHack) {
      window.history.pushState(window.history.state, null, window.location.pathname);
    }
  }, [isLoaded]);

  useEffect(() => {
    addEventListener('GameReady', handleGameReady);
    addEventListener('GameComplete', handleGameComplete);
    return () => {
      removeEventListener('GameReady', handleGameReady);
      removeEventListener('GameComplete', handleGameComplete);
    };
  }, [addEventListener, removeEventListener, handleGameReady]);

  return (
    <div>
      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.

        <div className="emptyMessageStudent">
          <div>Loading {loadingPercentage}%...</div>
          <Spin size="large" />
        </div>
      )}

      <Unity className="unity" unityProvider={unityProvider} style={{ width: '100vw', height: '100vh' }} />
    </div>
  );
};

UnityView.propTypes = {
  unityTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    template_id: PropTypes.string.isRequired,
    server_url: PropTypes.string.isRequired,
    build: PropTypes.string.isRequired,
    streaming_assets: PropTypes.bool.isRequired
  }).isRequired,
  mission: PropTypes.any,
  assignment: PropTypes.any,
  userType: PropTypes.string,
  unloadUnity: PropTypes.bool,
  onClose: PropTypes.func,
  useBackHack: PropTypes.bool
};

UnityView.defaultProps = {
  mission: null,
  assignment: null,
  userType: 'students',
  unloadUnity: false,
  useBackHack: false,
  onClose: () => {}
};

export default UnityView;
