import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

import { useCredits } from '@hooks';
import CreditIcon from '@assets/images/credit.svg';

import './CreditBadge.scss';

export const CreditBadge = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });
  const { data: credits, loading } = useCredits();

  if (loading) {
    return <Skeleton.Button active shape="round" size="large" />;
  }

  return (
    <div className="credit_badge__container">
      <img src={CreditIcon} alt="credit" />
      {t('credits', { credits: credits.available })}
    </div>
  );
};
