import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '../../index';

export const StepOneModal = ({ show, onCancel, onLinkStudent, onCreateNewStudent }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  return (
    <EducatorModal
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">{t('add_students')}</div>
          <div className="fixCardtextStyle mt-2">{t('choose_add_method')}</div>
          <Form>
            <Form.Item className="pt-4">
              <EducatorButton
                key="button"
                label={t('create_new_accounts')}
                type="button"
                width="100%"
                onClick={onCreateNewStudent}
              />
            </Form.Item>
            <Form.Item>
              <EducatorButton
                key="submit"
                label={t('link_existing_accounts')}
                type="button"
                width="100%"
                onClick={onLinkStudent}
              />
            </Form.Item>
          </Form>
        </div>
      }
    />
  );
};

StepOneModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onLinkStudent: PropTypes.func.isRequired,
  onCreateNewStudent: PropTypes.func.isRequired
};
