import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';

export const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const isPrevPageAvailable = currentPage > 1;
  const isNextPageAvailable = currentPage < totalPages;
  const showPagination = totalPages > 1;

  if (!showPagination) return null;

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i < Math.min(3, totalPages); i++) {
      pageNumbers.push(
        <button key={i} onClick={() => onPageChange(i)} className={i === currentPage ? 'active' : ''}>
          {i}
        </button>
      );
    }

    if (totalPages > 3) {
      pageNumbers.push(<span key="ellipsis">...</span>);
    }

    pageNumbers.push(
      <button
        key={totalPages}
        onClick={() => onPageChange(totalPages)}
        className={totalPages === currentPage ? 'active' : ''}>
        {totalPages}
      </button>
    );

    return pageNumbers;
  };

  return (
    <div className="pagination__container">
      <button disabled={!isPrevPageAvailable} onClick={() => onPageChange(currentPage - 1)}>
        &lt;
      </button>
      <div className="page-numbers">{renderPageNumbers()}</div>
      <button disabled={!isNextPageAvailable} onClick={() => onPageChange(currentPage + 1)}>
        &gt;
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};
