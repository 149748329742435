export const SET_TOKEN = 'SET_TOKEN';

export const SET_CURRENT_CLASS_ID = 'SET_CURRENT_CLASS_ID';

export const SET_CLASSES = 'SET_CLASSES';

export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_IS_GOOGLE_AUTH = 'SET_IS_GOOGLE_AUTH';
