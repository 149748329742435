import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { EducatorButton, EducatorInputField, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import { API_URL, postApiWithAuth } from '../../../utils';
import { EducatorModal } from '../../commonComponents';
import './parentAddChild.css';

export const ParentAddChild = () => {
  const navigate = useNavigate();

  const [childData, setChildData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [showCopyMessage, setShowCopyMessage] = useState({ value: false });
  const addNewChild = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_CREATE_CHILD_ACCOUNT, childData);
    if (success) {
      message.success('Child data is added');
      setUserData(data);
      setButtonSpinner(false);
      showDetail();
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setChildData({ ...childData, [name]: value });
  };
  const showDetail = () => {
    setShowDetailModal(true);
  };

  const nextPage = () => {
    setShowDetailModal(false);
    navigate('/parent-create-account/curriculum-setup', {
      state: { student_id: userData.student_link_id, userName: childData }
    });
  };
  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row  py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Child Setup" check={[1, 0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">Create your child’s account</div>
            <div className="pt-3 textStyle">
              To take advantage of Cyber Legends your account must be linked to a child. Fill out this form to create
              their account - we’ll send you an email with their account details when you’re done.
            </div>
            <Form onFinish={addNewChild}>
              <Form.Item
                className="pt-3"
                name="first_name"
                rules={[
                  { required: true, message: 'Please enter First Name!' },
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message: 'Must contain Alphabets'
                  }
                ]}>
                <EducatorInputField
                  label="Child’s First Name"
                  type="text"
                  name="first_name"
                  inputValue={childData.first_name}
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item
                className="pt-3"
                name="last_name"
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z]{1}$/,
                    message: 'First Letter of Last Name'
                  }
                ]}>
                <EducatorInputField
                  label="First Initial of Last Name:"
                  type="text"
                  name="last_name"
                  inputValue={childData.last_name}
                  onChange={onChangeHandle}
                />
              </Form.Item>

              <Form.Item className="pt-3">
                <EducatorButton label="Add Account" type="submit" showSpinner={buttonSpinner} />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          {showDetailModal ? (
            <HelperBot style={{ position: 'absolute', zIndex: 9999 }}>
              These are your child’s login credentials for the game. Keep them somewhere safe. <br />
              <br />
              These details are also available in your Parent Portal account.
            </HelperBot>
          ) : (
            <HelperBot>Your child’s account is securely connected to your account.</HelperBot>
          )}
        </div>
      </div>
      <EducatorModal
        visible={showDetailModal}
        onCancel={nextPage}
        bodyContent={
          <div>
            {showCopyMessage.value ? (
              <div className="yellowTitle container copyMessage">
                <p className="copyMessage__text">{showCopyMessage.text} Copied to Clipboard!</p>
              </div>
            ) : null}
            <div className="fixCardHeadingStyle">Your child’s account has been created!</div>
            <div className="fixCardtextStyle mt-2">
              This new account has been successfully created! Below are the login details. Write them down or store them
              in a secure password manager
            </div>

            <Form onFinish={nextPage}>
              <div className="mt-3 childSecondText">Child Username: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{userData?.user_name ? userData?.user_name : 'Your UserName'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(userData?.user_name);
                    setShowCopyMessage({ value: true, text: 'Username' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="mt-3 childSecondText">Child Password: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{userData?.password ? userData?.password : 'Your Password'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(userData?.password);
                    setShowCopyMessage({ value: true, text: 'Password' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="py-3">
                <EducatorButton label="That’s it for now!" type="submit" width="100%" />
              </div>
            </Form>
          </div>
        }
      />
    </div>
  );
};
export default ParentAddChild;
