import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'antd';
import './modalOptionsStyle.css';
import BackgroundColor from '../BackgroundColor';

const ModalOptions = ({ title, background, subTitle, onChange, id, textColor, checkValue }) => {
  return (
    <div className="optionsModalStyle">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <Checkbox onChange={onChange} value={id} defaultChecked={checkValue} />
          &nbsp;&nbsp;
          <span style={{ alignSelf: 'center', textAlign: 'center' }}> {title}</span>
        </div>
        <div>
          <BackgroundColor title={subTitle} background={background} textColor={textColor} />
        </div>
      </div>
    </div>
  );
};
export default ModalOptions;

ModalOptions.defaultProps = {
  checkValue: false
};

ModalOptions.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  textColor: PropTypes.string.isRequired,
  checkValue: PropTypes.bool
};
