import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../theme/colors';

const StyledBadge = styled.div`
  padding: 6px 10px;
  font-family: Lexend, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  border-radius: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.block ? '100%': 'auto'};
  color: ${colors.WHITE_0};
  &.ant-outline {
    padding: 4px 8px;
    background-color: transparent !important;
  }
  &.ant-invalid {
    background-color: #dddddd;
    color: ${colors.GREY_50};
    &.ant-outline {
      border: 2px solid ${colors.WHITE_200};
    }
  }
  &.ant-danger {
    background-color: ${colors.FIRETRUCK_0};
    color: ${colors.CHERRY_100};
    &.ant-outline {
      border: 2px solid ${colors.FIRETRUCK_50};
    }
  }
  &.ant-warning {
    background-color: ${colors.BANANA};
    color: ${colors.DAFFODIL_300};
    &.ant-outline {
      border: 2px solid ${colors.DAFFODIL};
      color: ${colors.DAFFODIL_300};
    }
  }
  &.ant-success {
    background-color: ${colors.SPRING};
    &.ant-outline {
      border: 2px solid ${colors.SPRING};
      color: ${colors.SPRING_300};
    }
  }
  &.ant-cyber-kind {
    background-color: ${colors.SKY};
    color: ${colors.GREY_100};
  }
  &.ant-cyber-secure {
    background-color: ${colors.LAVENDER_100};
  }
  &.ant-cyber-positive {
    background-color: ${colors.LILAC};
  }
  &.ant-cyber-informed {
    background-color: ${colors.SPRING};
  }
  &.ant-cyber-safe {
    background-color: ${colors.DAFFODIL};
    color: ${colors.GREY_100};
  }
  &.ant-cyber-healthy {
    background-color: ${colors.GOLD};
  }
  & > svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    stroke-width: 2.5px;
  }
`;

const getTypeClassName = type => {
  switch (type) {
    case 'invalid':
      return 'ant-invalid';
    case 'warning':
      return 'ant-warning';
    case 'danger':
      return 'ant-danger';
    case 'success':
      return 'ant-success';
    case 'cyber-kind':
      return 'ant-cyber-kind';
    case 'cyber-secure':
      return 'ant-cyber-secure';
    case 'cyber-positive':
      return 'ant-cyber-positive';
    case 'cyber-informed':
      return 'ant-cyber-informed';
    case 'cyber-safe':
      return 'ant-cyber-safe';
    case 'cyber-healthy':
      return 'ant-cyber-healthy';
    default:
      return '';
  }
};

const Badge = ({ children, variant, type, className, icon, ...rest }) => {
  const variantClassName = variant === 'outline' ? 'ant-outline' : 'ant-fill';
  const customClassName = `${getTypeClassName(type)} ${variantClassName} ${className}`;
  return (
    <StyledBadge className={customClassName} type={type} variant={variant} {...rest}>
      {icon}
      {children}
    </StyledBadge>
  );
};

export default Badge;

Badge.defaultProps = {
  variant: 'fill',
  type: 'success',
  icon: null,
  className: '',
  block: false
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  /**  Can be set to 'fill' and 'outline'. 'fill' is default */
  variant: PropTypes.oneOf(['fill', 'outline']),
  /**  Can be set to invalid, success, 'warning', 'danger',
   'cyber-kind', 'cyber-secure', 'cyber-positive', 'cyber-informed', 'cyber-safe', 'cyber-healthy' */
  type: PropTypes.oneOf([
    'invalid',
    'success',
    'warning',
    'danger',
    'cyber-kind',
    'cyber-secure',
    'cyber-positive',
    'cyber-informed',
    'cyber-safe',
    'cyber-healthy'
  ]),
  /** Can be set to show icon */
  icon: PropTypes.node,
  /** Can be set to pass custom classes */
  className: PropTypes.string,
  /** Can be set to take full width */
  block: PropTypes.bool,
};
