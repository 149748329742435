import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);

  return <ModalContext.Provider value={{ modalContent, setModalContent }}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};
