import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Navbar } from '@components/layoutComponents';
import { EducatorButton } from '@components/commonComponents';
import { useAppState } from '@context';
import { API_URL, getApiWithAuth, setFlowComplete } from '@utils';

import award from '@assets/images/award.svg';

import './SignedUp.scss';
import {message} from 'antd';
export const SignedUp = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signed_up' });
  const navigate = useNavigate();

  const { fetchCurrentUser } = useAppState();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const flowComplete = async () => {
    setButtonSpinner(true);
    const { success, data} = await getApiWithAuth(API_URL.VALIDATE_USER_AUTH);
    if (success) {
      fetchCurrentUser();
      setFlowComplete(true);
      setButtonSpinner(false);
      navigate('/dashboard');
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };

  return (
    <div className="signed-up__container mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5">
        <div className="mt-2 w-50">
          <div className="d-flex justify-content-center">
            <img src={award} alt="award" width="110px" height="101px" />
          </div>
          <div className="textStyleHeading mt-3">{t('ready_to_start')}</div>
          <div className="my-3 textStyle400Cabin18">{t('signed_up_membership')}</div>
          <div className="pt-4 d-flex justify-content-center">
            <EducatorButton
              label="Sign Into the Dashboard"
              onClick={flowComplete}
              type="button"
              width="276px"
              showSpinner={buttonSpinner}
              dataTestId="signIntoDashboardButton"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
