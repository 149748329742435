import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { CardBlock } from '@components/commonComponents';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import './Faq.scss';

export const Faq = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });
  const { Panel } = Collapse;

  const getFaqData = () => [
    {
      question: t('who_can_i_refer'),
      answer: () => <>{t('who_can_i_refer_text')}</>
    },
    {
      question: t('how_do_i_get_credit'),
      answer: () => <>{t('how_do_i_get_credit_text')}</>
    },
    {
      question: t('how_many_referrals'),
      answer: () => <>{t('how_many_referrals_text')}</>
    },
    {
      question: t('referral_expire'),
      answer: () => <>{t('referral_expire_text')}</>
    },
    {
      question: t('what_is_included'),
      answer: () => <>{t('what_is_included_text')}</>
    }
  ];

  return (
    <CardBlock className="faq__container">
      <h3>{t('faq')}</h3>
      <Collapse
        className="collapseStyle"
        expandIcon={({ isActive }) =>
          isActive ? <MinusOutlined className="referalIconStyle" /> : <PlusOutlined className="referalIconStyle" />
        }
        expandIconPosition={'end'}>
        {getFaqData().map((item, index) => (
          <Panel className="faq__panel mt-2" header={item.question} key={index}>
            <div className="faqAnswer">{item.answer()}</div>
          </Panel>
        ))}
      </Collapse>
    </CardBlock>
  );
};
