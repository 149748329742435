import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CheckOutlined } from '@ant-design/icons';
import PrevArrow from '@assets/images/prev.svg';
import NextArrow from '@assets/images/next.svg';

import './VideoPlayer.scss';
import i18n from '@services/i18n';
import { sendGaEvent } from '@utils';

const VideoThumbnail = ({ item }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  return (
    <div
      className="video-thumbnail__container imageThumbnailStyle"
      style={{
        backgroundImage: `url(${item.thumbnail})`
      }}>
      <div className="video-info">
        <div className="video-info__text">
          <p>{item.name}</p>
          <span>{t('lesson_video')}</span>
        </div>
        <button>{t('start_series')} &gt;</button>
      </div>
    </div>
  );
};

VideoThumbnail.propTypes = {
  item: PropTypes.object.isRequired
};

export const VideoPlayer = ({ videos }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const [thumbnailSlider, setThumbnailSlider] = useState(null);
  const videoRef = useRef(null);
  const [viewedVideos, setViewedVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [currentSubtitleUrl, setCurrentSubtitleUrl] = useState('');
  const [autoplay, setAutoplay] = useState(true);

  return (
    <div className="video-player">
      <div className="video-player__container">
        <ReactPlayer
          playing={autoplay}
          width="100%"
          height="100%"
          ref={videoRef}
          url={currentVideoUrl || videos[0].url}
          controls
          config={{
            file: {
              attributes: {
                crossOrigin: 'true'
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: currentSubtitleUrl || videos[0].subtitle,
                  srcLang: i18n.language.split('-')[0],
                  default: true
                }
              ]
            }
          }}
          light={<VideoThumbnail item={videos[currentIndex]} />}
          onStart={() => {
            setAutoplay(true);
          }}
          onEnded={() => {
            setViewedVideos([...viewedVideos, currentIndex]);
            thumbnailSlider.slickNext();
            sendGaEvent('video_ended', 'edubyte__video', videos[currentIndex]?.name);
          }}
          onPlay={() => {
            sendGaEvent('video_play', 'edubyte__video', videos[currentIndex]?.name);
          }}
          onPause={() => {
            sendGaEvent('video_pause', 'edubyte__video', videos[currentIndex]?.name);
          }}
        />
      </div>
      {videos.length > 1 ? (
        <div className="thumbnail-slider">
          <Slider
            arrows={false}
            afterChange={current => {
              setCurrentIndex(current);
              setCurrentVideoUrl(videos[current]?.url);
              setCurrentSubtitleUrl(videos[current].subtitle);
            }}
            ref={slider => setThumbnailSlider(slider)}
            slidesToShow={videos.length >= 4 ? 4 : videos.length}
            swipe={false}
            className={'thumbnail-slides__container '}>
            {videos.map((item, index) => {
              return (
                <div key={`${index}__${item.thumbnail}`}>
                  <div className="slide-inner">
                    <div className="thumbnail__wrapper">
                      {viewedVideos.includes(item.id) ? (
                        <div className="viewed-video">
                          <CheckOutlined />
                          &nbsp; {t('viewed')}
                        </div>
                      ) : null}
                      <img src={item.thumbnail} alt={item.name} />
                    </div>
                    <p>{item.name}</p>
                    <span>{t('lesson_video')}</span>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div>
            <button
              aria-label="Previous"
              className="prev-arrow"
              onClick={() => {
                thumbnailSlider.slickPrev();
                setAutoplay(false);
                sendGaEvent('previous_video', 'edubyte__video', videos[currentIndex]?.name);
              }}>
              <img src={PrevArrow} alt="Prev slide" />
            </button>
            <button
              aria-label="Next"
              className="next-arrow"
              onClick={() => {
                thumbnailSlider.slickNext();
                setAutoplay(false);
                sendGaEvent('next_video', 'edubyte__video', videos[currentIndex]?.name);
              }}>
              <img src={NextArrow} alt="Next slide" />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

VideoPlayer.propTypes = {
  videos: PropTypes.array.isRequired
};
