import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import './CardBlock.scss';

export const CardBlock = ({ children, className, ...rest }) => {
  return (
    <Card bordered={false} className={`card-block ${className}`} {...rest}>
      {children}
    </Card>
  );
};

CardBlock.defaultProps = {
  className: '',
  children: null
};

CardBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
