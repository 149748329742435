import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import { Card, Stack, Title, Text, Button } from '@teacher-app/design-system';

import AssignmentWhatsNew from './AssignmentWhatsNew';
import NotFoundImage from '@assets/images/icon_researchPaperBlack.svg';

const NoAssignments = ({ onClick, showWhatsNew }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  const [whatsNewVisible, setWhatsNewVisible ] = React.useState(showWhatsNew);

  return (
    <Stack direction='column' spacing={30} >
      {whatsNewVisible && (
        <AssignmentWhatsNew onCancel={() => setWhatsNewVisible(false)} />
      )}
      <Card width='100%'>
        <Stack direction='column' spacing={40}>
          <Stack direction='column'>
            <Image src={NotFoundImage} alt={t('empty_here')} />
            <Title level={3}>{t('empty_here')}</Title>
            <Text size="small">{t('empty_here_detail')}</Text>
          </Stack>
          <Button onClick={onClick}>{t('create_assignment')}</Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default NoAssignments;

NoAssignments.propTypes = {
  onClick: PropTypes.func.isRequired,
  showWhatsNew: PropTypes.bool.isRequired
};
