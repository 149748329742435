import dayjs from 'dayjs';

export const getAssignmentStatus = (startDate, endDate, t) => {
  let status = t('completed');
  let textColor = '#595D5C';
  let backgroundColor = '#DDDDDD';
  if (startDate > dayjs().format('YYYY-MM-DD')) {
    status = t('starting_soon');
    textColor = '#753E12';
    backgroundColor = '#FFE78D';
  } else if (startDate <= dayjs().format('YYYY-MM-DD') && endDate > dayjs().format('YYYY-MM-DD')) {
    status = t('active');
    textColor = '#ffffff';
    backgroundColor = '#96BF30';
  } else if (endDate === dayjs().format('YYYY-MM-DD')) {
    status = t('due_today');
    textColor = '#C62438';
    backgroundColor = '#FFE6DF';
  }

  return { status, textColor, backgroundColor };
};

export const getAssignmentPercent = (score, maxScore) => {
  const percent = score && maxScore && maxScore > 0 ? Math.round((score * 100) / maxScore) : 0;
  let textColor = '#595D5C';
  let backgroundColor = '#DDDDDD';

  if (maxScore && maxScore && percent < 40) {
    textColor = '#C62438';
    backgroundColor = '#FFE6DF';
  } else if (percent >= 40 && percent < 80) {
    textColor = '#753E12';
    backgroundColor = '#FFE78D';
  } else if (percent >= 80) {
    textColor = '#ffffff';
    backgroundColor = '#96BF30';
  }

  return { percent, textColor, backgroundColor };
};
