import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { Navbar, Multistep } from '@components/layoutComponents';
import { EducatorButton, EducatorInputField, HelperBot } from '@components/commonComponents';
import { setToken, setFlowComplete } from '@utils';

import './EmailConformation.scss';

export const EmailConformation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'email_confirmation' });
  const navigate = useNavigate();

  const location = useLocation();
  const [code, setCode] = useState('');
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const { password, username } = location.state || {};
  const confirmationSignUp = () => {
    setButtonSpinner(true);
    Auth.confirmSignUp(username, code)
      .then(() => {
        signInUser();
      })
      .catch(err => {
        const errorMessage = t('validation_error', { code });
        if (err.message === errorMessage) {
          message.error(t('remove_extra_spaces'));
        } else {
          message.error(`${err.message}`);
        }
        setButtonSpinner(false);
      });
  };

  const signInUser = () => {
    Auth.signIn({
      username: username,
      password: password
    })
      .then(() => {
        setButtonSpinner(false);
        setFlowComplete(false);
        saveToken();
      })
      .catch(() => {
        setButtonSpinner(false);
      });
  };
  const saveToken = async () => {
    await Auth.currentSession()
      .then(data => {
        setToken(data.accessToken.jwtToken);
        setButtonSpinner(false);
        navigate('/teacher-create-account/curriculum-setup');
      })
      .catch(() => {});
  };

  const resendCode = () => {
    Auth.resendSignUp(username)
      .then(() => {
        message.success(t('code_resent'));
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <Multistep select="Personal Details" check={[0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">{t('check_email_for_code')}</div>
            <div className="pt-3 textStyle">
              {t('verify_email_with_code')}&nbsp;
              <span
                className="resendCodeStyle cursorPointer"
                onClick={() => {
                  resendCode();
                }}>
               {t('resend_code')}
              </span>
            </div>
            <Form onFinish={confirmationSignUp}>
              <Form.Item className="pt-3" name="code" rules={[{ required: true, message: 'Please enter your code!' }]}>
                <EducatorInputField
                  label={t('enter_your_code')}
                  type="text"
                  name="code"
                  inputValue={code}
                  onChange={e => setCode(e.target.value)}
                />
              </Form.Item>

              <Form.Item className="pt-3">
                <EducatorButton
                  label={t('continue')}
                  type="submit"
                  showSpinner={buttonSpinner}
                  dataTestId="continueButton"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          <HelperBot>{t('dont_close_this_window')}</HelperBot>
        </div>
      </div>
    </div>
  );
};
export default EmailConformation;
