import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Paperclip, Check } from 'react-feather';

import { EducatorModal } from '@components/commonComponents';

import './TeachingResourcesModal.scss';

export const TeachingResourcesModal = ({ show, handleOnCancel, resources }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });

  return (
    <EducatorModal visible={show} onCancel={handleOnCancel}>
      <div className="teaching_resources_modal__container">
        <h3>
          <Paperclip /> {t('teaching_resources')}
        </h3>
        <p>{t('teaching_resources_modal_text')}</p>
        <ul>
          {resources.map((resource, index) => (
            <li key={index}>
              <Check color="#169C49" />
              &nbsp;
              {resource}
            </li>
          ))}
        </ul>
      </div>
    </EducatorModal>
  );
};

TeachingResourcesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  resources: PropTypes.array.isRequired
};
