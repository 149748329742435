import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select, Card } from 'antd';

import { AuthenticatedNavbar } from '../../layoutComponents';
import { API_URL, getApiWithAuth } from '../../../utils';
import graduation from '../../../assets/images/graduation.svg';
import bulb_icon from '../../../assets/images/bulb_icon.svg';
import game from '../../../assets/images/game.svg';
import rightArrow from '../../../assets/images/rightArrow.svg';

import './learningStyle.css';

const { Option } = Select;

const renderChildName = child => `${child.first_name} ${child.last_name.substring(0, 1).toUpperCase()}`;

export const Learning = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {};

  const [parentChildren, setParentChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);

  const selectChild = gradeName => {
    setSelectedChild(JSON.parse(gradeName));
  };

  const checkChildPayment = async navigation => {
    const { success, data } = await getApiWithAuth(`payments/child/${selectedChild.student_id}/subscription-detail`);
    if (success) {
      navigate(parentChildren?.length === 0 ? null : navigation, {
        state: { child_info: selectedChild, isFreePlan: data.student_plan === 'free' }
      });
    }
  };

  const childName = `${selectedChild?.first_name} ${selectedChild?.last_name}`;

  useEffect(() => {
    const fetchData = async () => {
      const { success, data } = await getApiWithAuth(API_URL.PARENT_CHILDREN);
      if (success) {
        setParentChildren(data);
        if (item === undefined) {
          setSelectedChild(data[0]);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (item) {
      setSelectedChild(item);
    }
  }, [item]);

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 referalMainHeight">
        <AuthenticatedNavbar playButton />
        <div className="row">
          <div className="col col-12 cardTextStyle">Select the child you’d like to view reports for</div>
          <div className="col col-12 mt-3">
            {parentChildren?.length > 0 ? (
              <Select onChange={selectChild} value={childName} className="learningStudentName" name="student">
                {parentChildren.map(item => (
                  <Option key={item.student_id} value={JSON.stringify(item)}>
                    {renderChildName(item)}
                  </Option>
                ))}
              </Select>
            ) : (
              <div className="learningStudentName">Please Add Child</div>
            )}
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-start">
          <div className="cardOuterDivReporting">
            <Card bordered={false} className="cardStyleReportingFirst p-3 mt-3">
              <div className="cardHeadingReportingStyle pt-3">Child Comprehension</div>
              <div className="cardInnerBodyTextStyle mt-2">
                See how well your kids understand the concepts they’ve been practicing.
              </div>
              <div className="imageReportingStyle mt-5 p-2">
                <img src={bulb_icon} alt="bulb_icon" width="92px" height="87px" />
                <div
                  className="reportsStyle mt-3 "
                  onClick={() => checkChildPayment('/parent-child-comprehension-report')}>
                  View Reports &nbsp;
                  <img src={rightArrow} alt="rightArrow" />
                </div>
              </div>
            </Card>
            <Card bordered={false} className="cardStyleReportingFirst p-3 mt-3">
              <div className="cardHeadingReportingStyle pt-3">Weekly Usage Report</div>
              <div className="cardInnerBodyTextStyle mt-2">
                See how much time your kids are spending playing Cyber Legends.
              </div>
              <div className="imageReportingStyle mt-5 p-2">
                <img src={game} alt="game" width="92px" height="87px" />
                <div
                  className="reportsStyle mt-3"
                  onClick={() => checkChildPayment('/parent-child-weekly-usage-report')}>
                  View Reports &nbsp;
                  <img src={rightArrow} alt="rightArrow" />
                </div>
              </div>
            </Card>
            <Card bordered={false} className="cardStyleReportingFirst p-3 mt-3">
              <div className="cardHeadingReportingStyle pt-3">Customize Learning Paths</div>
              <div className="cardInnerBodyTextStyle mt-2">
                Prioritize different learning topics to help shape your child’s learning.
              </div>
              <div className="imageReportingStyle mt-5 p-2">
                <img src={graduation} alt="graduation" width="92px" height="87px" />
                <div
                  className="reportsStyle mt-3"
                  onClick={() => checkChildPayment('/parent-child-customize-learning-path')}>
                  Edit Learning Path &nbsp;
                  <img src={rightArrow} alt="rightArrow" />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default Learning;
