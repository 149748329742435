import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select, message, Skeleton } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { AuthenticatedNavbar } from '../../layoutComponents';
import {
  API_URL,
  getApiWithAuth,
  postApiWithAuth,
  patchApiWithAuth,
  getApiWithoutAuth,
  getUserID
} from '../../../utils';
import { AssignmentCardWithColor, EducatorButton, EducatorModal, EducatorInputField } from '../../commonComponents';
import { LoginDetails } from './LoginDetails';
import { useGrades } from '../../../hooks';
import './ChildAccounts.scss';

const { Option } = Select;

export const ChildAccounts = () => {
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const [subscriptionDetail, setSubscriptionDetail] = useState({ subscribed_children: [] });
  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const [showCreateChildModal, setShowCreateChildModal] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [showAddCurriculumModal, setShowAddCurriculumModal] = useState(false);
  const [linkExistingModal, setLinkExistingModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailDta, setDetailData] = useState({});
  const [childData, setChildData] = useState({});
  const [childCurriculum, setChildCurriculum] = useState({});
  const [selectedProvince, setSelectedProvince] = useState('');
  const [dataCountry, setDataCountry] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [parentOverView, setParentOverView] = useState({});
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getUserSubscriptionDetail = async () => {
    const parent_id = getUserID();
    setIsLoading(true);
    const { success, data } = await getApiWithAuth(`payments/parent/${parent_id}/subscription-details`);
    if (success) {
      setSubscriptionDetail(data);
    }
    setIsLoading(false);
  };

  const getParentOverView = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PARENT_OVERVIEW);
    if (success) {
      setParentOverView(data);
    }
  };
  const editAccountDetail = data => {
    navigate(`/edit-child-account/${data.student.student_id}`, { state: { userInfo: data } });
  };

  const showAddChild = () => {
    setShowAddChildModal(true);
  };
  const hideAddChild = () => {
    setShowAddChildModal(false);
  };
  const showCreateChild = () => {
    setShowAddChildModal(false);
    setShowCreateChildModal(true);
  };
  const hideCreateChild = () => {
    setShowCreateChildModal(false);
  };
  const showCurriculumChild = () => {
    setShowCreateChildModal(false);
    setShowAddCurriculumModal(true);
  };
  const hideCurriculumChild = () => {
    setShowAddCurriculumModal(false);
  };

  const showDetail = () => {
    setShowAddCurriculumModal(false);
    setShowDetailModal(true);
  };
  const hideDetail = async () => {
    setShowDetailModal(false);
    await getUserSubscriptionDetail();
  };
  const showExistingCode = () => {
    setShowAddChildModal(false);
    setLinkExistingModal(true);
  };
  const hideExistingCode = () => {
    setLinkExistingModal(false);
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setChildData({ ...childData, [name]: value });
  };
  const curriculumChild = async () => {
    setButtonSpinner(true);
    const { success } = await patchApiWithAuth(API_URL.PARENT_CURRICULUM_SETUP, childCurriculum);
    if (success) {
      showDetail();
      await getUserSubscriptionDetail();
      await getParentOverView();
    }
    setButtonSpinner(false);
  };
  const createChild = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_CREATE_CHILD_ACCOUNT, childData);
    if (success) {
      setDetailData(data);
      setChildCurriculum({ ...childCurriculum, student_id: data.student_link_id });
      showCurriculumChild();
    }
    setButtonSpinner(false);
  };

  const selectCountry = countryName => {
    setChildCurriculum({ ...childCurriculum, country: countryName });
  };
  const selectProvince = provinceId => {
    const temp = provinces.filter(item => item.id === provinceId);
    setSelectedProvince(temp[0].name);
    setChildCurriculum({ ...childCurriculum, province_id: provinceId });
  };

  const selectGrade = gradeName => {
    setChildCurriculum({ ...childCurriculum, child_grade: gradeName });
  };

  const getCountries = useCallback(async () => {
    const { success, data } = await getApiWithoutAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
      setDataCountry(data[0]?.name);
    }
  }, []);

  useEffect(() => {
    if (grades && grades.length > 0) {
      setChildCurriculum({ ...childCurriculum, child_grade: grades[0]?.name });
    }
  }, [grades]);

  useEffect(() => {
    getUserSubscriptionDetail();
    getCountries();
    getParentOverView();
  }, []);

  useEffect(() => {
    if (dataCountry !== null) {
      setChildCurriculum({ ...childCurriculum, country: dataCountry });
    }
  }, [dataCountry]);

  useEffect(() => {
    if (selectedProvince !== null) {
      setChildCurriculum({ ...childCurriculum, province: selectedProvince });
    }
  }, [selectedProvince]);

  useEffect(() => {
    getProvinces(childCurriculum.country);
  }, [childCurriculum.country]);

  const getProvinces = useCallback(
    async country => {
      const { success, data } = await getApiWithoutAuth(`${API_URL.GET_PROVINCES}?name=${country}`);
      if (success) {
        setProvinces(data);
        setSelectedProvince(data[0]?.name);
        setSelectedProvinceId(data[0]?.id);
      }
    },
    [childCurriculum.country]
  );

  useEffect(() => {
    if (selectedProvinceId !== null) {
      setChildCurriculum({ ...childCurriculum, province_id: selectedProvinceId });
    }
  }, [selectedProvinceId]);

  return (
    <>
      <div className="child-account container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle py-5">
        <AuthenticatedNavbar title="Children" playButton />
        <div className="unitPlanTextStyle row mb-3">
          <div className="col-9">
            <p>
              This is where you can view and edit details of your children’s accounts and view their login details. To
              edit their membership and billing info, head to our{' '}
              <Link to="/memberships-and-billing">memberships page</Link>.
            </p>
          </div>
        </div>
        <h3 className="fixCardHeadingStyle">Your Users</h3>
        {!isLoading ? (
          <div className="row">
            <div className="col col-sm-7 pb-3">
              <div>
                <div className="pb-3">
                  {subscriptionDetail.subscribed_children?.map(item => {
                    return (
                      <div key={item.subscription_id}>
                        <AssignmentCardWithColor
                          account
                          title={`${item.student.first_name} ${item.student.last_name.substring(0, 1).toUpperCase()}`}
                          userInfo={item}
                          color={item.plan_type.plan === 'Premium' ? '#F8CA42' : '#4B3AD3'}
                          isFree={item.plan_type.plan !== 'Premium'}
                          viewReports={() => editAccountDetail(item)}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="pt-3 textStyle400Cabin18Black">
                  Want to add another child?
                  <span className="parentOverviewHadding cursorPointer addLineBottom" onClick={showAddChild}>
                    &nbsp;Add a Child &gt;
                  </span>
                </div>
              </div>
            </div>
            <div className="col col-sm-5 pb-3">
              <div className="py-3">
                <LoginDetails />
              </div>
            </div>
          </div>
        ) : (
          <Skeleton height={200} active />
        )}
      </div>

      <EducatorModal visible={showAddChildModal} onCancel={hideAddChild}>
        <div>
          <div className="fixCardHeadingStyle">Add a Child</div>
          <div className="fixCardtextStyle mt-2">
            Would you like to create a new account for the child you’re adding or are you looking to link an account
            that already exists?
          </div>

          <div className="mt-3">
            <EducatorButton
              key="button"
              label="I’d like to create a new account"
              type="button"
              width="100%"
              onClick={showCreateChild}
            />
          </div>
          <div className="mt-3">
            <EducatorButton
              key="button"
              label="I’d like to connect an existing account"
              type="button"
              width="100%"
              onClick={showExistingCode}
            />
          </div>
        </div>
      </EducatorModal>

      <EducatorModal visible={showCreateChildModal} onCancel={hideCreateChild}>
        <div>
          <div className="fixCardHeadingStyle">Create Your Child’s Account</div>
          <div className="fixCardtextStyle mt-2 mb-3">
            Fill out the form below to sign your child up for Cyber Legends. Educational details allows us to ensure the
            content they’re seeing reflects that they learn about in school.
          </div>
          <Form onFinish={createChild}>
            <Form.Item
              name="first_name"
              rules={[
                { required: true, message: 'Please input your First Name!' },
                {
                  pattern: new RegExp(/^[A-Za-z]+$/),
                  message: 'Must contain Alphabets'
                }
              ]}>
              <EducatorInputField
                label="First Name:"
                type="text"
                inputValue={childData.first_name}
                headerColor="#000000"
                name="first_name"
                onChange={onChangeHandle}
                width="100%"
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  pattern: /^[a-zA-Z]{1}$/,
                  message: 'First Letter of Last Name'
                }
              ]}>
              <EducatorInputField
                label="First Letter of Last Name:"
                headerColor="#000000"
                inputValue={childData.last_name}
                type="text"
                name="last_name"
                onChange={onChangeHandle}
                width="100%"
              />
            </Form.Item>
            <div className="pt-3">
              <EducatorButton label="Create Account" type="submit" width="100%" showSpinner={buttonSpinner} />
            </div>
          </Form>
        </div>
      </EducatorModal>

      <EducatorModal visible={showAddCurriculumModal} onCancel={hideCurriculumChild}>
        <div>
          <div className="fixCardHeadingStyle">Add Education Details</div>
          <div className="fixCardtextStyle mt-2">
            Entering your child’s education details allows us to match our content with what they learn about in school.
          </div>
          <Form onFinish={curriculumChild}>
            <Form.Item>
              <div className="pt-4">
                <div>
                  <label className="labelStyleCard">
                    What country does{' '}
                    {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to school
                    in?
                  </label>
                  <div className="mt-3">
                    <Select
                      className="fixCardInputField"
                      value={childCurriculum?.country}
                      onChange={selectCountry}
                      name="country">
                      {countries.map(item => (
                        <Option key={item.name} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="pt-4">
                <div>
                  <label className="labelStyleCard">
                    What province does{' '}
                    {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to school
                    in?
                  </label>
                  <div className="mt-3">
                    <Select
                      className="fixCardInputField"
                      value={selectedProvince}
                      onChange={selectProvince}
                      name="country">
                      {provinces.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="pt-4">
                <div>
                  <label className="labelStyleCard">
                    What grade is{' '}
                    {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} in?
                  </label>
                  <div className="mt-3">
                    <Select
                      onChange={selectGrade}
                      value={childCurriculum?.child_grade}
                      className="fixCardInputField"
                      name="child_grade">
                      {grades.map(item => (
                        <Option key={item.name} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </Form.Item>
            <div className="pt-3">
              <EducatorButton label="Add Curriculum" type="submit" width="100%" showSpinner={buttonSpinner} />
            </div>
          </Form>
        </div>
      </EducatorModal>
      <EducatorModal visible={showDetailModal} onCancel={hideDetail}>
        <div>
          <div className="fixCardHeadingStyle">Your child’s account has been created!</div>
          <div className="fixCardtextStyle mt-2">
            This new account has been successfully created! Below are the login details. Write them down or store them
            in a secure password manager
          </div>

          <Form onFinish={hideDetail}>
            <div className="mt-3 nameAndButtonContainer">
              <div className="childHaddingStyle">{detailDta?.user_name ? detailDta?.user_name : 'Your UserName'}</div>
              <CopyOutlined
                style={{ color: 'blue', fontSize: '18px' }}
                className="ms-4"
                onClick={() => {
                  navigator.clipboard.writeText(detailDta?.user_name);
                  message.info('Copied to Clipboard!');
                }}
              />
            </div>
            <div className="mt-3 childSecondText">Your Password:</div>
            <div className="mt-3 nameAndButtonContainer">
              <div className="childHaddingStyle">{detailDta?.password ? detailDta?.password : 'Your Password'}</div>
              <CopyOutlined
                style={{ color: 'blue', fontSize: '18px' }}
                className="ms-4"
                onClick={() => {
                  navigator.clipboard.writeText(detailDta?.password);
                  message.info('Copied to Clipboard!');
                }}
              />
            </div>
            <div className="py-3">
              <EducatorButton label="That’s it for now!" type="submit" width="100%" />
            </div>
          </Form>
        </div>
      </EducatorModal>
      <EducatorModal visible={linkExistingModal} onCancel={hideExistingCode}>
        <div>
          <div className="fixCardHeadingStyle fixCradCenter">Link an Existing Account</div>
          <div className="linkExistingModalTextStyling fixCradCenter pt-2">
            You’ve indicated that your child already has a Cyber Legends account. To add them to your class, share the
            classcode below and have them enter it in the game.
          </div>
          <div className="linkExistingModalCodeStyling pt-3">{parentOverView.Household_code}</div>
          <div className="linkExistingModalTextStyling">Your Family Code</div>
        </div>
      </EducatorModal>
    </>
  );
};
