/* eslint-disable no-console */
import React, { useState } from 'react';
import { Navbar } from '../../layoutComponents';
import { EducatorButton } from '../../commonComponents';
import { useNavigate } from 'react-router-dom';
import award from '../../../assets/images/award.svg';
import { postApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import { setFlowComplete } from '@utils';
import { useAppState } from '../../../context';
import './parentSignedUp.css';
import { message } from 'antd';

const ParentSignedUp = () => {
  const { fetchCurrentUser } = useAppState();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const navigate = useNavigate();
  const parentFlowComplete = async () => {
    setButtonSpinner(true);
    const { success, data} = await postApiWithAuth(API_URL.SIGNUP_FREE_PARENT);
    if (buttonSpinner) return;
    if (success) {
      fetchCurrentUser();
      setFlowComplete(true);
      setButtonSpinner(false);
      navigate('/dashboard');
    } else {
      setButtonSpinner(false);
      message.error(data.message);
    }
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5">
        <div className="mt-2 w-50">
          <div className="d-flex justify-content-center">
            <img src={award} alt="award" width="110px" height="101px" />
          </div>
          <div className="textStyleHeading mt-3">You’re ready to start using Cyber Legends!</div>
          <div className="mt-2 textStyle400Cabin18">
            Enjoy the game!
          </div>
          <div className="pt-4 d-flex justify-content-center">
            <EducatorButton
              label="Sign Into the Dashboard"
              onClick={parentFlowComplete}
              type="button"
              width="276px"
              showSpinner={buttonSpinner}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentSignedUp;
