import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { Auth } from 'aws-amplify';

import { clearStorage } from '@utils';

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logoutUser();
  });

  const logoutUser = async () => {
    try {
      await Auth.signOut();
      clearStorage();
      navigate('/login');
    } catch (error) {
      navigate('/login');
    }
  };

  return (
    <div className="mainDivStyle">
      <div className="spineerCenter">
        <Spin size="large" />
      </div>
    </div>
  );
};
