import React from 'react';
import { useTranslation } from 'react-i18next';
import { Copy } from 'react-feather';
import { Card, Colors, Stack, Text, Title } from '@teacher-app/design-system';
import { useAppState } from '@context';
import { message } from 'antd';

const ClassCodeCopyCard = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);

  const copyToClipboard = async classCode => {
    try {
      await navigator.clipboard.writeText(classCode);
      message.success(t('class_code_copied'));
    } catch (error) {
      message.error(t('class_code_copy_fail'));
    }
  };

  return (
    <Card>
      <Stack direction="column" justifyContent='center' alignItems='center'>
        <Stack justifyContent='center'>
          <Title level={2} style={{ paddingTop: 3 }}>
            {currentClass?.class_code}
          </Title>
          <Copy style={{ cursor: 'pointer', color: Colors.VIOLET }} onClick={() => copyToClipboard(currentClass?.class_code)} />
        </Stack>
        <Text size="small" style={{ textAlign: 'center' }}>{t('share_class_code')}</Text>
      </Stack>
    </Card>
  );
};

export default ClassCodeCopyCard;
