import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import colors from '../../theme/colors';

const AntText = Typography.Text;

const getTypographySizes = size => {
  switch (size) {
    case 'x-small':
      return '14px !important';
    case 'small':
      return '16px !important';
    case 'large':
      return '20px !important';
    default:
      return '18px !important';
  }
};

const StyledText = styled(AntText)`
  font-size: ${props => getTypographySizes(props.size)};
  line-height: 150% !important;
  word-break: break-word;
  &.ant-typography {
    font-family: Lexend, sans-serif;
    color: ${colors.GREY_200};
    text-align: left;
    font-weight: 400;
  }
  &.ant-typography-secondary {
    color: ${colors.LAVENDER_50};
  }
  &.ant-typography-danger {
    color: ${colors.CHERRY};
  }
  &.ant-typography-warning {
    color: ${colors.GOLD};
  }
  &.ant-typography-success {
    color: ${colors.SPRING};
  }
  &.ant-typography-decorative {
    font-family: Cutive Mono, monospace;
  }
`;

const Text = ({ children, size, bold, decorative, className, ...rest }) => {
  const customClassName = `${decorative ? 'ant-typography-decorative' : ''} ${className}`;
  return (
    <StyledText className={customClassName} size={size} strong={bold} {...rest}>
      {children}
    </StyledText>
  );
};

export default Text;

Text.defaultProps = {
  children: '',
  type: 'primary',
  size: 'medium',
  bold: false,
  decorative: false,
  className: ''
};

Text.propTypes = {
  children: PropTypes.node,
  /**  Can be set to 'x-small', 'small', 'medium' and 'large'. 'medium' is default */
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  /**  Can be set to primary, secondary, success, warning and danger */
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'danger']),
  /** Can be set to show bold style */
  bold: PropTypes.bool,
  /** Can be set to show decorative style */
  decorative: PropTypes.bool,
  /** Can be set to pass custom classes */
  className: PropTypes.string
};
