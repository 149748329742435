import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Divider, Form, Radio, Switch, message, Skeleton, Input } from 'antd';
import dayjs from 'dayjs';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { useStudentSubscriptionDetails, useCards } from '../../../hooks';
import { EducatorButton } from '../../commonComponents';
import { API_URL, MEMBERSHIP_TYPE, TAX_RATE, getApiWithAuth, getDollars, patchApiWithAuth } from '../../../utils';
import * as Modal from '../Subscriptions/modals';
import './EditMembership.scss';

export const EditMembership = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const [isYearly, setIsYearly] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState({});
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  useEffect(() => {
    (async () => {
      const { success, data } = await getApiWithAuth(API_URL.PAYMENT_PLANS);
      if (success) {
        const [, monthly, yearly] = data;
        setPaymentPlans({ monthly, yearly });
      }
    })();
  }, []);

  const { data: cardData, revalidate } = useCards();
  const showAddPaymentMethod = useMemo(() => cardData?.length === 0, [cardData]);

  const { data, isLoading, mutate } = useStudentSubscriptionDetails(id);

  const student = data?.student_subscription_details?.student;
  const subscriptionDetails = data?.student_subscription_details;
  const dates = {
    startDate: dayjs(data?.student_subscription_details?.start_date).format('M/D/YY'),
    endDate: dayjs(data?.student_subscription_details?.end_date).format('M/D/YY')
  };

  const currentPlanType = useMemo(() => {
    const plan = data?.student_subscription_details?.plan_type.plan;
    setSelectedPlan(plan);
    return plan;
  }, [data]);

  const isUpgrading = currentPlanType === MEMBERSHIP_TYPE.FREEMIUM && selectedPlan === MEMBERSHIP_TYPE.PREMIUM;
  const isDowngrading = currentPlanType === MEMBERSHIP_TYPE.PREMIUM && selectedPlan === MEMBERSHIP_TYPE.FREEMIUM;
  const isSamePlan = currentPlanType === selectedPlan;

  const calculatedTotal = useMemo(() => {
    if (isCouponApplied || Object.entries(paymentPlans).length === 0) {
      return {
        subtotal: getDollars(0),
        estimatedTax: getDollars(0),
        total: getDollars(0)
      };
    }

    const subtotal = isYearly ? paymentPlans.yearly.amount : paymentPlans.monthly.amount;
    const estimatedTax = subtotal * TAX_RATE; // 13% tax

    return {
      subtotal: getDollars(subtotal),
      estimatedTax: getDollars(estimatedTax),
      total: getDollars(subtotal + estimatedTax)
    };
  }, [paymentPlans, isYearly, isCouponApplied]);

  const handleAddCardModal = useCallback(() => {
    setShowAddCardModal(false);
    revalidate();
  }, []);

  if (isLoading || Object.entries(paymentPlans).length === 0) {
    return (
      <div className="edit-membership container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar smallHeading1="Account Management" smallHeading2="Edit Membership" />
        <br />
        <Skeleton active />
      </div>
    );
  }

  const handleOnChange = event => {
    setSelectedPlan(event.target.value);
  };

  const handleSwitch = checked => {
    setIsYearly(checked);
  };

  const handleOnFinish = async ({ coupon }) => {
    setIsCouponApplied(false);

    const planId = isYearly ? paymentPlans.yearly.id : paymentPlans.monthly.id;
    const { success, data } = await getApiWithAuth(`coupons/${coupon}/${planId}`);

    if (success) {
      if (data.data.remaining_redemptions === 0) {
        message.error('Coupon is expired');
      } else {
        message.success(`This coupon is valid for ${data.data.remaining_redemptions} Children`);
        setIsCouponApplied(true);
      }
    } else {
      message.error(data.detail);
    }
  };

  const updateMembership = async () => {
    setIsButtonLoading(true);
    const { success, data } = await patchApiWithAuth(API_URL.UPDATE_SUBSCRIPTION_USER, {
      child: {
        id: student.student_id,
        plan_type: isUpgrading ? MEMBERSHIP_TYPE.PREMIUM : MEMBERSHIP_TYPE.FREEMIUM,
        subscription_id: subscriptionDetails.subscription_id
      },
      coupon_code: isCouponApplied ? form.getFieldValue('coupon') : null,
      payment_id: cardData[0]?.payment_id,
      premium_choice: isYearly ? 'year' : 'month'
    });

    setIsButtonLoading(false);

    if (success) {
      mutate();
      message.success('Membership updated successfully');
    } else {
      message.error(data.message);
    }
  };

  return (
    <>
      <div className="edit-membership container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar smallHeading1="Account Management" smallHeading2="Edit Membership" playButton />
        <div className="unitPlanTextStyle row">
          <div className="col-8">
            <h2 className="authenticatedNavbarHeadingStyle capital mt-4">
              Edit {student?.first_name} {student?.last_name}&rsquo;s Membership
            </h2>
            <p>This is where you can update your child’s membership.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-sm-7 pb-3">
            <Card bordered={false} className="cardsStyling p-4 mb-4">
              <div className="card__inner">
                <h3 className="settingHeadingStyle">Select Your Membership Type:</h3>
                <Radio.Group onChange={handleOnChange} defaultValue={currentPlanType} className="membership-type">
                  <div className="membership-type__wrapper">
                    <Radio value={MEMBERSHIP_TYPE.FREEMIUM}>
                      <h4 className="card-text">Regular Member</h4>
                      <p className="card-text-cost">Free!</p>
                    </Radio>
                  </div>
                  <div className="membership-type__wrapper">
                    <Radio value={MEMBERSHIP_TYPE.PREMIUM}>
                      <h4 className="card-text">Premium Member</h4>
                      <p className="card-text-cost">{getDollars(paymentPlans.yearly.amount)}/year</p>
                    </Radio>
                  </div>
                </Radio.Group>
              </div>
              <div className="card__inner">
                <h3 className="settingHeadingStyle">Select a Billing Cycle:</h3>
                <div className="billing-cycle__wrapper">
                  <h4>Monthly</h4>
                  <Switch onChange={handleSwitch} checked={isYearly} disabled={isSamePlan || isDowngrading} />
                  <h4>Yearly - save 35%</h4>
                </div>
              </div>
              <div className="card__inner">
                <h3 className="settingHeadingStyle">Redeem Coupon Code:</h3>
                <Form form={form} onFinish={handleOnFinish}>
                  <Form.Item name="coupon">
                    <Input
                      placeholder="Enter Coupon Code"
                      className="inputFieldStyle"
                      type="text"
                      name="coupon"
                      disabled={!isUpgrading}
                      suffix={
                        isCouponApplied ? (
                          <EducatorButton
                            key="button"
                            label="Coupon Applied!"
                            type="submit"
                            className="applyCouponButton"
                            isCoupon
                            disabled
                          />
                        ) : (
                          <EducatorButton
                            key="button"
                            label="Apply Coupon"
                            type="submit"
                            className="applyCouponButton"
                            isCoupon
                          />
                        )
                      }
                    />
                  </Form.Item>
                </Form>
              </div>
            </Card>
            {showAddPaymentMethod ? (
              <div className="add-payment__button">
                <button onClick={() => setShowAddCardModal(true)}>+ Add Your Payment Method</button>
              </div>
            ) : null}
          </div>
          <div className="col col-5 col-sm-5 pb-3">
            {isSamePlan ? (
              <Card bordered={false} className="cardsStyling p-4 mb-4">
                <p>
                  You have selected your exciting subscription. <br /> Please select a different option.
                </p>
              </Card>
            ) : (
              <Card bordered={false} className="cardsStyling p-4 mb-4">
                {isUpgrading ? (
                  <div className="pt-2  spaceBetweenDiv">
                    <p>Premium {isYearly ? 'Annual' : 'Subscription'}</p>
                    <div className="pt-2  spaceBetweenDiv">
                      <p>
                        {isYearly ? getDollars(paymentPlans.yearly.amount) : getDollars(paymentPlans.monthly.amount)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="pt-2  spaceBetweenDiv">
                    <p>Downgrade Membership</p>
                    <div className="pt-2  spaceBetweenDiv">
                      <p>{getDollars(0)}</p>
                    </div>
                  </div>
                )}

                {isCouponApplied ? (
                  <div className="pt-2  spaceBetweenDiv">
                    <p>Coupon Code</p>
                    <div className="pt-2  spaceBetweenDiv">
                      <p>
                        -{isYearly ? getDollars(paymentPlans.yearly.amount) : getDollars(paymentPlans.monthly.amount)}
                      </p>
                    </div>
                  </div>
                ) : null}
                {isUpgrading ? (
                  <>
                    <Divider className="manageDividerStyleOne" />
                    <div className="pt-2  spaceBetweenDiv">
                      <p>Subtotal</p>
                      <div className="pt-2  spaceBetweenDiv">
                        <p>{calculatedTotal.subtotal}</p>
                      </div>
                    </div>
                    <div className="pt-2 spaceBetweenDiv">
                      <p>Estimated Tax</p>
                      <div className="pt-2  spaceBetweenDiv">
                        <p>{calculatedTotal.estimatedTax}</p>
                      </div>
                    </div>
                  </>
                ) : null}
                <Divider className="manageDividerStyleTwo" />
                <div className="total pt-2 secondCardHeading spaceBetweenDiv">
                  <h3>Total</h3>
                  <div className="pt-2  spaceBetweenDiv">
                    <h3>{calculatedTotal.total}</h3>
                  </div>
                </div>
                <Divider className="manageDividerStyleTwo" />
                <div className="total-text">
                  {isCouponApplied && isUpgrading ? (
                    <p>
                      You have added a coupon to your account. You will see this discount applied on your next billing
                      cycle, starting <span>{dates.startDate}</span>.
                    </p>
                  ) : !isCouponApplied && isUpgrading ? (
                    <p>
                      Memberships are non-refundable. If you downgrade your membership, changes will take effect at the
                      end of your billing cycle.
                    </p>
                  ) : (
                    <p>
                      You have chosen to downgrade your child’s account. They will continue to have premium access until{' '}
                      <span>{dates.endDate}</span> (the end of your pre-paid billing cycle).
                    </p>
                  )}
                  <div className="pt-3">
                    <EducatorButton
                      showSpinner={isButtonLoading}
                      label={isUpgrading ? 'Upgrade Membership' : 'Downgrade Membership'}
                      type="button"
                      width="100%"
                      onClick={updateMembership}
                      disabled={cardData.length === 0 && !isCouponApplied}
                    />
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
      <Modal.AddCardModal
        show={showAddCardModal}
        handleOnCancel={handleAddCardModal}
        handleAddCard={handleAddCardModal}
      />
    </>
  );
};
