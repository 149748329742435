import React from 'react';
import PropTypes from 'prop-types';
import AddCard from '@pages/MembershipAndBilling/AddCard';
import { EducatorModal } from '@components/commonComponents';

export const AddCardModal = ({ show, handleOnCancel, handleAddCard }) => {
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div className="">
          <AddCard closeModal={handleAddCard} />
        </div>
      }
    />
  );
};

export default AddCardModal;

AddCardModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired
};
