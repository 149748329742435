/* eslint-disable no-console */
import React, { useState } from 'react';
import { Navbar } from '../../layoutComponents';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import {
  clearStorage,
  getApiWithAuth,
  getIsGoogle,
  getLocalStorageItem,
  setFlowComplete,
  setToken,
  setUserID
} from '@utils';
import { API_URL, GOOGLE_SSO_AUTH_URL, GOOGLE_SSO_LOGOUT_URL } from '../../../utils/constants';
import './parentSignIn.css';

import googleImage from '../../../assets/images/googleImage.png';

const ParentSignIn = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ email: '', password: '' });
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [googleSpinner, setGoogleSpinner] = useState(false);

  const signInParent = () => {
    setButtonSpinner(true);
    Auth.signIn({
      username: data.email,
      password: data.password
    })
      .then(() => {
        saveParentToken();
      })
      .catch(err => {
        message.error(`${err.message}`);
        setButtonSpinner(false);
      });
  };
  const getCurrentUser = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      return data;
    }
  };

  const logoutUser = async () => {
    const Google = getIsGoogle();
    if (Google === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          setButtonSpinner(false);
          clearStorage();
          message.error('This account does not exist as a parent');
        })
        .catch(err => {
          setButtonSpinner(false);
          message.error(`${err.message}`);
        });
    }
  };

  const saveParentToken = async () => {
    await Auth.currentSession()
      .then(async data => {
        setToken(data.accessToken.jwtToken);
        const responseData = await getCurrentUser();
        if (responseData.type !== getLocalStorageItem('user_role')) {
          logoutUser();
        } else {
          setUserID(responseData.id);
          if (responseData.is_auth) {
            setFlowComplete(true);
            setButtonSpinner(false);
          } else {
            setFlowComplete(false);
            setButtonSpinner(false);
            navigate('/parent-create-account/child-setup');
          }
        }
      })
      .catch(() => {
        navigate('/parent-signin');
      });
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const googleSignIn = () => {
    setGoogleSpinner(true);
    window.location.href = GOOGLE_SSO_AUTH_URL;
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5">
        <div className="mt-2">
          <div className="textStyleHeading mb-1">Parent Login</div>
          <Form onFinish={signInParent}>
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
              <EducatorInputField
                label="Email Address:"
                type="email"
                name="email"
                inputValue={data.email}
                onChange={onChangeHandle}
              />
            </Form.Item>
            <Form.Item
              className="pt-3"
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}>
              <EducatorInputField
                label="Password:"
                type="password"
                passwordValue={data.password}
                name="password"
                onChange={onChangeHandle}
              />
            </Form.Item>
            <div className="pt-3">
              <EducatorButton label="Sign In " type="submit" showSpinner={buttonSpinner} />
            </div>
          </Form>
          <div className="pt-3 textStyle400Cabin18">OR</div>
          <div className="pt-3">
            <EducatorButton
              label="Sign in with Google "
              className="googleStyle"
              onClick={googleSignIn}
              showSpinner={googleSpinner}
              type="button"
              icon={<img src={googleImage} alt="googleLogo" width={40} height={40} />}
            />
          </div>
          <div className="mt-3 textStyle400Cabin18">
            Don’t have an account?
            <span
              className="labelStyle cursorPointer"
              onClick={() => {
                navigate('/parent-create-account');
              }}>
              {' '}
              Sign Up!
            </span>
          </div>
          <div
            className=" mt-3 textStyleForgetPassword cursorPointer"
            onClick={() => {
              navigate('/forgot-password');
            }}>
            Forgot Password?
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentSignIn;
