import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin, Empty } from 'antd';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Pagination } from '@components/commonComponents';
import { API_URL, getApiWithAuth, sendGaEvent } from '@utils';
import './EdubytesStyle.scss';
import { EdubyteCard } from './Detail/EdubyteCard';

export const Edubytes = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const navigate = useNavigate();

  const [edubytesData, setEdubytesData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getEdubytes = async () => {
    setButtonSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.SCHOOL_EDUBYTES}`);
    if (success) {
      setEdubytesData(data);
      setCurrentPage(1);
      setButtonSpinner(false);
    } else {
      setButtonSpinner(false);
    }
  };

  useEffect(() => {
    getEdubytes();
  }, []);

  const handleCardClick = item => {
    sendGaEvent('card_click', 'edubyte__card_click', item.name);
    navigate(`/edubytes/${item.id}`, {
      state: {
        itemId: item.id,
        title: item.name
      }
    });
  };

  const totalPages = Math.ceil(edubytesData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = edubytesData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="edubytes__container container-fluid p-2 p-md-3 p-lg-4 mainDivParentTopicsStyle">
        <AuthenticatedNavbar
          title={
            <div className="row">
              <div className="col col-12">{t('edubytes')}</div>
            </div>
          }
        />
        <div className="row">
          <div className="col-12 col-sm-12">
            <div className="edubytes__description">
              <span>{t('edubytes_description')}</span>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-sm-12">
            <div>
              {buttonSpinner ? (
                <div className="emptyMessageStudent">
                  <Spin size="large" />
                </div>
              ) : edubytesData?.length === 0 ? (
                <div className="emptyMessageStudent">
                  {buttonSpinner ? <Spin size="large" /> : <Empty description={false} />}
                </div>
              ) : (
                <div>
                  <div className="row">
                    {currentItems?.map(item => {
                      return (
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-3" key={item.id}>
                          <EdubyteCard
                            image={item.thumbnail}
                            title={item.name}
                            videos={item.videos}
                            onClick={() => handleCardClick(item)}
                            isLocked={item.is_locked}
                            itemId={item.id}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
