import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Empty, Spin } from 'antd';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL } from '@utils';
import defaultImage from '@assets/images/cyber-academy/default.png';

export const BadgePage = forwardRef(({ onBadgeClick }, ref) => {
  const { t } = useTranslation('student', { keyPrefix: 'badge_booklet' });
  const { data: badgesData, error: badgesError, isLoading: isBadgesLoading } = useSWR(`${API_URL.GET_STUDENT_BADGES}`);
  const {
    data: badgeAchievementsData,
    error: badgeAchievementsError,
    isLoading: isBadgeAchievementsLoading
  } = useSWR(`${API_URL.GET_STUDENT_BADGES_ACHIEVEMENTS}`);

  const isLoading = isBadgesLoading || isBadgeAchievementsLoading;
  const isError = badgesError || badgeAchievementsError;

  const badges = badgesData?.data;

  const badgeAchievements = badgeAchievementsData?.data.reduce((acc, achievement) => {
    const categoryId = achievement.sub_heading.id;
    const category = achievement.sub_heading.name;
    const rank = parseInt(achievement.sub_heading_grade.grade.number);
    const displayName = achievement.sub_heading.name;
    const isAchieved = !!achievement.user_achievement_id;
    const image = achievement.achievement.image;

    if (!acc[categoryId]) {
      acc[categoryId] = {
        name: category,
        totalRanks: 0,
        earnedRanks: 0,
        rank,
        displayName,
        displayImage: image,
        isAchieved: false
      };
    }

    acc[categoryId].totalRanks++;
    if (isAchieved) {
      acc[categoryId].earnedRanks++;
      acc[categoryId].isAchieved = true;
      acc[categoryId].rank = rank;
      acc[categoryId].displayImage = image;
    } else {
      if (!acc[categoryId].isAchieved && rank < acc[categoryId].rank) {
        acc[categoryId].rank = rank;
        acc[categoryId].displayImage = image;
      }
    }

    return acc;
  }, {});

  const handleBadgeClick = (badge, index, isAchieved) => {
    if (!isAchieved) return;
    onBadgeClick(badge, index + 2);
  };

  return (
    <div className="page" ref={ref}>
      <div className="page-content">
        <h3 className="page-header">{t('becoming_cyber_legend')}</h3>
        {isLoading ? (
          <div className="emptyMessageStudent">
            <Spin size="large" />
          </div>
        ) : isError ? (
          <div className="emptyMessageStudent">
            <Empty description="Error loading data" />
          </div>
        ) : badges?.length === 0 ? (
          <div className="emptyMessageStudent">
            <Empty description={false} />
          </div>
        ) : (
          <div className="row justify-content-center badges__container">
            {badges.map((badge, index) => (
              <div className="col-4 mt-2 mb-2 d-flex flex-column align-items-center" key={badge.displayRank}>
                <div
                  className="d-flex flex-column w-100 align-items-center h-100"
                  onClick={() => handleBadgeClick(badge, index, badgeAchievements[badge.id]?.isAchieved)}>
                  <img
                    src={badgeAchievements[badge.id]?.displayImage ?? defaultImage}
                    alt={badgeAchievements[badge.id]?.displayName}
                    className={`text-center img-fluid mb-2 ${
                      !badgeAchievements[badge.id]?.isAchieved ? 'greyed-out' : ''
                    }`}
                    style={{ margin: 'auto' }}
                  />
                  <h6 className="badge-name">{badgeAchievements[badge.id]?.displayName}</h6>
                  <div className="progress w-100">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (badgeAchievements[badge.id]?.earnedRanks * 100) / badgeAchievements[badge.id]?.totalRanks
                        }%`
                      }}
                      aria-valuenow={
                        (badgeAchievements[badge.id]?.earnedRanks * 100) / badgeAchievements[badge.id]?.totalRanks
                      }
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                    <p className="progress-text">
                      {`${badgeAchievements[badge.id]?.earnedRanks ?? 0}/${
                        badgeAchievements[badge.id]?.totalRanks ?? 0
                      }`}{' '}
                      {t('ranks_earned')}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

BadgePage.displayName = 'Badge Page';
BadgePage.propTypes = {
  onBadgeClick: PropTypes.func.isRequired
};
