import React from 'react';
import { Trans } from 'react-i18next';
import './footer.scss';

function Footer() {
  return (
    <div className="footer">
      <Trans i18nKey="nav.need_help" ns="translation">
        Need help? email us at <a href="mailto:support@cyberlegends.com">support@cyberlegends.com</a>
      </Trans>
    </div>
  );
}

export default Footer;
