import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { API_URL, getApiWithAuth } from '@utils';

import './StudentDashboardDetailStyle.scss';
import UnityView from '@components/commonComponents/Unity/unity';

export const StudentDashboardDetail = () => {
  const { id, type } = useParams();

  const [mission, setMission] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [unityTemplate, setUnityTemplate] = useState(null);

  const navigate = useNavigate();

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const getMission = async () => {
    setLoadingSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_MISSIONS_LIST}/${id}`);
    if (success) {
      setMission({ mission_id: data.external_id });
      setUnityTemplate(data.unity_template);
      setLoadingSpinner(false);
    } else {
      setLoadingSpinner(false);
    }
  };

  const getAssignment = async () => {
    setLoadingSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_ASSIGNMENTS_LIST}/${id}`);
    if (success) {
      setAssignment({ assignment_id: id });
      setUnityTemplate(data.unity_template);
      setLoadingSpinner(false);
    } else {
      setLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (type === 'mission') {
      getMission();
    } else if (type === 'assignment') {
      getAssignment();
    }
  }, [type]);

  const onClose = () => {
    navigate('/student-dashboard', { replace: true });
  };

  return (
    <div className="student-dashboard-details__container">
      <div style={{ position: 'fixed', left: '0', top: '0' }}>
        {loadingSpinner ? (
          <div className="emptyMessageStudent">
            <Spin size="large" />
          </div>
        ) : !mission && !assignment && !unityTemplate ? (
          <div className="emptyMessageStudent">
            <div>Something Went Wrong</div>
          </div>
        ) : (
          <div>
            <UnityView
              useBackHack
              mission={mission}
              assignment={assignment}
              unityTemplate={unityTemplate}
              userType={'students'}
              onClose={onClose}
            />
          </div>
        )}
      </div>
    </div>
  );
};
