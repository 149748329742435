import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Stack, Text, Title, Colors } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
    tbody {
        vertical-align: top;
    }
    .table-row-dark {
        background: #F1F5F7;
    }
    .ant-table-cell {
        border: none;
    }
    .ant-table-thead>tr>th {
        padding: 0 20px 10px 20px;
        background: none;
    }
    .ant-table-thead>tr>th:before {
        width: 0 !important;
    }
    .ant-table-tbody>tr>td {
        padding: 20px 40px 20px 20px;
    }
`;

const columns = t => [
  {
    title: <Title level={4} style={{ color: Colors.GREY_100 }}>{t('curriculum')}</Title>,
    dataIndex: 'curriculum',
    key: 'curriculum',
    width: 180,
    render: text => (
      <Text size='x-small' style={{ color: Colors.GREY_100 }}>
        {text}
      </Text>
    )
  },
  {
    title: <Title level={4} style={{ color: Colors.GREY_100 }}>{t('standard')}</Title>,
    dataIndex: 'standard',
    key: 'standard',
    width: 142,
    render: text => (
      <Text size='x-small' style={{ color: Colors.GREY_100 }}>
        {text}
      </Text>
    )
  },
  {
    title: <Title level={4} style={{ color: Colors.GREY_100 }}>{t('description')}</Title>,
    dataIndex: 'description',
    key: 'description',
    width: 398,
    render: text => (
      <Text size='x-small' style={{ color: Colors.GREY_100 }}>
        {text}
      </Text>
    )
  }
];

const AlignmentDetailsModal = ({ isOpen, onClose, curriculums }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const data = [];

  curriculums.forEach((curriculum, i) => {
    curriculum.alignments.forEach((alignment, j) => {
      data.push({
        key: `${i}.${j}`,
        curriculum: curriculum.name,
        standard: alignment.standard,
        description: alignment.description
      });
    });
  });

  return (
    <Modal width={800} closable centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" alignItems='flex-start' spacing={0}>
        <Title level={3}>{t('curriculum_alignments_details')}</Title>
        <Text size='small' style={{ color: Colors.GREY_50 }}>{t('curriculum_alignments_details_text')}</Text>
        <Stack alignItems="flex-start" direction="column" style={{ paddingTop: 30 }}>
          <StyledTable
            columns={columns(t)}
            scroll={{ y: 550 }}
            pagination={{ hideOnSinglePage: true }}
            dataSource={data}
            rowClassName={(_, index) => index % 2 === 0 ? 'table-row-dark' :  ''}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AlignmentDetailsModal;

AlignmentDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  curriculums: PropTypes.array.isRequired
};
