import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card as AntCard } from 'antd';
import colors from '../../theme/colors';

const { Meta } = AntCard;

const StyledCard = styled(AntCard)`
  &.ant-card {
    border-radius: 15px;
    width: ${props => props.width};
    max-width: ${props => props.maxwidth};
    box-shadow: 0 4px 25px 0 #00000014;
    .ant-card-body {
      padding: 30px !important;
    }
    .ant-card-meta-title {
      font-family: Catamaran, sans-serif;
      color: ${colors.GREY_200};
      margin-bottom: 0;
      text-align: left;
      font-weight: 800;
      font-size: 28px;
      line-height: 33.6px;
      padding-bottom: 12px;
    }
    .ant-card-meta-description {
      font-family: Lexend, sans-serif;
      color: ${colors.GREY_100};
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      padding-bottom: 12px;
    }
  }
`;

const Card = ({ children, title, description, maxWidth, ...rest }) => {
  return (
    <StyledCard maxwidth={maxWidth} {...rest}>
      <Meta title={title} description={description} />
      {children}
    </StyledCard>
  );
};

export default Card;

Card.defaultProps = {
  title: '',
  description: '',
  width: 300,
  maxWidth: '100%'
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  /** Card title */
  title: PropTypes.string,
  /** Description content */
  description: PropTypes.string,
  /** Width of the card */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Max-width of the card */
  maxWidth: PropTypes.string
};
