export const lilac = {
  LILAC_50: '#D2C2FF',
  LILAC: '#AF8EF4',
  LILAC_100: '#9167B8',
  LILAC_200: '#5D337D',
  LILAC_300: '#3C1C55'
};

export const lavender = {
  LAVENDER_50: '#D4DFFC',
  LAVENDER: '#85A4F7',
  LAVENDER_100: '#6B8BE8',
  LAVENDER_200: '#2E4DA7',
  LAVENDER_300: '#202D4D'
};

export const violet = {
  VIOLET_50: '#AA80FF',
  VIOLET: '#674EFF',
  VIOLET_100: '#4E38F0',
  VIOLET_200: '#31247C',
  VIOLET_300: '#31247C'
};

export const midnight = {
  MIDNIGHT_50: '#8492E1',
  MIDNIGHT: '#674EFF',
  MIDNIGHT_100: '#3C46A8',
  MIDNIGHT_200: '#31247C',
  MIDNIGHT_300: '#1B1250'
};

export const purple = {
  PURPLE_50: '#8F76B3',
  PURPLE: '#63527C',
  PURPLE_100: '#554568',
  PURPLE_200: '#453558',
  PURPLE_300: '#2E243B'
};

export const plum = {
  PLUM_50: '#B75AAA',
  PLUM: '#732066',
  PLUM_100: '#520345',
  PLUM_200: '#390132',
  PLUM_300: '#1E0018'
};

export const fuchsia = {
  FUCHSIA_50: '#FFA0C9',
  FUCHSIA: '#EA6EDC',
  FUCHSIA_100: '#CD51BE',
  FUCHSIA_200: '#B236A3',
  FUCHSIA_300: '#732068'
};

export const barbie = {
  BARBIE_50: '#FD90F7',
  BARBIE: '#EF4CAD',
  BARBIE_100: '#D43486',
  BARBIE_200: '#B61C6B',
  BARBIE_300: '#740E39'
};

export const deepRose = {
  DEEP_ROSE_50: '#F755C2',
  DEEP_ROSE: '#CD2D69',
  DEEP_ROSE_100: '#7A124F',
  DEEP_ROSE_200: '#681153',
  DEEP_ROSE_300: '#400135'
};

export const cherry = {
  CHERRY_50: '#FF487B',
  CHERRY: '#E62440',
  CHERRY_100: '#C62438',
  CHERRY_200: '#991222',
  CHERRY_300: '#652E32'
};

export const firetruck = {
  FIRETRUCK_0: '#FFE6DF',
  FIRETRUCK_50: '#FF987D',
  FIRETRUCK: '#F65143',
  FIRETRUCK_100: '#D13E32',
  FIRETRUCK_200: '#AC2216',
  FIRETRUCK_300: '#6B2427'
};

export const gold = {
  GOLD_50: '#FFD07D',
  GOLD: '#FFA828',
  GOLD_100: '#D1800E',
  GOLD_200: '#A96300',
  GOLD_300: '#6A3E00'
};

export const daffodil = {
  DAFFODIL_50: '#FFFF78',
  DAFFODIL: '#FEC93C',
  DAFFODIL_100: '#FD9E0E',
  DAFFODIL_200: '#CC6600',
  DAFFODIL_300: '#753E12'
};

export const banana = {
  BANANA_50: '#EEF153',
  BANANA: '#FFE78D',
  BANANA_100: '#E7CB67',
  BANANA_200: '#D4AC3A',
  BANANA_300: '#977825'
};

export const seaFoam = {
  SEA_FOAM_50: '#C4F6EB',
  SEA_FOAM: '#02D8A9',
  SEA_FOAM_100: '#09B699',
  SEA_FOAM_200: '#09937C',
  SEA_FOAM_300: '#045D48'
};

export const shamrock = {
  SHAMROCK_50: '#52D694',
  SHAMROCK: '#29B86B',
  SHAMROCK_100: '#169C49',
  SHAMROCK_200: '#0D6B36',
  SHAMROCK_300: '#0A5229'
};

export const spring = {
  SPRING_50: '#EEF153',
  SPRING: '#96BF30',
  SPRING_100: '#71A219',
  SPRING_200: '#618D13',
  SPRING_300: '#424F27'
};

export const moss = {
  MOSS_50: '#ABBF7A',
  MOSS: '#859858',
  MOSS_100: '#526022',
  MOSS_200: '#414D1D',
  MOSS_300: '#1A1C06'
};

export const olive = {
  OLIVE_50: '#FFFFDD',
  OLIVE: '#768451',
  OLIVE_100: '#49501A',
  OLIVE_200: '#33390D',
  OLIVE_300: '#1A1C07'
};

export const teal = {
  TEAL_50: '#7AAD9E',
  TEAL: '#4E7C6F',
  TEAL_100: '#055C49',
  TEAL_200: '#004636',
  TEAL_300: '#003628'
};

export const mist = {
  MIST_50: '#B6FBEE',
  MIST: '#E8FEF7',
  MIST_100: '#B2D1C8',
  MIST_200: '#4E7D70',
  MIST_300: '#314E46'
};

export const sky = {
  SKY_50: '#BAF9FD',
  SKY: '#7FD5DF',
  SKY_100: '#50A6B2',
  SKY_200: '#2F4F52',
  SKY_300: '#1D3335'
};

export const steel = {
  STEEL_50: '#6FD3D5',
  STEEL: '#4187A0',
  STEEL_100: '#38739F',
  STEEL_200: '#395380',
  STEEL_300: '#213438'
};

export const space = {
  SPACE_50: '#A1B7CF',
  SPACE: '#304862',
  SPACE_100: '#2A3859',
  SPACE_200: '#1F2C4E',
  SPACE_300: '#0B1425'
};

export const white = {
  WHITE_0: '#FFFFFF',
  WHITE_50: '#FDFBF9',
  WHITE_100: '#F9F3EE',
  WHITE_200: '#CCC8C7'
};

export const grey = {
  GREY_0: '#8E8E8E',
  GREY_50: '#595D5C',
  GREY_100: '#3B3D3C',
  GREY_200: '#222222',
  GREY_300: '#020000'
};

const colors = {
  ...lilac,
  ...lavender,
  ...violet,
  ...midnight,
  ...purple,
  ...plum,
  ...fuchsia,
  ...barbie,
  ...deepRose,
  ...cherry,
  ...firetruck,
  ...gold,
  ...daffodil,
  ...banana,
  ...seaFoam,
  ...shamrock,
  ...spring,
  ...moss,
  ...olive,
  ...teal,
  ...mist,
  ...sky,
  ...steel,
  ...space,
  ...white,
  ...grey
};

export default colors;
