import useSWR, { useSWRConfig } from 'swr';
import { getLocalStorageItem, USER_ROLE } from '@utils';
import { useAppState } from '@context';
import { useStudents } from './useStudents';

const useData = url => {
  const { data, error, isLoading, mutate } = useSWR(url);
  return { data: data?.data, error, isLoading, revalidate: mutate };
};

export const usePdfs = () => {
  const { cache } = useSWRConfig();
  const { state } = useAppState();
  const role = getLocalStorageItem('user_role');

  if (role === USER_ROLE.TEACHER) {
    const classId = state.classId;
    const { data: students } = useStudents(classId);
    const hasStudent = students && students.length > 0;

    const URL = {
      accountDetails: classId && hasStudent ? `/users/class/${classId}/all-students-account-credentials` : null,
      loginInstructions: classId && hasStudent ? `/users/class/${classId}/all-students-login-instructions` : null,
      parentLetter: classId && hasStudent ? `/users/class/${classId}/all-students-parent-letters` : null
    };

    const accountDetails = useData(URL.accountDetails);
    const loginInstructions = useData(URL.loginInstructions);
    const parentLetter = useData(URL.parentLetter);

    return {
      loading: accountDetails.isLoading || loginInstructions.isLoading || parentLetter.isLoading,
      error: accountDetails.error || loginInstructions.error || parentLetter.error,
      data: {
        accountDetails: accountDetails.data,
        loginInstructions: loginInstructions.data,
        parentLetter: parentLetter.data
      },
      isEmpty: !accountDetails.data && !loginInstructions.data && !parentLetter.data,
      revalidate: () => {
        cache.delete(URL.accountDetails);
        cache.delete(URL.loginInstructions);
        cache.delete(URL.parentLetter);

        accountDetails.revalidate();
        loginInstructions.revalidate();
        parentLetter.revalidate();
      }
    };
  }

  const URL = {
    accountDetails: '/users/parent/children-account-credentials',
    loginInstructions: '/users/parent/children-login-instructions'
  };

  const accountDetails = useData(URL.accountDetails);
  const loginInstructions = useData(URL.loginInstructions);

  return {
    loading: accountDetails.isLoading || loginInstructions.isLoading,
    error: accountDetails.error || loginInstructions.error,
    data: {
      accountDetails: accountDetails.data,
      loginInstructions: loginInstructions.data
    },
    isEmpty: !accountDetails.data && !loginInstructions.data,
    revalidate: () => {
      accountDetails.revalidate();
      loginInstructions.revalidate();
    }
  };
};
