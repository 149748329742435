import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Divider, message } from 'antd';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  API_URL,
  postApiWithAuth,
  subTotalFunction,
  subTotalFunctionWithTax,
  totalFunction,
  getUserID
} from '../../../utils';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import './ParentPaymentStripe.scss';
const BillingForm = ({ totalChildPayment, selectedPlanId, paymentChild }) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const [data, setData] = useState({
    payment_id: undefined
  });
  const [isLoading, setIsLoading] = useState(false);
  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: '18px',
            color: '#222222',
            fontFamily: 'Cabin, sans-serif',
            fontWeight: '500',
            '::placeholder': {
              color: '#222222'
            }
          }
        },
        placeholder: ''
      }),
      []
    );

    return options;
  };
  const [checkCard, setCheckCard] = useState({
    isValidNumber: false,
    isValidExpiry: false,
    isValidCvc: false
  });

  const options = useOptions();

  const isDisabled =
    !data.name ||
    !data.zip_or_postalCode ||
    !checkCard.isValidNumber ||
    !checkCard.isValidExpiry ||
    !checkCard.isValidCvc;

  const [isValidNumber, setIsValidNumber] = useState(true);

  const PostPaymentId = async () => {
    const newData = data;
    newData.child_list = totalChildPayment;
    newData.price_id = selectedPlanId;
    newData.user_id = getUserID();
    const res = await postApiWithAuth(API_URL.PAYMENT_CHECK, newData);
    if (res.success) {
      navigate('/parent-create-account/payment-confirm');
      setIsLoading(false);
    } else {
      setIsLoading(false);
      message.error(`${res.data.detail}`);
    }
  };

  const handleSubmit = async event => {
    setIsLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    let cardElement = null;
    cardElement = {
      ...cardElement,
      ...elements.getElement(CardCvcElement),
      ...elements.getElement(CardExpiryElement),
      ...elements.getElement(CardNumberElement)
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      metadata: {
        name: data.name,
        zip_or_postalCode: data.zip_or_postalCode
      }
    });

    if (error) {
      setIsLoading(false);
      message.error(error.message);
    } else {
      setData({
        ...data,
        payment_id: paymentMethod?.id
      });
    }
  };

  useEffect(() => {
    if (data.payment_id != undefined) PostPaymentId();
  }, [data.payment_id]);
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onChangeHandleCard = event => {
    if (event.elementType === 'cardNumber' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidNumber: true
      });
      setIsValidNumber(true);
    } else if (event.elementType === 'cardNumber' && !event.complete) {
      if (event.error.code == 'invalid_number') {
        setIsValidNumber(false);
      }
    } else if (event.elementType === 'cardExpiry' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidExpiry: true
      });
    } else if (event.elementType === 'cardCvc' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidCvc: true
      });
    }
  };
  return (
    <div className="row parent-payment-stripe__container">
      <div className="col-8 px-4">
        <Form>
          <h2 className="CurriculumSetupStyleHeading">Add Payment Details</h2>

          <Form.Item name="name" className="pt-3">
            <EducatorInputField
              label="Cardholder’s Name:"
              type="name"
              name="name"
              width="100%"
              inputValue={data.name}
              onChange={onChangeHandle}
            />
          </Form.Item>
          <Form.Item name="zip_or_postalCode" className="pt-3">
            <EducatorInputField
              label="ZIP/ Postal Code:"
              type="text"
              name="zip_or_postalCode"
              width="100%"
              inputValue={data.zip_or_postalCode}
              onChange={onChangeHandle}
            />
          </Form.Item>

          <Form.Item className="pt-4">
            <div>
              <label className="labelCurriculumStyle">Card Number:</label>
              <div className="mt-3">
                <div className="fixCardInputField card-info">
                  <CardNumberElement onChange={onChangeHandleCard} options={options} />
                </div>
              </div>
              <div className="mt-2 text-danger">{!isValidNumber ? 'Please enter a valid card number' : ''}</div>
            </div>
          </Form.Item>

          <div className="pt-3 row">
            <div className="col-6">
              <Form.Item>
                <div>
                  <label className="labelCurriculumStyle">Expiration Date:</label>
                  <div className="fixCardInputField card-info mt-3">
                    <CardExpiryElement onChange={onChangeHandleCard} options={options} />
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item>
                <div>
                  <label className="labelCurriculumStyle">CVC:</label>
                  <div className="fixCardInputField card-info mt-3">
                    <CardCvcElement onChange={onChangeHandleCard} options={options} />
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <div className="col-4 pt-3 px-4">
        <div className="textStyleTotalPayment ">Billing Details</div>
        <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
          <div>Membership x {totalChildPayment?.length} </div>
          <div> ${paymentChild} </div>
        </div>
        <Divider className="dividerStyle" />
        <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
          <div>Subtotal </div>
          <div>${subTotalFunction(totalChildPayment?.length, paymentChild)} </div>
        </div>
        <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
          <div>Estimated Tax </div>
          <div>${subTotalFunctionWithTax(totalChildPayment?.length, paymentChild)}</div>
        </div>
        <Divider className="dividerStyle" />
        <div className="pt-2 labelCurriculumStyle spaceBetweenDiv">
          <div>Total </div>
          <div>${totalFunction(totalChildPayment?.length, paymentChild)}</div>
        </div>
        <div className="pt-3">
          <EducatorButton
            label="Place Order"
            type="button"
            width="100%"
            showSpinner={isLoading}
            onClick={handleSubmit}
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingForm;

BillingForm.propTypes = {
  totalChildPayment: PropTypes.array.isRequired,
  selectedPlanId: PropTypes.string.isRequired,
  paymentChild: PropTypes.number.isRequired
};
