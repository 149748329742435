import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { DatePicker, Skeleton } from 'antd';
import { DotChartOutlined, DownOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { AuthenticatedNavbar } from '../../layoutComponents';
import { EducatorButton } from '../../commonComponents';
import { option, option2, series } from './helpers';
import { useWeeklyReport } from '../../../hooks';
import './parentChildWeeklyReportStyle.css';

const ChartPlaceholder = () => (
  <>
    <div className="weeklyReportsPlaceholderStyles">
      <Skeleton.Node active style={{ width: '100%', height: '100%' }}>
        <DotChartOutlined style={{ fontSize: 140, color: '#bfbfbf' }} />
      </Skeleton.Node>
    </div>
    <div className="weeklyReportsPlaceholderStyles">
      <Skeleton.Node active style={{ width: '100%', height: '100%' }}>
        <DotChartOutlined style={{ fontSize: 140, color: '#bfbfbf' }} />
      </Skeleton.Node>
    </div>
  </>
);

export const ParentChildWeeklyReports = () => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const navigate = useNavigate();

  const { child_info, isFreePlan } = location.state || {};
  const [showDummyData, setShowDummyData] = useState(false);
  const [dates, setDates] = useState([moment().subtract(7, 'days'), moment()]);

  const {
    data: reportData,
    revalidate,
    loadingPlayer
  } = useWeeklyReport({ classId: null, userId: child_info.student_id, dates });

  const disabledDate = current => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
    return !!tooEarly || !!tooLate;
  };

  useEffect(() => {
    if (!dates) {
      return false;
    }
  }, [dates]);

  useEffect(() => {
    if (!isFreePlan) {
      setShowDummyData(false);
    } else {
      setShowDummyData(true);
    }
  }, [isFreePlan]);

  return (
    <>
      {showDummyData ? (
        <div className="yellowTitle px-3">
          {`${child_info.first_name.toUpperCase()}. ${child_info.last_name.toUpperCase()}`} is currently playing for
          free. To unlock custom game skins and activity kits for parents,&nbsp;&nbsp;
          <span className="parentOverviewHadding cursorPointer" onClick={() => navigate('/memberships-and-billing')}>
            UPGRADE NOW
          </span>
        </div>
      ) : null}
      {!showDummyData ? (
        <div className="container-fluid p-2 p-md-3 p-lg-4 pb-3 weeklyMianDivHeight">
          <AuthenticatedNavbar
            smallHeading1="Learning"
            smallHeading2="Usage Report"
            playButton
            bodyButton={<EducatorButton label="Print Report" type="button" width="152px" />}
          />
          <div className="row ">
            <div className="row mt-4">
              <div className="col col-12 weeklyReportsHeadingStyle">
                Usage from
                <span className="addLineBottom">
                  {' '}
                  <RangePicker
                    bordered={false}
                    className="rangepikerStyling"
                    disabledDate={disabledDate}
                    onCalendarChange={val => {
                      setDates(val);
                      revalidate();
                    }}
                    format={'MMM-D'}
                    suffixIcon={<DownOutlined />}
                    separator={<MinusOutlined />}
                    value={dates}
                  />
                </span>
              </div>
              <div className="weeklyReportsSecondHeadingStyle mt-5">
                Weekly Averages for {`${child_info.first_name.toUpperCase()} ${child_info.last_name.toUpperCase()}`}:
              </div>
              <div className="col col-12 mt-4">
                <div className="mainWidth">
                  {loadingPlayer ? (
                    <ChartPlaceholder />
                  ) : (
                    <>
                      <div className="weeklyReportsCardStyles p-4">
                        <Chart
                          options={option}
                          series={reportData.playerData.school}
                          type="scatter"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="weeklyReportsCardStyles p-4">
                        <Chart
                          options={option2}
                          series={reportData.playerData.home}
                          type="scatter"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid p-2 p-md-3 p-lg-4 pb-3 weeklyMianDivHeight">
          <AuthenticatedNavbar
            smallHeading1="Learning"
            smallHeading2="Usage Report"
            bodyButton={<EducatorButton label="Print Report" type="button" width="152px" />}
          />
          <div className="row ">
            <div className="row mt-4">
              <div className="col col-12 weeklyReportsHeadingStyle">
                Sample Usage from
                <span className="addLineBottom">
                  {' '}
                  <RangePicker
                    bordered={false}
                    className="rangepikerStyling"
                    format={'MMM-D'}
                    disabled
                    suffixIcon={<DownOutlined />}
                    separator={<MinusOutlined />}
                    value={dates}
                  />
                </span>
              </div>
              <div className="blueTileWeeklyReport mainWidth px-3">
                This is a sample report, to unlock reporting on your child’s progress,
                <span
                  className="parentOverviewHadding cursorPointer mx-2"
                  onClick={() => navigate('/memberships-and-billing')}>
                  Upgrade Now
                </span>
              </div>
              <div className="weeklyReportsSecondHeadingStyle mt-2">
                Weekly Averages for {`${child_info.first_name.toUpperCase()} ${child_info.last_name.toUpperCase()}`}:
              </div>
              <div className="col col-12 mt-4">
                <div className="mainWidth">
                  <div className="weeklyReportsCardStyles p-4">
                    <Chart options={option} series={series} type="scatter" width="100%" height="100%" />
                  </div>
                  <div className="weeklyReportsCardStyles p-4">
                    <Chart options={option2} series={series} type="scatter" width="100%" height="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ParentChildWeeklyReports;
