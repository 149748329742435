import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button as AntButton, Spin } from 'antd';
import Colors from '../../theme/colors';

const StyledButton = styled(AntButton)`
  &.ant-btn {
    font-family: Catamaran, sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    padding: 11.5px 17.5px !important;
    height: 52px;
    border-radius: 15px;
    border: 2.5px solid ${Colors.GOLD_200};
    color: ${Colors.GREY_200};
    background: #fec93c;
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      background-color: ${Colors.GOLD_50};
      border-color: ${Colors.GOLD_100};
      color: ${Colors.GREY_50};
      cursor: not-allowed;
    }
  }
  &.ant-btn-text {
    color: ${Colors.GOLD_300};
    background: none;
    border: none;
    padding: 14px 20px !important;
    &:focus,
    &:hover {
      background: none;
    }
    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover {
      background: none;
      cursor: not-allowed;
      color: ${Colors.WHITE_200};
    }
  }
  &.ant-btn > .ant-btn-loading-icon {
    display: none;
  }
  &.ant-btn > .ant-btn-loading {
    cursor: not-allowed;
  }
`;

const Button = ({ children, loading, ...rest }) => {
  return (
    <StyledButton loading={loading} {...rest}>
      {loading ? <Spin /> : children}
    </StyledButton>
  );
};
export default Button;

Button.defaultProps = {
  block: false,
  disabled: false,
  htmlType: 'button',
  loading: false,
  onClick: null,
  type: 'primary'
};

Button.propTypes = {
  /**  Can be set to primary, secondary, link, text */
  type: PropTypes.oneOf(['primary', 'text']),
  children: PropTypes.node.isRequired,
  /** Option to fit button width to its parent width */
  block: PropTypes.bool,
  /**  Disabled state of button */
  disabled: PropTypes.bool,
  /**  Set the loading status of button */
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      delay: PropTypes.number
    })
  ]),
  /**  Set the original html type of button, see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type */
  htmlType: PropTypes.string,
  /**  Set the handler to handle click event */
  onClick: PropTypes.func
};
