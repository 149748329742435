import React from 'react';
import { Link } from 'react-router-dom';

import { Stack } from '@student-app/design-system';
import LanguageSwitcher from '../LanguageSwitcher';
import Logo from '@assets/images/cyber-academy/logo.svg';

import { getFlowComplete } from '@utils';
import { useAppState } from '@context';

const LoginNavBar = () => {
  const { state } = useAppState();
  const currentPath = window.location.pathname;
  const isParentFlow = currentPath.includes('parent-');
  const searchParams = new URLSearchParams(window.location.search);
  const isRostered = searchParams.get('rostered') === 'true';

  return (
    <Stack justifyContent="space-between" style={{ padding: 20, paddingBottom: 0 }}>
        {isRostered ? (
          <img style={{ marginLeft: '5vw' }} src={Logo} alt="cyber-academy-logo" width="150" height="77" />
        ) : (
          <Link to={state.authToken !== null && getFlowComplete() ? '/dashboard' : '/'}>
            <img style={{ marginLeft: '5vw' }} src={Logo} alt="cyber-academy-logo" width="150" height="77" />
          </Link>
        )}
      <Stack justifyContent="flex-end">
        {!isParentFlow && <LanguageSwitcher />}
      </Stack>
    </Stack>
  );
};

export default LoginNavBar;
