import useSWR from 'swr';
import { API_URL, postApiWithAuth } from '@utils';

export const useCredits = () => {
  const URL = API_URL.PAYMENTS.GET_CREDITS;

  const { data, mutate, error, isLoading } = useSWR(URL);
  const response = data?.data;

  const stats = response?.reduce(
    (acc, credit) => {
      if (credit.redeemed_date) {
        acc.used += 1;
      }

      if (credit.redeemed_date === null) {
        acc.available += 1;
      }

      acc.earned += 1;

      return acc;
    },
    {
      earned: 0,
      used: 0,
      available: 0
    }
  );

  const revalidate = () => {
    mutate();
  };

  const redeemProducts = async products => {
    return await postApiWithAuth(API_URL.PAYMENTS.REDEEM_PRODUCTS, { products });
  };

  const redeemEdubyte = async id => {
    return await postApiWithAuth(API_URL.EDUBYTES.REDEEM_EDUBYTE.replace(':id', id), {});
  };

  return {
    loading: isLoading,
    error: error,
    data: stats,
    revalidate,
    redeemProducts,
    redeemEdubyte
  };
};
