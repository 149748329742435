import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import Footer from '../Footer';

import './NoSidebarStyle.scss';

const { Content } = Layout;

const NoSidebar = ({ children, marginContent }) => {
  return (
    <Layout
      style={{
        minHeight: '100vh'
      }}>
      <Layout className="mainDivParentResourcesStyle" hasSider={false}>
        <Layout className="site-layout">
          <Content className={marginContent ? 'marginContent' : null}>
            <div className="site-layout-background">{typeof children === 'function' ? children() : children}</div>
          </Content>
        </Layout>
      </Layout>
      <Footer />
    </Layout>
  );
};
export default NoSidebar;

NoSidebar.defaultProps = {
  marginContent: true
};

NoSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]).isRequired,
  marginContent: PropTypes.bool
};
