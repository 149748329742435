import useSWR from 'swr';
import { API_URL } from '@utils';

export const useClassAssignments = classId => {
  const url = classId ? `${API_URL.CLASS_ASSIGNMENTS}/${classId}` : null;

  const { data, error, isLoading, mutate, isValidating } = useSWR(url);

  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error,
    data: response ?? [],
    revalidate,
    isValidating
  };
};
