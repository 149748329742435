/* eslint-disable no-console */
import React from 'react';
import { EducatorButton } from '../../commonComponents';
import { useNavigate } from 'react-router-dom';
import './childAccount.css';

const ChildAccount = () => {
  const navigate = useNavigate();

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-5">
          <div className="childHaddingStyle">Play the Game</div>
          <div className="mt-3">
            <EducatorButton
              label="Sign In"
              type="button"
              onClick={() => {
                navigate('/child-signin');
              }}
            />
          </div>
          <div className="mt-3 d-none">
            <EducatorButton
              label="Create an Account"
              type="button"
              onClick={() => {
                navigate('/create-account-landing');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildAccount;
