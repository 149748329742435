import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { ReferFriends } from './ReferFriends';
import { ReferralProgress } from './ReferralProgress';
import { Faq } from './Faq';
import { RewardStats } from './RewardStats';
import './Referrals.scss';

export const Referrals = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });

  return (
    <>
      <div className="referrals__container container-fluid p-2 p-md-3 p-lg-4 mainDivParentTopicsStyle">
        <AuthenticatedNavbar title={t('referrals')} />
        <div className="row my-4">
          <div className="col-12">
            <ReferFriends />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-9">
            <div className="row mb-4">
              <div className="col-12">
                <ReferralProgress />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Faq />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-3 mt-4 mt-xl-0">
            <RewardStats />
          </div>
        </div>
      </div>
    </>
  );
};
