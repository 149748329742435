import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField, EducatorModal } from '../../index';
import deleteImage from '@assets/images/deleteImage.svg';

export const EditStudentModal = ({
  show,
  onCancel,
  handleOnFinish,
  student,
  handleOnChange,
  handleDeleteClick,
  showSpinner
}) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  return (
    <>
      <EducatorModal
        visible={show}
        onCancel={onCancel}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">{t('edit_student')}</div>
            <div className="fixCardtextStyle ">{t('update_student_details_below')}</div>
            <Form onFinish={handleOnFinish}>
              <Form.Item
                className="pt-4"
                name="first_name"
                rules={[
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message: t('must_contain_alphabets', { ns: 'common' })
                  }
                ]}>
                <EducatorInputField
                  label={t('students_name')}
                  type="text"
                  inputValue={`${student.first_name} ${student.last_name}`}
                  name="student_name"
                  className="fixCardInputField"
                  onChange={handleOnChange}
                  disabled
                />
              </Form.Item>
              <Form.Item className="pt-4">
                <EducatorInputField
                  label={t('link_a_parent_to_this_student')}
                  type="email"
                  inputValue={student.student_parent}
                  name="student_parent"
                  className="fixCardInputField"
                  onChange={handleOnChange}
                />
              </Form.Item>
              <div className="spaceBetweenDisplay mt-3">
                <Form.Item>
                  <button
                    type="button"
                    className="deleteButtonStyling cursorPointer"
                    onClick={() => handleDeleteClick(student.id)}>
                    <span>{t('remove_from_class')}</span>&nbsp;
                    <img src={deleteImage} alt="deleteImage" />
                  </button>
                </Form.Item>
                <Form.Item>
                  <EducatorButton
                    key="submit"
                    showSpinner={showSpinner}
                    label={t('save')}
                    type="submit"
                    disabled={student.student_parent === ''}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        }
      />
    </>
  );
};

EditStudentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleOnFinish: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired
};
