import React, { forwardRef } from 'react';
import { Avatar, Empty, Spin } from 'antd';
import PropTypes from 'prop-types';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';

// import { sendGaEvent } from '@utils';
import { LOADING_STATE } from '@pages/Dashboard/StudentDashboard/Collections/Common';
import { MissionCard } from '@components/studentDashboardComponent';
import { EducatorButton } from '@components/commonComponents';

import defaultImage from '@assets/images/cyber-academy/default.png';

export const MissionBadgePage = forwardRef(
  (
    {
      state,
      showHeader,
      missions,
      grade,
      activeGrade,
      nextButton,
      previousButton,
      handleNextPage,
      handlePreviousPage,
      progress,
      badgeImage
    },
    ref
  ) => {
    const { t } = useTranslation('student', { keyPrefix: 'badge_booklet' });

    // const handleResourceCardClick = async item => {
    //   sendGaEvent('mission_card_click', 'Mission Card Click', item.name);
    //   navigate(`/student-dashboard/${item.id}`, {
    //     state: {
    //       itemId: item.id,
    //       title: item.name
    //     }
    //   });
    // };

    const missionList = missions;
    const missionProgressPercent = (progress.completed * 100) / progress.total;

    if (state === LOADING_STATE.LOADING) {
      return (
        <div className="page mission-badge-page" ref={ref}>
          <div className="page-content d-flex flex-column h-100">
            <div className="emptyMessageStudent h-100">
              <Spin size="large" />
            </div>
          </div>
        </div>
      );
    }

    if (state === LOADING_STATE.ERROR) {
      return (
        <div className="page mission-badge-page" ref={ref}>
          <div className="page-content d-flex flex-column h-100">
            <div className="emptyMessageStudent h-100">
              <Empty description={false} />
            </div>
          </div>
        </div>
      );
    }

    if (state === LOADING_STATE.SUCCESS) {
      return (
        <div className="page mission-badge-page" ref={ref}>
          <div className="page-content d-flex flex-column h-100">
            {missionList?.length === 0 ? (
              <div className="emptyMessageStudent h-100">
                {<Empty description={false} imageStyle={{ display: 'none' }} />}
              </div>
            ) : (
              <div className="d-flex h-100 flex-column flex-grow-1">
                {showHeader && (
                  <div className="mt-2 mb-5 d-flex">
                    <div className="flex-shrink-0 pe-3">
                      <Avatar size={130} shape="circle" src={badgeImage ?? defaultImage} alt={'Badge'} />
                    </div>
                    <div className="flex-grow ps-3 page__text">
                      <span>
                        <Trans>{t('current_rank', { activeGrade: activeGrade })}</Trans>
                      </span>
                      <h2>{t('working_on', { grade: grade })}</h2>
                      <div className="progress mt-4 mb-2">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${missionProgressPercent}%` }}
                          aria-valuenow={missionProgressPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                        <p className="progress-text">
                          {t('missions_completed', { completed: progress.completed, total: progress.total })}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  {missionList?.map(item => {
                    const mission = item.mission;
                    return (
                      <div className="col-4" key={`${mission.id}-${new Date().getTime()}`}>
                        <MissionCard
                          image={mission.thumbnail ?? defaultImage}
                          title={''}
                          isLocked={false}
                          badge={mission.topic.sub_heading}
                          topicName={item.topic?.name}
                          userAchievement={!!item.user_achievement_id}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!!previousButton && (
              <div className="previous-button d-flex justify-content-start sticky-bottom">
                <EducatorButton onClick={handlePreviousPage} width={'auto'}>
                  <ArrowLeft color={'#A96300'} /> {previousButton.cyberName}
                </EducatorButton>
              </div>
            )}
            {!!nextButton && (
              <div className="next-button d-flex justify-content-end sticky-bottom">
                <EducatorButton onClick={handleNextPage} width={'auto'}>
                  {nextButton.cyberName} <ArrowRight color={'#A96300'} />
                </EducatorButton>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);

MissionBadgePage.displayName = 'Loader Page';

MissionBadgePage.defaultProps = {
  showHeader: false,
  missions: [],
  activeGrade: '',
  grade: '',
  state: '',
  nextButton: null,
  previousButton: null,
  handleNextPage: () => {},
  handlePreviousPage: () => {},
  progress: {
    completed: 0,
    total: 0
  },
  badgeImage: undefined
};

MissionBadgePage.propTypes = {
  state: PropTypes.string,
  showHeader: PropTypes.bool,
  missions: PropTypes.array,
  grade: PropTypes.string,
  activeGrade: PropTypes.string,
  nextButton: PropTypes.object,
  previousButton: PropTypes.object,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  progress: PropTypes.object,
  badgeImage: PropTypes.string
};
