import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@teacher-app/design-system';
import { Modal } from 'antd';

import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { getAssignmentPercent } from '@pages/Assignments/common';
import { ResultPdfListItem, resultPdfStyles } from '@pages/Assignments/PdfModals/ResultPDFCommon';

export const IndividualResultPdfModal = ({ isOpen, onClose, student, details, currentClass }) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  const newReport = student.results.map(result => {
    const { percent, textColor, backgroundColor } = getAssignmentPercent(result.score, result.max_score);

    return { ...result, percent, textColor, backgroundColor };
  });

  const { percent, textColor, backgroundColor } = getAssignmentPercent(student?.score, student?.max_score);

  return (
    <Modal
      width="80vw"
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      open={isOpen}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}>
      <Stack direction="column" alignItems="flex-start" spacing={12}>
        <PDFViewer style={{ width: '100%', height: '90vh' }}>
          <Document title={`${currentClass?.name} - ${details?.name} - ${student?.user} - ${formattedDate}`}>
            <Page size="A4" style={resultPdfStyles.page}>
              <View style={resultPdfStyles.header}>
                <View style={resultPdfStyles.classRow}>
                  <Text style={resultPdfStyles.className}>{currentClass?.name}</Text>
                  <Text style={resultPdfStyles.todayDate}>{formattedDate}</Text>
                </View>
                <Text style={resultPdfStyles.assignmentName}>{details?.name}</Text>
                <ResultPdfListItem>Student Name: {student?.user}.</ResultPdfListItem>
                <ResultPdfListItem>
                  Start: {details?.start_date} - End: {details?.end_date}
                </ResultPdfListItem>
                <ResultPdfListItem>
                  {details?.grade?.name} - {details?.curriculum_type} - {details?.topic?.name} -{' '}
                  {details?.missions.map(mission => mission.name).join(', ')} and Assessment
                </ResultPdfListItem>
              </View>
              <View style={resultPdfStyles.table}>
                <View style={resultPdfStyles.overallRow}>
                  <View style={[resultPdfStyles.overallHeaderCell, resultPdfStyles.learningGoalCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Overall Result:</Text>
                  </View>
                  <View style={[resultPdfStyles.overallHeaderCell, resultPdfStyles.learningGoalOutcomeCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>
                      {student?.score}/{student?.max_score} correct
                    </Text>
                  </View>
                  <View style={[resultPdfStyles.overallHeaderCell, resultPdfStyles.resultCell]}>
                    <View style={resultPdfStyles.resultCellRow}>
                      <Text
                        style={[
                          resultPdfStyles.resultCellText,
                          { color: textColor, backgroundColor: backgroundColor }
                        ]}>
                        {percent} %
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={resultPdfStyles.tableRow}>
                  <View style={[resultPdfStyles.tableHeaderCell, resultPdfStyles.learningGoalCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Learning Goal</Text>
                  </View>
                  <View style={[resultPdfStyles.tableHeaderCell, resultPdfStyles.learningGoalOutcomeCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Outcome</Text>
                  </View>
                  <View style={[resultPdfStyles.tableHeaderCell, resultPdfStyles.resultCell]}>
                    <Text style={resultPdfStyles.tableHeaderText}>Result</Text>
                  </View>
                </View>
                {newReport.map((result, i) => (
                  <View key={i} style={resultPdfStyles.tableRow}>
                    <View style={[resultPdfStyles.tableCell, resultPdfStyles.learningGoalCell]}>
                      <Text style={resultPdfStyles.studentCellText}>{result.learning_goal}</Text>
                    </View>
                    <View style={[resultPdfStyles.tableCell, resultPdfStyles.learningGoalOutcomeCell]}>
                      <Text style={resultPdfStyles.studentCellText}>
                        {result.score}/{result.max_score} correct
                      </Text>
                    </View>
                    <View style={[resultPdfStyles.tableCell, resultPdfStyles.resultCell]}>
                      <View style={resultPdfStyles.resultCellRow}>
                        <Text
                          style={[
                            resultPdfStyles.resultCellText,
                            { color: result.textColor, backgroundColor: result.backgroundColor }
                          ]}>
                          {result.percent} %
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
              <Text
                style={resultPdfStyles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
            </Page>
          </Document>
        </PDFViewer>
      </Stack>
    </Modal>
  );
};

IndividualResultPdfModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  currentClass: PropTypes.object.isRequired
};
