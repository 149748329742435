/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { Form, message } from 'antd';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { API_URL, GOOGLE_SSO_LOGOUT_URL } from '../../../utils/constants';
import { clearStorage, getIsGoogle, getRefereshToken, getToken, getUserID } from '../../../utils/sessionStorage';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';

const ChildLinkParent = () => {
  const [userData, setUserData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const logoutUser = async () => {
    const Google = getIsGoogle();
    const token = getToken();
    const refreshToken = getRefereshToken();

    if (Google === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
      window.open(`${import.meta.env.REACT_APP_LINK_GAME_SERVER}?token=${token}&mode=home`, '_self');
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
          window.open(
            `${import.meta.env.REACT_APP_LINK_GAME_SERVER}?token=${token}&refresh=${refreshToken}&mode=home`,
            '_self'
          );
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };

  const sendCodeParent = async () => {
    const childId = getUserID();
    setButtonSpinner(true);
    const { data } = await patchApiWithAuth(`${API_URL.CODE_ALLOW}/${childId}`, userData);
    if (data.success) {
      message.success(data.message);
      setButtonSpinner(false);
      logoutUser();
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { status } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
      if (status === 401) {
        navigate('/');
      }
    };
    fetchUser();
  }, [location.pathname === '/child-create-account/link-parent']);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childSecondHaddingStyle">Link yourself with your parent’s account</div>
          <div className="mt-3">
            <Form onFinish={sendCodeParent}>
              <Form.Item name="parent_code" rules={[{ required: true, message: 'Please input your Family Code!' }]}>
                <EducatorInputField
                  label="Enter Your Family Code:"
                  inputValue={userData.parent_code}
                  type="text"
                  name="parent_code"
                  headerColor="#000000"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="py-3">
                <EducatorButton label="Link Accounts" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildLinkParent;
