export const setUserID = id => {
  sessionStorage.setItem('user_id', id);
};

export const getUserID = () => sessionStorage.getItem('user_id');

export const setToken = token => {
  sessionStorage.setItem('token', token);
};
export const setRefreshToken = token => {
  sessionStorage.setItem('refreshToken', token);
};

export const getToken = () => sessionStorage.getItem('token');
export const getRefereshToken = () => sessionStorage.getItem('refreshToken');

export const setFlowComplete = value => {
  sessionStorage.setItem('flow_check', JSON.stringify(value));
};

export const getFlowComplete = () => JSON.parse(sessionStorage.getItem('flow_check'));

export const removeFlowComplete = () => {
  sessionStorage.removeItem('flow_check');
};

export const setIsGoogle = isGoogle => {
  sessionStorage.setItem('isGoogle', isGoogle);
};

export const getIsGoogle = () => sessionStorage.getItem('isGoogle');

export const setIsFromStudent = fromStudent => {
  sessionStorage.setItem('fromStudent', fromStudent);
};

export const getIsFromStudent = () => sessionStorage.getItem('fromStudent');

export const setMemberShipType = memberShipType => {
  sessionStorage.setItem('memberShipType', memberShipType);
};

export const getMemberShipType = () => sessionStorage.getItem('memberShipType');

export const getLogoutUrl = () => sessionStorage.getItem('logoutUrl');

export const setLogoutUrl = logoutUrl => {
  sessionStorage.setItem('logoutUrl', logoutUrl);
};

export const removeLogoutUrl = () => {
  sessionStorage.removeItem('logoutUrl');
};

export const setValue = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};
