import { Button, Colors, Stack, Text } from '@teacher-app/design-system';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Triangle } from 'react-feather';

const FeatureWalkthroughPopup = ({ goTo, close, step, section, setOpenSubMenuKey }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'onboarding' });
  const polygonClass = step < 10 ? 'ploygon' : 'ploygon-top';

  const handleClick = () => {
    if (step === 11) {
      close();
      return;
    }
    // To open the sub-menu for feature walkthrough
    if(step === 2) {
      setOpenSubMenuKey('TeachingResources');
      setTimeout(() => goTo(step), 300);
      return;
    }

    // To open the sub-menu for feature walkthrough
    if(step === 4) {
      setOpenSubMenuKey('Class');
      setTimeout(() => goTo(step), 300);
      return;
    }
    goTo(step);
  };

  return (
    <Stack direction="column" spacing={30}>
      <Stack alignItems="flex-start" direction="column">
        <Text bold>{t(`${section}_title`)}</Text>
        <Text size="x-small">{t(`${section}_description`)}</Text>
      </Stack>
      <Stack justifyContent="space-between">
        <Text size="x-small">{step} of 11</Text>
        <Button size="small" onClick={handleClick}>
          {step === 11 ? t('got_it') : t('next')}
        </Button>
      </Stack>
      <Triangle className={`position-absolute ${polygonClass}`} color={Colors.WHITE_0} size={24} fill={Colors.WHITE_0} />
    </Stack>
  );
};

export default FeatureWalkthroughPopup;

FeatureWalkthroughPopup.propTypes = {
  goTo: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  section: PropTypes.string.isRequired,
  setOpenSubMenuKey: PropTypes.func.isRequired,
};
