/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { getUserID } from '../../../utils/sessionStorage';
import { Card, Divider, Radio, message } from 'antd';
import { API_URL } from '../../../utils/constants';
import { subTotalFunction, subTotalFunctionWithTax, totalFunction } from '../../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import './manageSubscriptions.css';

const ManageSubscriptions = () => {
  const navigate = useNavigate();
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [updateSubscriptionDetail, setUpdateSubscriptionDetail] = useState(null);
  const [freeChild, setFreeChild] = useState([]);
  const [premiumChild, setPremiumChild] = useState([]);
  const [couponCode, setCouponCode] = useState();
  const [isCouponApply, setIsCouponApply] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [plan, setPlan] = useState([]);
  const [showPlan, setShowPlan] = useState([]);
  const [selectedPlanDay, setSellectedPlanDay] = useState();
  const [selectedPlan, setSellectedPlan] = useState('');
  const [selectedPlanId, setSellectedPlanId] = useState(null);
  const [sendData, setSendData] = useState({ child_list: [] });
  const [sendNewData, setNewSendData] = useState({ child_list: [] });

  useEffect(() => {
    if (subscriptionDetail != null) {
      if (subscriptionDetail?.subscribed_children?.length === sendData?.child_list?.length) {
        let isCheck = false;
        if (subscriptionDetail?.plan === sendData?.premium_choice) {
          for (let i = 0; i < subscriptionDetail.subscribed_children?.length; i++) {
            isCheck = checkInnerData(subscriptionDetail?.subscribed_children[i].student?.student_id);
            if (isCheck) {
              break;
            }
          }
          if (!isCheck) {
            setIsButtonDisabled(true);
          }
        } else {
          setIsButtonDisabled(false);
        }
      }
    }
  }, [sendData]);

  const checkInnerData = studentId => {
    let check = false;

    const dataOne = sendData?.child_list.filter(item => item.id === studentId);
    const dataTwo = subscriptionDetail?.subscribed_children.filter(item => item.student?.student_id === studentId);

    if (dataOne[0]?.plan_type !== dataTwo[0]?.plan_type?.plan) {
      setIsButtonDisabled(false);
      check = true;
      return check;
    }
  };

  const getUserSubscriptionDetail = async () => {
    const parent_id = getUserID();
    const { success, data } = await getApiWithAuth(`payments/parent/${parent_id}/subscription-details`);
    if (success) {
      setSubscriptionDetail(data);
      setUpdateSubscriptionDetail(data);
    }
  };
  const getPaymentPlans = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PAYMENT_PLANS);
    if (success) {
      setPlan(data);
    }
  };
  const onKeyDown = e => {
    if (e.key === 'Enter') {
      applyCoupon();
    }
  };
  const onChangeHandleCoupon = e => {
    const { value } = e.target;
    setCouponCode(value);
  };
  useEffect(() => {
    getPaymentPlans();
  }, []);
  useEffect(() => {
    if (plan.length !== 0) {
      setShowPlan([plan[1], plan[2]]);
      getUserSubscriptionDetail();
    }
  }, [plan]);
  useEffect(() => {
    if (subscriptionDetail !== null) {
      const month = subscriptionDetail.subscribed_children?.filter(item => item.plan_type.status === 'Monthly');
      const year = subscriptionDetail.subscribed_children?.filter(item => item.plan_type.status === 'Yearly');
      const newValue = subscriptionDetail?.subscribed_children.map(item => {
        return { id: item.student.student_id, plan_type: item.plan_type.plan, subscription_id: item.subscription_id };
      });
      if (month.length !== 0) {
        setSellectedPlanId(showPlan[0]);
        setSellectedPlanDay(showPlan[0]?.interval);
        setSendData({ ...sendData, premium_choice: showPlan[0]?.interval, child_list: newValue });
        setSellectedPlan(showPlan[0]?.amount);
      } else if (year.length !== 0) {
        setSellectedPlanId(showPlan[1]);
        setSendData({ ...sendData, premium_choice: showPlan[1]?.interval, child_list: newValue });
        setSellectedPlanDay(showPlan[1]?.interval);
        setSellectedPlan(showPlan[1]?.amount);
      } else {
        setSellectedPlanId(showPlan[1]);
        setSellectedPlanDay(showPlan[1]?.interval);
        setSendData({ ...sendData, premium_choice: showPlan[1]?.interval, child_list: newValue });
        setSellectedPlan(showPlan[1]?.amount);
      }
    }
  }, [subscriptionDetail]);
  const onChange = e => {
    setSellectedPlanId(e.target.value);
    setSellectedPlanDay(e.target.value.interval);
    setSendData({ ...sendData, premium_choice: e.target.value.interval });
    setSellectedPlan(e.target.value.amount);
  };
  const applyCoupon = async () => {
    const { success, data } = await getApiWithAuth(`coupons/${couponCode}/${selectedPlanId?.id}`);
    if (success) {
      if (data.data.remaining_redemptions === 0) {
        message.error('Coupon is expired');
      } else {
        message.success(`This coupon is valid for ${data.data.remaining_redemptions} Children`);
        setIsCouponApply(true);
      }
    } else {
      message.error(data.detail);
      setIsCouponApply(false);
    }
  };

  const onChangePlan = e => {
    const Data = updateSubscriptionDetail.subscribed_children?.map(item => {
      if (item.student.student_id === e.target.name)
        return {
          ...item,
          plan_type: { ...item.plan_type, plan: item.plan_type.plan === 'Premium' ? 'Fremium' : 'Premium' }
        };
      else {
        return item;
      }
    });
    setUpdateSubscriptionDetail({ ...updateSubscriptionDetail, subscribed_children: Data });
    const result = sendData.child_list.filter(item => item.id !== e.target.name);
    setSendData({ ...sendData, child_list: result });
    const resultTwo = sendData.child_list.filter(item => item.id === e.target.name);
    setNewSendData({ id: e.target.name, plan_type: e.target.value, subscription_id: resultTwo[0]?.subscription_id });
  };
  useEffect(() => {
    if (updateSubscriptionDetail !== null) {
      const premiumChild = updateSubscriptionDetail.subscribed_children?.filter(
        item => item.plan_type.plan === 'Premium'
      );
      setPremiumChild(premiumChild);
      const FreeChild = updateSubscriptionDetail.subscribed_children?.filter(item => item.plan_type.plan === 'Fremium');
      setFreeChild(FreeChild);
    }
  }, [updateSubscriptionDetail]);
  useEffect(() => {
    if (sendNewData.child_list?.length !== 0) {
      setSendData({ ...sendData, child_list: [...sendData.child_list, sendNewData] });
    }
  }, [sendNewData]);

  const paymentFunction = async () => {
    const newSendData = sendData;
    newSendData.coupon_code = couponCode;
    setButtonSpinner(true);
    const { success, data } = await patchApiWithAuth(API_URL.UPDATE_PAYMENT, newSendData);
    if (success) {
      setButtonSpinner(false);
      navigate('/memberships-and-billing');
    } else {
      setButtonSpinner(false);
      message.error(`${data.message}`);
    }
  };
  const removeCouponCode = async () => {
    setIsCouponApply(false);
    setCouponCode('');
    message.success('Coupon code remove Successfully');
  };
  return (
    <>
      {selectedPlanId !== null ? (
        <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
          <AuthenticatedNavbar smallHeading1="Account Management" smallHeading2="Manage Subscriptions" />
          <div className="unitPlanTextStyle row">
            <div className="col-9">
              <div className="authenticatedNavbarHeadingStyle capital mt-4">Manage Subscriptions</div>
              <div>
                Downgrade your memberships to free or upgrade to premium. If you’re upgrading, the total amount due will
                update on the right and you’ll be taken to the check out page.
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-sm-7 col-lg-8  pb-3 ">
              <Card
                bordered={false}
                className="manageSubscriptionFirstCard cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 ">
                <div>
                  <div className="fixCardHeadingStyle">Choose Your Billing Cycle</div>
                  <div className="py-3">
                    <Radio.Group
                      onChange={onChange}
                      disabled={isCouponApply}
                      defaultValue={selectedPlanId}
                      className="planRadioStyle">
                      {showPlan &&
                        showPlan?.map(item => {
                          return (
                            <div key={item.id} className="radioInline">
                              <Radio value={item}>
                                <div className="fixCardtext">{item.interval}</div>
                                <div className="fixCardHeadingStyle">
                                  ${item.amount / 100} / {item.interval}
                                </div>
                              </Radio>
                            </div>
                          );
                        })}
                    </Radio.Group>
                  </div>
                  <div className="fixCardHeadingStyle">Your Users</div>
                  <div className="pb-3">
                    {updateSubscriptionDetail?.subscribed_children.map(item => {
                      return (
                        <div key={item.subscription_id} className="mt-3">
                          <div className="spaceBetweenDiv">
                            <div className="secondRadioInline parentOverviewHadding">
                              {item.student.first_name} {item.student.last_name}
                            </div>
                            <Radio.Group
                              disabled={isCouponApply}
                              onChange={onChangePlan}
                              defaultValue={item.plan_type.plan}
                              name={item.student.student_id}
                              className="radioInline2">
                              <Radio value="Premium">Premium</Radio>
                              <Radio value="Fremium">Free</Radio>
                            </Radio.Group>
                            <div className="secondRadioInline">
                              {selectedPlanDay
                                ? item.plan_type.plan === 'Premium'
                                  ? `$${selectedPlan / 100} ${selectedPlanDay}ly`
                                  : `$${0} ${selectedPlanDay}ly`
                                : ''}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Card>
            </div>
            <div className="col col-sm-5 col-lg-4 pb-3 ">
              <Card bordered={false} className="cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 memberStyle">
                <div className="pt-2  spaceBetweenDiv">
                  <div>Premium Membership</div>
                  <div className="pt-2  spaceBetweenDiv">
                    <div>{premiumChild.length}&nbsp;x&nbsp;</div>
                    <div> ${selectedPlanDay === 'year' ? showPlan[1].amount / 100 : showPlan[0]?.amount / 100} </div>
                  </div>
                </div>
                <div className="pt-2  spaceBetweenDiv">
                  <div>Free Membership</div>
                  <div className="pt-2  spaceBetweenDiv">
                    <div>{freeChild.length}&nbsp;x&nbsp;</div>
                    <div>${0}</div>
                  </div>
                </div>
                <Divider className="managedividerStyleOne" />
                <div className="mt-3">
                  <EducatorInputField
                    headerColor="#000000"
                    label="Redeem Coupon Code:"
                    type="text"
                    name="coupon"
                    width="100%"
                    inputValue={couponCode}
                    onChange={onChangeHandleCoupon}
                    onKeyDown={onKeyDown}
                    suffix={
                      <EducatorButton
                        width="96px"
                        key="button"
                        label="Apply"
                        type="button"
                        className="applyCouponButton"
                        onClick={applyCoupon}
                        disabled={isButtonDisabled}
                        isCoupon
                      />
                    }
                  />
                </div>
                {isCouponApply ? (
                  <div className="pt-1 memberStyle">
                    Removed coupon &nbsp;
                    <span onClick={removeCouponCode} className="parentOverviewHadding cursorPointer">
                      {couponCode}&nbsp;
                    </span>
                    ?
                  </div>
                ) : null}
                <Divider className="managedividerStyleOne" />
                <div className="pt-2  spaceBetweenDiv">
                  <div>Subtotal </div>
                  <div>
                    $
                    {subTotalFunction(
                      premiumChild.length,
                      selectedPlanDay === 'year' ? showPlan[1].amount / 100 : showPlan[0]?.amount / 100
                    )}
                  </div>
                </div>
                <div className="pt-2  spaceBetweenDiv">
                  <div>Estimated Tax </div>
                  <div>
                    $
                    {subTotalFunctionWithTax(
                      premiumChild.length,
                      selectedPlanDay === 'year' ? showPlan[1].amount / 100 : showPlan[0]?.amount / 100
                    )}
                  </div>
                </div>
                {isCouponApply ? (
                  <div className="pt-2  spaceBetweenDiv">
                    <div>Coupon Code </div>
                    <div>
                      $
                      {totalFunction(
                        premiumChild.length,
                        selectedPlanDay === 'year' ? showPlan[1].amount / 100 : showPlan[0]?.amount / 100
                      )}
                    </div>
                  </div>
                ) : null}
                <Divider className="managedividerStyleTwo" />
                <div className="pt-2 secondCardHadding spaceBetweenDiv">
                  <div>Total </div>
                  <div>
                    $
                    {isCouponApply
                      ? '0.00'
                      : totalFunction(
                          premiumChild.length,
                          selectedPlanDay === 'year' ? showPlan[1].amount / 100 : showPlan[0]?.amount / 100
                        )}{' '}
                  </div>
                </div>
                <Divider className="managedividerStyleTwo" />

                <div className="pt-3">
                  <EducatorButton
                    label="Check Out"
                    type="button"
                    width="100%"
                    disabled={isButtonDisabled}
                    showSpinner={buttonSpinner}
                    onClick={() => {
                      isCouponApply
                        ? paymentFunction()
                        : navigate('/make-payment', {
                            state: {
                              data: sendData,
                              selectedPlanId: selectedPlanId,
                              premiumChildDetail: {
                                length: premiumChild.length,
                                payment:
                                  selectedPlanDay === 'year' ? showPlan[1].amount / 100 : showPlan[0]?.amount / 100
                              },
                              freeChildDetail: {
                                length: freeChild.length,
                                payment: 0
                              }
                            }
                          });
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ManageSubscriptions;
