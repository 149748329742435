import useSWR from 'swr';
import dayjs from 'dayjs';
import { API_URL } from '../utils';

const daysInOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const buildSeries = data => {
  let arr = [];
  for (const k in data) {
    const tmp = {
      id: daysInOrder.indexOf(k),
      value: data[k]
    };
    arr.push(tmp);
  }

  arr.sort((a, b) => a.id - b.id);

  arr = arr.map(data => {
    return data.value;
  });

  return [
    {
      name: '',
      data: arr.length !== 0 ? arr : Array(7).fill(null)
    }
  ];
};

export const useWeeklyReport = ({ classId, userId, dates }) => {
  let startDate = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
  let endDate = dayjs().format('YYYY-MM-DD');

  if (dates) {
    startDate = dates[0].format('YYYY-MM-DD');
    endDate = dates[1].format('YYYY-MM-DD');
  }

  const classUrl = `${API_URL.GET_CLASS_WEEKLY_USAGE}/${classId}?start_date=${startDate}&end_date=${endDate}`;
  const playerUrl = `${API_URL.GET_PLAYER_WEEKLY_USAGE}/${userId}?start_date=${startDate}&end_date=${endDate}`;

  const { data: classData, mutate: classMutate, error: classError } = useSWR(classId ? classUrl : null);
  const { data: playerData, mutate: playerMutate, error: playerError } = useSWR(userId ? playerUrl : null);

  const classReportData = classData?.data?.data;
  const playerReportData = playerData?.data?.data;

  const revalidate = () => {
    classMutate();
    playerMutate();
  };

  return {
    loadingClass: classId && !classData && !classError,
    loadingPlayer: userId && !playerData && !playerError,
    error: classError || playerError,
    data: {
      classData: {
        school: buildSeries(classReportData?.school),
        home: buildSeries(classReportData?.home)
      },
      playerData: {
        school: buildSeries(playerReportData?.school),
        home: buildSeries(playerReportData?.home)
      }
    },
    revalidate
  };
};
