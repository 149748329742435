import useSWR from 'swr';
import { API_URL } from '@utils';

export const useCurrentUser = () => {
  const URL = API_URL.GET_CURRENT_USER;

  const { data, mutate, error, isLoading } = useSWR(URL);
  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error: error,
    data: response,
    revalidate
  };
};
