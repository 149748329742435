import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Colors, Stack, Text } from '@parent-app/design-system';
import PageLayout from '@parent-app/components/PageLayout';
import HelpfulLinks from '@parent-app/components/HelpfulLinks';
import LoginDetails from '@parent-app/pages/Overview/LoginDetails';
import ParentResources from './ParentResources';
import { useCurrentUser } from '@hooks';
import useSWR from 'swr';
import { API_URL, fetcher } from '@utils';

const Overview = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [showBanner, setShowBanner] = useState(false);
  const { data: parentData } = useCurrentUser();
  const { data, isLoading } = useSWR(`${API_URL.PARENT_CHILDREN_PAGE}?page=1`, fetcher);
  const { results } = data?.data || {};

  return (
    <PageLayout title={data ? t('welcome_parent', { name: parentData?.first_name || '' }): ''} cyberAcademyButton>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {showBanner ? (
            <Stack style={{ background: Colors.VIOLET_50, padding: 16 }}>
              <Text size='small' style={{ color: Colors.WHITE_0 }}>
                {t('premium_banner_text')}
              </Text>
            </Stack>
          ) : null}
        </Col>
        <Col span={16}>
          <ParentResources setShowBanner={setShowBanner} />
        </Col>
        <Col span={8}>
          <Stack direction="column" spacing={33}>
            <LoginDetails />
            <HelpfulLinks loading={isLoading} hasChildren={results?.length > 0} />
          </Stack>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default Overview;
