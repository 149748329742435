import React from 'react';
import PropTypes from 'prop-types';
import { Form, message, Skeleton } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { EducatorButton, EducatorModal } from '../../../commonComponents';
import { useLoginDetails } from '../../../../hooks';

export const PasswordModal = ({ show, handleOnCancel, selectedLoginDetails }) => {
  const { data: loginDetails, loading } = useLoginDetails(selectedLoginDetails?.id);

  return (
    <EducatorModal visible={show} onCancel={handleOnCancel}>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="show-password__modal">
          <h3 className="fixCardHeadingStyle">How to play Cyber Legends</h3>
          <p className="fixCardTextStyle mt-2">
            Have your child visit game.cyberlegends.com. Then enter their login information below:
          </p>
          <Form onFinish={handleOnCancel}>
            <div className="mt-3">
              <h4>USERNAME:</h4>
              <div className="clipboard-code pt-3">
                {loginDetails?.username}
                <CopyOutlined
                  style={{ color: '#4B3AD3', fontSize: '23px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(loginDetails?.username);
                    message.info('Copied to Clipboard!');
                  }}
                />
              </div>
            </div>
            <div className="mt-3">
              <h4>PASSWORD:</h4>
              <div className="clipboard-code pt-3">
                {loginDetails?.password}
                <CopyOutlined
                  style={{ color: '#4B3AD3', fontSize: '23px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(loginDetails?.password);
                    message.info('Copied to Clipboard!');
                  }}
                />
              </div>
            </div>
            <div className="py-3">
              <EducatorButton label="Got It!" type="submit" width="100%" />
            </div>
          </Form>
        </div>
      )}
    </EducatorModal>
  );
};

PasswordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  selectedLoginDetails: PropTypes.object.isRequired
};
