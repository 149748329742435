import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Select, Checkbox, message } from 'antd';
import { Auth } from 'aws-amplify';

import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { API_URL, getApiWithoutAuth, postApiWithoutAuth, setRefreshToken, setToken } from '../../../utils';
import { useGrades } from '../../../hooks';

const { Option } = Select;

export const ChildSignUp = () => {
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const [userData, setUserData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dataCountry, setDataCountry] = useState(null);
  const [dataGrade, setDataGrade] = useState(null);
  const [isCheckBox, setIsCheckBox] = useState(false);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const getCountries = async () => {
    const { success, data } = await getApiWithoutAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
      setDataCountry(data[0]?.name);
    }
  };
  const selectCountry = countryName => {
    setUserData({ ...userData, country: countryName });
  };

  const selectGrade = gradeName => {
    setUserData({ ...userData, grade: gradeName });
  };

  const signUpChild = async () => {
    if (isCheckBox) {
      setButtonSpinner(true);
      const { success, data } = await postApiWithoutAuth(API_URL.CREATE_CHILD, userData);
      if (success) {
        signInUser(data);
      } else {
        setButtonSpinner(false);
      }
    } else {
      setButtonSpinner(false);
      message.error('First Select the Terms & Conditions');
    }
  };

  const signInUser = data => {
    Auth.signIn({
      username: data.username,
      password: data.password
    })
      .then(() => {
        setButtonSpinner(false);
        saveToken(data);
      })
      .catch(() => {
        setButtonSpinner(false);
      });
  };
  const saveToken = async data => {
    await Auth.currentSession()
      .then(res => {
        setToken(res.accessToken.jwtToken);
        setButtonSpinner(false);
        setRefreshToken(res.refreshToken.token);
        navigate('/child-create-account/parent-connect', {
          state: { student_id: data.student_link_id }
        });
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  const onChangedCheck = event => {
    const { checked } = event.target;
    setIsCheckBox(checked);
  };

  useEffect(() => {
    if (dataGrade !== null) {
      setUserData({ ...userData, grade: dataGrade });
    }
  }, [dataGrade]);

  useEffect(() => {
    if (dataCountry !== null) {
      setUserData({ ...userData, country: dataCountry });
    }
  }, [dataCountry]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (grades.length > 0) {
      setDataGrade(grades[0]?.name);
    }
  }, [grades]);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-5">
          <div className="childHaddingStyle">Sign Up To Play!</div>
          <div className="childTextStyle">Let us know a bit about yourself to get started.</div>
          <div className="pt-4">
            <Form onFinish={signUpChild}>
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    name="first_name"
                    rules={[
                      { required: true, message: 'Please input your First Name!' },
                      {
                        pattern: new RegExp(/^[A-Za-z]+$/),
                        message: 'Must contain Alphabets'
                      }
                    ]}>
                    <EducatorInputField
                      label="First Name:"
                      type="text"
                      inputValue={userData.first_name}
                      headerColor="#000000"
                      name="first_name"
                      onChange={onChangeHandle}
                      width="100%"
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        pattern: /^[a-zA-Z]{1}$/,
                        message: 'First Letter of Last Name'
                      }
                    ]}>
                    <EducatorInputField
                      label="First Letter of Last Name:"
                      headerColor="#000000"
                      inputValue={userData.last_name}
                      type="text"
                      name="last_name"
                      onChange={onChangeHandle}
                      width="100%"
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">What country do you live in?:</label>
                    <div className="mt-3">
                      <Select
                        className="fixCardInputField"
                        value={userData?.country}
                        onChange={selectCountry}
                        name="country">
                        {countries.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">What grade are you in?:</label>
                    <div className="mt-3">
                      <Select onChange={selectGrade} value={userData?.grade} className="fixCardInputField" name="grade">
                        {grades.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="textStyle400Cabin18Black">
                <div className="displayInline my-3">
                  <Checkbox onChange={onChangedCheck} />
                  <div>
                    &nbsp; By signing up you agree to our
                    <span className="labelStyleCard cursorPointer">
                      {' '}
                      <a
                        href="https://www.cyberlegends.com/terms-and-conditions"
                        className="labelStyleCard"
                        target="_blank"
                        rel="noreferrer">
                        Terms & Conditions
                      </a>
                    </span>
                    &nbsp;and&nbsp;
                    <span className="labelStyleCard cursorPointer">
                      {' '}
                      <a
                        href="https://www.cyberlegends.com/privacy-policy"
                        className="labelStyleCard"
                        target="_blank"
                        rel="noreferrer">
                        Privacy Policy.
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <EducatorButton label="Create Account" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildSignUp;
