import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { API_URL } from '../../../../utils';
import { ParentResourceCard } from '../../../commonComponents';
import './OtherPlaylists.scss';

export const OtherPlaylists = ({ itemId, isMemberShipType }) => {
  const navigate = useNavigate();

  const { data: suggestedActivitiesResponse } = useSWR(`${API_URL.GET_FETCH_SUGGESTED_ACTIVITIES}/${itemId}`);
  let suggestedActivities = suggestedActivitiesResponse?.data || [];

  if (suggestedActivities.length > 3) {
    suggestedActivities = suggestedActivities.slice(0, 3);
  }

  return (
    <div className="other-playlists__container">
      <div className="wrapper">
        <h3>Other Playlists You May Like:</h3>
        <div className="d-flex parent-resource-card__container">
          {suggestedActivities?.map(item => (
            <div key={item.id}>
              <ParentResourceCard
                image={item.thumbnail}
                title={item.title}
                description={item.description}
                grade={item.grade}
                topic={item.topic}
                disabled={isMemberShipType === 'Free' && !item.allow_fremium}
                onClick={() =>
                  navigate(`/parent-resource/${item.id}`, {
                    state: {
                      itemId: item.id,
                      isAllowFreemium: item.allow_fremium,
                      isMemberShipType: isMemberShipType,
                      title: item.title
                    }
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

OtherPlaylists.propTypes = {
  itemId: PropTypes.string.isRequired,
  isMemberShipType: PropTypes.string.isRequired
};
