import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { EducatorButton, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import { API_URL, getApiWithAuth } from '../../../utils';
import './parentConnectChild.css';

const ParentConnectChild = () => {
  const navigate = useNavigate();

  const [familyUniqueCode, setFamilyUniqueCode] = useState('');

  const getFamilyCode = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PARENT_FAMILY_CODE);
    if (success) {
      setFamilyUniqueCode(data[0].family_code);
    }
  };
  const onFinishHandler = async () => {
    navigate('/parent-create-account/additional-child');
  };
  useEffect(() => {
    getFamilyCode();
  }, []);
  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Child Setup" check={[1, 0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">Connect your child’s account</div>
            <div className="pt-2 textStyle">
              Have your child enter this code when they login to Cyber Legends Game. Doing this will link you together.
            </div>
            <div className="py-5">
              <div className="textFamilyCode">Your Family Code</div>
              <div className="emailCodeStyleHeading cursorPointer">{familyUniqueCode || 'ZZ'}</div>
            </div>
            <Form onFinish={onFinishHandler}>
              <Form.Item className="pt-3">
                <EducatorButton label="Next" type="submit" />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          <HelperBot>
            This family code connects your new account to your child’s existing account! Please write it down.
          </HelperBot>
        </div>
      </div>
    </div>
  );
};
export default ParentConnectChild;
