import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Collapse, DatePicker, Divider, Form, Select } from 'antd';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField, EducatorModal, ModalOptions } from '@components/commonComponents';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;

export const EditAssignment = ({
  show,
  onCancel,
  assignmentDataById,
  onChange,
  onChangeStartDate,
  onChangeEndDate,
  disabledDate,
  onCheckboxChange,
  assignedStudentsId,
  onSelectChange,
  studentData,
  handleTopicsChange,
  onSaveAssignment,
  loading
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  return (
    <EducatorModal
      width="600"
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">{t('update_assignment')}</div>
          <div className="fixCardtext mt-2 mb-3">{t('enter_assignment_details')}</div>
          <Form>
            <Form.Item className="pt-3">
              <EducatorInputField
                label={t('name_assignment')}
                type="text"
                name="name"
                className="fixCardInputField"
                inputValue={assignmentDataById.name}
                onChange={onChange}
              />
            </Form.Item>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <label className="selectCreateAssignment mb-1 ">{t('select_start_date')}</label>
                <DatePicker
                  value={moment(assignmentDataById.start_date, 'YYYY - MM - DD')}
                  suffixIcon={false}
                  className="datePickerAssignmentStyle"
                  name="start_date"
                  onChange={onChangeStartDate}
                  disabledDate={disabledDate}
                />
              </div>
              <div>
                <label className="selectCreateAssignment mb-1">{t('select_end_date')}</label>
                <DatePicker
                  value={moment(assignmentDataById.end_date, 'YYYY - MM - DD')}
                  suffixIcon={false}
                  className="datePickerAssignmentStyle"
                  name="end_date"
                  onChange={onChangeEndDate}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
            <div className="fixCardtext d-flex mt-3">
              <Checkbox
                name="assign_all_students"
                checked={assignmentDataById.assign_all_students}
                onChange={onCheckboxChange}
              />
              &nbsp;&nbsp;
              <span style={{ alignSelf: 'center', textAlign: 'center' }}>{t('assign_to_entire_class')}</span>
            </div>
            <Divider plain>{t('or')}</Divider>
            <label className="selectCreateAssignment mb-2">{t('select_students')}</label>
            <Select
              className="selectStudentInputField"
              mode="multiple"
              optionLabelProp="label"
              value={assignedStudentsId}
              onChange={onSelectChange}>
              {studentData.map(item => {
                return (
                  <Option
                    key={item.id}
                    value={item.id}
                    label={`${item.first_name} ${item.last_name.substring(0, 1).toUpperCase()}`}
                    name="assigned_students">
                    {item.first_name} {item.last_name.substring(0, 1).toUpperCase()}.
                  </Option>
                );
              })}
            </Select>
            <div className="selectCreateAssignment pt-3">{t('select_skills')}</div>
            <Collapse
              accordion
              className="collapseStyle"
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined className="iconStyle" /> : <PlusOutlined className="iconStyle" />
              }
              expandIconPosition={'end'}>
              {assignmentDataById.all_topics?.map(item => {
                return (
                  <Panel className="panelHeadingsOrIconStyle mt-2" header={item.heading} key={item.heading}>
                    {item.topics.map(topic => {
                      return (
                        <ModalOptions
                          checkValue={topic.included}
                          key={topic.id}
                          id={topic.id}
                          title={topic.name}
                          background={topic.sub_heading.background_color}
                          subTitle={topic.sub_heading.name}
                          textColor={topic.sub_heading.text_color}
                          onChange={e => {
                            handleTopicsChange(e);
                          }}
                        />
                      );
                    })}
                  </Panel>
                );
              })}
            </Collapse>
          </Form>
          <div className="mt-3">
            <EducatorButton
              label={t('save_changes')}
              type="button"
              width="100%"
              onClick={onSaveAssignment}
              showSpinner={loading}
            />
          </div>
        </div>
      }
    />
  );
};

EditAssignment.defaultProps = {
  assignedStudentsId: []
};

EditAssignment.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  assignmentDataById: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  disabledDate: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  assignedStudentsId: PropTypes.array,
  onSelectChange: PropTypes.func.isRequired,
  studentData: PropTypes.array.isRequired,
  handleTopicsChange: PropTypes.func.isRequired,
  onSaveAssignment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
