/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { EducatorButton } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import tickImage from '../../../assets/images/tick.svg';
import { useNavigate } from 'react-router-dom';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import { Switch } from 'antd';
import { setFlowComplete } from '../../../utils/sessionStorage';
import { setUserID } from '../../../utils/sessionStorage';

import './parentSelectPlan.css';

const ParentSelectPlan = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [yearStatus, setYearStatus] = useState(false);
  const [showPlan, setShowPlan] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const getPaymentPlans = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PAYMENT_PLANS);
    if (success) {
      setPlan(data);
    }
  };
  const parentFlowComplete = async () => {
    setButtonSpinner(true);
    const { success } = await patchApiWithAuth(API_URL.UPDATE_USER_AUTH, { is_auth: true });
    if (success) {
      setFlowComplete(true);
      setButtonSpinner(false);
      navigate('/dashboard');
    }
  };

  const paymentId = item => {
    if (item.amount === 0) {
      parentFlowComplete();
    } else {
      navigate('/parent-create-account/payment-setup', { state: item });
    }
  };

  useEffect(() => {
    getPaymentPlans();
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      setUserID(data.id);
    }
  };
  const onChange = checked => {
    setYearStatus(checked);
  };

  useEffect(() => {
    if (plan.length !== 0) {
      if (yearStatus) {
        setShowPlan([plan[0], plan[2]]);
      } else {
        setShowPlan([plan[0], plan[1]]);
      }
    }
  }, [yearStatus, plan]);
  const ShowFreePlan = () => {
    return (
      <div className="selectParentCardText">
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3"> Core gameplay features </div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3"> Access to all cyber safety educational content</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3"> Parent Dashboard</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3"> Basic Reporting Features</div>
        </div>
      </div>
    );
  };

  const ShowPremimumPlan = () => {
    return (
      <div className="selectParentCardText">
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">Access to coding educational content in addition to cyber safety</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">Membership exclusive rewards and quests</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">Unlock member exclusive pets</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">Earn in-game premium currency (Crystals)</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">Comprehensive competency based reporting</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">At home activity kit and educational videos</div>
        </div>
        <div className="selectParentTickStyle mt-2">
          <img src={tickImage} alt="tickImage" />
          <div className="ps-3">Ability to customize child’s learning path</div>
        </div>
      </div>
    );
  };
  return (
    <div className="mainDivSignUpStyle container-fluid">
      <Navbar />
      <div className="row pt-sm-3 pt-lg-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Select a Plan" check={[1, 1, 1, 0, 0]} />
        </div>
        <div className="col-8 d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">Select Your Plan</div>
            <div className="pt-2 textStyleSeelectPlan">
              Before you’re ready to go we need to know if your child will be playing with a free or premium membership.
            </div>
            <div className="pt-2 textStyleSeelectPlan">
              Billed Monthly &nbsp;&nbsp; <Switch size="large" className="switchStyle" onChange={onChange} />{' '}
              &nbsp;&nbsp; Billed Annually
            </div>
            <div className="py-5">
              <div className="d-flex">
                {showPlan?.map(item => {
                  return (
                    <div className="selectParentCard p-4 me-3" key={item.id}>
                      <div className="selectParentCardHadding">
                        {item.amount === 0 ? 'Regular Member' : 'Premium Member'}
                      </div>
                      <div className="selectParentCardText mt-3">
                        This plan allows your child to customize Cyber Legends to be a world of their own.
                      </div>
                      <div className="selectParentCardPrize">
                        {item.amount === 0 ? 'Free' : `$ ${item.amount / 100}`}{' '}
                        <span className="monthStyle">
                          {item.amount === 0 ? ' ' : `/ ${item.interval.toUpperCase()}`}
                        </span>
                      </div>
                      {item.amount === 0 ? ShowFreePlan() : ShowPremimumPlan()}
                      <div className="mt-4">
                        <EducatorButton
                          label={item.amount === 0 ? 'Sign up for Freemium' : 'Upgrade to Premium'}
                          width="100%"
                          background={item.amount === 0 ? 'none' : ''}
                          border={item.amount === 0 ? '2px solid #F8CA42' : ''}
                          type="button"
                          showSpinner={item.amount === 0 ? buttonSpinner : null}
                          onClick={() => paymentId(item)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentSelectPlan;
