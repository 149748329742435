import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EducatorModal } from '@components/commonComponents';
import { API_URL, getApiWithAuth } from '@utils';
import { Spin } from 'antd';
import UnityView from '@components/commonComponents/Unity/unity';

export const MissionModal = ({ show, handleOnCancel, missionId }) => {
  const [missionData, setMissionData] = useState(null);
  const [mission, setMission] = useState(null);
  const [unloadUnity, setUnloadUnity] = useState(false);

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const getMission = async () => {
    setButtonSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_SCHOOL_MISSIONS_LIST}/${missionId}`);
    if (success) {
      setMissionData(data);

      setButtonSpinner(false);
    } else {
      setButtonSpinner(false);
    }
  };

  useEffect(() => {
    if (!missionData) return;
    setMission({
      id: missionData.id,
      mission_id: missionData.external_id,
      current_game_id: '',
      status: null,
      started_at: null,
      completed_at: null,
      topic: {
        id: missionData.topic.id,
        topic_id: `${missionData.external_id.split('.')[0]}.${missionData.external_id.split('.')[1]}`,
        topic_name: missionData.topic.name
      }
    });
  }, [missionData]);

  useEffect(() => {
    if (missionId) getMission();
  }, [missionId]);

  const onCancel = () => {
    setUnloadUnity(true);
    handleOnCancel();
  };

  return (
    <EducatorModal destroyOnClose className="mission_modal" width={'100vw'} height={'100vh'} visible={show} onCancel={onCancel}>
      <div style={{ position: 'fixed', left: '0', top: '0' }}>
        {buttonSpinner ? (
          <div className="emptyMessageStudent">
            <Spin size="large" />
          </div>
        ) : !mission ? (
          <div className="emptyMessageStudent">
            <div>Something Went Wrong</div>
          </div>
        ) : (
          <div>
            <UnityView
              useBackHack
              mission={mission}
              unityTemplate={missionData.unity_template}
              userType={'school'}
              unloadUnity={unloadUnity}
              onClose={onCancel}
            />
          </div>
        )}
      </div>
    </EducatorModal>
  );
};

MissionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  missionId: PropTypes.number.isRequired
};
