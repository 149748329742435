import React, { useEffect, useState } from 'react';
import { Input, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Button, Card, Form, Link, Stack, Text, Title } from '@teacher-app/design-system';
import UploadStudentsModal from '@teacher-app/components/UploadStudentsModal';
import { useAppState } from '@context';
import { useGrades, useStudents } from '@hooks';
import { API_URL, postApiWithAuth } from '@utils';

const { Option } = Select;

const CreateStudent = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { state } = useAppState();
  const [form] = Form.useForm();
  const { data: grades } = useGrades();
  const [uploadStudents, setUploadStudents] = useState(false);
  const [saving, setSaving] = useState(false);
  const { revalidate: getStudents } = useStudents(state.classId, '');

  const currentClass = state.classes.find(item => item.id === state.classId);

  const createStudent = async values => {
    setSaving(true);
    const { success, data: plannerResponse } = await postApiWithAuth(
      `${API_URL.ADD_STUDENTS_V2}${state.classId}/students/create`,
      values
    );
    if (success) {
      getStudents();
      setSaving(false);
      form.resetFields();
      form.setFieldValue('grade', currentClass?.grade);
      message.success(t('student_added_successfully'));
    } else {
      message.error(plannerResponse.message ?? t('student_added_error'));
      setSaving(false);
    }
  };

  useEffect(() => {
    form.setFieldValue('grade', currentClass?.grade);
  }, [currentClass?.grade]);

  return (
    <>
      <Card>
        <Stack spacing={30} direction="column">
          <Stack direction="column" alignItems="flex-start">
            <Title level={3} style={{ paddingTop: 3 }}>
              {t('create_student')}
            </Title>
            <Text size="small">
              {t('manually_create_student')}&nbsp;
              <Link style={{ display: 'inline-block' }} onClick={() => setUploadStudents(true)}>
                {t('upload_csv_text')}
              </Link>
            </Text>
          </Stack>
          <Form onFinish={createStudent} size="small" form={form}>
            <Stack alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
              <Form.Item
                style={{ flex: '1 1 110px' }}
                label={t('student_name')}
                name="name"
                tooltip={{
                  color: 'purple',
                  title: t('student_name_tooltip'),
                  icon: <QuestionCircleFilled style={{ color: '#D4DFFC', fontSize: '16px' }} />
                }}>
                <Input placeholder="John D" />
              </Form.Item>
              <Form.Item style={{ flex: '1 1 110px' }} name="external_id" label={t('student_id')}>
                <Input placeholder="johnd000" />
              </Form.Item>
              <Form.Item
                style={{ flex: '1 1 140px' }}
                name="student_email"
                validateTrigger="submit"
                rules={[
                  {
                    type: 'email',
                    message: t('invalid_email')
                  }
                ]}
                label={t('student_email')}>
                <Input placeholder="example@email.com" />
              </Form.Item>
              <Form.Item
                style={{ flex: '1 1 140px' }}
                name="parent_email"
                validateTrigger="submit"
                rules={[
                  {
                    type: 'email',
                    message: t('invalid_email')
                  }
                ]}
                label={t('parent_email')}>
                <Input placeholder="parents@email.com" />
              </Form.Item>
              <Form.Item style={{ flex: '1 1 140px' }} name="grade" label={t('grade')} required>
                <Select name="grade">
                  {grades.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Stack style={{ minHeight: 76, width: 92 }} alignItems="flex-end">
                <Button block size="small" htmlType="submit" loading={saving}>
                  {t('create')}
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Stack>
      </Card>
      <UploadStudentsModal
        isOpen={uploadStudents}
        onClose={() => {
          setUploadStudents(false);
        }}
        handleOnFinish={() => {
          getStudents();
          setUploadStudents(false);
        }}
      />
    </>
  );
};

export default CreateStudent;
