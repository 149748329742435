export const stripeOptions = {
  style: {
    base: {
      fontSize: '18px',
      color: '#222222',
      fontFamily: 'Cabin, sans-serif',
      fontWeight: '600',
      '::placeholder': {
        color: '#222222'
      }
    }
  },
  placeholder: ''
};
