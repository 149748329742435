import React from 'react';
import { Navigate } from 'react-router-dom';
import { USER_ROLE, getLocalStorageItem } from '@utils';

export const Dashboard = () => {
  const currentUserRole = getLocalStorageItem('user_role');

  switch (currentUserRole) {
    case USER_ROLE.PARENT:
      return <Navigate to={'/parent-dashboard'} replace />;
    case USER_ROLE.TEACHER:
      return <Navigate to={'/teacher-dashboard'} replace />;
    case USER_ROLE.CHILD:
      return <Navigate to={'/student-dashboard'} replace />;
    default:
      return <Navigate to={'/teacher-dashboard'} replace />;
  }
};
