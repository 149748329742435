import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { EducatorButton, EducatorModal } from '@components/commonComponents';

export const AssignmentType = ({ show, onCancel, onFinish, onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  return (
    <EducatorModal
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">{t('assignment_type')}</div>
          <div className="fixCardtextStyle mt-2">{t('you_have_ability')}</div>
          <Form onFinish={onFinish}>
            <Form.Item className="pt-4">
              <EducatorButton key="submit" label={t('create_practice_assignment')} type="submit" width="100%" />
            </Form.Item>
            <Form.Item>
              <EducatorButton
                key="button"
                label={t('create_graded_assignment')}
                type="button"
                width="100%"
                onClick={onClick}
              />
            </Form.Item>
          </Form>
        </div>
      }
    />
  );
};

AssignmentType.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};
