import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Stack, Text, Title } from '@teacher-app/design-system';
import ResetIcon from '@assets/images/icon_recoverPassword.png';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ResetPlannerModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });
  const navigate = useNavigate();

  const handleBuy = () => {
    onClose();
    navigate('/planner/new');
  };

  return (
    <Modal centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" spacing={50}>
        <Stack direction="column" spacing={22}>
          <Image src={ResetIcon} preview={false} />
          <Stack direction="column" alignItems="center" spacing={20}>
            <Title style={{ textAlign: 'center' }} level={3}>{t('planner_reset_modal_title')}</Title>
            <Text className="text-center">{t('planner_reset_modal_description')}</Text>
          </Stack>
        </Stack>
        <Stack justifyContent='center'>
          <Button onClick={handleBuy}>{t('planner_reset_modal_button')}</Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ResetPlannerModal;

ResetPlannerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
