/* eslint-disable no-console */
import React from 'react';
import { EducatorButton } from '../../commonComponents';
import { Form, message } from 'antd';
import { clearStorage, getIsGoogle } from '../../../utils/sessionStorage';
import { GOOGLE_SSO_LOGOUT_URL } from '@utils';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const ChildClassNotExist = () => {
  const navigate = useNavigate();

  const logoutUser = async () => {
    const Google = getIsGoogle();
    if (Google === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
          navigate('/');
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childSecondHaddingStyle">Oh no! It looks like your class isn’t playing Cyber Legends.</div>
          <div className="mt-3">
            <Form onFinish={logoutUser}>
              <div className="py-3">
                <EducatorButton label="Return to Main Screen" type="submit" />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildClassNotExist;
