import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin } from 'antd';
import './educatorButtonStyle.css';

const EducatorButton = ({
  showSpinner,
  loading,
  label,
  type,
  onClick,
  className,
  icon,
  width,
  disabled,
  height,
  background,
  border,
  coupon,
  dataTestId,
  variant,
  smallSpinner,
  children,
  ...rest
}) => {
  const buttonStyle = {
    width,
    height,
    background,
    border
  };

  const buttonClass = classNames(
    'buttonStyle',
    { buttonDisabledStyle: disabled && !coupon },
    { buttonDisabledCoupon: disabled && coupon },
    { buttonOutlineStyle: variant === 'outline' },
    { buttonTextStyle: variant === 'text' },
    className
  );

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClass}
      style={buttonStyle}
      data-testid={dataTestId}
      {...rest}>
      {icon ? (
        <div className="d-flex align-items-center justify-content-center">
          {icon}&nbsp;&nbsp;&nbsp;{label}
        </div>
      ) : showSpinner || loading ? (
        <Spin size={smallSpinner ? 'small' : 'large'} />
      ) : (
        children ?? label
      )}
    </button>
  );
};
export default EducatorButton;

EducatorButton.defaultProps = {
  showSpinner: false,
  loading: false,
  disabled: false,
  smallSpinner: false,
  label: '',
  type: '',
  onClick: () => {},
  className: '',
  icon: '',
  width: '',
  height: '',
  border: '',
  background: '',
  coupon: null,
  dataTestId: null,
  variant: '',
  children: null
};
EducatorButton.propTypes = {
  showSpinner: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  smallSpinner: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
  background: PropTypes.string,
  coupon: PropTypes.object,
  dataTestId: PropTypes.string,
  variant: PropTypes.oneOf(['', 'outline', 'text']),
  children: PropTypes.node
};
