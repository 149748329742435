import React from 'react';
import { Empty, Spin } from 'antd';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL } from '@utils';
import { EducatorButton } from '@components/commonComponents';
import { MissionCard, StatusNotification } from '@components/studentDashboardComponent';

export const MissionModal = ({ onDone, mission }) => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  const { data: missionDetailData, error: missionDetailError } = useSWR(
    mission ? `${API_URL.GET_STUDENT_MISSIONS_LIST}/${mission.id}` : null
  );
  const { data: missionProgressData, error: missionProgressError } = useSWR(
    mission ? `${API_URL.GET_STUDENT_MISSIONS_LIST}/${mission.id}/progress` : null
  );

  const missionDetail = missionDetailData?.data;
  const missionProgress = missionProgressData?.data;

  const isLoading = !missionDetail || !missionProgress || missionDetailError || missionProgressError;

  const modalText = t('learn_all_about', { missionName: missionDetail?.name });

  return (
    <div className="container-fluid modal__container">
      {isLoading ? (
        <div className="emptyMessageStudent">
          <Spin size="large" />
        </div>
      ) : !missionDetail || !missionProgress ? (
        <div className="emptyMessageStudent">
          <Empty description={false} />
        </div>
      ) : (
        <div className="childWhiteBackgroung py-4">
          <h2 className="childSecondHaddingStyle">{missionDetail.name}</h2>
          <div className="d-flex gap-3 mt-5">
            <div>
              <MissionCard
                image={missionDetail.thumbnail}
                title=""
                badge={missionDetail.topic.sub_heading}
                topicName=""
                isLocked={false}
                onClick={() => {}}
                status={mission.status}
                userAchievement
              />
            </div>
            <div>
              <div>
                <StatusNotification
                  status={mission.status}
                  text={`${
                    missionProgress.minigames.filter(
                      minigame => minigame.status && minigame.status.name === 'Completed'
                    ).length
                  }/${missionDetail.minigames.length} ${t('tasks_completed')}`}
                />
              </div>
              <p className="description">{modalText}</p>
            </div>
          </div>
          <div className="mt-3">
            <div className="py-3 d-flex justify-content-center">
              <EducatorButton label={t('play_mission')} onClick={onDone} type="submit" width={'auto'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MissionModal.defaultProps = {
  mission: null
};

MissionModal.propTypes = {
  onDone: PropTypes.func.isRequired,
  mission: PropTypes.object
};
