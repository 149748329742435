import React, { useEffect, useState } from 'react';
import { Card, message } from 'antd';
import { getAuthToken } from '@utils';

const AccountMenu = () => {
  const [token, setToken] = useState('');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(token);
      message.success('Token copied to clipboard!');
    } catch (error) {
      message.error('Failed to copy:');
    }
  };

  useEffect(async () => {
    const _token = await getAuthToken();
    setToken(_token);
  }, []);

  return (
    <Card title="Media Settings" className="p-2">
      <Card
        type="inner"
        title="Token"
        style={{ padding: 10,  }}
        extra={
          <a href="#" onClick={copyToClipboard}>
            Copy to Clipboard
          </a>
        }>
        <p style={{ wordBreak: 'break-all', maxWidth: 400, maxHeight: 200, overflowY: 'scroll' }}>{token}</p>
      </Card>
    </Card>
  );
};

export default AccountMenu;

