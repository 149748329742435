import React from 'react';
import PropTypes from 'prop-types';
import { AlertCircle, Check, MoreHorizontal, Star } from 'react-feather';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import './StatusNotification.scss';

const daysLeft = dueDate => dayjs(dueDate).diff(dayjs(), 'day');

export const StatusNotification = ({ endDate, status, text }) => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  return (
    <div>
      {endDate ? (
        <div className="notification__container due_soon">
          <AlertCircle />
          {daysLeft(endDate)} {t('days_left')}
        </div>
      ) : null}
      {status === 'Completed' ? (
        <div className="notification__container completed">
          <Check />
          {text}
        </div>
      ) : null}
      {status === 'Started' ? (
        <div className="notification__container in_progress">
          <MoreHorizontal />
          {text}
        </div>
      ) : null}
      {status === 'Available' ? (
        <div className="notification__container available">
          <Star />
          {t('new')}
        </div>
      ) : null}
    </div>
  );
};

StatusNotification.defaultProps = {
  endDate: null,
  status: '',
  text: ''
};

StatusNotification.propTypes = {
  endDate: PropTypes.string,
  status: PropTypes.string,
  text: PropTypes.string
};
