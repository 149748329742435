import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select, message, Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, HelperBot } from '@components/commonComponents';
import { Navbar, Multistep } from '@components/layoutComponents';
import { API_URL, postApiWithAuth, putApiWithAuth } from '@utils';
import { useCurrentUser, useSchools } from '@hooks';

import './SchoolSetup.scss';

export const SchoolSetup = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'school_setup' });
  const navigate = useNavigate();
  const location = useLocation();
  const { Option } = Select;
  const [schoolName, setSchoolName] = useState('');
  const [schoolId, setSchoolId] = useState(null);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [homeschoolSpinner, setHomeschoolSpinner] = useState(false);
  const { country, province } = location.state || {};

  const { data: currentUser } = useCurrentUser();
  const { data: schools, loading } = useSchools(country, province, schoolName);

  const handleSearch = value => {
    setSchoolName(value);
  };

  const handleSelect = value => {
    if (value === 'new_school') {
      navigate('/teacher-create-account/create-school');
    } else {
      setSchoolId(value);
    }
  };

  const addSchool = async () => {
    setButtonSpinner(true);
    const URL = `school/${schoolId}/user/${currentUser.id}/add`;
    const { success, data } = await postApiWithAuth(URL);

    if (success) {
      setButtonSpinner(false);
      navigate('/teacher-dashboard');
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };

  const addHomeshool = async () => {
    setHomeschoolSpinner(true);
    const { success, data } = await putApiWithAuth(API_URL.UPDATE_HOME_SCHOOL, { is_homeschool: true });

    if (success) {
      setHomeschoolSpinner(false);
      navigate('/teacher-dashboard');
    } else {
      message.error(data.message);
      setHomeschoolSpinner(false);
    }
  };

  return (
    <div className="school-setup__container mainDivStyle container-fluid">
      <Navbar />

      <div className="row  py-5">
        <div className="col-3  d-flex justify-content-center">
          <Multistep select="School Setup" check={[1, 1, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center mb-5">
          {homeschoolSpinner ? (
            <div className="mainDivStyle">
              <div className="spineerCenter">
                <Spin size="large" />
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center flex-column justify-content-center mb-5">
              <div className="SchoolSetupStyleHeading">{t('school_setup')}</div>

              <Form>
                <div className="pt-3">
                  <div>
                    <label className="schoolSetUpLabel">{t('type_school_name')}</label>
                    <div className="mt-2">
                      <Select
                        loading={loading}
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearch}
                        onSelect={handleSelect}
                        notFoundContent={null}
                        className="inputFieldStyle"
                        allowClear
                        popupClassName="school-name__search">
                        {schools?.map(item => (
                          <Option key={item.id} value={item.id}>
                            <div className="d-flex flex-column">
                              <div className="school-name">{item.name}</div>
                              <div className="school-details">
                                {item.district}, {item.city}
                              </div>
                            </div>
                          </Option>
                        ))}
                        <Option key="new_school" data-testid="dontSeeSchoolButton" value="new_school">
                          <div className="d-flex flex-column">
                            <div className="school-name fw-bold">{t('dont_see_your_school')}</div>
                          </div>
                        </Option>
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="pt-3">
                  <EducatorButton
                    label={t('add_school')}
                    onClick={addSchool}
                    showSpinner={buttonSpinner}
                    dataTestId="addSchoolButton"
                  />
                </div>
              </Form>
              <div
                className="pt-3 textStyleYellow cursorPointer mt-2"
                onClick={addHomeshool}
                role="button"
                tabIndex="0"
                data-testid="imHomeschoolTeacher">
                {t('im_homeschool_teacher')}
              </div>
            </div>
          )}
        </div>
        <div className="col-3 d-flex align-items-end">
          <HelperBot>{t('more_schools_coming_soon')}</HelperBot>
        </div>
      </div>
    </div>
  );
};
export default SchoolSetup;
