import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { Navbar } from '@components/layoutComponents';
import { EducatorButton, EducatorInputField } from '@components/commonComponents';

const ResetPassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'reset_password' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({ password: '', confirm_password: '' });
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [code, setCode] = useState('');
  const email = location.state || {};

  const ResetPasswordFunction = () => {
    setButtonSpinner(true);
    Auth.forgotPasswordSubmit(email, code, data.password)
      .then(() => {
        setButtonSpinner(false);
        navigate(-2);
      })
      .catch(err => {
        setButtonSpinner(false);
        message.error(`${err.message}`);
      });
  };

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5 w-100">
        <div className="mt-2">
          <div className="textStyleHeading">{t('password_reset')}</div>
          <div className="mt-2 forgetPasswordTextStyle">{t('password_recovery_instructions')}</div>
          <div className="mt-4">
            <Form onFinish={ResetPasswordFunction}>
              <Form.Item className="pt-3" name="code" rules={[{ required: true, message: t('enter_code') }]}>
                <EducatorInputField
                  label={t('enter_your_code')}
                  type="text"
                  name="code"
                  inputValue={code}
                  onChange={e => setCode(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className="pt-2"
                name="password"
                rules={[
                  { required: true, message: commonT('input_password') },
                  {
                    required: true,
                    pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                    message: commonT('password_requirements')
                  }
                ]}>
                <EducatorInputField
                  label={t('new_password')}
                  passwordValue={data.password}
                  type="password"
                  name="password"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item
                className="pt-2"
                name="confirm_password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('confirm_password_message')
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('passwords_do_not_match')));
                    }
                  })
                ]}>
                <EducatorInputField
                  label={t('confirm_password')}
                  type="password"
                  name="confirm_password"
                  passwordValue={data.confirm_password}
                  dependencies={data.password}
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="pt-3">
                <EducatorButton
                  label={t('save_password')}
                  type="submit"
                  showSpinner={buttonSpinner}
                  dataTestId="savePasswordButon"
                />
              </div>
            </Form>
          </div>
          <div className=" mt-3 textStyleForgetPassword">
            <span className="textStyleOrStyle">{commonT('or')}</span>
            <span
              className="cursorPointer"
              onClick={() => {
                navigate('/teacher-signin');
              }}>
              {t('return_to_login')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
