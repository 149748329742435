import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { message, Skeleton } from 'antd';
import { useReferralCoupon } from '../../../hooks';

export const AvailableCoupons = () => {
  const { data, loading, error } = useReferralCoupon();

  const hasCoupon = data?.coupon_code && !error;

  return (
    <div className="available-coupon__container">
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="">
          <h3>Available Coupons</h3>
          <p>
            Below are the coupons available to you. Copy the code below and head over to <a href="#">subscriptions</a>{' '}
            to apply them.
          </p>
          <hr />
          <div className="monthly-coupon__container subscription__input">
            <div className="d-flex justify-content-between">
              <h4>Monthly free subscription</h4>
              <h4>x{data.count || '0'}</h4>
            </div>
            <div className="coupon-code clipboard-code">
              {!error && data.coupon_code ? data.coupon_code : 'No coupon code available'}
              {hasCoupon && (
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(data.coupon_code);
                    message.info('Class Code Copied to Clipboard!');
                  }}
                />
              )}
            </div>
            <span>This coupon can be used on monthly subscriptions.</span>
          </div>
          <hr className="d-none" />
          <div className="annual-coupon__container subscription__input d-none">
            <h4>Annual free subscription</h4>
            <div className="coupon-code">Coupon Code</div>
            <span>This coupon can be used on annual subscriptions.</span>
          </div>
        </div>
      )}
    </div>
  );
};
