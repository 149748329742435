import React from 'react';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card, Link, Title, Text, Stack } from '@teacher-app/design-system';

import { usePdfs } from '@hooks';
import PropTypes from 'prop-types';

export const HelpfulLinks = ({ loading, hasChildren }) => {
  const { t } = useTranslation('translation');
  const { data, loading: fetchingPdfs, isEmpty } = usePdfs();

  return (
    <Card width="100%">
      {loading || fetchingPdfs ? (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      ) : isEmpty && !hasChildren ? (
        <Stack direction="column" alignItems="flex-start" spacing={30}>
          <Title level={3}>{t('overview.helpful_links')}</Title>
          <Stack direction="column" justifyContent="center" spacing={30}>
            <Stack justifyContent="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('overview.no_students')} />
            </Stack>
          </Stack>
        </Stack>
      ) : isEmpty && hasChildren ? (
        <Stack direction="column" alignItems="flex-start">
          <Title level={3}>{t('overview.helpful_links')}</Title>
          <Stack justifyContent="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('overview.no_links_found')} />
          </Stack>
        </Stack>
      ) : (
        <Stack direction="column" alignItems="flex-start" spacing={30}>
          <Title level={3}>{t('overview.helpful_links')}</Title>
          {data.loginInstructions ? (
            <Stack direction="column" alignItems="flex-start">
              <Link ellipsis href={data.loginInstructions.link} to={data.loginInstructions.link}>
                {t('parents.login_instructions_for_students')}
              </Link>
              <Text size="small">{t('parents.help_students_sign_in')}</Text>
            </Stack>
          ) : null}
          {data.accountDetails ? (
            <Stack direction="column" alignItems="flex-start">
              <Link ellipsis href={data.accountDetails.link} to={data.accountDetails.link}>
                {t('parents.student_account_details')}
              </Link>
              <Text size="small">{t('parents.login_credentials')}</Text>
            </Stack>
          ) : null}
        </Stack>
      )}
    </Card>
  );
};
export default HelpfulLinks;

HelpfulLinks.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasChildren: PropTypes.bool.isRequired,
};

