import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Checkbox, message, Radio } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation, Trans } from 'react-i18next';

import { EducatorButton, EducatorInputField, HelperBot } from '@components/commonComponents';
import { Navbar, Multistep } from '@components/layoutComponents';
import { emailValidationPattern, USER_ROLE, GOOGLE_SSO_AUTH_URL } from '@utils';
import googleImage from '@assets/images/googleImage.png';

import './CreateAccount.scss';

export const CreateAccount = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [signupData, setSignupData] = useState({ firstName: '', lastName: '', email: '', password: '' });
  const [googleSpinner, setGoogleSpinner] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPrincipal, setIsPrincipal] = useState('yes');

  const handleSubmit = () => {
    if (isChecked) {
      setButtonSpinner(true);
      const username = signupData.email.replace('@', '_');

      Auth.signUp({
        username: username,
        password: signupData.password,
        attributes: {
          preferred_username: username,
          email: signupData.email,
          given_name: signupData.firstName,
          family_name: signupData.lastName,
          'custom:user_role': `${USER_ROLE.SCHOOL_ADMIN}`,
          'custom:preferred_language': i18n.language
        }
      })
        .then(() => {
          setButtonSpinner(false);
          navigate('/school-admin/email-confirmation', {
            state: { ...signupData, username: username }
          });
        })
        .catch(err => {
          message.error(`${err.message}`);
          setButtonSpinner(false);
        });
    } else {
      setButtonSpinner(false);
      message.error(t('first_select_terms', { ns: 'schoolAdmin' }));
    }
  };

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
  };

  const googleSignIn = () => {
    setGoogleSpinner(true);
    window.location.href = GOOGLE_SSO_AUTH_URL;
  };

  const onChangedCheck = event => {
    const { checked } = event.target;
    setIsChecked(checked);
  };

  const handleRadioChange = event => {
    const { value } = event.target;
    setIsPrincipal(value);
  };

  return (
    <div className="create-account__container mainDivSignUpStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <Multistep select="School Account Setup" check={[0, 0, 0]} userType={USER_ROLE.SCHOOL_ADMIN} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <Form onFinish={handleSubmit} initialValues={{ check: true }}>
            <div className="textStyleHeading">{t('create_account', { ns: 'schoolAdmin' })}</div>
            <div className="aboutTextStyle">{t('let_us_know_about_you', { ns: 'schoolAdmin' })}</div>
            <div className="pt-4 inlineFormDiv">
              <Form.Item name="firstName" rules={[{ required: true, message: t('input_first_name') }]}>
                <EducatorInputField
                  label={t('first_name')}
                  name="firstName"
                  type="text"
                  inputValue={signupData.firstName}
                  small
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item name="lastName" rules={[{ required: true, message: t('input_last_name') }]}>
                <EducatorInputField
                  label={t('last_name')}
                  name="lastName"
                  type="text"
                  inputValue={signupData.lastName}
                  small
                  onChange={onChangeHandle}
                />
              </Form.Item>
            </div>
            <Form.Item className="pt-3" name="email" rules={[{ required: true, message: t('input_email') }]}>
              <EducatorInputField
                label={t('email')}
                type="email"
                name="email"
                inputValue={signupData.email}
                onChange={onChangeHandle}
              />
            </Form.Item>
            <Form.Item
              className="pt-3"
              name="password"
              rules={[
                { required: true, message: t('input_password') },
                {
                  required: true,
                  pattern: emailValidationPattern,
                  message: t('password_requirements')
                }
              ]}>
              <EducatorInputField
                label={t('password')}
                type="password"
                passwordValue={signupData.password}
                name="password"
                onChange={onChangeHandle}
              />
            </Form.Item>
            <Form.Item className="py-3 principal_check">
              <h4>{t('principal_check', { ns: 'schoolAdmin' })}</h4>
              <Radio.Group onChange={handleRadioChange} defaultValue={isPrincipal} className="principal_check__wrapper">
                <Radio value={'yes'}>{t('yes')}</Radio>
                <Radio value={'no'}>{t('no')}</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="textStyle400Cabin18">
              <div className="displayInline my-3">
                <Checkbox onChange={onChangedCheck} />
                <div className="t-and-c">
                  <Trans i18nKey="terms_and_privacy_policy" ns="schoolAdmin">
                    By signing up you agree to our
                    <span className="labelStyle cursorPointer">
                      {' '}
                      <a
                        href="https://www.cyberlegends.com/terms-and-conditions"
                        className="labelStyle"
                        target="_blank"
                        rel="noreferrer">
                        Terms & Conditions
                      </a>
                    </span>
                    and
                    <span className="labelStyle cursorPointer">
                      {' '}
                      <a
                        href="https://www.cyberlegends.com/privacy-policy"
                        className="labelStyle"
                        target="_blank"
                        rel="noreferrer">
                        Privacy Policy.
                      </a>
                    </span>
                  </Trans>
                </div>
              </div>
            </div>
            <Form.Item>
              <EducatorButton
                label={t('create_account', { ns: 'schoolAdmin' })}
                type="submit"
                showSpinner={buttonSpinner}
              />
            </Form.Item>
            <div className="textStyle400Cabin18">{t('or')}</div>
            <div className="pt-2">
              <EducatorButton
                label={t('sign_up_with_google')}
                className="googleStyle"
                type="button"
                onClick={googleSignIn}
                showSpinner={googleSpinner}
                icon={<img src={googleImage} alt="googleLogo" width={40} height={40} />}
              />
            </div>
          </Form>
        </div>
        <div className="col-3 pb-3 d-flex align-items-end">
          <HelperBot>
            <div>{t('account_access_and_resources', { ns: 'schoolAdmin' })}</div>
          </HelperBot>
        </div>
      </div>
    </div>
  );
};
