import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Calendar as BigCalendar, Views, dayjsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import { MonthHeader, MonthEvent, MonthDateHeader } from './MonthCustomComponents';
import CustomToolbar from './CustomToolbar';
import styled from 'styled-components';
import Colors from '../../theme/colors';
import AgendaEventComponent from './AgendaEventComponent';

require('globalize/lib/cultures/globalize.culture.en-CA');
require('globalize/lib/cultures/globalize.culture.en-US');
require('globalize/lib/cultures/globalize.culture.fr-CA');

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const StyledCalendar = styled(DragAndDropCalendar)`
  width: 100%;
  overflow: hidden;
  .rbc-month-view {
    border-radius: 15px;
  }
  .rbc-event:focus {
    outline: none;
  }
  .rbc-month-view,
  .rbc-header,
  .rbc-day-bg + .rbc-day-bg {
    border-color: ${Colors.WHITE_200};
  }
  .rbc-event {
    padding: 0;
    background-color: transparent;
  }
  .rbc-agenda-table > thead,
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    display: none;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
  .rbc-agenda-event-cell,
  .rbc-agenda-view table.rbc-agenda-table,
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border: none;
    padding: 0;
  }
  .rbc-event-content {
    padding: 4px;
  }
`;

const customComponents = {
  month: { header: MonthHeader, dateHeader: MonthDateHeader, event: MonthEvent },
  agenda: { event: AgendaEventComponent, time: null, date: null },
  toolbar: CustomToolbar
};
const mLocalizer = dayjsLocalizer(dayjs);

const Calendar = ({ events, ...rest }) => {
  const eventPropGetter = useCallback(
    event => ({
      ...(event.isDraggable ? { className: 'isDraggable' } : { className: 'nonDraggable' })
    }),
    []
  );
  return (
    <StyledCalendar
      draggableAccessor="isDraggable"
      components={customComponents}
      defaultDate={new Date()}
      defaultView={Views.MONTH}
      events={events}
      eventPropGetter={eventPropGetter}
      localizer={mLocalizer}
      views={{
        month: true,
        agenda: true
      }}
      {...rest}
    />
  );
};

export default Calendar;
Calendar.propTypes = {
  events: PropTypes.array.isRequired
};
