import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { EducatorButton, EducatorInputField, EducatorModal } from '../../../commonComponents';

export const CreateChildModal = ({ show, handleOnCancel, handleOnSubmit, showSpinner }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');

  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">Create Your Child’s Account</div>
          <div className="fixCardtextStyle mt-2 mb-3">
            Fill out the form below to sign your child up for Cyber Legends. Educational details allows us to ensure the
            content they’re seeing reflects that they learn about in school.
          </div>
          <Form onFinish={handleOnSubmit}>
            <Form.Item
              name="first_name"
              rules={[
                { required: true, message: 'Please input your First Name!' },
                {
                  pattern: new RegExp(/^[A-Za-z]+$/),
                  message: 'Must contain Alphabets'
                }
              ]}>
              <EducatorInputField
                label="First Name:"
                type="text"
                inputValue={firstName}
                headerColor="#000000"
                name="first_name"
                onChange={event => setFirstName(event.target.value)}
                width="100%"
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  pattern: /^[a-zA-Z]{1}$/,
                  message: 'First Letter of Last Name'
                }
              ]}>
              <EducatorInputField
                label="First Letter of Last Name:"
                headerColor="#000000"
                inputValue={lastName}
                type="text"
                name="last_name"
                onChange={event => setLastName(event.target.value)}
                width="100%"
              />
            </Form.Item>
            <div className="pt-3">
              <EducatorButton label="Create Account" type="submit" width="100%" showSpinner={showSpinner} />
            </div>
          </Form>
        </div>
      }
    />
  );
};

export default CreateChildModal;

CreateChildModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired
};
