import React from 'react';
import { Table as AntTable } from 'antd';
import { ChevronLeft, ChevronRight } from 'react-feather';
import styled from 'styled-components';
import Colors from '../../theme/colors';

const StyledTable = styled(AntTable)`
    width: 100%;
    overflow-x: auto;
    .ant-table-thead > tr > th {
        font-family: Catamaran, sans-serif;
        font-size: 18px;
        font-weight: 800;
        line-height: 19.2px;
        text-align: left;
        color: ${Colors.GREY_200};
        padding: 0 30px 14px 0;
        background: none;
        border-color: ${Colors.WHITE_200};
        overflow-wrap: break-word;
        word-break: break-word;
        :before {
            width: 0 !important;
        }
    }
    .ant-table-tbody > tr > td {
        font-family: Lexend, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        overflow-wrap: break-word;
        word-break: break-word;
        padding: 14px 0;
        color: ${Colors.GREY_100}
        border-color: ${Colors.WHITE_200};
    }
    .ant-table-tbody > tr > td:not(:last-child) {
        padding-right: 30px;
    }
    td.ant-table-column-sort {
        background: none;
    }
    .ant-table-pagination {
            .ant-pagination-item {
        min-width: 10px;
        border: none;
        height: 26px;
        line-height: 16px;
        display: inline-flex;
        > a {
            align-self: center;
            font-family: Catamaran, sans-serif;
            font-size: 18px;
            font-weight: 800;
            line-height: 18px;
            min-width: 10px;
            padding: 0;
            color: ${Colors.MIDNIGHT_50};
        }
    }
    .ant-pagination-prev, .ant-pagination-next {
        min-width: 16px;
        height: 27px;
        display: inline-flex;
        > svg {
            align-self: center;
            color: ${Colors.VIOLET};
        }
    }
    .ant-pagination-prev, .ant-pagination-item {
        margin-right: 20px;
    }
    .ant-pagination-item-active {
        border-radius: 120px;
        background-color: ${Colors.MIDNIGHT};
        height: 26px;
        width: 26px;
        justify-content: center;
        > a {
            color: ${Colors.WHITE_0};
        }
    }
    .ant-pagination-disabled > svg {
        color: ${Colors.MIDNIGHT_50};
    }
    }
`;

const itemRender = (_, type, originalElement) => {
  if (type === 'prev') {
    return <ChevronLeft size={16} strokeWidth={2.5} />;
  }
  if (type === 'next') {
    return <ChevronRight size={16} strokeWidth={2.5} />;
  }
  return originalElement;
};

const Table = ({ ...rest }) => {
  return <StyledTable {...rest} pagination={rest.pagination && { ...rest.pagination, itemRender: itemRender }} />;
};

export default Table;
