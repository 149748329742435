/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Result } from 'antd';
import babyImage from '../../assets/images/educatorTeacherImage.png';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <Result
            icon={<img src={babyImage} alt="error" />}
            status="error"
            title="Oops! Something went wrong."
            subTitle="Please try again later."
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
