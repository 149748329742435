import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '@components/commonComponents';

import KeyLock from '@assets/images/keyLock.svg';
import './UnlockModal.scss';

export const UnlockModal = ({ show, handleOnCancel, handleOnClick }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });

  return (
    <EducatorModal visible={show} onCancel={handleOnCancel}>
      <div className="unlock_modal__container">
        <div className="my-4 modal_image">
          <img src={KeyLock} alt="Gift icon" width="90" height="90" />
        </div>
        <h3>{t('unlock_modal_title')}</h3>
        <p className="mb-5 px-5">{t('unlock_modal_text')}</p>
        <EducatorButton label={t('unlock_modal_button')} onClick={handleOnClick} />
      </div>
    </EducatorModal>
  );
};

UnlockModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired
};
