import useSWR from 'swr';
import { API_URL } from '@utils';

export const useCountries = () => {
  const url = API_URL.GET_COUNTRIES;

  const { data, mutate, error, isLoading } = useSWR(url);
  const response = data?.data || [];

  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error: error,
    data: response,
    revalidate
  };
};
