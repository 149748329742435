import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Skeleton } from 'antd';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL, getUserID, postApiWithAuth } from '@utils';

import { BillingDetails } from './BillingDetails';
import { RedeemCoupon } from './RedeemCoupon';
import { PaymentDetails } from './PaymentDetails';

export const PaymentElements = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'payment' });
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState({
    payment_id: null,
    user_id: getUserID()
  });
  const [cardValidation, setCardValidation] = useState({
    isDirty: false,
    isValidNumber: true,
    isValidExpiry: true,
    isValidCvc: true
  });
  const [couponApplied, setCouponApplied] = useState(null);

  const { data, isLoading: planLoading } = useSWR(API_URL.FETCH_TEACHER_PLAN);
  const planData = data?.data;

  const invalidCardInfo =
    !cardData.name ||
    !cardData.zip_or_postalCode ||
    !cardValidation.isValidNumber ||
    !cardValidation.isValidExpiry ||
    !cardValidation.isValidCvc;

  const isInvalidCard = invalidCardInfo;
  const isCouponFullDiscount = couponApplied && parseInt(couponApplied.discount_percent) === 100;
  const isButtonDisabled = isInvalidCard && !isCouponFullDiscount;


  const handleOnChange = e => {
    const { name, value } = e.target;
    setCardData({ ...cardData, [name]: value });
  };

  const handleOnChangeCardElement = event => {
    setCardValidation({
      ...cardValidation,
      isDirty: true
    });

    if (event.elementType === 'cardNumber' && !event.complete) {
      if (event.error?.code === 'invalid_number') {
        setCardValidation({
          ...cardValidation,
          isValidNumber: false
        });
        return;
      }
    }

    if (event.elementType === 'cardNumber' && event.complete) {
      setCardValidation({
        ...cardValidation,
        isValidNumber: true
      });
    } else if (event.elementType === 'cardExpiry' && event.complete) {
      setCardValidation({
        ...cardValidation,
        isValidExpiry: true
      });
    } else if (event.elementType === 'cardCvc' && event.complete) {
      setCardValidation({
        ...cardValidation,
        isValidCvc: true
      });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }
    let cardElement = null;
    cardElement = {
      ...cardElement,
      ...elements.getElement(CardCvcElement),
      ...elements.getElement(CardExpiryElement),
      ...elements.getElement(CardNumberElement)
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      metadata: {
        name: cardData.name,
        zip_or_postalCode: cardData.zip_or_postalCode
      }
    });

    if (error) {
      setIsLoading(false);
      message.error(error.message);
      return;
    }

    const { success: addCardSuccess } = await postApiWithAuth(API_URL.ADD_NEW_CARD, {
      ...cardData,
      payment_id: paymentMethod?.id
    });

    if (!addCardSuccess) {
      setIsLoading(false);
      message.error(t('add_card_failed'));
      return;
    }

    const res = await postApiWithAuth(API_URL.CREATE_TEACHER_SUBSCRIPTION, {
      ...cardData,
      payment_id: paymentMethod?.id,
      coupon_code: couponApplied ? couponApplied.coupon_code : null
    });
    setIsLoading(false);

    if (res.success) {
      message.success(t('payment_successful'));
      navigate('/teacher-create-account/signed-up');
    } else {
      message.error(t('payment_failed'));
    }
  };

  return (
    <div className="row">
      <div className="col-8">
        <h2 className="emailCodeStyleHeading">{t('add_payment_details')}</h2>
        <BillingDetails
          cardValidation={cardValidation}
          handleOnChangeCardElement={handleOnChangeCardElement}
          handleOnChange={handleOnChange}
        />
        <div className="divider">
          <div className="dividerStyle" />
          <span>{t('or')}</span>
          <div className="dividerStyle" />
        </div>
        <RedeemCoupon planId={planData?.id} couponApplied={couponApplied} setCouponApplied={setCouponApplied} />
      </div>
      <div className="col-4">
        {planLoading ? (
          <Skeleton active />
        ) : (
          <PaymentDetails
            handleSubmit={handleSubmit}
            planData={planData}
            disabled={isButtonDisabled}
            showSpinner={isLoading}
            couponApplied={couponApplied}
          />
        )}
      </div>
    </div>
  );
};
