import React from 'react';
import { Card, Text, Link, Stack } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import { useAppState } from '@context';
import { useTopics } from '@hooks';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import ResourceCard from '@teacher-app/components/ResourceCard';
import LoadingCards from '@teacher-app/components/LoadingCards';

const TeachingResources = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { state } = useAppState();
  const navigate = useNavigate();
  const currentClass = state.classes.find(item => item.id === state.classId);
  const selectedGrade = currentClass?.grade;
  const { data: topics, loading: topicLoading, error } = useTopics(selectedGrade);

  if (error) {
    return null;
  }

  return (
    <Card title={t('teaching_resources')} width="100%">
      {topicLoading ? (
        <Stack spacing={10}>
          <LoadingCards count={3} image title={false} paragraph={{ rows: 6 }} style={{ display: 'flex' }} />
        </Stack>
      ) : (
        <Stack direction="column" spacing={30}>
          <Stack justifyContent="space-between">
            <Text size="small">
              Resources shown are for{' '}
              <Text size="small" bold>
                {selectedGrade}
              </Text>
              .
            </Text>
            <Link onClick={() => navigate('/resources')}>{t('view_all')}</Link>
          </Stack>
          <Row className="align-items-start" gutter={16}>
            {topics?.slice(0, 3).map(item => {
              return (
                <Col className="gutter-row" span={8} key={item.id}>
                  <ResourceCard key={item.id} resourceItem={item} disabled={false} />
                </Col>
              );
            })}
          </Row>
        </Stack>
      )}
    </Card>
  );
};

export default TeachingResources;
