import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Collapse, Popover } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { BackgroundColor, EducatorButton, ReportOptions } from '../../commonComponents';
import questionIcon from '@assets/images/questionIcon.svg';
import './parentChildComprehensionReport.css';

const { Panel } = Collapse;

export const FreeVersionHeader = ({ child_info, navigate }) => (
  <div className="yellowTitle px-3">
    {`${child_info.first_name.toUpperCase()}. ${child_info.last_name.toUpperCase()}`} is currently playing on free. To
    see more in depth stats and unlock custom skins,&nbsp;&nbsp;
    <span className="parentOverviewHadding cursorPointer" onClick={() => navigate('/memberships-and-billing')}>
      Upgrade Now
    </span>
  </div>
);

const Extra = () => (
  <div className="extraContentStyle">
    <div>8</div>
    <div>
      <BackgroundColor title="Beginner" background={'#22C172'} textColor={'#FFFFFF'} />
    </div>
  </div>
);

const Extra1 = () => (
  <div className="extraContentStyle">
    <div>12</div>
    <div>
      <BackgroundColor title="Advanced" background={'#4B3AD3'} textColor={'#FFFFFF'} />
    </div>
  </div>
);

export const ParentChildComprehensionReport = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { child_info, isFreePlan } = location.state || {};
  const [showDummyData, setShowDummyData] = useState(false);
  const csvData = [
    {
      topic: 'Topic',
      completedActivities: 'Completed Activities'
    }
  ];

  const csvReport = {
    data: csvData,
    headers: [
      { label: 'Topic', key: 'topic' },
      { label: 'Completed Activities', key: 'completedActivities' },
      { label: 'Skill Level', key: 'skillLevel' }
    ],
    filename: 'Student_Comprehension_Report.csv'
  };

  useEffect(() => {
    if (!isFreePlan) {
      setShowDummyData(false);
    } else {
      setShowDummyData(true);
    }
  }, [isFreePlan]);

  return (
    <>
      {showDummyData ? (
        <>
          <FreeVersionHeader child_info={child_info} navigate={navigate} />
          <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
            <AuthenticatedNavbar
              smallHeading1="Learning"
              smallHeading2="Child Comprehension Report"
              bodyButton={<EducatorButton label="Print Report" type="button" width="152px" />}
            />
            <div className="row mt-3 cardResourceMainDiv">
              <div className="authenticatedNavbarHeadingStyle">Sample Comprehension Report</div>
              <div className="pt-1 textGradedAssignmentsStyle">
                The table below will show how well your kiddo understands a topic so you can see which topics they’re
                struggling to learn.
              </div>
              <div className="blueTileComprehensionReport col-9 p-md-3 p-3">
                This is a sample report, to unlock reporting on your child’s progress,
                <span
                  className="parentOverviewHadding cursorPointer mx-2"
                  onClick={() => navigate('/memberships-and-billing')}>
                  Upgrade Now
                </span>
              </div>
              <Card
                bordered={false}
                className=" col-9 cardStyleSecondStudentComprehension cardsStyling p-md-3 p-3 mt-3">
                <div className="headerReportStyle mt-2">
                  <div className="itemStyle"> TOPIC</div>
                  <div style={{ display: 'flex', justifyContent: 'space-around', width: '60%' }}>
                    {' '}
                    <div>
                      COMPLETED ACTIVITIES{' '}
                      <Popover
                        content="Completed Activities refer to the number of questions and quests the students has completed on this topic."
                        trigger="hover">
                        {' '}
                        <img src={questionIcon} alt="question" width="17px" height="17px" />
                      </Popover>
                    </div>
                    <div>
                      {' '}
                      SKILL LEVEL{' '}
                      <Popover
                        content="The student’s grade percentage based on questions the correct answers column."
                        trigger="hover">
                        {' '}
                        <img src={questionIcon} alt="question" width="17px" height="17px" />
                      </Popover>
                    </div>
                  </div>
                </div>
                <Collapse
                  accordion
                  className="collapseStyle"
                  defaultActiveKey={['1']}
                  expandIcon={({ isActive }) =>
                    isActive ? <MinusOutlined className="iconStyle" /> : <PlusOutlined className="iconStyle" />
                  }
                  expandIconPosition={'end'}>
                  <Panel
                    className="panelHeadingsStyleStudentReport mt-2"
                    header="Grade 1 - Safety"
                    extra={<Extra />}
                    key="1">
                    <ReportOptions
                      title="Password Protection"
                      completedActivities={'0'}
                      skillLevel={'Beginner'}
                      backgroundColor="#22C172"
                      textColor="#FFFFFF"
                    />
                    <ReportOptions
                      title="Identify a Trusted Adult"
                      completedActivities={'5'}
                      skillLevel={'Intermediate'}
                      backgroundColor="#6BD3E8"
                      textColor="#000000"
                    />
                    <ReportOptions
                      title="Password Protection"
                      completedActivities={'3'}
                      skillLevel={'At Risk'}
                      backgroundColor="#EC5B47"
                      textColor="#FFFFFF"
                    />
                  </Panel>
                  <Panel
                    className="panelHeadingsStyleStudentReport mt-2"
                    header="Grade 1 - Coding"
                    extra={<Extra1 />}
                    key="2">
                    <ReportOptions
                      title="Password Protection"
                      completedActivities={'0'}
                      skillLevel={'Beginner'}
                      backgroundColor="#22C172"
                      textColor="#FFFFFF"
                    />
                    <ReportOptions
                      title="Identify a Trusted Adult"
                      completedActivities={'5'}
                      skillLevel={'Intermedate'}
                      backgroundColor="#6BD3E8"
                      textColor="#000000"
                    />
                    <ReportOptions
                      title="Password Protection"
                      completedActivities={'3'}
                      skillLevel={'At Risk'}
                      backgroundColor="#EC5B47"
                      textColor="#FFFFFF"
                    />
                  </Panel>
                </Collapse>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
          <AuthenticatedNavbar
            smallHeading1="Learning"
            smallHeading2="Child Comprehension Report"
            bodyButton={
              <CSVLink {...csvReport}>
                <EducatorButton label="Print Report" type="button" width="152px" />
              </CSVLink>
            }
            playButton
          />
          <div className="row mt-3 cardResourceMainDiv">
            <div className="authenticatedNavbarHeadingStyle">
              {`${child_info.first_name.toUpperCase()} ${child_info.last_name.toUpperCase()}'s`} Comprehension Report
            </div>
            <div className="pt-1 textGradedAssignmentsStyle">
              The table below will show how well your kiddo understands a topic so you can see which topics they’re
              struggling to learn.
            </div>
            <Card bordered={false} className=" col-9 cardStyleSecondStudentComprehension cardsStyling p-md-3 p-3 mt-3">
              <div className="headerReportStyle mt-2">
                <div className="itemStyle"> TOPIC</div>
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '60%' }}>
                  {' '}
                  <div>
                    COMPLETED ACTIVITIES{' '}
                    <Popover
                      content="Completed Activities refer to the number of questions and quests the students has completed on this topic."
                      trigger="hover">
                      {' '}
                      <img src={questionIcon} alt="question" width="17px" height="17px" />
                    </Popover>
                  </div>
                  <div>
                    {' '}
                    SKILL LEVEL{' '}
                    <Popover
                      content="The student’s grade percentage based on questions the correct answers column."
                      trigger="hover">
                      {' '}
                      <img src={questionIcon} alt="question" width="17px" height="17px" />
                    </Popover>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
export default ParentChildComprehensionReport;

FreeVersionHeader.propTypes = {
  child_info: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
};
