import { SET_TOKEN, SET_AUTHENTICATION, SET_CURRENT_USER, SET_IS_GOOGLE_AUTH } from '@context';

export const auth = (state, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return { ...state, isAuthenticated: action.payload };
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case SET_TOKEN:
      return { ...state, authToken: action.payload };
    case SET_IS_GOOGLE_AUTH:
      return { ...state, isGoogleAuth: action.payload };
    default:
      return state;
  }
};
