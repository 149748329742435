import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { getDollars, TAX_RATE } from '@utils';
import { EducatorButton } from '@components/commonComponents';

export const PaymentDetails = ({ handleSubmit, planData, disabled, showSpinner, couponApplied }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'payment' });

  const amount = planData?.amount;
  const discount = couponApplied ? planData?.amount * couponApplied.discount_percent / 100 : 0;
  const subtotal = planData?.amount - discount;
  const estimatedTax = subtotal * TAX_RATE;
  const total = subtotal + estimatedTax;

  const paymentDetails = {
    amount: amount,
    discount: discount,
    subtotal: subtotal,
    estimatedTax: estimatedTax,
    total: total
  };

  return (
    <div className="pt-3 px-4">
      <div className="textStyleTotalPayment ">{t('payment_details')}</div>
      <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
        <div>{t('teacher_license')} x 1 </div>
        <div>{getDollars(paymentDetails.amount)}</div>
      </div>
      {couponApplied && (
        <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
          <div>Coupon Code</div>
          <div>-{getDollars(paymentDetails.discount)}</div>
        </div>
      )}
      <Divider className="dividerStyle" />
      <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
        <div>{t('subtotal')} </div>
        <div>{getDollars(paymentDetails.subtotal)}</div>
      </div>
      <div className="pt-2 textStyle400Cabin18 spaceBetweenDiv">
        <div>{t('estimated_tax')} </div>
        <div>{getDollars(paymentDetails.estimatedTax)}</div>
      </div>
      <Divider className="dividerStyle" />
      <div className="pt-2 labelCurriculumStyle spaceBetweenDiv">
        <div>{t('total')} </div>
        <div>{getDollars(paymentDetails.total)}</div>
      </div>
      <div className="pt-3">
        <EducatorButton
          label={t('place_order')}
          type="button"
          width="100%"
          showSpinner={showSpinner}
          onClick={handleSubmit}
          disabled={disabled}
          dataTestId="placeOrderButton"
        />
      </div>
    </div>
  );
};

PaymentDetails.defaultProps = {
  disabled: true,
  showSpinner: false,
  planData: null,
  couponApplied: null
};

PaymentDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  planData: PropTypes.object,
  disabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  couponApplied: PropTypes.object
};
