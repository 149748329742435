import React, { useState } from 'react';
import { List, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Card, Link, Title, Text, Stack } from '@parent-app/design-system';

import { API_URL, fetcher } from '@utils';
import useSWR from 'swr';
import { Colors } from '@teacher-app/design-system';
import Pagination from '../../design-system/components/Pagination/Pagination';

export const LoginDetails = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useSWR(`${API_URL.PARENT_CHILDREN_PAGE}?page=${currentPage}`, fetcher);
  const { count, results } = data?.data || {};

  return (
    <Card width="100%">
      {isLoading ? (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      ) : !results?.length ? (
        <Stack direction="column" alignItems="flex-start" spacing={20}>
          <Title level={3}>{t('login_details')}</Title>
          <Text size="small">{t('no_child')}</Text>
          <Button type="secondary" block>
            {t('add_child')}
          </Button>
        </Stack>
      ) : (
        <Stack direction="column" alignItems="flex-start" spacing={20}>
          <Title level={3}>{t('login_details')}</Title>
          <Text size="small">{t('click_child_description')}</Text>
          <List
            style={{ width: '100%' }}
            itemLayout="horizontal"
            dataSource={results}
            renderItem={item => (
              <List.Item style={{ padding: '20px 0', borderColor: Colors.WHITE_200 }}>
                <Link size="medium" hideIcon>
                  {`${item.first_name} ${item.last_name}`}
                </Link>
              </List.Item>
            )}
          />
          <Stack justifyContent="center">
            <Pagination
              defaultCurrent={1}
              defaultPageSize={5}
              total={count}
              onChange={page => setCurrentPage(page)}
              hideOnSinglePage
            />
          </Stack>
          <Button type="secondary" block>
            {t('add_child')}
          </Button>
        </Stack>
      )}
    </Card>
  );
};
export default LoginDetails;
