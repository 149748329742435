import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ChildAccountRoute = ({ children }) => {
  /**
   * This is a placeholder route for the child account route.
   * Add additional logic here if needed.
   */
  return children ?? <Outlet />;
};

ChildAccountRoute.defaultProps = {
  children: null
};

ChildAccountRoute.propTypes = {
  children: PropTypes.element
};

export default ChildAccountRoute;
