import React from 'react';
import PropTypes from 'prop-types';
import { getDataTestId } from '@utils';
import './AssignmentCard.scss';

import Available from '@assets/images/cyber-academy/available.svg';
import InProgress from '@assets/images/cyber-academy/in-progress.svg';
import Completed from '@assets/images/cyber-academy/completed.svg';
import DueSoon from '@assets/images/cyber-academy/due-soon.svg';
import DefaultImage from '@assets/images/cyber-academy/default_assignment.svg';

const itemStatusIconMap = {
  Available: Available,
  Started: InProgress,
  Completed: Completed,
  'Due Soon': DueSoon
};

export const AssignmentCard = ({ image, title, onClick, status }) => {
  return (
    <div className="assignment-card__container" onClick={onClick} data-testid={getDataTestId(title)}>
      <div className="main-style">
        <div className="card__thumbnail">
          <img src={image} alt="resourcesImage" />
        </div>
        <div className="card__status">
          <img src={itemStatusIconMap[status]} alt={status} width={60} height={60} />
        </div>
      </div>
      <div className={'text-style'}>
        <div className="card__heading">{title}</div>
      </div>
    </div>
  );
};

AssignmentCard.defaultProps = {
  title: '',
  image: DefaultImage
};

AssignmentCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};
