import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { Card, Form, message, Select, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { EducatorInputField, EducatorButton } from '../../commonComponents';
import { API_URL, patchApiWithAuth } from '../../../utils';
import { useLoginDetails, useGrades } from '../../../hooks';
import robotHead from '../../../assets/images/robot_head.svg';
import graduationCap from '../../../assets/images/graduation.svg';

const { Option } = Select;

import './EditChildAccount.scss';

export const EditChildAccount = () => {
  const location = useLocation();
  const { id } = useParams();

  const { userInfo } = location.state || {};
  const [userData, setUserData] = useState(userInfo);
  const [passwordData, setPasswordData] = useState({ current_password: '', new_password: '', re_type_password: '' });
  const [buttonSpinnerPassword, setButtonSpinnerPassword] = useState(false);
  const [newChildData, setNewChildData] = useState({});

  const { data: grades, loading: gradesLoading } = useGrades();
  const { data: loginDetails, revalidate } = useLoginDetails(id);

  const isFreeMember = userData?.plan_type.plan === 'Fremium';

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const updatePasswordFunction = async () => {
    setButtonSpinnerPassword(true);

    const payload = {
      ...passwordData,
      user_name: userInfo.student?.student_username,
      student_id: userInfo.student?.student_id
    };

    const { success, data } = await patchApiWithAuth(API_URL.RESET_CHILD_PASSWORD_V2, payload);

    if (success) {
      message.success(data.message);
      setPasswordData({ current_password: '', new_password: '', re_type_password: '' });
      revalidate();
      setButtonSpinnerPassword(false);
    } else {
      message.error(data.message);
      setButtonSpinnerPassword(false);
    }
  };
  const selectGrade = gradeName => {
    setUserData({ ...userData, student: { ...userData.student, student_grade: gradeName } });
    setNewChildData({ grade: gradeName });
  };

  const onChangedCheck = event => {
    const { checked } = event.target;
    setUserData({ ...userData, student: { ...userData.student, student_is_consent: checked } });
    setNewChildData({ is_consent: checked });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (Object.entries(newChildData).length > 0) {
        const { success, data } = await patchApiWithAuth(
          `${API_URL.PARENT_UPDATE_USER}/${userData.student?.student_id}`,
          newChildData
        );

        if (success) {
          message.success('Child updated successfully');
        } else {
          message.error(data.detail);
        }
      }
    };

    fetchData();
  }, [newChildData.grade, newChildData.is_consent]);

  return (
    <>
      <div className="edit-child-account container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar smallHeading1="Account Management" smallHeading2="Edit Account" playButton />
        <div className="unitPlanTextStyle row">
          <div className="col-8">
            <h2 className="authenticatedNavbarHeadingStyle capital mt-4">
              Edit {userData.student?.first_name} {userData.student?.last_name}&rsquo;s Account
            </h2>
            <p>
              Edit the details of your child’s account. To update their membership and billing info, head to our{' '}
              <Link to="/memberships-and-billing">memberships page.</Link>
            </p>
          </div>
        </div>
        <div className="row mt-4 assignmentSrolling">
          <div className="col col-sm-7 pb-3">
            <Card bordered={false} className="cardsStyling p-4 mb-4">
              <h3 className="settingHeadingStyle">User Login Credentials</h3>
              <div className="row">
                <div className="col-12 d-flex">
                  <h4>Child&rsquo;s Username:</h4>&nbsp;
                  <p>{loginDetails?.username}</p>
                </div>
                <div className="col-12 d-flex">
                  <h4>Child&rsquo;s Password:</h4>&nbsp;
                  <p className="word-break">{loginDetails?.password}</p>
                </div>
                <div className="col-12 d-flex">
                  <h4>Membership:</h4>&nbsp;
                  <p className="word-break">
                    {isFreeMember
                      ? 'Freemium member'
                      : `${userData.plan_type.plan} member until ${dayjs(userData.end_date).format('M/D/YY')}`}
                  </p>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="cardsStyling p-4 mb-4">
              <h3 className="settingHeadingStyle">Users Details</h3>
              <Form className="pt-4">
                <div className="row pt-3">
                  <div className="col-6">
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          pattern: new RegExp(/^[A-Za-z]+$/),
                          message: 'Must contain Alphabets'
                        }
                      ]}>
                      <EducatorInputField
                        label="Child’s First Name:"
                        inputValue={userData.student?.first_name}
                        type="text"
                        name="first_name"
                        className="fixCardInputFieldTwo"
                        width="100%"
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: false,
                          pattern: /^[a-zA-Z]{1}$/,
                          message: 'First Letter of Last Name'
                        }
                      ]}>
                      <EducatorInputField
                        label="Child’s Last Initial:"
                        inputValue={userData.student?.last_name}
                        type="text"
                        name="last_name"
                        className="fixCardInputFieldTwo"
                        width="100%"
                        disabled
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <div className="pt-4">
                    <div>
                      <label className="labelStyleCard">Child’s Grade</label>
                      <div className="mt-3">
                        <Select
                          onChange={selectGrade}
                          value={userData.student?.student_grade}
                          className="fixCardInputField"
                          name="child_grade"
                          loading={gradesLoading}>
                          {grades &&
                            grades.map(item => (
                              <Option key={item.name} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </Card>
            <Card bordered={false} className="cardsStyling p-4 mb-4">
              <h3 className="settingHeadingStyle">Digital Health & Safety Access</h3>
              <div className="unitPlanTextStyle mt-3">
                Cyber Legends has created digital health and safety curriculum which introduces sensitive and complex
                topics. For a child to have these lessons included in their gameplay, a parent is required to provide
                consent. The topics and detailed lesson plans can be found{' '}
                <a href="https://www.cyberlegends.com/digital-health-consent" target="_blank" rel="noopener noreferrer">
                  here.
                </a>
              </div>

              <div className="displayInline pt-2">
                <Checkbox
                  name="student_is_consent"
                  checked={userData.student?.student_is_consent}
                  onChange={onChangedCheck}
                />
                &nbsp;&nbsp;
                <p style={{ alignSelf: 'center' }}>I consent to my child learning digital health & safety.</p>
              </div>
            </Card>
            <Card bordered={false} className="cardsStyling p-4 mb-5">
              <div className="row">
                <div className="col-3 align-self-center">
                  <img src={graduationCap} alt="Graduation Cap" />
                </div>
                <div className="col-9">
                  <h3 className="settingHeadingStyle">Customize Learning Paths</h3>
                  <p className="mb-3">Prioritize different learning topics to help shape your child’s learning.</p>

                  <Link
                    to={'/parent-child-customize-learning-path'}
                    state={{
                      child_info: userData.student,
                      isFreePlan: userData.plan_type.plan === ('Fremium' || 'Freemium')
                    }}>
                    Edit Learning Path &gt;
                  </Link>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="cardsStyling p-4">
              <h3 className="settingHeadingStyle">Delete Account</h3>
              <p>
                Deleting your account is a permanent action that will cancel all your current subscriptions (which are
                non refundable). If you’re just looking to change to a free account, you can{' '}
                <Link to="/memberships-and-billing">change your subscription.</Link>
                Email us at <a href="mailto:legal@cyberlegends.com">legal@cyberlegends.com</a> if you’d like to delete
                your account.
              </p>
            </Card>
          </div>
          <div className="update-password__container col col-5 col-sm-5 pb-3">
            <div>
              <Card bordered={false} className="settingSecondCard cardsStyling p-4 px-sm-3 px-lg-4 px-xxl-5">
                <div className="settingHaddingStyle">Update Password</div>
                <Form onFinish={updatePasswordFunction} className="pt-4">
                  <Form.Item
                    className="pt-3"
                    name="current_password"
                    rules={[
                      { required: true, message: 'Please input your Current Password!' },
                      {
                        required: true,
                        pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                        message:
                          'Must contain Number , Special Character , upper case letter, lower case letter, min length 8'
                      }
                    ]}>
                    <EducatorInputField
                      label="Current password:"
                      passwordValue={passwordData.current_password}
                      type="password"
                      name="current_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="new_password"
                    rules={[
                      { required: true, message: 'Please input your New Password!' },
                      {
                        required: true,
                        pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                        message:
                          'Must contain Number , Special Character , upper case letter, lower case letter, min length 8'
                      }
                    ]}>
                    <EducatorInputField
                      label="New password:"
                      passwordValue={passwordData.new_password}
                      type="password"
                      name="new_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="re_type_password"
                    dependencies={['new_password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        }
                      })
                    ]}>
                    <EducatorInputField
                      label="Re-type new password:"
                      type="password"
                      name="re_type_password"
                      className="fixCardInputField"
                      dependencies={passwordData.new_password}
                      passwordValue={passwordData.re_type_password}
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item className="pt-2">
                    <EducatorButton
                      key="submit"
                      label="Confirm New Password"
                      type="submit"
                      width="100%"
                      showSpinner={buttonSpinnerPassword}
                    />
                  </Form.Item>
                </Form>
                <div className="d-flex mt-4">
                  <img src={robotHead} alt="Tooltip robot" />
                  <div className="bubble">
                    Remember, to create a strong password we must use capitals, numbers and symbols.
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
