import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Empty, Row, Col, Image } from 'antd';

import { API_URL, getApiWithAuth } from '@utils';
import { useCurrentUser } from '@hooks';
import TutorialVideoModal from '@student-app/components/TutorialVideoModal';
import PageLayout from '@student-app/components/PageLayout';
import { Button, Frame, Stack, Text, Title } from '@student-app/design-system';
import StaghornIcon from '@assets/images/cyber-academy/staghorn.png';

export const StudentHelpCenter = () => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  const { data: currentUser } = useCurrentUser();
  const [tutorialData, setTutorialData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [videoResource, setVideoResource] = useState(null);

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getTutorials = async () => {
    setButtonSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_TUTORIALS}`);
    if (success) {
      setTutorialData(data);
      setButtonSpinner(false);
    } else {
      setButtonSpinner(false);
    }
  };

  useEffect(() => {
    getTutorials();
  }, []);

  const totalPages = Math.ceil(tutorialData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tutorialData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <PageLayout pageTitle={t('help_center')}>
        <Stack direction='column' style={{ position: 'relative', width: '80vw', maxWidth: 1046, paddingTop: 40 }}>
            {buttonSpinner ? (
              <Stack justifyContent="center" width="100%" style={{ minHeight: '75vh' }}>
                <Spin size="large" />
              </Stack>
            ) : tutorialData?.length === 0 ? (
              <Stack justifyContent="center" width="100%" style={{ minHeight: '75vh' }}>
                {buttonSpinner ? <Spin size="large" /> : <Empty description={false} />}
              </Stack>
            ) : (
              <Stack justifyContent='center'>
                <Row gutter={16} style={{ width: '100%' }}>
                  <Col xs={0} sm={0} md={12} lg={9} xl={9}>
                    <Stack justifyContent="center">
                      <Stack style={{ position: 'relative', marginTop: 119, marginRight: 28 }}>
                        <Stack
                          style={{
                            position: 'absolute',
                            border: '2px solid #CCC8C7',
                            padding: '30px 30px 50px',
                            borderRadius: 20,
                            background: '#fff',
                            width: 255,
                            left: 21,
                            top: -140
                          }}>
                          <Text size="small" strong>
                            {t('help_center_text', { name: currentUser?.first_name ?? 'Student' })}
                          </Text>
                        </Stack>
                        <Image src={StaghornIcon} preview={false} />
                      </Stack>
                    </Stack>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={15} xl={15}>
                    <Stack direction="column" gap={16} justifyContent="center" style={{ paddingTop: 30 }}>
                      <Row gutter={24}>
                        {currentItems.map((item, index) => (
                          <Col key={index} sm={24} md={24} lg={12} xl={12} onClick={() => setVideoResource(item)}>
                            <Stack justifyContent='center'>
                              <Stack direction="column" style={{ maxWidth: 300, minWidth: 200, marginBottom: 24, cursor: 'pointer' }}>
                                <Frame frameWithShadow={false}>
                                  <Image src={item?.video_thumbnail} preview={false} />
                                </Frame>
                                <Stack justifyContent="center" style={{ padding: '0 24px' }}>
                                  <Title level={4} ellipsis>
                                    {item?.name}
                                  </Title>
                                </Stack>
                              </Stack>
                            </Stack>
                          </Col>
                        ))}
                      </Row>
                      {totalPages > 1 && (
                        <Button onClick={handlePageChange} dataTestId="moreButton">
                          {t('load_more')}
                        </Button>
                      )}
                    </Stack>
                  </Col>
                </Row>
              </Stack>
            )}
        </Stack>
      </PageLayout>
      <TutorialVideoModal
        isOpen={videoResource !== null}
        onClose={() => {
          setVideoResource(null);
        }}
        videoResource={videoResource}
      />
    </>
  );
};

export default StudentHelpCenter;
