import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { Helmet } from 'react-helmet-async';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { AssignmentCardWithColor } from '@components/commonComponents';
import { API_URL, sendGaEvent } from '@utils';

import { RelatedEdubytes } from './RelatedEdubytes';
import { VideoPlayer } from './VideoPlayer';

import './EdubytesDetail.scss';

export const EdubytesDetail = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { id } = useParams();

  const { data: edubyteResponse, isLoading } = useSWR(`${API_URL.SCHOOL_EDUBYTES}/${id}`, {
    revalidateOnFocus: false
  });

  const edubyte = edubyteResponse && edubyteResponse.data ? edubyteResponse.data : {};
  const videos = edubyte ? edubyte.videos : [];
  const title = edubyte ? edubyte.name : [];
  const resources = edubyte ? edubyte.resources : [];

  return (
    <>
      <Helmet>
        <title>{title ? `${t('teaching_resources')}: ${title} - ` : ''}Cyber Legends</title>
      </Helmet>
      {isLoading ? (
        <div className="spineerCenter">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="edubyte-detail__container">
            <div className="edubyte-detail__main">
              <div className="edubyte-detail__header">
                <div className="container">
                  <AuthenticatedNavbar smallHeading1={`${t('edubytes')}`} smallHeading2={`${edubyte.name}`} />
                  <div className="row mt-3 mb-3">
                    <div className="col-12 col-md-11 col-lg-10 ">
                      <div className="row mt-3 mb-3">
                        <div className="col-2">
                          <img src={edubyte.badge} alt="Badge" className="img-fluid" />
                        </div>
                        <div className="col-10">
                          <div className="overview__container">
                            <h3 className="edubyte-detail__heading--sub mt-3">{edubyte.name}</h3>
                            <div className="edubyte-detail__text--sub">{edubyte.description}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-5">
              {videos.length > 0 && (
                <div className="row mt-4">
                  <h3 className="edubyte-detail__heading--sub mt-3">{t('videos_to_help_you_level_up')}</h3>
                  <div>
                    <VideoPlayer videos={videos} />
                  </div>
                </div>
              )}
            </div>
            <div className="container">
              <div className="row mt-5">
                <div className="col-12 col-md-8 mr-auto">
                  <div className="related-resources__container mt-5">
                    <h3 className="edubyte-detail__heading--sub">{t('resources_to_use_in_class')}</h3>
                    {resources?.map(item => (
                      <div key={item.id}>
                        <AssignmentCardWithColor
                          color={'#6BD3E8'}
                          title={item.name}
                          past
                          resources
                          resourcesTwo
                          downloadResources={() => {
                            sendGaEvent('resource_download', 'edubyte__resource_download', item.name);
                            window.open(item.url, '_blank');
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-5">
              <RelatedEdubytes itemId={id} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EdubytesDetail;
