import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import { Stack, Colors } from '@student-app/design-system';

const NavbarButton = ({ icon, children, onClick, alt }) => (
  <Stack
    justifyContent="center"
    onClick={onClick}
    style={{
      cursor: 'pointer',
      padding: 10,
      width: 60,
      height: 60,
      borderRadius: 60,
      border: `3px solid ${Colors.GOLD_200}`,
      background: Colors.DAFFODIL
    }}>
    {icon && <Image src={icon} alt={alt} preview={false} height={40} width={40} />}
    {children}
  </Stack>
  );

export default NavbarButton;

NavbarButton.defaultProps = {
  onClick: null,
  icon: null,
  children: null
};

NavbarButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
};