import useSWR from 'swr';
import { API_URL } from '@utils';

export const useStudents = (classId, searchString = '') => {
  let url = classId ? `${API_URL.GET_CLASS_STUDENTS}${classId}/` : null;

  if (classId && searchString !== '') {
    url = `${API_URL.GET_CLASS_STUDENTS_WITH_NAME}${classId}/?search=${searchString}`;
  }

  const { data, error, isLoading, mutate, isValidating } = useSWR(url);

  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error,
    data: response ?? [],
    revalidate,
    isValidating
  };
};
