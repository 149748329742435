import React, { forwardRef } from 'react';
import { Avatar, Checkbox, Empty, Spin } from 'antd';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL } from '@utils';

export const RankPage = forwardRef((_, ref) => {
  const { t } = useTranslation('student', { keyPrefix: 'badge_booklet' });
  const { data: ranksData, error, isLoading } = useSWR(`${API_URL.GET_STUDENT_GRADES_ACHIEVEMENTS}`);
  const ranks = ranksData?.data.sort((a, b) => a.grade.number - b.grade.number);

  return (
    <div className="page" ref={ref}>
      <div className="page-content">
        <h3 className="page-header">{t('ranks_available')}</h3>
        {isLoading ? (
          <div className="emptyMessageStudent">
            <Spin size="large" />
          </div>
        ) : error ? (
          <Empty description="Failed to load ranks" className="emptyMessageStudent" />
        ) : ranks?.length === 0 ? (
          <Empty description="No ranks available" className="emptyMessageStudent" />
        ) : (
          <div className="ranks__container">
            {ranks.map(rank => (
              <div
                className={`rank__item ${rank?.user_achievement_id ? 'rank__icon--completed' : ''}`}
                key={rank?.grade?.id}>
                <div className="rank__icon">
                  <Avatar
                    size={110}
                    shape="circle"
                    src={rank?.achievement?.image}
                    alt={`Rank ${rank?.grade?.cyber_name?.split(' ')[0]}`}
                  />
                  <h4 className="rank__text">{rank?.grade?.cyber_name}</h4>
                  <Checkbox className="d-none align-self-center" checked={rank?.user_achievement_id} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

RankPage.displayName = 'Rank Page';
RankPage.propTypes = {};
