import PropTypes from 'prop-types';
import React from 'react';
import './reportOptionsStyle.css';
import BackgroundColor from '../BackgroundColor';

const ReportOptions = ({ title, completedActivities, skillLevel, backgroundColor, textColor }) => {
  return (
    <div className="optionsStyle">
      <div className="d-flex justify-content-between optionStyleContainer">
        <div className="optionStyleTitle">{title}</div>
        <div className="optionsReportRightDivStyle mb-4">
          <div>{completedActivities}</div>
          <div style={{ display: 'flex' }}>
            <BackgroundColor title={skillLevel} background={backgroundColor} textColor={textColor} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportOptions;

ReportOptions.propTypes = {
  title: PropTypes.string.isRequired,
  completedActivities: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  skillLevel: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired
};
