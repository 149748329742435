import React, { useRef, useState } from 'react';
import { sendGaEvent } from '@utils';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Stack, Modal } from '@student-app/design-system';

const VideoWrapper = ({ children }) => (
  <Stack
    width="100%"
    style={{
      borderRadius: 20,
      width: '100%',
      height: '100%',
      padding: 0,
      overflow: 'hidden'
    }}>
    {children}
  </Stack>
);

VideoWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

const TutorialVideoModal = ({ videoResource, isOpen, onClose }) => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);

  const video = videoResource
    ? {
        name: videoResource?.video_title,
        thumbnail: videoResource?.video_thumbnail,
        url: videoResource?.video_url
      }
    : null;

  return (
    <Modal width={'80vw'} destroyOnClose centered onCancel={onClose} open={isOpen}>
      <Stack style={{ padding: '0 20px' }}>
        <ReactPlayer
          width="100%"
          height="100%"
          controls
          ref={videoRef}
          url={video?.url}
          playing={isPlaying}
          wrapper={VideoWrapper}
          light={
            <Stack
              style={{
                width: '100%',
                height: 'calc((70vw) * .5625)',
                backgroundSize: 'contain',
                backgroundImage: `url(${video?.thumbnail})`
              }}>
              {' '}
            </Stack>
          }
          onEnded={() => {
            setPlaying(false);
            sendGaEvent('video_ended', 'Video Ended', video?.name);
          }}
          onPlay={() => {
            setPlaying(true);
            sendGaEvent('video_play', 'Video Play', video?.name);
          }}
          onPause={() => {
            setPlaying(false);
            sendGaEvent('video_pause', 'Video Pause', video?.name);
          }}
        />
      </Stack>
    </Modal>
  );
};

export default TutorialVideoModal;

TutorialVideoModal.defaultProps = {
  videoResource: null
};

TutorialVideoModal.propTypes = {
  videoResource: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
