/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Navbar, MultistepParent } from '../../layoutComponents';
import { Checkbox, Divider, message } from 'antd';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { getApiWithAuth, postApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import {getErrorMessage, subTotalFunction} from '../../../utils/Helper';
import './parentPaymentStyle.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getUserID } from '../../../utils/sessionStorage';

const ParentPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { amount, id } = location.state || {};

  const [checkboxData, setCheckboxData] = useState([]);
  const [parentChilds, setParentChilds] = useState([]);
  const [couponCode, setCouponCode] = useState();
  const [coupon, setCoupon] = useState(null);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onChange = event => {
    const value = event.target.id;
    if (!checkboxData.some(item => item === value)) {
      setCheckboxData([...checkboxData, value]);
    } else {
      const filteredArray = checkboxData.filter(item => item !== value);
      setCheckboxData(filteredArray);
    }
  };

  const onChangeHandleCoupon = e => {
    const { value } = e.target;
    setCouponCode(value);
  };
  const applyCoupon = async () => {
    const { success, data } = await getApiWithAuth(`coupons/${couponCode}/${id}`);

    if (success) {
      if (data.data.remaining_redemptions === 0) {
        message.error('Coupon is expired');
      } else if (checkboxData.length <= data.data.remaining_redemptions) {
        message.success('Coupon added successfully');
        setCoupon(data.data);
      } else {
        message.error(`Coupon is valid for ${data.data.remaining_redemptions} child's`);
      }
    } else {
      message.error(getErrorMessage(data));
      setCoupon(null);
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      applyCoupon();
    }
  };
  const getPaymentChilds = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PARENT_CHILDREN);
    if (success) {
      setParentChilds(data);
    }
  };
  const paymentFunction = async () => {
    setButtonSpinner(true);
    const data = await postApiWithAuth(API_URL.PAYMENT_CHECK, {
      child_list: checkboxData,
      user_id: getUserID(),
      price_id: id,
      coupon_code: coupon.coupon_code
    });

    console.log(data);
    if (data.success) {
      setButtonSpinner(false);
      navigate('/parent-create-account/payment-confirm');
    } else {
      setButtonSpinner(false);
      message.error(getErrorMessage(data));
    }
  };

  useEffect(() => {
    getPaymentChilds();
  }, []);

  const removeCouponCode = async () => {
    setCoupon(null);
    setCouponCode('');
    message.success('Coupon code remove Successfully');
  };
  return (
    <div className="mainDivSignUpStyle container-fluid">
      <Navbar />
      <div className="row pb-5 pt-sm-3 pt-lg-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Payment" check={[1, 1, 1, 1, 0]} />
        </div>
        <div className="col-7 d-flex justify-content-center">
          <div>
            <div className="emailCodeStyleHeading">Connect Your Memberships</div>
            <div className="pt-4">
              <div>
                <label className="labelCurriculumStyle">Select children to add this plan to:</label>
                <div className="mt-2 row">
                  {parentChilds?.map(item => {
                    return (
                      <div key={item.student_id} className="textStylePayment col-4">
                        <Checkbox id={item.student_id} className="me-3" onChange={onChange} disabled={coupon} />
                        {item.first_name} {item.last_name.substring(0, 1).toUpperCase()}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="pt-1">
              <Divider className="dividerPaymentOne" />
            </div>
            <div className="labelCurriculumStyle mt-4">
              <EducatorInputField
                label="Redeem Coupon Code:"
                type="text"
                name="coupon"
                placeholder={checkboxData.length === 0 ? 'Select minimum 1 child' : 'Enter Coupon code'}
                inputValue={couponCode}
                onChange={onChangeHandleCoupon}
                onKeyDown={onKeyDown}
                disabled={checkboxData.length === 0}
                suffix={
                  <EducatorButton
                    key="button"
                    label="Apply Coupon"
                    type="button"
                    className="applyCouponButton"
                    onClick={applyCoupon}
                    disabled={checkboxData.length === 0}
                    coupon={coupon}
                  />
                }
              />
            </div>
            {coupon ? (
              <div className="pt-1 textStylePayment">
                Want to remove coupon &nbsp;
                <span onClick={removeCouponCode} className="textStyleForgetPassword cursorPointer">
                  {couponCode}&nbsp;
                </span>
                ?
              </div>
            ) : null}

            <div className="pt-1">
              <Divider className="dividerPaymentOne" />
            </div>
            <div className="pt-3">
              <div className="textStyleSubtotalPayment">
                <div>Subtotal</div>
                <div>
                  {checkboxData.length} x ${amount / 100}
                </div>
              </div>
              <div className="mt-3 textStyleSubtotalPayment">
                <div>Estimated Tax</div>
                <div>Calculated at checkout</div>
              </div>
            </div>
            {coupon ? (
              <div className="pt-3">
                <div className="textStyleSubtotalPayment">
                  <div>Coupon</div>
                  <div>
                    {checkboxData.length} x ${amount / 100}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="pt-3">
              <Divider className="dividerPaymentTwo" />
            </div>
            <div className="mt-3 textStyleTotalPayment">
              <div>Total</div>
              <div>${coupon ? '0.00' : subTotalFunction(checkboxData.length, amount / 100)}</div>
            </div>
            <div className="pt-2">
              <Divider className="dividerPaymentTwo" />
            </div>
            <div className="pt-3">
              <EducatorButton
                label="Proceed to Checkout"
                type="button"
                width="100%"
                showSpinner={buttonSpinner}
                onClick={() => {
                  coupon
                    ? paymentFunction()
                    : navigate('/parent-create-account/payment', {
                        state: { plan_id: id, total_child: checkboxData, payment_child: amount / 100 }
                      });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentPayment;
