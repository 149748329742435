import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from 'antd';
import { Eye, ArrowDownCircle } from 'react-feather';

import { EducatorButton } from '@components/commonComponents';

import './TeachingResourceCardStyle.scss';

const IconButton = ({ children, onClick, ...rest }) => (
  <EducatorButton variant="text" style={{ padding: 0, minHeight: 0 }} onClick={onClick} {...rest}>
    {children}
  </EducatorButton>
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export const TeachingResourceCard = ({
  color,
  title,
  resourcePdf,
  downloadResourcePdf,
  resourceGoogle,
  downloadResourceGoogle,
  resourceDocument,
  downloadResourceDocument,
  loading
}) => {
  return (
    <div className="mt-3">
      <Card bordered={false} className="teachingResourceCardStyle" loading={loading}>
        <div className="noPadding">
          <Divider type="vertical" className={'cardLineteachingResource'} style={{ background: color }} />
          <div className=" px-sm-2 px-lg-3 px-xl-4 teachingResourceCardMainDiv">
            <div>
              <div className="teachingResourceInnerCardHeading">{title}</div>
            </div>
            <div className="d-flex flex-row gap-4">
              {resourcePdf && (
                <div className="teachingResourceCardSecondInnerDiv">
                  <span>{resourcePdf}</span>
                  <IconButton onClick={() => downloadResourcePdf('open')}>
                    <Eye size={16} className="cursorPointer" color={'#674EFF'} />
                  </IconButton>
                  <IconButton onClick={() => downloadResourcePdf('download')}>
                    <ArrowDownCircle size={16} className="cursorPointer" color={'#674EFF'} />
                  </IconButton>
                </div>
              )}
              {resourceGoogle && (
                <div className="teachingResourceCardSecondInnerDiv">
                  <span>{resourceGoogle}</span>
                  <IconButton onClick={() => downloadResourceGoogle('open')}>
                    <Eye size={16} className="cursorPointer" color={'#674EFF'} />
                  </IconButton>
                  <IconButton onClick={() => downloadResourceGoogle('download')}>
                    <ArrowDownCircle size={16} className="cursorPointer" color={'#674EFF'} />
                  </IconButton>
                </div>
              )}
              {resourceDocument && (
                <div className="teachingResourceCardSecondInnerDiv">
                  {resourceDocument}
                  <IconButton onClick={() => downloadResourceDocument('open')} className="d-none">
                    <Eye size={16} className="cursorPointer" color={'#674EFF'} />
                  </IconButton>
                  <IconButton onClick={() => downloadResourceDocument('download')}>
                    <ArrowDownCircle size={16} className="cursorPointer" color={'#674EFF'} />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default TeachingResourceCard;

TeachingResourceCard.defaultProps = {
  resourcePdf: null,
  downloadResourcePdf: () => {},
  resourceGoogle: null,
  downloadResourceGoogle: () => {},
  resourceDocument: null,
  downloadResourceDocument: () => {},
  color: '',
  loading: false
};
TeachingResourceCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  resourcePdf: PropTypes.string,
  downloadResourcePdf: PropTypes.func,
  resourceGoogle: PropTypes.string,
  downloadResourceGoogle: PropTypes.func,
  resourceDocument: PropTypes.string,
  downloadResourceDocument: PropTypes.func,
  loading: PropTypes.bool
};
