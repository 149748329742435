/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Spin } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import {
  API_URL,
  clearStorage,
  getApiWithAuth,
  getLocalStorageItem,
  GOOGLE_SSO_LOGOUT_URL,
  patchApiWithAuth,
  setFlowComplete,
  setIsGoogle,
  setToken,
  setUserID,
  USER_ROLE
} from '@utils';
import { useAppState } from '@context';

export const AuthGoogle = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const { state } = useAppState();
  const { isGoogleAuth } = state;
  const currentUserType = getLocalStorageItem('user_role');

  const logoutUser = async () => {
    if (isGoogleAuth) {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };

  const updateGoogleRole = async () => {
    const { success, status } = await patchApiWithAuth(API_URL.USER_TYPE_UPDATE, {
      type: currentUserType
    });

    if (status === 401) {
      logoutUser();
      return;
    }

    if (success) {
      checkUserInfo();
    }
  };

  const checkUserAuth = async () => {
    const { success, data, status } = await getApiWithAuth(API_URL.GET_CURRENT_USER);

    if (!success) {
      return;
    }

    if (status === 401) {
      logoutUser();
      return;
    }

    if (success) {
      if (data.type === currentUserType) {
        checkUserInfo();
      } else if (data.is_auth && data.type !== currentUserType) {
        const messages = {
          [USER_ROLE.TEACHER]: 'teacher_account_created',
          [USER_ROLE.PARENT]: 'parent_account_created',
          default: 'child_account_created'
        };
        message.warning(t(messages[data.type] || messages.default), 10);
        logoutUser();
      } else {
        updateGoogleRole();
      }
    }
  };

  const checkUserInfo = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);

    if (!success) {
      return;
    }

    if (data.is_auth) {
      setUserID(data.id);
      setFlowComplete(true);
    } else {
      setFlowComplete(false);
      const navigations = {
        [USER_ROLE.TEACHER]: '/teacher-create-account/curriculum-setup',
        [USER_ROLE.PARENT]: '/parent-create-account/child-setup',
        [USER_ROLE.CHILD]: '/child-create-account/info-update'
      };
      navigate(navigations[data.type]);
    }
  };

  const getTokens = async code => {
    try {
      await Auth.federatedSignIn('google', { code });
      await saveToken();
    } catch (error) {
      console.error(error);
    }
  };

  const saveToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const { accessToken } = currentSession;
      setToken(accessToken.jwtToken);
      setIsGoogle('1');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      getTokens(code);
      checkUserAuth();
    } else {
      navigate('/');
    }
  }, []);

  return (
    <div className="mainDivStyle">
      <div className="spineerCenter">
        <Spin size="large" />
      </div>
    </div>
  );
};

export default AuthGoogle;
