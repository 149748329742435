/* eslint-disable no-console */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, MultistepParent } from '../../layoutComponents';
import Payment from './payment';

const ParentPaymentStripe = () => {
  const location = useLocation();
  const { plan_id, total_child, payment_child } = location.state || {};
  return (
    <div className="mainDivSignUpStyle container-fluid">
      <Navbar />
      <div className="row  py-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Payment" check={[1, 1, 1, 1, 0]} />
        </div>
        <div className="col-9">
          <Payment totalChildPayment={total_child} selectedPlanId={plan_id} paymentChild={payment_child} />
        </div>
      </div>
    </div>
  );
};
export default ParentPaymentStripe;
