import React from 'react';
import { Row, Col } from 'antd';
import { Stack } from '@teacher-app/design-system';
import TeachingResources from './TeachingResources';
import Edubytes from './Edubytes';
import Lessons from './Lessons';
import Assignments from './Assignments';

const Overview = () => {
  return (
    <Row style={{ paddingTop: '50px' }}>
      <Col span={16}>
        <Stack direction="column" spacing={33}>
          <TeachingResources />
          <Edubytes />
        </Stack>
      </Col>
      <Col span={8}>
        <Stack direction="column" spacing={33} style={{ paddingLeft: '33px' }}>
          <Lessons />
          <Assignments />
        </Stack>
      </Col>
    </Row>
  );
};

export default Overview;
