import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { TagOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField } from '@components/commonComponents';
import { getApiWithAuth } from '@utils';

export const RedeemCoupon = ({ planId, couponApplied, setCouponApplied }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'payment' });
  const [couponCode, setCouponCode] = useState('');

  const handleApplyCoupon = async () => {
    const { success, data } = await getApiWithAuth(`coupons/${couponCode}/${planId}`);
    if (success) {
      if (data.data.remaining_redemptions === 0) {
        message.error(t('coupon_expired'));
      } else {
        message.success(t('coupon_applied'));
        setCouponApplied(data.data);
        setCouponCode('');
      }
    } else {
      message.error(t('invalid_coupon'));
    }
  };

  const handleOnChange = e => {
    setCouponCode(e.target.value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleApplyCoupon();
    }
  };

  const handleRemove = () => {
    setCouponApplied(null);
    setCouponCode('');
  };

  return (
    <div className="redeem-coupon__container form__container">
      <h3 className="form__heading">{t('redeem_coupon')}</h3>
      <EducatorInputField
        label={t('enter_coupon_code')}
        type="text"
        name="coupon"
        inputValue={couponCode}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        disabled={!!couponApplied}
        suffix={
          <EducatorButton
            key="button"
            label={t('apply_coupon')}
            type="button"
            className="applyCouponButton"
            onClick={handleApplyCoupon}
            isCoupon
            disabled={!couponCode || !!couponApplied}
            dataTestId="applyCouponButton"
          />
        }
      />
      {couponApplied ? (
        <div className="coupon--applied">
          <TagOutlined rotate={270} />
          <h4>{couponApplied.coupon_code}</h4>
          <button onClick={handleRemove}>
            <CloseOutlined />
          </button>
        </div>
      ) : null}
    </div>
  );
};

RedeemCoupon.defaultProps = {
  planId: '',
  couponApplied: null
};

RedeemCoupon.propTypes = {
  planId: PropTypes.string,
  couponApplied: PropTypes.object,
  setCouponApplied: PropTypes.func.isRequired
};
