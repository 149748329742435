import React from 'react';
import PropTypes from 'prop-types';
import { Lock, Unlock, Folder, Video, Paperclip, Check, HelpCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { getDataTestId } from '@utils';
import { EducatorButton } from '@components/commonComponents';
import { useModal } from '@context/modalContext';
import { UnlockModal } from '@pages/TeachingResources/UnlockModal';
import { TeachingResourcesModal } from '@pages/TeachingResources/TeachingResourcesModal';
import { AlignmentDetailsModal } from '@pages/TeachingResources/Detail/AlignmentDetailsModal';
import { ReferTeacherModal } from '@pages/TeachingResources/ReferTeacherModal';
import { useCredits } from '@hooks';

import premium from '../../../assets/images/Premium.svg';
import BackgroundColor from '../BackgroundColor';
import CreditIcon from '@assets/images/credit.svg';

import './ResourceCard.v2.scss';

export const ResourceCard = React.memo(({ resourceItem, onClick, disabled }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const {
    name,
    lesson_count,
    thumbnail,
    sub_heading,
    is_locked,
    product_id,
    has_video,
    resources_overview,
    curriculum_details
  } = resourceItem;
  const { setModalContent } = useModal();
  const { data: credits, redeemProducts } = useCredits();

  const handleOnClick = () => {
    if (is_locked) {
      return;
    }

    onClick();
  };

  const handleUnlock = () => {
    if (is_locked) {
      if (credits.available >= 1) {
        setModalContent(
          <UnlockModal
            show
            handleOnCancel={() => setModalContent(null)}
            handleOnClick={async () => {
              const { success, data } = await redeemProducts([product_id]);
              if (success) {
                setModalContent(null);
                onClick();
              } else {
                message.error(data.message);
              }
            }}
          />
        );
        return;
      }

      setModalContent(<ReferTeacherModal show handleOnCancel={() => setModalContent(null)} handleOnClick={() => {}} />);
    }
  };

  return (
    <div className="resources-card-v2__container" onClick={handleOnClick} data-testid={getDataTestId(name)}>
      <div className="main-style pb-3">
        <div className="card__thumbnail">
          <img src={thumbnail} alt="resourcesImage" />
          {is_locked && (
            <div className="card--locked">
              <Lock color={'#CCC8C7'} size={82} />
              <EducatorButton
                type="button"
                className="unlock__button"
                dataTestId="unlockButton"
                variant="outline"
                onClick={handleUnlock}>
                {t('unlock_with')} <img src={CreditIcon} alt="credit" width="19" height="18" />
              </EducatorButton>
            </div>
          )}
        </div>
        <div className={'px-3 pt-4'}>
          <div className="premiumMainDivStyle pb-3">
            <div className="card__heading">
              {is_locked ? (
                <Lock color={'#CCC8C7'} size={18} className="lockIcon" />
              ) : (
                <Unlock color={'#FFA828'} size={18} className="unlockIcon" />
              )}
              {name}
            </div>
            {disabled ? (
              <div className="premiumOuterDivStyle">
                <div className="premiumInnerDivStyle">
                  <img src={premium} alt="premium" className="premiumImageStyle" />
                  <div className="assignmentInnerCardDate">{t('premium')}</div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="card__list-item">
            <Folder color="#8E8E8E" size={18} />
            {lesson_count}&nbsp;
            {t('lessons')}
          </div>
          {has_video ? (
            <div className="card__list-item">
              <Video color="#8E8E8E" size={18} />
              {t('video_lesson')}
            </div>
          ) : null}

          <div className="card__list-item">
            <Paperclip color="#8E8E8E" size={18} />
            {t('teaching_resources')}&nbsp;
            <button
              onClick={() => {
                setModalContent(
                  <TeachingResourcesModal
                    show
                    handleOnCancel={() => setModalContent(null)}
                    resources={resources_overview}
                  />
                );
              }}>
              <HelpCircle color="#FFFFFF" size={18} fill="#8E8E8E" />
            </button>
          </div>
          <div className="card__list-item">
            <Check color="#8E8E8E" size={18} />
            {t('curriculum_alignment')}&nbsp;
            <button
              onClick={() => {
                setModalContent(
                  <AlignmentDetailsModal
                    show
                    handleOnCancel={() => setModalContent(null)}
                    curriculums={curriculum_details}
                  />
                );
              }}>
              <HelpCircle color="#FFFFFF" size={18} fill="#8E8E8E" />
            </button>
          </div>

          <div className="spaceBetweenResources">
            <div className="tag-topic">
              <BackgroundColor
                title={sub_heading.name}
                background={sub_heading.background_color}
                textColor={sub_heading.text_color}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ResourceCard.defaultProps = {
  disabled: false
};

ResourceCard.propTypes = {
  resourceItem: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
