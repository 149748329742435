import useSWR from 'swr';
import { API_URL } from '@utils';

export const useStates = country => {
  const url = `${API_URL.GET_PROVINCES}?name=${country}`;

  const { data, mutate, error, isLoading } = useSWR(country ? url : null);
  const response = data?.data || [];

  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error: error,
    data: response,
    revalidate
  };
};
