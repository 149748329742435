import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setLocalStorageItem, USER_ROLE } from '@utils';

export const useDetectRouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/teacher-signin':
      case '/teacher-create-account':
      case '/teacher-signup/new-password':
        setLocalStorageItem('user_role', USER_ROLE.TEACHER);
        break;
      case '/parent-signin':
      case '/parent-create-account':
        setLocalStorageItem('user_role', USER_ROLE.PARENT);
        break;
      case '/child-account':
      case '/child-signin':
      case '/student-signin':
      case '/child-create-account':
        setLocalStorageItem('user_role', USER_ROLE.CHILD);
        break;
      case '/school-admin/create-account':
        setLocalStorageItem('user_role', USER_ROLE.SCHOOL_ADMIN);
        break;
      default:
    }
  }, [location]);
};
