import React from 'react';
import PropTypes from 'prop-types';
import { Lock } from 'react-feather';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton } from '@components/commonComponents';
import CreditIcon from '@assets/images/credit.svg';
import { UnlockModal } from '@pages/TeachingResources/UnlockModal';
import { ReferTeacherModal } from '@pages/TeachingResources/ReferTeacherModal';
import { useModal } from '@context/modalContext';
import { useCredits } from '@hooks';

import './EdubyteCard.scss';

export const EdubyteCard = ({ image, title, videos, onClick, isLocked, itemId }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { setModalContent } = useModal();
  const { data: credits, redeemEdubyte } = useCredits();

  const handleOnClick = () => {
    if (isLocked) {
      return;
    }

    onClick();
  };

  const handleUnlock = () => {
    if (isLocked) {
      if (credits.available >= 1) {
        setModalContent(
          <UnlockModal
            show
            handleOnCancel={() => setModalContent(null)}
            handleOnClick={async () => {
              const { success, data } = await redeemEdubyte(itemId);
              if (success) {
                setModalContent(null);
                onClick();
              } else {
                message.error(data.message);
              }
            }}
          />
        );
        return;
      }

      setModalContent(<ReferTeacherModal show handleOnCancel={() => setModalContent(null)} handleOnClick={() => {}} />);
    }
  };

  return (
    <div className="edubyte-card__container" onClick={handleOnClick}>
      <div className="main-style pb-3">
        <div className="edubyte__thumbnail">
          <img src={image} alt="resourcesImage" />
          {isLocked && (
            <div className="card--locked">
              <Lock color={'#CCC8C7'} size={82} />
              <EducatorButton
                type="button"
                className="unlock__button"
                dataTestId="unlockButton"
                variant="outline"
                onClick={handleUnlock}>
                {t('unlock_with')} <img src={CreditIcon} alt="credit" width="19" height="18" />
              </EducatorButton>
            </div>
          )}
        </div>
        <div className={'px-3 pt-4'}>
          <div className="premiumMainDivStyle pb-3">
            <div className="edubyte__heading">{title}</div>
          </div>
          <ol style={{ paddingLeft: '1rem' }}>
            {videos.map((video, i) => (
              <li className="edubyte__description" key={i}>
                {video}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

EdubyteCard.defaultProps = {
  isLocked: false
};

EdubyteCard.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  videos: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  itemId: PropTypes.number.isRequired
};
