import { useState } from 'react';
import { getApiWithAuth, postApiWithAuth, API_URL, isValidUrl } from '@utils';

export const useCheckoutFlow = () => {
  const [loading, setLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const [checkoutError, setCheckoutError] = useState(null);

  const executeCheckoutFlow = async () => {
    setLoading(true);
    setCheckoutData(null);
    setCheckoutError(null);

    const teacherPlan = await getApiWithAuth(API_URL.GET_TEACHER_PLAN_V2);
    if (!teacherPlan.success) {
      setLoading(false);
      setCheckoutError(teacherPlan.data.message);
      return;
    }

    const checkoutResponse = await postApiWithAuth(API_URL.CHECKOUT, {
      line_items: teacherPlan.data.data
    });

    setLoading(false);

    if (!checkoutResponse.success) {
      setCheckoutError(checkoutResponse.data.message);
      return;
    }

    if (isValidUrl(checkoutResponse.data.data)) {
      setCheckoutData(checkoutResponse.data.data);
    } else {
      setCheckoutError('Invalid checkout URL');
    }
  };

  return { executeCheckoutFlow, loading, checkoutData, checkoutError };
};
