import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Select } from 'antd';
import { EducatorButton } from '../../commonComponents';
import { API_URL, getApiWithoutAuth, patchApiWithAuth } from '../../../utils';
import { useGrades } from '../../../hooks';

const { Option } = Select;

const ChildInfoGoogle = () => {
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const [newData, setNewData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dataCountry, setDataCountry] = useState(null);
  const [dataGrade, setDataGrade] = useState(null);

  const getCountries = async () => {
    const { success, data } = await getApiWithoutAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
      setDataCountry(data[0]?.name);
    }
  };
  const selectCountry = countryName => {
    setNewData({ ...newData, country: countryName });
  };

  const selectGrade = gradeName => {
    setNewData({ ...newData, grade: gradeName });
  };
  useEffect(() => {
    if (dataGrade !== null) {
      setNewData({ ...newData, grade: dataGrade });
    }
  }, [dataGrade]);

  useEffect(() => {
    if (dataCountry !== null) {
      setNewData({ ...newData, country: dataCountry });
    }
  }, [dataCountry]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (grades?.length > 0) {
      setDataGrade(grades[0]?.name);
    }
  }, [grades]);

  const UserUpdate = async () => {
    setButtonSpinner(true);
    const { success, data } = await patchApiWithAuth(API_URL.UPDATE_CHILD_GOOGLE, newData);
    if (success) {
      setButtonSpinner(false);
      navigate('/child-create-account/parent-connect', { state: { student_id: data.student_data.id } });
    } else {
      setButtonSpinner(false);
    }
  };
  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childHaddingStyle">Sign Up Information!</div>
          <div className="childTextStyle">Let us know a bit about yourself to get started.</div>
          <div className="pt-4">
            <Form onFinish={UserUpdate}>
              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard ">What country do you live in?:</label>
                    <div className="mt-3">
                      <Select
                        className="fixCardInputField"
                        value={newData?.country}
                        onChange={selectCountry}
                        name="country">
                        {countries.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">What grade are you in?:</label>
                    <div className="mt-3">
                      <Select onChange={selectGrade} value={newData?.grade} className="fixCardInputField" name="grade">
                        {grades.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <div className="pt-3">
                <EducatorButton label="Save Changes" type="submit" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildInfoGoogle;
