import PropTypes from 'prop-types';
import React from 'react';
import './multistepParentStyle.css';

const MultistepParent = ({ select, check }) => {
  return (
    <div className="mt-2">
      <div className="d-flex align-items-center">
        <div
          className={
            check[0] === 0
              ? select === 'Personal Details'
                ? 'circleRadioSelected'
                : 'circleRadioNotSelected'
              : 'circleRadioWhite'
          }
        />
        <div
          className={
            check[0] === 0
              ? select === 'Personal Details'
                ? 'textStyleSelected'
                : 'textStyleNotSelected'
              : 'textStyleWhite'
          }>
          Parent Account Setup
        </div>
      </div>
      <div className="mt-3 d-flex align-items-center">
        <div
          className={
            check[1] === 0
              ? select === 'Child Setup'
                ? 'circleRadioSelected'
                : 'circleRadioNotSelected'
              : 'circleRadioWhite'
          }
        />
        <div
          className={
            check[1] === 0
              ? select === 'Child Setup'
                ? 'textStyleSelected'
                : 'textStyleNotSelected'
              : 'textStyleWhite'
          }>
          Child Setup
        </div>
      </div>
      <div className="mt-3 d-flex align-items-center">
        <div
          className={
            check[2] === 0
              ? select === 'Curriculum Setup'
                ? 'circleRadioSelected'
                : 'circleRadioNotSelected'
              : 'circleRadioWhite'
          }
        />
        <div
          className={
            check[2] === 0
              ? select === 'Curriculum Setup'
                ? 'textStyleSelected'
                : 'textStyleNotSelected'
              : 'textStyleWhite'
          }>
          Curriculum Setup
        </div>
      </div>
      <div className="mt-3 d-flex align-items-center">
        <div
          className={
            check[3] === 0
              ? select === 'Select a Plan'
                ? 'circleRadioSelected'
                : 'circleRadioNotSelected'
              : 'circleRadioWhite'
          }
        />
        <div
          className={
            check[3] === 0
              ? select === 'Select a Plan'
                ? 'textStyleSelected'
                : 'textStyleNotSelected'
              : 'textStyleWhite'
          }>
          Select a Plan
        </div>
      </div>
      <div className="mt-3 d-flex align-items-center">
        <div
          className={
            check[4] === 0
              ? select === 'Payment'
                ? 'circleRadioSelected'
                : 'circleRadioNotSelected'
              : 'circleRadioWhite'
          }
        />
        <div
          className={
            check[4] === 0 ? (select === 'Payment' ? 'textStyleSelected' : 'textStyleNotSelected') : 'textStyleWhite'
          }>
          Payment
        </div>
      </div>
    </div>
  );
};
export default MultistepParent;

MultistepParent.propTypes = {
  select: PropTypes.string.isRequired,
  check: PropTypes.array.isRequired
};
