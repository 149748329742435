import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Checkbox, Input, message, Radio, Select, Tooltip } from 'antd';
import { HelpCircle as HelpCircleIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useGrades } from '@hooks';
import { Button, Colors, Form, Modal, Stack, Title, Text } from '@teacher-app/design-system';
import { API_URL, postApiWithAuth } from '@utils';

const { Option } = Select;

const CLASS_TYPES = ['single_grade', 'multiple_grade'];

const AddClassModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'overview' });
  const [form] = Form.useForm();
  const { data: allGrades } = useGrades();
  const [loading, setLoading] = useState(false);

  const classType = Form.useWatch('class_type', form);

  const handleSubmit = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const inputValues = {
      name: values?.class_name,
      grades: values?.grades,
      grade: values?.grade,
    };

    const { success, detail } = await postApiWithAuth(API_URL.POST_CLASS_SETUP, inputValues);
    if (success) {
      message.success(t('class_created_successfully'));
      form.resetFields();
      onClose(true);
    } else {
      message.error(detail ?? t('general_error_message'));
      setLoading(false);
    }
  };

  const handleClassTypeChange = (e) => {
    form.setFieldValue('class_type', e.target.value);
    // reset grade and update_student_grade when class_type is changed
    form.setFieldValue('grades', undefined);
    form.setFieldValue('grade', undefined);
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setLoading(false);
    }
  }, [isOpen]);

  return (
    <Modal width={585} open={isOpen} closable onCancel={onClose}>
      <Stack direction="column" spacing={22}>
        <Stack direction="column" spacing={12}>
          <Stack>
            <Title level={3}>{t('add_class')}</Title>
            <Tooltip color="purple" title={t('add_class_tooltip')}>
              <HelpCircleIcon style={{ cursor: 'pointer' }} size={20} color={Colors.WHITE_0} fill={Colors.VIOLET} />
            </Tooltip>
          </Stack>
          <Text size="small">{t('add_class_detail')}</Text>
        </Stack>
        <Form form={form} requiredMark={false} name="add-class" onFinish={handleSubmit}>
          <Stack direction="column" spacing={20}>
            <Form.Item
              name="class_name"
              label={t('name_your_class')}
              rules={[{ required: true, message: t('add_class_name_missing') }]}>
              <Input label={t('name_your_class')} type="text" value={name} />
            </Form.Item>
            <Form.Item name="class_type" label={t('class_type')}>
              <Radio.Group onChange={handleClassTypeChange}>
                <Radio value={CLASS_TYPES[0]}>{t(CLASS_TYPES[0])}</Radio>
                <Radio value={CLASS_TYPES[1]}>
                  {t(CLASS_TYPES[1])}
                  <Tooltip color="purple" title={t('multiple_grades_tooltip')}>
                    <HelpCircleIcon style={{ marginLeft: 5 }} size={20} color={Colors.WHITE_0} fill={Colors.VIOLET} />
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {classType === CLASS_TYPES[1] ? (
              <Form.Item
                name="grades"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: t('add_class_grade_missing') },
                  () => ({
                    validator(_, value) {
                      if (value?.length > 0 && value?.length < 2) {
                        return Promise.reject(new Error(t('add_class_select_minimum_2_grades')));
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
                label={t('grades')}>
                <Checkbox.Group>
                  {allGrades.map(gradeItem => (
                    <Checkbox value={gradeItem.id} key={gradeItem.name}>
                      {gradeItem.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            ) : (
              <Form.Item
                name="grade"
                label={t('grade')}
                rules={[{ required: true, message: t('add_class_grade_missing') }]}>
                <Select placeholder={t('grade_placeholder')} disabled={!classType}>
                  {allGrades.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item>
              <Button htmlType="submit" block loading={loading}>
                {t('add_class')}
              </Button>
            </Form.Item>
          </Stack>
        </Form>
      </Stack>
    </Modal>
  );
};
export default AddClassModal;

AddClassModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
