import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, message } from 'antd';
import { EducatorButton, EducatorInputField, LanguageSwitcher } from '../../commonComponents';
import {
  API_URL,
  clearStorage,
  getApiWithAuth,
  getIsGoogle,
  getLocalStorageItem,
  GOOGLE_SSO_LOGOUT_URL,
  setRefreshToken,
  setToken
} from '@utils';

export const ChildSignIn = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ username: '', password: '' });
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const signInChild = () => {
    setButtonSpinner(true);
    Auth.signIn({
      username: data.username,
      password: data.password
    })
      .then(() => {
        saveToken();
      })
      .catch(error => {
        message.error(`${error.message}`);
        setButtonSpinner(false);
      });
  };

  const saveToken = async () => {
    await Auth.currentSession()
      .then(data => {
        setToken(data.accessToken.jwtToken);
        checkUserInfo();
        setRefreshToken(data.refreshToken.token);
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  const checkUserInfo = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      if (data.type !== getLocalStorageItem('user_role')) {
        logoutUser();
      } else {
        setButtonSpinner(false);
        navigate('/child-where-play');
      }
    }
  };

  const logoutUser = async () => {
    const Google = getIsGoogle();
    if (Google === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          setButtonSpinner(false);
          clearStorage();
          message.error('This account does not exist as a child');
        })
        .catch(err => {
          setButtonSpinner(false);
          message.error(`${err.message}`);
        });
    }
  };

  return (
    <div className="childBackgroundDStyle container-fluid">
      <nav className="top-nav">
        <LanguageSwitcher />
      </nav>
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-5">
          <div className="childHaddingStyle">Play the Game</div>
          <div className="mt-1">
            <Form onFinish={signInChild}>
              <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                <EducatorInputField
                  headerColor="#000000"
                  inputValue={data.username}
                  label="Username:"
                  type="text"
                  name="username"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item
                className="pt-3"
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}>
                <EducatorInputField
                  label="Password:"
                  type="password"
                  headerColor="#000000"
                  passwordValue={data.password}
                  name="password"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="pt-3">
                <EducatorButton label="Sign In " type="submit" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildSignIn;
