import React, { useEffect, useState } from 'react';
import { Card, Text, Link, Stack } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API_URL, getApiWithAuth } from '@utils';
import EdubyteCard from '../../components/EdubyteCard';
import LoadingCards from '@teacher-app/components/LoadingCards';

const Edubytes = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const navigate = useNavigate();
  const [edubytesData, setEdubytesData] = useState([]);
  const [fetchingEdubytes, setFetchingEdubytes] = useState(false);

  const getEdubytes = async () => {
    setFetchingEdubytes(true);
    const { success, data } = await getApiWithAuth(`${API_URL.SCHOOL_EDUBYTES}`);
    if (success) {
      setEdubytesData(data);
    }
    setFetchingEdubytes(false);
  };

  useEffect(() => {
    getEdubytes();
  }, []);

  return (
    <Card title={t('edubytes')} width="100%">
      {fetchingEdubytes ? (
        <Stack spacing={10}>
          <LoadingCards count={3} image title={false} paragraph={{ rows: 4 }} />
        </Stack>
      ) : (
        <Stack direction="column" spacing={30}>
          <Stack justifyContent="space-between">
            <Text size="small">{t('edubytes_overview_description')}</Text>
            <Link onClick={() => navigate('/edubytes')}>{t('view_all')}</Link>
          </Stack>
          <Row className="align-items-start" gutter={16}>
            {edubytesData?.slice(0, 3)?.map(item => {
              return (
                <Col className="gutter-row" span={8} key={item.id}>
                  <EdubyteCard edubyteItem={item} disabled={false} />
                </Col>
              );
            })}
          </Row>
        </Stack>
      )}
    </Card>
  );
};

export default Edubytes;
