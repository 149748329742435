import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../Stack/Stack';
import Title from '../Typography/Title';
import Text from '../Typography/Text';
import { getColor } from './utils';

export const MonthHeader = ({ label }) => (
  <Stack justifyContent="center" style={{ padding: '10px 0px' }}>
    <Title level={5}>{label.toUpperCase()}</Title>
  </Stack>
);
MonthHeader.propTypes = {
  label: PropTypes.string.isRequired
};

export const MonthDateHeader = ({ label }) => (
  <Stack justifyContent="flex-end" style={{ paddingTop: 10 }}>
    <Text size="small">{label}</Text>
  </Stack>
);
MonthDateHeader.propTypes = {
  label: PropTypes.string.isRequired
};

export const MonthEvent = ({ event }) => {
  const { backgroundColor, color } = getColor(event.isEvent, event?.sub_heading?.name);
  return (
    <Stack
      style={{
        padding: '6px 10px',
        borderRadius: 4,
        backgroundColor
      }}>
      <Text size="x-small" ellipsis style={{ color }}>
        {event.title}
      </Text>
    </Stack>
  );
};
MonthEvent.propTypes = {
  event: PropTypes.object.isRequired
};
