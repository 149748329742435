import React, { useEffect, useState } from 'react';
import { Card, Title, Link, Stack } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { API_URL, getApiWithAuth, setMemberShipType } from '@utils';
import LoadingCards from '@parent-app/components/LoadingCards';
import ParentResourceCard from './ParentResourceCard';
import PropTypes from 'prop-types';

const ParentResources = ({ setShowBanner }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [parentOverView, setParentOverView] = useState({});
  const [fetchingEdubytes, setFetchingEdubytes] = useState(false);

  const getParentOverView = async () => {
    setFetchingEdubytes(true);
    const { success, data } = await getApiWithAuth(API_URL.PARENT_OVERVIEW);
    if (success) {
      setParentOverView(data);
      setMemberShipType(data.MemeberShip_Type);
    }
    setFetchingEdubytes(false);
  };

  useEffect(() => {
    getParentOverView();
  }, []);

  return (
    <Card width="100%">
      {fetchingEdubytes ? (
        <Stack spacing={10}>
          <LoadingCards count={3} image title={false} paragraph={{ rows: 4 }} />
        </Stack>
      ) : (
        <Stack direction="column" spacing={30}>
          <Stack justifyContent="space-between">
            <Title level={3}>{t('parent_resources')}</Title>
            {/* Add link here */}
            <Link>{t('view_all')}</Link>
          </Stack>
          <Row className="align-items-start" gutter={[16, 16]}>
            {parentOverView?.Parent_Resources?.slice(0, 3)?.map(item => {
              return (
                <Col className="gutter-row" span={8} xs={24} sm={24} md={12} lg={12} xl={8} key={item.id}>
                  <ParentResourceCard resourceItem={item} disabled={false} setShowBanner={setShowBanner} />
                </Col>
              );
            })}
          </Row>
        </Stack>
      )}
    </Card>
  );
};

export default ParentResources;

ParentResources.propTypes = {
  setShowBanner: PropTypes.func.isRequired
};
