import React from 'react';
import { Stack } from '@teacher-app/design-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import  Navbar  from '../Navbar';

const StyledDiv = styled.div`
    min-height: 100vh;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: auto;
`;

const PageLayout = ({ children, title, cyberAcademyButton }) => {
  return (
    <StyledDiv className="p-2 p-md-3 p-lg-4">
      <Navbar title={title} cyberAcademyButton={cyberAcademyButton} />
      <Stack direction='column' alignItems='flex-start' style={{ paddingTop: '50px' }}>
        {children}
      </Stack>
    </StyledDiv>
  );
};

export default PageLayout;

PageLayout.defaultProps = {
  cyberAcademyButton: false
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  cyberAcademyButton: PropTypes.bool,
};
