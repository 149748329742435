import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

import { Colors, Title, Stack } from '@student-app/design-system';
import { updateUserAttributes, setLocalStorageItem } from '@utils';
import { useAppState } from '@context';
import caFlag from '@assets/images/ca-flag.svg';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { state } = useAppState();
  const { isAuthenticated } = state;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useLayoutEffect(() => {
    dayjs.locale(i18n.language.toLowerCase());
  }, []);

  const handleLanguageChange = async newLang => {
    if (isAuthenticated) {
      await updateUserAttributes({ 'custom:preferred_language': newLang });
    }
    setLocalStorageItem('language', newLang);
    window.location.reload();
  };

  const displayLanguage = {
    'en-CA': 'EN',
    'fr-CA': 'FR'
  };

  const items = [
    {
      label: (
        <button onClick={() => handleLanguageChange('en-CA')} data-testid="enButton">
          <img className="flag" src={caFlag} alt="Canada Flag" />
          EN
        </button>
      ),
      key: 'en-CA'
    },
    {
      label: (
        <button onClick={() => handleLanguageChange('fr-CA')} data-testid="frButton">
          <img className="flag" src={caFlag} alt="Canada Flag" />
          FR
        </button>
      ),
      key: 'fr-CA'
    }
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      open={isDropdownOpen}
      onOpenChange={open => setIsDropdownOpen(open)}
      dropdownRender={menu => <div className="lang-switcher__dropdown">{menu}</div>}>
      <Stack
        justifyContent="center"
        style={{
          cursor: 'pointer',
          padding: 10,
          width: 60,
          height: 60,
          borderRadius: 60,
          border: `3px solid ${Colors.GOLD_200}`,
          background: Colors.DAFFODIL
        }}>
        <Title level={4} style={{ color: Colors.GOLD_200 }}>
          {displayLanguage[i18n.language]}
        </Title>
      </Stack>
    </Dropdown>
  );
};

export default LanguageSwitcher;
