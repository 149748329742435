import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Stack, Text, Button, Title, Colors } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const RescheduleEventModal = ({ isOpen, onClose, onSave, currentEvent, loading }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });

  return (
    <Modal width={491} closable centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" alignItems="flex-start" spacing={30}>
        <Title level={3}>{t('schedule_a_lesson')}</Title>
        {currentEvent && (
          <Text size="small" style={{ color: Colors.GREY_50 }}>
            {t('schedule_lesson_detail').replace('[DATE]', dayjs(currentEvent.date).format('MM-DD-YYYY'))}
          </Text>
        )}
        <Stack alignItems="flex-start" direction="column">
          <Button block onClick={onSave} loading={loading}>
            {t('yes_delete')}
          </Button>
          <Button block type='secondary' onClick={onClose} disabled={loading}>{t('oops_nevermind')}</Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RescheduleEventModal;

RescheduleEventModal.defaultProps = {
  currentEvent: null,
};

RescheduleEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentEvent: PropTypes.object,
};
