import React from 'react';
import styled from 'styled-components';
import { X as CloseIcon } from 'react-feather';
import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';
import colors from '../../theme/colors';

const StyledModal = styled(AntModal)`
  & .ant-modal-content {
    position: relative;
    border-radius: 15px !important;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.08) !important;
    max-height: 95vh;
    overflow-y: auto;
    background-color: ${props => props.background};
  }
  & .ant-modal-body {
    padding: 50px 30px;
  }
`;

const Modal = ({ children, closable, footer, onCancel, ...rest }) => {
  return (
    <StyledModal onCancel={onCancel} closable={false} footer={footer} {...rest}>
      {closable && (
        <CloseIcon
          onClick={onCancel}
          className="position-absolute cursorPointer"
          color={colors.LAVENDER}
          size={32}
          style={{ right: '20px', top: '20px' }}
        />
      )}
      {children}
    </StyledModal>
  );
};

export default Modal;

Modal.defaultProps = {
  title: '',
  width: 510,
  footer: null,
  closable: true,
  centered: true,
  onCancel: undefined,
  background: colors.WHITE_0
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  /** Card title */
  title: PropTypes.string,
  /** Width of the modal */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Footer content to show at the bottom */
  footer: PropTypes.node,
  closable: PropTypes.bool,
  centered: PropTypes.bool,
  onCancel: PropTypes.func,
  background: PropTypes.string
};
