import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  display: ${props => props.display};
  align-items: ${props => props.alignitems};
  justify-content: ${props => props.justifycontent};
  gap: ${props => props.spacing}px;
  flex-direction: ${props => props.direction};
`;

const Stack = ({ children, justifyContent, alignItems, ...rest }) => {
  return (
    <StyledDiv justifycontent={justifyContent} alignitems={alignItems} {...rest}>
      {children}
    </StyledDiv>
  );
};

export default Stack;

Stack.defaultProps = {
  direction: 'row',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  spacing: 10
};

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  /** Defines the space between immediate children. */
  spacing: PropTypes.number,
  /** Defines the flex-direction style property. It is applied for all screen sizes. */
  direction: PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
  /** Defines the display style property */
  display: PropTypes.oneOf(['flex', 'inline-block', 'inline-flex', 'block', 'none']),
  /** Defines the justify style property */
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-evenly',
    'space-between',
    'space-around'
  ]),
  /** Defines the align style property */
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline'])
};
