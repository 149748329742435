import { Auth } from 'aws-amplify';
import { API_URL, patchApiWithAuth } from '@utils';

/**
 * Update user attributes
 * @param attributes
 * @returns {Promise<boolean>}
 */
export const updateUserAttributes = async attributes => {
  try {
    const currentUser = await Auth.currentUserPoolUser({ bypassCache: true });

    if (!currentUser) {
      return false;
    }

    await Auth.updateUserAttributes(currentUser, attributes);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Complete flow
 * @return {Promise<boolean>}
 */
export const completeFlow = async () => {
  try {
    const { success } = await patchApiWithAuth(API_URL.UPDATE_USER_AUTH, { is_auth: true });
    return success;
  } catch (error) {
    return false;
  }
};

/**
 * Get Cognito user data
 * @return {Promise<*|boolean>}
 */
export const getCognitoUserId = async () => {
  try {
    const currentUser = await Auth.currentUserPoolUser({ bypassCache: true });

    if (!currentUser) {
      return false;
    }

    return currentUser.attributes.sub;
  } catch (error) {
    return false;
  }
};
