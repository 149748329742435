import React from 'react';
import PropTypes from 'prop-types';
import { Lock } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { getDataTestId } from '@utils';
import { useModal } from '@context/modalContext';
import { EducatorButton, EducatorModal } from '@components/commonComponents';

import KeyLock from '@assets/images/keyLock.svg';
import Available from '@assets/images/cyber-academy/available.svg';
import InProgress from '@assets/images/cyber-academy/in-progress.svg';
import Completed from '@assets/images/cyber-academy/completed.svg';
import CloseIcon from '@assets/images/cyber-academy/close.svg';
import defaultImage from '@assets/images/cyber-academy/default.png';

import './MissionCard.scss';

const itemStatusIconMap = {
  Available: Available,
  Started: InProgress,
  Completed: Completed
};

export const MissionCard = ({ image, title, userAchievement, topicName, onClick, isLocked, status }) => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  const { setModalContent } = useModal();

  const handleOnClick = () => {
    if (isLocked) {
      setModalContent(
        <EducatorModal
          className="unlock_modal"
          visible
          onCancel={() => {
            setModalContent(null);
          }}
          onClick={() => {}}
          closeIcon={<img src={CloseIcon} alt="Close" />}>
          <div className="unlock_modal__container">
            <div className="my-4 modal_image">
              <img src={KeyLock} alt="Gift icon" width="90" height="90" />
            </div>
            <h3>{t('unlock_missions')}</h3>
            <p className="mb-5 px-5">{t('unlock_missions_description')}</p>
          </div>
        </EducatorModal>
      );
    } else {
      onClick();
    }
  };

  return (
    <div className="mission-card__container" data-testid={getDataTestId(title)}>
      <div className="main-style">
        <div
          className={`card__thumbnail ${isLocked ? 'card--locked' : ''} ${userAchievement ? 'card--achieved' : ''}`}
          onClick={handleOnClick}>
          <img src={userAchievement ? image : defaultImage} alt={topicName} />
          {isLocked && (
            <div className="locked_icon__container">
              <Lock color={'rgba(0, 0, 0, 0.5)'} size={82} />
              <EducatorButton
                type="button"
                className="unlock__button"
                dataTestId="unlockButton"
                onClick={handleOnClick}>
                {t('unlock_mission')}
              </EducatorButton>
            </div>
          )}
        </div>
        {status && (
          <div className="card__status">
            <img src={itemStatusIconMap[status]} alt={status} width={60} height={60} />
          </div>
        )}
      </div>
      <div className="text-style">
        <div className="card__heading">{title}</div>
        <div className="card__sub_heading">{topicName}</div>
      </div>
    </div>
  );
};

MissionCard.defaultProps = {
  topicName: '',
  status: null,
  userAchievement: false,
  title: '',
  onClick: () => {}
};

MissionCard.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string,
  userAchievement: PropTypes.bool,
  onClick: PropTypes.func,
  isLocked: PropTypes.bool.isRequired,
  topicName: PropTypes.string,
  status: PropTypes.string
};
