import PropTypes from 'prop-types';
import React from 'react';
import { Card, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import './cardWithColorStyle.css';

const CardWithColor = ({ loading, color, tag, title }) => {
  const navigate = useNavigate();

  return (
    <div className="mt-3">
      <Card bordered={false} className="cardStyle cursorPointer" loading={loading} >
        <div className="noPadding" onClick={() => navigate('/resources')}>
          <Divider type="vertical" className="cardLine" style={{ background: color }} />
          <div className="p-3 directionCol">
            <div className="headdingTextStyleCardWithColor">{title}</div>
            <div className="textStyleCardWithColor">{tag}</div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default CardWithColor;
CardWithColor.defaultProps = {
  loading: false
};
CardWithColor.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
};
