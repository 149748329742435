import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { Navbar, Multistep } from '@components/layoutComponents';
import { EducatorButton, EducatorInputField, HelperBot } from '@components/commonComponents';
import { setToken, setFlowComplete, USER_ROLE } from '@utils';

import lineImage from '@assets/images/Line.png';

import './EmailConfirmation.scss';

export const EmailConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const [code, setCode] = useState('');
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const { password, username } = location.state || {};
  const confirmationSignUp = () => {
    setButtonSpinner(true);
    Auth.confirmSignUp(username, code)
      .then(() => {
        signInUser();
      })
      .catch(err => {
        const errorMessage = t('email_confirmation.validation_error', { code });
        if (err.message === errorMessage) {
          message.error(t('email_confirmation.remove_extra_spaces'));
        } else {
          message.error(`${err.message}`);
        }
        setButtonSpinner(false);
      });
  };

  const signInUser = () => {
    Auth.signIn({
      username: username,
      password: password
    })
      .then(() => {
        setButtonSpinner(false);
        setFlowComplete(false);
        saveToken();
      })
      .catch(() => {
        setButtonSpinner(false);
      });
  };
  const saveToken = async () => {
    await Auth.currentSession()
      .then(data => {
        setToken(data.accessToken.jwtToken);
        setButtonSpinner(false);
        navigate('/school-admin/school-setup');
      })
      .catch(() => {});
  };

  const resendCode = () => {
    Auth.resendSignUp(username)
      .then(() => {
        message.success(t('email_confirmation.code_resent'));
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <Multistep select="School Account Setup" check={[0, 0, 0]} userType={USER_ROLE.SCHOOL_ADMIN} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">{t('email_confirmation.check_email_for_code')}</div>
            <div className="pt-3 textStyle">
              {t('email_confirmation.verify_email_with_code')}
              <span
                className="resendCodeStyle cursorPointer"
                onClick={() => {
                  resendCode();
                }}>
                &nbsp;{t('email_confirmation.resend_code')}
              </span>
              <div className="imageDiv ">
                <img className="imageResendCodeStyle mr-5" src={lineImage} alt="line" width="102" height="0" />
              </div>
            </div>
            <Form onFinish={confirmationSignUp}>
              <Form.Item
                className="pt-3"
                name="code"
                rules={[{ required: true, message: t('email_confirmation.enter_code') }]}>
                <EducatorInputField
                  label={t('email_confirmation.enter_your_code')}
                  type="text"
                  name="code"
                  inputValue={code}
                  onChange={e => setCode(e.target.value)}
                />
              </Form.Item>

              <Form.Item className="pt-3">
                <EducatorButton label={t('email_confirmation.continue')} type="submit" showSpinner={buttonSpinner} />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          <HelperBot>{t('email_confirmation.dont_close_this_window')}</HelperBot>
        </div>
      </div>
    </div>
  );
};
