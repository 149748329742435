import React from 'react';
import PropTypes from 'prop-types';
import { Button, Colors, Modal, Stack, Text, Title } from '@student-app/design-system';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { clearStorage, getIsGoogle, GOOGLE_SSO_LOGOUT_URL, setLogoutUrl } from '@utils';
import { Auth } from 'aws-amplify';

const SignoutModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const logoutUser = async () => {
    const isGoogle = getIsGoogle();
    if (isGoogle === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          setLogoutUrl('/student-signin');
          clearStorage();
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
    onClose();
  };

  return (
    <Modal width={530} closable={false} centered onCancel={onClose} open={isOpen}>
      <Stack direction="column" spacing={30}>
        <Stack direction="column" alignItems="center">
          <Title style={{ textAlign: 'center', color: Colors.SKY_200 }} level={2}>
            {t('sign_out')}
          </Title>
          <Text style={{ color: Colors.GREY_100 }} className="text-center">
            {t('sign_out_description')}
          </Text>
        </Stack>
        <Stack justifyContent="center" spacing={20}>
          <Button type="text" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button onClick={logoutUser}>{t('yes_sign_out')}</Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default SignoutModal;

SignoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
