/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { getApiWithAuth, postApiWithAuth } from '../../../utils/api';
import { API_URL, GOOGLE_SSO_LOGOUT_URL } from '../../../utils/constants';
import { clearStorage, getIsGoogle, getRefereshToken, getToken } from '../../../utils/sessionStorage';
import { Auth } from 'aws-amplify';

const ChildParentConsent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const logoutUser = async () => {
    const Google = getIsGoogle();
    const token = getToken();
    const refreshToken = getRefereshToken();
    if (Google === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
      window.open(`${import.meta.env.REACT_APP_LINK_GAME_SERVER}?token=${token}&mode=home`, '_self');
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
          window.open(
            `${import.meta.env.REACT_APP_LINK_GAME_SERVER}?token=${token}&refresh=${refreshToken}&mode=home`,
            '_self'
          );
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };

  const sendCodeParent = async () => {
    setButtonSpinner(true);
    const data = await postApiWithAuth(API_URL.USER_CONSENT, userData);
    if (data.success) {
      setButtonSpinner(false);
      if (data.data.is_parent_linked) {
        logoutUser();
      } else {
        message.info('Invite has been sent to the parent. You can play once parent signed up successfully.');
        navigate('/child-where-play');
      }
    } else {
      setButtonSpinner(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { status } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
      if (status === 401) {
        navigate('/');
      }
    };
    fetchUser();
  }, [location.pathname === '/child-create-account/parent-consent']);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childSecondHaddingStyle">Before you can play we need consent from your parent!</div>
          <div className="mt-3">
            <Form onFinish={sendCodeParent}>
              <Form.Item name="email" rules={[{ required: true, message: 'Please input your Parent Email!' }]}>
                <EducatorInputField
                  label="Enter Parent’s Email:"
                  type="email"
                  inputValue={userData.email}
                  headerColor="#000000"
                  name="email"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="py-3">
                <EducatorButton label="Send Request" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
              <Link to="/child-create-account/link-parent" className="childLinkStyle mt-1 cursorPointer">
                My parent already has an account
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildParentConsent;
