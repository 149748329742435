import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Stack, Text, Title } from '@teacher-app/design-system';
import { API_URL, patchApiWithAuth } from '@utils';

const LinkParentModal = ({ isOpen, onClose, handleOnFinish, selectedStudent }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const [form] = Form.useForm();

  const [showSpinner, setShowSpinner] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSubmit = async values => {
    setShowSpinner(true);

    const { success, data } = await patchApiWithAuth(`${API_URL.SCHOOL_STUDENTS}/${selectedStudent.id}/edit`, {
      parent_email: values.email
    });
    if (success) {
      message.success(t('email_sent_successfully'));
      handleOnFinish();
    } else {
      message.error(data.message);
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);

  if (!selectedStudent) return null;

  return (
    <Modal width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Form
        onFinish={handleSubmit}
        onValuesChange={() => {
          setIsButtonDisabled(false);
        }}
        form={form}
        name="invite-parents">
        <Stack direction="column" spacing={22}>
          <Stack direction="column" alignItems='flex-start' spacing={12}>
            <Title level={3}>{t('link_parents')}</Title>
            <Text size="small">{t('link_parents_details')}</Text>
          </Stack>
          <Form.Item
            label={`${selectedStudent.username}'s ${t('parent_email')}`}
            rules={[
              {
                type: 'email',
                message: t('enter_valid_email')
              }
            ]}
            name="email">
            <Input name="email" />
          </Form.Item>
          <Button block htmlType="submit" loading={showSpinner} disabled={isButtonDisabled}>
            {t('link')}
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};

export default LinkParentModal;

LinkParentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOnFinish: PropTypes.func.isRequired,
  selectedStudent: PropTypes.object.isRequired
};
