import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { getUserID, patchApiWithAuth } from '@utils';
import { EducatorButton, EducatorInputField } from '@components/commonComponents';

export const UpdateCardInfo = ({ closeModal, userInfo, setSingleUser }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const useOptions = () => {
    return useMemo(
      () => ({
        style: {
          base: {
            fontSize: '18px',
            color: '#222222',
            fontFamily: 'Cabin, sans-serif',
            '::placeholder': {
              color: '#222222'
            },
            fontWeight: '500'
          }
        },
        placeholder: ''
      }),
      []
    );
  };
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [isLoading, setIsLoading] = useState(false);
  const [sendData, setSendData] = useState({
    new_payment_id: undefined,
    user_id: parseInt(getUserID())
  });

  const [checkCard, setCheckCard] = useState({
    isValidNumber: false,
    isValidExpiry: false,
    isValidCvc: false
  });

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setSendData({ ...sendData, [name]: value });
    setSingleUser({ ...userInfo, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }
    let cardElement = null;
    cardElement = {
      ...cardElement,
      ...elements.getElement(CardCvcElement),
      ...elements.getElement(CardExpiryElement),
      ...elements.getElement(CardNumberElement)
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      metadata: {
        name: sendData.name,
        zip_or_postalCode: sendData.zip_or_postalCode
      }
    });

    if (error) {
      setIsLoading(false);
      message.error(error.message);
    } else {
      setSendData({
        ...sendData,
        new_payment_id: paymentMethod?.id
      });
    }
  };

  useEffect(() => {
    if (sendData.new_payment_id != undefined) PostPaymentId();
  }, [sendData.new_payment_id]);

  const onChangeHandleCard = event => {
    if (event.elementType === 'cardNumber' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidNumber: true
      });
    } else if (event.elementType === 'cardExpiry' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidExpiry: true
      });
    } else if (event.elementType === 'cardCvc' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidCvc: true
      });
    }
  };
  const PostPaymentId = async () => {
    const newData = sendData;
    delete newData.name;
    delete newData.zip_or_postalCode;
    const res = await patchApiWithAuth(`payments/${userInfo.id}/update-card`, newData);
    if (res.success) {
      setIsLoading(false);
      closeModal();
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSendData({
      ...sendData,
      old_payment_id: userInfo.payment_id,
      name: userInfo.name,
      zip_or_postalCode: userInfo.zip_or_postalCode
    });
  }, [userInfo]);

  return (
    <div className="update-card-info">
      <Form onFinish={handleSubmit}>
        <div className="headingStyle">{t('edit_payment_details')}</div>

        <Form.Item name="name" className="pt-3">
          <EducatorInputField
            label={t('cardholder_name')}
            className="fixCardInputField user-info"
            inputValue={userInfo.name}
            type="name"
            name="name"
            onChange={onChangeHandle}
          />
        </Form.Item>
        <Form.Item name="zip_or_postalCode" className="pt-3">
          <EducatorInputField
            className="fixCardInputField user-info"
            label={t('zip_postal_code')}
            type="text"
            name="zip_or_postalCode"
            inputValue={userInfo.zip_or_postalCode}
            onChange={onChangeHandle}
          />
        </Form.Item>

        <Form.Item className="pt-4">
          <div>
            <label className="parentOverviewHadding">{t('card_number')}</label>
            <div className="mt-3">
              <div className="fixCardInputField card-info">
                <CardNumberElement onChange={onChangeHandleCard} options={options} />
              </div>
            </div>
          </div>
        </Form.Item>

        <div className="pt-3 row">
          <div className="col-6">
            <Form.Item>
              <div>
                <label className="parentOverviewHadding">{t('expiration_date')}</label>
                <div className="fixCardInputField card-info mt-3">
                  <CardExpiryElement onChange={onChangeHandleCard} options={options} />
                </div>
              </div>
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item>
              <div>
                <label className="parentOverviewHadding ">{t('cvc')}</label>
                <div className="fixCardInputField card-info mt-3">
                  <CardCvcElement onChange={onChangeHandleCard} options={options} />
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <div className="pt-3">
            <EducatorButton
              label={t('save_details')}
              type="submit"
              showSpinner={isLoading}
              disabled={!checkCard.isValidNumber || !checkCard.isValidExpiry || !checkCard.isValidCvc}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default UpdateCardInfo;

UpdateCardInfo.propTypes = {
  closeModal: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  setSingleUser: PropTypes.func.isRequired
};
