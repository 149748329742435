import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Empty, Image, Select, Spin } from 'antd';
import dayjs from 'dayjs';

import { EducatorButton, EducatorModal, Pagination } from '@components/commonComponents';
import { API_URL, getApiWithAuth, sendGaEvent } from '@utils';
import { useGrades } from '@hooks';

import { AssignmentCard, MissionCard } from '@components/studentDashboardComponent';
import { AssignmentModal } from '@pages/Dashboard/StudentDashboard/AssignmentModal';
import { MissionModal } from '@pages/Dashboard/StudentDashboard/MissionModal';
import PageLayout from '@student-app/components/PageLayout';
import { Stack, Title } from '@student-app/design-system';

import CloseIcon from '@assets/images/cyber-academy/close.svg';
import HelpIcon from '@assets/images/cyber-academy/Help.png';

import './StudentDashboardStyle.scss';

const { Option } = Select;

const isDueSoon = dueDate => dayjs(dueDate).diff(dayjs(), 'day') <= 3;

const createItemsWithType = (data, type, statusCheck) =>
  data.filter(item => statusCheck(item)).map(item => ({ ...item, type }));

export const StudentDashboard = () => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: grades } = useGrades();
  const [badges, setBadges] = useState([]);
  const [topics, setTopics] = useState([]);
  const [missionStatuses, setMissionStatuses] = useState([]);

  const [selectedMission, setSelectedMission] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  const [missionsData, setMissionsData] = useState([]);
  const [assignmentData, setAssignmentData] = useState([]);
  const [currentProfile, setCurrentProfile] = useState([]);
  const [missionsProgressData, setMissionsProgressData] = useState([]);
  const [filteredMissionsData, setFilteredMissionsData] = useState([]);

  const dataGrade = searchParams.get('grade') ?? 'All';
  const dataBadge = searchParams.get('badge') ?? 'All';
  const dataTopic = searchParams.get('topic') ?? 'All';
  const dataStatus = searchParams.get('status') ?? 'All';

  const [missionLoading, setMissionLoading] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [assignmentLoading, setAssignmentLoading] = useState(false);

  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);

  const [initialGrade, setInitialGrade] = useState('All');

  const selectGrade = gradeName => {
    setSearchParams(params => {
      params.set('grade', gradeName);
      return params;
    });
  };

  const selectBadge = badge => {
    setSearchParams(params => {
      params.set('badge', badge);
      return params;
    });
  };

  const selectTopic = topic => {
    setSearchParams(params => {
      params.set('topic', topic);
      return params;
    });
  };

  const selectStatus = status => {
    setSearchParams(params => {
      params.set('status', status);
      return params;
    });
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getMissions = async () => {
    setMissionLoading(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_MISSIONS_LIST}?grade=${dataGrade}`);
    if (success) {
      const topicsData = new Set();
      const badgesData = new Set();
      const newData = data.map(mission => {
        const topic = mission['topic'];

        topicsData.add(JSON.stringify({ name: topic.name }));
        badgesData.add(JSON.stringify({ name: topic.sub_heading.name }));

        return mission;
      });
      setMissionsData(newData);
      setTopics(Array.from(topicsData).map(topic => JSON.parse(topic)));
      setBadges(Array.from(badgesData).map(badge => JSON.parse(badge)));

      setMissionLoading(false);
    } else {
      setMissionLoading(false);
    }
  };

  const getAssignments = async () => {
    setAssignmentLoading(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_ASSIGNMENTS_LIST}`);
    if (success) {
      setAssignmentData(data);
      setAssignmentLoading(false);
    } else {
      setAssignmentLoading(false);
    }
  };

  const getMissionProgress = async () => {
    setProgressLoading(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_MISSIONS_LIST}/progress?grade=${dataGrade}`);
    if (success) {
      const statusData = new Set();
      const newData = data.map(({ mission_id, status }) => {
        statusData.add(JSON.stringify({ name: status.name }));

        return { mission_id: mission_id, status: status.name };
      });

      setMissionStatuses(Array.from(statusData).map(status => JSON.parse(status)));
      setMissionsProgressData(newData);
      setProgressLoading(false);
    } else {
      setProgressLoading(false);
    }
  };

  const getProfile = async () => {
    setProfileLoading(true);
    const { success, data } = await getApiWithAuth(`${API_URL.GET_STUDENT_PROFILE}`);
    if (success) {
      selectGrade(data['grade']);
      setInitialGrade(data['grade']);
      setCurrentProfile(data);
    }
    setProfileLoading(false);
  };

  const handleReset = () => {
    setSearchParams(params => {
      params.set('grade', initialGrade);
      params.delete('status');
      params.delete('topic');
      params.delete('badge');
      return params;
    });
    getFilterData();
  };

  useEffect(() => {
    if (dataGrade !== 'All') {
      getMissions();
      getMissionProgress();
    }
  }, [dataGrade]);

  useEffect(() => {
    getFilterData();
  }, [missionsData, missionsProgressData, dataBadge, dataStatus, dataTopic, assignmentData]);

  useEffect(() => {
    getProfile();
    getAssignments();
  }, []);

  const getFilterData = () => {
    const assignmentMissions = [];

    if (assignmentData) {
      assignmentData.forEach(assignment => {
        assignmentMissions.push(...assignment['mission_ids']);
      });
    }

    const filteredData = missionsData
      .filter(mission => !assignmentMissions.find(id => mission.id === id))
      .filter(mission => mission.topic.name === dataTopic || dataTopic === 'All')
      .filter(mission => mission.topic.sub_heading.name === dataBadge || dataBadge === 'All')
      .map(mission => {
        const obj = missionsProgressData.find(o => o.mission_id === mission.id);
        return { ...mission, status: obj ? obj.status : 'Available' };
      })
      .filter(mission => mission.status === dataStatus || dataStatus === 'All');

    setFilteredMissionsData(filteredData);
    setCurrentPage(1);
  };

  const handleMissionClick = async item => {
    sendGaEvent('mission_card_click', 'Mission Card Click', item.name);

    navigate(`/student-dashboard/mission/${item.id}`, {
      state: {
        itemId: item.id,
        title: item.name
      }
    });

    setSelectedMission(null);
  };

  const handleAssignmentClick = async item => {
    sendGaEvent('assignment_card_click', 'Assignment Card Click', item.name);

    navigate(`/student-dashboard/assignment/${item.assignment_id}`, {
      state: {
        itemId: item.id,
        title: item.name
      }
    });
    setSelectedAssignment(null);
  };

  const assignmentsDueSoon = createItemsWithType(
    assignmentData,
    'assignment',
    item => item.status.name === 'Started' && isDueSoon(item.end_date)
  );
  const assignmentsInProgress = createItemsWithType(
    assignmentData,
    'assignment',
    item => item.status.name === 'Started' && !isDueSoon(item.end_date)
  );
  const availableAssignments = createItemsWithType(
    assignmentData,
    'assignment',
    item => item.status.name === 'Available'
  );

  const missionsInProgress = createItemsWithType(
    filteredMissionsData,
    'mission',
    mission => mission.status === 'Started'
  );
  const availableMissions = createItemsWithType(
    filteredMissionsData,
    'mission',
    mission => mission.status === 'Available'
  );
  const completedMissions = createItemsWithType(
    filteredMissionsData,
    'mission',
    mission => mission.status === 'Completed'
  );

  const statusOrder = ['Started', 'Available', 'Completed'];
  const combinedMissions = [...missionsInProgress, ...availableMissions, ...completedMissions].sort((a, b) => {
    if (a.is_locked === b.is_locked) {
      return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
    }
    return a.is_locked ? 1 : -1;
  });

  const combinedItems = [...assignmentsDueSoon, ...assignmentsInProgress, ...availableAssignments, ...combinedMissions];

  const totalPages = Math.ceil(combinedItems.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = combinedItems.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <PageLayout>
        <Stack spacing={50} direction='column' style={{ width: '75vw', maxWidth: 1000, padding: '42px 0', position: 'relative' }}>
        <Stack spacing={30} direction='column' >
            <Title level={2} style={{ textAlign: 'center' }}>{t('welcome_cyber_academy', { name: currentProfile?.user?.first_name ?? 'Student' })}</Title>
              <Stack className="cardActivityMainDiv" justifyContent='center' style={{ flex: 'wrap' }}>
                <Select
                  onChange={selectGrade}
                  value={dataGrade}
                  className="fixCardInputField"
                  name="grade"
                  popupClassName="custom-select-dropdown"
                  options={[
                    { label: t('select_grade'), value: 'All' },
                    ...grades.map(item => ({ label: item.cyber_name, value: item.name }))
                  ]}
                />
                <Select
                  onChange={selectBadge}
                  value={dataBadge}
                  className="fixCardInputField"
                  name="badge"
                  popupClassName="custom-select-dropdown"
                  options={[
                    { label: t('select_badge'), value: 'All' },
                    ...badges.map(item => ({ label: item.name, value: item.name }))
                  ]}
                />
                <Select onChange={selectTopic} value={dataTopic} className="fixCardInputField d-none" name="skill">
                  <Option key={-1} value={'All'}>
                    {t('select_topic')}
                  </Option>
                  {topics?.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                <Select
                  onChange={selectStatus}
                  value={dataStatus}
                  className="fixCardInputField"
                  name="curriculum"
                  popupClassName="custom-select-dropdown"
                  options={[
                    { label: t('select_mission_status'), value: 'All' },
                    ...missionStatuses.map(item => ({ label: item.name, value: item.name }))
                  ]}
                />
                <EducatorButton
                  className="filter__button"
                  label={t('reset')}
                  type="button"
                  onClick={handleReset}
                  dataTestId="resetButton"
                  width={'auto'}
                />
              </Stack>
          </Stack>
          <Stack justifyContent='center' style={{ position: 'relative', minHeight: '40vh' }}>
            <Stack style={{ right: -100, top: '40%', position: 'absolute', cursor: 'pointer', width: 'fit-content' }}>
              <Image src={HelpIcon} preview={false} onClick={() => navigate('/student-dashboard/help-center')} />
            </Stack>
            {missionLoading || assignmentLoading || profileLoading || progressLoading ? (
                <Spin size="large" />
            ) : filteredMissionsData?.length === 0 && assignmentData?.length === 0 ? (
                <Empty description={false} />
            ) : (
              <div className="student-dashboard__body">
                <div className="student-dashboard__body__items">
                  {currentItems.map(item => {
                    if (item.type === 'assignment') {
                      return (
                        <div className="col-12 col-sm-6 col-md-3 col-xl-3" key={`${item.id}-${new Date().getTime()}`}>
                          <AssignmentCard
                            title={item.name}
                            badge={item.topic.sub_heading}
                            onClick={() => setSelectedAssignment(item)}
                            status={isDueSoon(item.end_date) ? 'Due Soon' : item.status.name}
                          />
                        </div>
                      );
                    }
                    return (
                      <div className="col-12 col-sm-6 col-md-3 col-xl-3" key={`${item.id}-${new Date().getTime()}`}>
                        <MissionCard
                          image={item.thumbnail}
                          title={item.name}
                          badge={item.topic.sub_heading}
                          isLocked={item.is_locked}
                          topicName={item.topic.name}
                          onClick={() => {
                            setSelectedMission(item);
                          }}
                          status={item.status}
                          userAchievement
                        />
                      </div>
                    );
                  })}
                </div>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
              </div>
            )}
          </Stack>
        </Stack>
      </PageLayout>
      <EducatorModal
        className="assignment__modal"
        visible={selectedAssignment !== null}
        onCancel={() => {
          setSelectedAssignment(null);
        }}
        bodyContent={
          <AssignmentModal
            assignment={selectedAssignment}
            onDone={() => {
              handleAssignmentClick(selectedAssignment);
            }}
          />
        }
        closeIcon={<img src={CloseIcon} alt="Close" />}
        destroyOnClose
        width={700}
      />
      <EducatorModal
        className="mission__modal"
        visible={selectedMission !== null}
        onCancel={() => {
          setSelectedMission(null);
        }}
        bodyContent={
          <MissionModal
            mission={selectedMission}
            onDone={() => {
              handleMissionClick(selectedMission);
            }}
          />
        }
        closeIcon={<img src={CloseIcon} alt="Close" />}
        destroyOnClose
        width={700}
      />
    </>
  );
};
