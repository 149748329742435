import React from 'react';
import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { EducatorButton, EducatorModal } from '../../../commonComponents';

export const ShowDetailsModal = ({ show, handleOnCancel, handleOnSubmit, childData }) => {
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">Your child’s account has been created!</div>
          <div className="fixCardtextStyle mt-2">
            This new account has been successfully created! Below are the login details. Write them down or store them
            in a secure password manager
          </div>

          <Form onFinish={handleOnSubmit}>
            <div className="mt-3 nameAndButtonContainer">
              <div className="childHaddingStyle">{childData?.user_name ? childData?.user_name : 'Your UserName'}</div>
              <CopyOutlined
                style={{ color: 'blue', fontSize: '18px' }}
                className="ms-4"
                onClick={() => {
                  navigator.clipboard.writeText(childData?.user_name);
                  message.success('Copied to Clipboard!');
                }}
              />
            </div>
            <div className="mt-3 childSecondText">Your Password:</div>
            <div className="mt-3 nameAndButtonContainer">
              <div className="childHaddingStyle">{childData?.password ? childData?.password : 'Your Password'}</div>
              <CopyOutlined
                style={{ color: 'blue', fontSize: '18px' }}
                className="ms-4"
                onClick={() => {
                  navigator.clipboard.writeText(childData?.password);
                  message.success('Copied to Clipboard!');
                }}
              />
            </div>
            <div className="py-3">
              <EducatorButton label="That’s it for now!" type="submit" width="100%" />
            </div>
          </Form>
        </div>
      }
    />
  );
};

export default ShowDetailsModal;

ShowDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  childData: PropTypes.object.isRequired
};
