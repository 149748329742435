import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  API_URL,
  deleteApiWithAuth,
  getApiWithAuth,
  getIsFromStudent,
  patchApiWithAuth,
  postApiWithAuth,
  setIsFromStudent
} from '@utils';
import { message, Form } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useClassAssignments, useStudents } from '@hooks';
import { useAppState } from '@context';
import { logoutTeacher } from '@utils';

import { CreateAssignment } from './CreateAssignment';
import { EditAssignment } from './EditAssignment';
import { DeleteAssignment } from './DeleteAssignment';
import { AssignmentCurriculum } from './AssignmentCurriculum';
import { AssignmentType } from './AssignmentType';
import { AssignmentSkills } from './AssignmentSkills';

export const AssignmentModalManager = ({ modal, setModal, closeFn, selectedAssignment }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { state } = useAppState();

  const { data: studentData } = useStudents(state.classId);
  const { revalidate: getAssignments } = useClassAssignments(state.classId);

  const [createAssignmentData, setCreateAssignmentData] = useState({
    topics: [],
    assigned_class: state.classId,
    assign_all_students: false
  });

  const [assignmentId, setAssignmentId] = useState(0);
  const [classAssignmentTopics, setClassAssignmentTopics] = useState([]);
  const [assignmentNameOnModel, setAssignmentNameOnModel] = useState();
  const [checkCurriculum, setCheckCurriculum] = useState({});
  const [checkAssignmentTopic, setCheckAssignmentTopic] = useState(1);
  const [assignedStudentsId, setAssignedStudentsId] = useState([]);
  const [assignmentDataById, setAssignmentDataById] = useState({
    start_date: dayjs().format('YYYY - MM - DD'),
    end_date: dayjs().format('YYYY - MM - DD')
  });

  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);

  const assignmentTypeGraded = () => {
    setCreateAssignmentData({ ...createAssignmentData, graded_or_not: true });

    closeFn();
    setModal('assignmentCurriculum');
  };
  const assignmentTypePractice = () => {
    setCreateAssignmentData({ ...createAssignmentData, graded_or_not: false });

    closeFn();
    setModal('assignmentCurriculum');
  };

  const assignmentYourCurriculum = () => {
    setCheckAssignmentTopic(1);

    closeFn();
    setModal('assignmentSkills');
  };
  const assignmentCyberLegendsCurriculum = () => {
    setCheckAssignmentTopic(2);

    closeFn();
    setModal('assignmentSkills');
  };
  const showAssignmentSkills = () => {
    closeFn();
    setModal('assignmentSkills');
  };

  const hideAssignmentSkillsPrevious = () => {
    closeFn();
    setModal('assignmentCurriculum');
  };
  const hideCreateAssignmentModal = () => {
    closeFn();
  };
  const hideEditAssignmentModal = () => {
    closeFn();
  };

  const createdAssignment = () => {
    closeFn();
    setModal('createAssignment');
  };

  const handleTopics = value => {
    if (!createAssignmentData.topics.some(item => item === value)) {
      setCreateAssignmentData({ ...createAssignmentData, topics: [...createAssignmentData.topics, value] });
    } else {
      const filteredArray = createAssignmentData.topics.filter(item => item !== value);
      setCreateAssignmentData({ ...createAssignmentData, topics: filteredArray });
    }
  };

  const handlerTopicsChange = e => {
    const { value } = e.target;
    if (!assignmentDataById.topics.some(item => item === value)) {
      setAssignmentDataById({ ...assignmentDataById, topics: [...assignmentDataById.topics, value] });
    } else {
      const filteredArray = assignmentDataById.topics.filter(item => item !== value);
      setAssignmentDataById({ ...assignmentDataById, topics: filteredArray });
    }
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setCreateAssignmentData({ ...createAssignmentData, [name]: value });
    setAssignmentDataById({ ...assignmentDataById, [name]: value });
  };
  const onChangeStartDateHandle = e => {
    const startDate = dayjs(e._d).format('YYYY-MM-DD');
    setCreateAssignmentData({ ...createAssignmentData, start_date: startDate });
    setAssignmentDataById({ ...assignmentDataById, start_date: startDate });
  };
  const onChangeEndDateHandle = e => {
    const lastDate = dayjs(e._d).format('YYYY-MM-DD');
    setCreateAssignmentData({ ...createAssignmentData, end_date: lastDate });
    setAssignmentDataById({ ...assignmentDataById, end_date: lastDate });
  };
  const assignCheckbox = e => {
    const { name, checked } = e.target;
    setCreateAssignmentData({ ...createAssignmentData, [name]: checked });
    setAssignmentDataById({ ...assignmentDataById, [name]: !assignmentDataById.assign_all_students });
  };

  const onSelectedHandle = value => {
    setCreateAssignmentData({ ...createAssignmentData, assigned_students: value });
  };

  const onSelectedHandleUpdate = value => {
    setAssignedStudentsId(value);
  };

  const createAssignment = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.CREATE_ASSIGNMENT, createAssignmentData);

    if (success) {
      hideCreateAssignmentModal();
      setButtonSpinner(false);
      form.resetFields();
      setIsFromStudent(false);
      message.success(t('assignments.assignment_created', { ns: 'translation' }));
      getAssignments();
    } else {
      message.error(data.detail);
      setButtonSpinner(false);
    }
  };

  const updateAssignmentData = async () => {
    setAssignmentDataById({ ...assignmentDataById, assigned_class: state.classId });
    await updateAssignment();
  };

  const updateAssignment = async () => {
    setButtonSpinner(true);
    const newData = assignmentDataById;
    delete newData.all_topics;
    delete newData.assigned_students;
    if (newData.assign_all_students) {
      newData.assigned_students = [];
    } else {
      newData.assigned_students = assignedStudentsId;
    }
    const { success, data } = await patchApiWithAuth(`${API_URL.UPDATE_ASSIGNMENT}/${assignmentId}/edit`, newData);
    if (success) {
      message.success(t('assignments.assignment_updated', { ns: 'translation' }));
      setButtonSpinner(false);
      setAssignmentDataById({});
      hideEditAssignmentModal();
      getAssignments();
    } else {
      message.error(data.detail);
      setButtonSpinner(false);
    }
  };

  const disabledDate = current => {
    return current && current.isBefore(dayjs().subtract(1, 'day'));
  };

  const editAssignmentId = async id => {
    setModal('editAssignment');
    setAssignmentId(id);
    await editAssignment(id);
  };

  const editAssignment = async id => {
    setShowDeleteSpinner(true);
    const { success, data, status } = await getApiWithAuth(`${API_URL.GET_ASSIGNMENT_BY_ID}${id}`);
    if (status === 401) {
      await logoutTeacher();
      return;
    }

    if (success) {
      setShowDeleteSpinner(false);
      setAssignmentDataById(data);
    } else {
      setShowDeleteSpinner(false);
    }
  };
  const deleteAssignmentId = (id, name) => {
    setAssignmentId(id);
    setAssignmentNameOnModel(name);
    showDeleteModal();
  };

  const showDeleteModal = () => {
    setModal('deleteAssignment');
  };
  const handleDeleteCancel = () => {
    closeFn();
  };
  const deleteAssignment = async () => {
    setShowDeleteSpinner(true);
    const { success } = await deleteApiWithAuth(`${API_URL.ASSIGNMENT_BY_ID}${assignmentId}`);
    if (success) {
      message.success(t('assignments.assignment_deleted', { ns: 'translation' }));
      setShowDeleteSpinner(false);
      getAssignments();
      handleDeleteCancel();
    } else {
      setShowDeleteSpinner(false);
    }
  };

  const getAssignmentTopics = async () => {
    const { success, data, status } = await getApiWithAuth(
      `${API_URL.TEACHER_STATE_TOPICS}?type=${checkAssignmentTopic}&class=${state.classId}`
    );
    if (status === 401) {
      await logoutTeacher();
      return;
    }

    if (success) {
      const orderedTopics = data.sort((a, b) => {
        if (a.heading === 'Safety') {
          return -1;
        } else if (b.heading === 'Safety') {
          return 1;
        } else {
          return 0;
        }
      });
      setClassAssignmentTopics(orderedTopics);
    }
  };

  const getCurriculumInfo = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRICULUM);
    if (success) {
      setCheckCurriculum(data);
      return data;
    }
  };

  useEffect(() => {
    setAssignedStudentsId(
      assignmentDataById.assigned_students?.map(item => {
        return item.id;
      })
    );
  }, [assignmentDataById.assigned_students]);

  useEffect(() => {
    getAssignmentTopics();
  }, [checkAssignmentTopic]);

  useEffect(() => {
    if (getIsFromStudent() !== 'false' && getIsFromStudent() !== null) {
      setModal('assignmentType');
    }
    getCurriculumInfo();
  }, []);

  useEffect(() => {
    if (selectedAssignment) {
      if (selectedAssignment.action === 'edit') {
        editAssignmentId(selectedAssignment.assignment_id);
      } else if (selectedAssignment.action === 'delete') {
        deleteAssignmentId(selectedAssignment.assignment_id, selectedAssignment.assignment_name);
      }
    }
  }, [selectedAssignment]);

  return (
    <>
      <AssignmentType
        show={modal === 'assignmentType'}
        onCancel={closeFn}
        onFinish={assignmentTypePractice}
        onClick={assignmentTypeGraded}
      />
      <AssignmentCurriculum
        show={modal === 'assignmentCurriculum'}
        onCancel={closeFn}
        onFinish={assignmentYourCurriculum}
        checkCurriculum={checkCurriculum}
        onClick={assignmentCyberLegendsCurriculum}
      />
      <AssignmentSkills
        show={modal === 'assignmentSkills'}
        onCancel={closeFn}
        classAssignmentTopics={classAssignmentTopics}
        handleTopics={handleTopics}
        onPreviousClick={hideAssignmentSkillsPrevious}
        onNextClick={createdAssignment}
      />
      <CreateAssignment
        show={modal === 'createAssignment'}
        onCancel={closeFn}
        onFinish={createAssignment}
        createAssignmentData={createAssignmentData}
        onChange={onChangeHandle}
        onChangeStartDate={onChangeStartDateHandle}
        onChangeEndDate={onChangeEndDateHandle}
        disabledDate={disabledDate}
        assignCheckbox={assignCheckbox}
        onSelected={onSelectedHandle}
        onPreviousClick={showAssignmentSkills}
        studentData={studentData}
        loading={buttonSpinner}
        form={form}
      />
      <EditAssignment
        show={modal === 'editAssignment'}
        onCancel={closeFn}
        assignmentDataById={assignmentDataById}
        onChange={onChangeHandle}
        onChangeStartDate={onChangeStartDateHandle}
        onChangeEndDate={onChangeEndDateHandle}
        disabledDate={disabledDate}
        onCheckboxChange={assignCheckbox}
        assignedStudentsId={assignedStudentsId}
        onSelectChange={onSelectedHandleUpdate}
        studentData={studentData}
        handleTopicsChange={handlerTopicsChange}
        onSaveAssignment={updateAssignmentData}
        loading={buttonSpinner}
      />
      <DeleteAssignment
        show={modal === 'deleteAssignment'}
        onCancel={closeFn}
        assignmentName={assignmentNameOnModel}
        onFinish={deleteAssignment}
        loading={showDeleteSpinner}
        onCancelClick={handleDeleteCancel}
      />
    </>
  );
};

AssignmentModalManager.defaultProps = {
  setModal: () => {},
  selectedAssignment: null
};

AssignmentModalManager.propTypes = {
  modal: PropTypes.string.isRequired,
  closeFn: PropTypes.func.isRequired,
  setModal: PropTypes.func,
  selectedAssignment: PropTypes.object
};
