import React from 'react';
import PropTypes from 'prop-types';
import babyImage from '../../../assets/images/educatorTeacherImage.png';
import './HelperBot.css';

export const HelperBot = ({ children, ...rest }) => {
  return (
    <div {...rest}>
      {children ? <div className="text-bubble">{children}</div> : null}
      <img src={babyImage} alt="Logo" width="235" height="255.43" />
    </div>
  );
};

HelperBot.defaultProps = {
  children: ''
};

HelperBot.propTypes = {
  children: PropTypes.node
};
