import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Checkbox, Input, message, Radio, Select, Spin, Tooltip } from 'antd';
import { HelpCircle as HelpCircleIcon, Trash2 as DeleteIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useGrades } from '@hooks';
import { Button, Colors, Form, Modal, Stack, Title, Text } from '@teacher-app/design-system';
import { API_URL, deleteApiWithAuth, patchApiWithAuth } from '@utils';
import { SET_CURRENT_CLASS_ID, useAppState } from '@context';

const { Option } = Select;

const CLASS_TYPES = ['single_grade', 'multiple_grade'];

const EditClassModal = ({ currentClass, isOpen, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'overview' });
  const [form] = Form.useForm();
  const { data: allGrades } = useGrades();
  const { dispatch } = useAppState();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const classType = Form.useWatch('class_type', form);
  const classGrade = Form.useWatch('grade', form);

  const handleSubmit = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const inputValues = {
      name: values?.class_name,
      grades: values?.grades || [],
      grade: values?.grade,
      update_students_grade: values?.update_students_grade
    };

    const { success, data } = await patchApiWithAuth(`${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/edit`, {
      ...inputValues
    });
    if (success) {
      message.success(t('class_updated_successfully'));
      onClose(true);
    } else {
      message.error(data.detail ?? t('general_error_message'));
      setLoading(false);
    }
  };

  const deleteClass = async () => {
    setDeleting(true);
    const { success, data } = await deleteApiWithAuth(`${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/`);
    if (success) {
      message.success(t('class_is_deleted'));
      dispatch({
        type: SET_CURRENT_CLASS_ID,
        payload: ''
      });
      onClose(true, true);
    } else {
      message.error(data.message);
      setDeleting(false);
    }
  };

  const handleClassTypeChange = e => {
    const { value } = e.target;
    form.setFieldValue('class_type', value);
    // reset grade and update_student_grade when class_type is changed
    if (value === CLASS_TYPES[1]) {
      form.setFieldValue('grades',  currentClass.grades.map(item => item.id));
      form.setFieldValue('grade', undefined);
    } else if (value === CLASS_TYPES[0]) {
      form.setFieldValue('grade', currentClass?.grade);
      form.setFieldValue('grades', []);
    }
    form.setFieldValue('update_students_grade', undefined);
  };

  const handleUpdateStudentGrade = e => {
    const { checked } = e.target;
    form.setFieldValue('update_students_grade', checked);
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setLoading(false);
      setDeleting(false);
    }
    if (isOpen) {
      form.setFieldsValue({
        grades: currentClass?.grades?.map(item => item.id) ?? [],
        grade: currentClass?.grade ?? undefined,
        class_name: currentClass?.name ?? undefined,
        class_type: currentClass?.grades?.length > 0 ? CLASS_TYPES[1] : CLASS_TYPES[0]
      });
    }
  }, [isOpen]);

  // show UpdateStudentGrade only when grade is changed
  const showUpdateStudentGrade = classType === CLASS_TYPES[0] && classGrade !== currentClass?.grade;

  return (
    <Modal width={585} open={isOpen} closable onCancel={onClose}>
      {!currentClass ? (
        <Stack justifyContent='center' style={{ minHeight: '400px' }}>
          <Spin size='large' />
        </Stack>
      ) :(
      <Stack direction="column" spacing={12}>
        <Stack>
          <Title level={3}>{t('edit_class')}</Title>
        </Stack>
        <Text size="small">{t('edit_class_detail')}</Text>
        <Form form={form} requiredMark={false} name="edit-class" onFinish={handleSubmit}>
          <Stack direction="column" spacing={12}>
            <Form.Item
              name="class_name"
              label={t('name_your_class')}
              rules={[{ required: true, message: t('add_class_name_missing') }]}>
              <Input label={t('name_your_class')} type="text" value={name} />
            </Form.Item>
            <Form.Item name="class_type" label={t('class_type')}>
              <Radio.Group onChange={handleClassTypeChange}>
                <Radio value={CLASS_TYPES[0]}>{t(CLASS_TYPES[0])}</Radio>
                <Radio value={CLASS_TYPES[1]}>
                  {t(CLASS_TYPES[1])}
                  <Tooltip color="purple" title={t('multiple_grades_tooltip')}>
                    <HelpCircleIcon style={{ marginLeft: 5 }} size={20} color={Colors.WHITE_0} fill={Colors.VIOLET} />
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {classType === CLASS_TYPES[1] ? (
              <Form.Item
                name="grades"
                validateTrigger="onSubmit"
                rules={[
                  { required: true, message: t('add_class_grade_missing') },
                  () => ({
                    validator(_, value) {
                      if (value?.length > 0 && value?.length < 2) {
                        return Promise.reject(new Error(t('add_class_select_minimum_2_grades')));
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
                label={t('grades')}>
                <Checkbox.Group>
                  {allGrades.map(gradeItem => (
                    <Checkbox value={gradeItem.id} key={gradeItem.name}>
                      {gradeItem.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            ) : (
              <Form.Item
                name="grade"
                label={t('grade')}
                rules={[{ required: true, message: t('add_class_grade_missing') }]}>
                <Select placeholder={t('grade_placeholder')} disabled={!classType}>
                  {allGrades.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {showUpdateStudentGrade && <Form.Item name="update_students_grade">
              <Checkbox className="mt-1 mb-1 ms-1 me-1" key={'updateStudentGrade'} onChange={handleUpdateStudentGrade}>
                {t('update_students_grade')}
              </Checkbox>
            </Form.Item>}
            <Form.Item>
              <Stack direction="column" style={{ paddingTop: 10 }}>
                <Button htmlType="submit" block disabled={deleting} loading={loading}>
                  {t('save')}
                </Button>
                <Button style={{ color: Colors.VIOLET }} type="text" disabled={loading} loading={deleting} onClick={deleteClass}>
                  <DeleteIcon size={20} strokeWidth={2.5} style={{ marginRight: 5 }} />
                  {t('delete_class')}
                </Button>
              </Stack>
            </Form.Item>
          </Stack>
        </Form>
      </Stack>)}
    </Modal>
  );
};
export default EditClassModal;

EditClassModal.defaultProps = {
  currentClass: null
};

EditClassModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentClass: PropTypes.object
};
