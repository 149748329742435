import React, { useEffect, useRef, useState } from 'react';
import { Empty, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Card, Link, Stack, Text, Title } from '@teacher-app/design-system';

import { usePdfs, useStudents } from '@hooks';
import { useAppState } from '@context';
import { API_URL, getApiWithAuth, sendGaEvent } from '@utils';
import { LoginInstructionsPdfs, ParentLettersPdf, StudentAccountDetailPdf } from '@teacher-app/components/pdf';

export const HelpfulLinks = () => {
  const { t } = useTranslation('translation');
  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);
  const { data: students } = useStudents(state.classId);
  const { data, loading, isEmpty } = usePdfs();
  const hasStudent = students && students.length > 0;

  const [fetchingVideo, setFetchingVideo] = useState(false);
  const [showAccountDetail, setShowAccountDetail] = useState(false);
  const [showParentLetters, setShowParentLetters] = useState(false);
  const [showLoginInstructions, setShowLoginInstructions] = useState(false);

  const [video, setVideo] = useState({
    name: '',
    thumbnail: '',
    url: ''
  });
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);

  useEffect(async () => {
    if (hasStudent) return;
    setFetchingVideo(true);
    const URL = `${API_URL.TUTORIAL_VIDEOS}/AddingStudents`;
    const { success, data } = await getApiWithAuth(URL);

    if (success) {
      if (data && data['data']) {
        setVideo({
          name: data['data']['video_title'],
          url: data['data']['video_url'],
          thumbnail: data['data']['video_thumbnail']
        });
      }
      setFetchingVideo(false);
    } else {
      message.error(data.message);
      setFetchingVideo(false);
    }
  }, [hasStudent]);

  return (
    <Card width="100%">
      {showAccountDetail && (
        <StudentAccountDetailPdf
          currentClass={currentClass}
          isOpen={showAccountDetail}
          onClose={() => {
            setShowAccountDetail(false);
          }}
        />
      )}
      {showParentLetters && (
        <ParentLettersPdf
          currentClass={currentClass}
          isOpen={showParentLetters}
          onClose={() => {
            setShowParentLetters(false);
          }}
        />
      )}

      {showLoginInstructions && (
        <LoginInstructionsPdfs
          currentClass={currentClass}
          isOpen={showLoginInstructions}
          onClose={() => {
            setShowLoginInstructions(false);
          }}
        />
      )}

      {loading || fetchingVideo ? (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      ) : isEmpty && !hasStudent ? (
        <Stack direction="column" alignItems="flex-start" spacing={30}>
          <Title level={3}>{t('overview.helpful_links')}</Title>
          <Stack direction="column" justifyContent="center" spacing={30}>
            <Text size="small" style={{ textAlign: 'center' }}>
              {t('overview.no_students')}
            </Text>
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              ref={videoRef}
              url={video?.url}
              playing={isPlaying}
              light={
                <Stack
                  style={{
                    width: '100%',
                    height: 'calc((22vw) * .5625)',
                    backgroundSize: 'contain',
                    backgroundImage: `url(${video?.thumbnail})`
                  }}>
                  {' '}
                </Stack>
              }
              onEnded={() => {
                setPlaying(false);
                sendGaEvent('video_ended', 'Video Ended', video?.name);
              }}
              onPlay={() => {
                setPlaying(true);
                sendGaEvent('video_play', 'Video Play', video?.name);
              }}
              onPause={() => {
                setPlaying(false);
                sendGaEvent('video_pause', 'Video Pause', video?.name);
              }}
            />
          </Stack>
        </Stack>
      ) : isEmpty && hasStudent ? (
        <Stack direction="column" alignItems="flex-start">
          <Title level={3}>{t('overview.helpful_links')}</Title>
          <Stack justifyContent="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('overview.no_links_found')} />
          </Stack>
        </Stack>
      ) : (
        <Stack direction="column" alignItems="flex-start" spacing={30}>
          <Title level={3}>{t('overview.helpful_links')}</Title>
          {data.parentLetter ? (
            <Stack direction="column" alignItems="flex-start">
              <Link
                onClick={() => {
                  setShowParentLetters(true);
                }}>
                {t('overview.parent_letter')}
              </Link>
              <Text size="small">{t('overview.parent_resource')}</Text>
            </Stack>
          ) : null}
          {data.accountDetails ? (
            <Stack direction="column" alignItems="flex-start">
              <Link
                onClick={() => {
                  setShowAccountDetail(true);
                }}>
                {t('overview.student_account_details')}
              </Link>
              <Text size="small">{t('students.help_students_sign_in')}</Text>
            </Stack>
          ) : null}
          {data.loginInstructions ? (
            <Stack direction="column" alignItems="flex-start">
              <Link
                onClick={() => {
                  setShowLoginInstructions(true);
                }}>
                {t('students.login_instructions_for_students')}
              </Link>
              <Text size="small">{t('students.login_credentials')}</Text>
            </Stack>
          ) : null}
        </Stack>
      )}
    </Card>
  );
};
export default HelpfulLinks;
