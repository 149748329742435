import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import colors from '../../theme/colors';

const AntTitle = Typography.Title;

const getTypographySizes = level => {
  switch (level) {
    case 1:
      return {
        fontSize: '72px !important',
        lineHeight: '79.2px !important'
      };
    case 2:
      return {
        fontSize: '42px !important',
        lineHeight: '110% !important'
      };
    case 3:
      return {
        fontSize: '28px !important',
        lineHeight: '140% !important'
      };
    case 4:
      return {
        fontSize: '20px !important',
        lineHeight: '24px !important'
      };
    case 5:
      return {
        fontSize: '16px !important',
        lineHeight: '120% !important'
      };
    default:
      return {
        fontSize: '18px !important',
        lineHeight: '110% !important'
      };
  }
};

const StyledTitle = styled(AntTitle)`
  font-size: ${props => getTypographySizes(props.level).fontSize};
  line-height: ${props => getTypographySizes(props.level).lineHeight};
  &.ant-typography {
    font-family: Catamaran, sans-serif;
    color: ${colors.GREY_200};
    margin-bottom: 0;
    text-align: left;
    font-weight: 800;
  }
  &.ant-typography-secondary {
    color: ${colors.LAVENDER_50};
  }
  &.ant-typography-danger {
    color: ${colors.CHERRY};
  }
  &.ant-typography-warning {
    color: ${colors.GOLD};
  }
  &.ant-typography-success {
    color: ${colors.SPRING};
  }
`;

const Title = ({ children, ...rest }) => {
  return <StyledTitle {...rest}>{children}</StyledTitle>;
};

export default Title;

Title.defaultProps = {
  children: '',
  type: 'primary',
  level: undefined,
  disabled: false
};

Title.propTypes = {
  children: PropTypes.node,
  /**  Disabled content */
  disabled: PropTypes.bool,
  /**  Can be set to 1, 2, 3, 4, 5. '1' is default */
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
  /**  Can be set to primary, secondary, success, warning and danger */
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'danger'])
};
