import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en_CA from '../locales/en-CA/translation.json';
import fr_CA from '../locales/fr-CA/translation.json';

import commonEn_CA from '../locales/en-CA/common.json';
import commonFr_CA from '../locales/fr-CA/common.json';
import teacherEn_CA from '../locales/en-CA/teacher.json';
import teacherFr_CA from '../locales/fr-CA/teacher.json';
import schoolAdminEn_CA from '../locales/en-CA/schoolAdmin.json';
import schoolAdminFr_CA from '../locales/fr-CA/schoolAdmin.json';
import studentEn_CA from '../locales/en-CA/student.json';
import studentFr_CA from '../locales/fr-CA/student.json';

const resources = {
  'en-CA': {
    translation: en_CA,
    common: commonEn_CA,
    teacher: teacherEn_CA,
    schoolAdmin: schoolAdminEn_CA,
    student: studentEn_CA
  },
  'fr-CA': {
    translation: fr_CA,
    common: commonFr_CA,
    teacher: teacherFr_CA,
    schoolAdmin: schoolAdminFr_CA,
    student: studentFr_CA
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-CA',
    debug: import.meta.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    load: 'currentOnly',
    ns: ['translation', 'common', 'teacher', 'schoolAdmin', 'student'],
    defaultNS: 'common',
    resources,
    detection: {
      lookupLocalStorage: 'cyberlegends_language'
    },
    supportedLngs: ['en-CA', 'fr-CA']
  });

export default i18n;
