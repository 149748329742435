import useSWR from 'swr';

export const useAssignments = classId => {
  const url = classId ? `school/class/${classId}/assignments` : null;

  const { data, error, isLoading, mutate, isValidating } = useSWR(url);

  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error,
    data: response ?? [],
    revalidate,
    isValidating
  };
};
