import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import { Title, Stack, Text, Colors, Badge } from '@parent-app/design-system';

import { getDataTestId, getMemberShipType } from '@utils';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getTopic } from '@parent-app/utils/getTopic';

const StyledCard = styled.div`
  border-radius: 15px;
  overflow: hidden;
  display: block;
  min-width: 200px;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ${Colors.WHITE_0};
`;

const StyledText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ParentResourceCard = React.memo(({ resourceItem, setShowBanner }) => {
  const { id, title, description, thumbnail, topic, allow_fremium } = resourceItem;
  const navigate = useNavigate();

  const printMessage = () => {
    setShowBanner(true);
    setTimeout(() => {
      setShowBanner(false);
    }, 3000);
  };

  const handleCardClick = () => {
    getMemberShipType() === 'Free' && !allow_fremium
      ? printMessage()
      : navigate(`/parent-resource/${id}`, {
          state: {
            itemId: id,
            isAllowFremium: allow_fremium,
            isMemberShipType: getMemberShipType(),
            title: title
          }
        });
  };

  return (
    <StyledCard onClick={handleCardClick} data-testid={getDataTestId(name)}>
      <Stack direction="row">
        <Image width={'100%'} height={150} src={thumbnail} alt="resourcesImage" preview={false} />
      </Stack>
      <Stack direction="column" alignItems="flex-start" style={{ padding: '24px 16px' }}>
        <Title ellipsis style={{ width: '100%' }}>
          {title}
        </Title>
        <StyledText size="x-small">{description}</StyledText>
        <Badge type={getTopic(topic?.text)}>{topic?.text}</Badge>
      </Stack>
    </StyledCard>
  );
});

export default ParentResourceCard;

ParentResourceCard.propTypes = {
  resourceItem: PropTypes.object.isRequired,
  setShowBanner: PropTypes.func.isRequired
};
