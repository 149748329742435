import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { EducatorButton, EducatorModal } from '../../../commonComponents';
import { API_URL, getApiWithoutAuth } from '../../../../utils';
import { useGrades } from '../../../../hooks';

export const AddCurriculumModal = ({ show, handleOnCancel, handleOnSubmit, childData, showSpinner }) => {
  const [form] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);

  const [grade, setGrade] = useState(null);
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState({});

  const { data: grades } = useGrades();

  const getProvinces = useCallback(async () => {
    const { success, data } = await getApiWithoutAuth(`${API_URL.GET_PROVINCES}?name=${country.label}`);
    if (success) {
      setProvinces(data);

      const value = {
        label: data[0]?.name,
        value: data[0]?.id
      };
      setProvince(value);
      form.setFieldsValue({ province: value });
    }
  }, [country]);

  const getCountries = useCallback(async () => {
    const { success, data } = await getApiWithoutAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);

      const value = {
        label: data[0]?.name,
        value: data[0]?.game_server_country_id
      };
      setCountry(value);
      form.setFieldsValue({ country: value });
    }
  }, []);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (country.label) {
      getProvinces(country.label);
    }
  }, [country]);

  useEffect(() => {
    if (grades.length > 0) {
      const value = {
        label: grades[0]?.name,
        value: grades[0]?.name
      };
      setGrade(value);
      form.setFieldsValue({ child_grade: value });
    }
  }, []);

  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">Add Education Details</div>
          <div className="fixCardtextStyle mt-2">
            Entering your child’s education details allows us to match our content with what they learn about in school.
          </div>
          <Form form={form} onFinish={handleOnSubmit}>
            <Form.Item name="country">
              <div className="pt-4">
                <div>
                  <label className="labelStyleCard">
                    What country does{' '}
                    {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to school
                    in?
                  </label>
                  <div className="mt-3">
                    <Select
                      className="fixCardInputField"
                      name="country"
                      value={country}
                      onChange={(value, option) => {
                        setCountry(option);
                        form.setFieldsValue({
                          province: option
                        });
                      }}
                      options={countries.map(item => {
                        return {
                          value: item.game_server_country_id,
                          label: item.name
                        };
                      })}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>

            <Form.Item name="province">
              <div className="pt-4">
                <div>
                  <label className="labelStyleCard">
                    What province does{' '}
                    {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to school
                    in?
                  </label>
                  <div className="mt-3">
                    <Select
                      className="fixCardInputField"
                      name="province"
                      value={province}
                      onChange={(value, option) => {
                        setProvince(option);
                        form.setFieldsValue({ province: option });
                      }}
                      options={provinces.map(item => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>

            <Form.Item name="child_grade">
              <div className="pt-4">
                <div>
                  <label className="labelStyleCard">
                    What grade is{' '}
                    {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} in?
                  </label>
                  <div className="mt-3">
                    <Select
                      value={grade}
                      className="fixCardInputField"
                      onChange={(value, option) => {
                        setGrade(option);
                        form.setFieldsValue({
                          child_grade: option
                        });
                      }}
                      options={grades.map(item => {
                        return {
                          value: item.name,
                          label: item.name
                        };
                      })}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
            <div className="pt-3">
              <EducatorButton label="Add Curriculum" type="submit" width="100%" showSpinner={showSpinner} />
            </div>
          </Form>
        </div>
      }
    />
  );
};

export default AddCurriculumModal;

AddCurriculumModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  childData: PropTypes.object.isRequired,
  showSpinner: PropTypes.bool.isRequired
};
