import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppState } from '@context';
import { Sidebar } from '../components/layoutComponents';
import { getFlowComplete, getLogoutUrl, removeLogoutUrl } from '@utils';
import NoSidebar from '@components/layoutComponents/NoSidebar';

export const PrivateRoute = ({ children, sidebar, marginContent }) => {
  const { state } = useAppState();
  const { isAuthenticated } = state;

  const logoutUrl = getLogoutUrl();
  const navigateTo = logoutUrl || '/';

  removeLogoutUrl();

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated && getFlowComplete()) {
    return sidebar ? (
      <Sidebar>{children ?? <Outlet />}</Sidebar>
    ) : (
      <NoSidebar marginContent={marginContent}>{children ?? <Outlet />}</NoSidebar>
    );
  }

  return <Navigate to={navigateTo} />;
};

export default PrivateRoute;

PrivateRoute.defaultProps = {
  children: null,
  sidebar: true,
  marginContent: true
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node]),
  sidebar: PropTypes.bool,
  marginContent: PropTypes.bool
};
