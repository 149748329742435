import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';

import { updateUserAttributes, setLocalStorageItem } from '@utils';
import { useAppState } from '@context';
import caFlag from '@assets/images/ca-flag.svg';
import arrowUp from '@assets/images/arrow_up.svg';

import arrowDown from '@assets/images/arrow_down.svg';
import './LanguageSwitcher.scss';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { state } = useAppState();
  const { isAuthenticated } = state;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useLayoutEffect(() => {
    dayjs.locale(i18n.language.toLowerCase());
  }, []);

  const handleLanguageChange = async newLang => {
    if (isAuthenticated) {
      await updateUserAttributes({ 'custom:preferred_language': newLang });
    }
    setLocalStorageItem('language', newLang);
    window.location.reload();
  };

  const displayLanguage = {
    'en-CA': 'EN',
    'fr-CA': 'FR'
  };

  const items = [
    {
      label: (
        <button onClick={() => handleLanguageChange('en-CA')} data-testid="enButton">
          <img className="flag" src={caFlag} alt="Canada Flag" />
          EN
        </button>
      ),
      key: 'en-CA'
    },
    {
      label: (
        <button onClick={() => handleLanguageChange('fr-CA')} data-testid="frButton">
          <img className="flag" src={caFlag} alt="Canada Flag" />
          FR
        </button>
      ),
      key: 'fr-CA'
    }
  ];

  return (
    <div className="lang-switcher__container">
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        open={isDropdownOpen}
        onOpenChange={open => setIsDropdownOpen(open)}
        dropdownRender={menu => <div className="lang-switcher__dropdown">{menu}</div>}>
        <div className="lang-switcher--current">
          <img className="flag" src={caFlag} alt="Canada Flag" />
          <span className="current-language">{displayLanguage[i18n.language]}</span>
          {isDropdownOpen ? <img src={arrowUp} /> : <img src={arrowDown} />}
        </div>
      </Dropdown>
    </div>
  );
};
