import React, { useCallback, useState } from 'react';
import { message, Skeleton } from 'antd';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { Card, Link, Stack, Text } from '@teacher-app/design-system';

import * as Modal from '@components/mainComponents/Subscriptions/modals';
import { useCards } from '@hooks';
import { API_URL, deleteApiWithAuth, getApiWithAuth, getCreditCardLogo } from '@utils';

import './BillingInfoStyle.scss';

export const BillingInfo = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [singleUser, setSingleUser] = useState({});

  const { data: cardDetails, loading, revalidate } = useCards();
  const { data: subscriptionExpiry } = useSWR(API_URL.GET_SUBSCRIPTION_EXPIRY);

  const subscriptionExpiryDate = subscriptionExpiry?.data?.expiry_date;

  const handleAddCard = async () => {
    setShowAddCardModal(false);
    revalidate();
  };

  const handleEditCard = async () => {
    setShowEditCardModal(false);
    revalidate();
  };

  const handleDeleteCard = useCallback(() => {
    setShowDeleteCardModal(false);
  }, []);

  const cardDelete = async id => {
    setShowDeleteSpinner(true);
    const { status } = await deleteApiWithAuth(`${API_URL.DELETE_CARD}/${id}`);
    if (status === 204) {
      setShowDeleteSpinner(false);
      handleDeleteCard();

      revalidate();
    } else {
      setShowDeleteSpinner(false);
    }
  };

  const openCustomerPortal = async () => {
    const { success, data } = await getApiWithAuth(API_URL.SCHOOL.GET_TEACHER_CUSTOMER_PORTAL);
    if (success) {
      if (data.url) {
        window.location.href = data.url;
      } else {
        message.error(t('customer_portal_error'));
      }
    } else {
      message.error(data.message);
    }
  };

  if (loading) {
    return (
      <Card bordered={false} className="billing-info__container cardsStyling">
        <Skeleton active />
      </Card>
    );
  }

  return (
    <>
      <Card title={t('billing_info')}>
        <div>
          {cardDetails?.length === 0 ? (
            <div>
              <Text size="small">{t('no_card_on_file')}</Text>
              <Link onClick={openCustomerPortal}>{t('add_payment_method')}</Link>
            </div>
          ) : (
            <div className="billing-info__card">
              {cardDetails?.map(item => (
                <div className="card__item" key={item.id}>
                  <Stack direction="row" spacing={10} justifyContent="space-between">
                    <Text size="small">
                      {getCreditCardLogo(item.card_type)} **** {item.last_digits} EXP{' '}
                      {dayjs(item.expiry_date).format('MM/YY')}
                    </Text>
                    <Link onClick={openCustomerPortal}>{t('update')}</Link>
                  </Stack>
                </div>
              ))}
              {subscriptionExpiryDate ? (
                <p>
                  {t('premium_membership')} {dayjs(subscriptionExpiryDate).format('MM/DD/YY')}.
                </p>
              ) : null}
            </div>
          )}
        </div>
      </Card>

      <Modal.AddCardModal show={showAddCardModal} handleOnCancel={handleAddCard} handleAddCard={handleAddCard} />

      <Modal.EditCardModal
        show={showEditCardModal}
        handleOnCancel={handleEditCard}
        singleUser={singleUser}
        setSingleUser={setSingleUser}
        handleEditCard={handleEditCard}
      />

      <Modal.DeleteCardModal
        show={showDeleteCardModal}
        handleOnCancel={handleDeleteCard}
        singleUser={singleUser}
        handleDelete={cardDelete}
        showSpinner={showDeleteSpinner}
      />
    </>
  );
};
