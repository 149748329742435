import React from 'react';
import { Pagination as AntPagination } from 'antd';
import { ChevronLeft, ChevronRight } from 'react-feather';
import styled from 'styled-components';
import Colors from '../../theme/colors';

const StyledPagination = styled(AntPagination)`
  .ant-pagination-item {
    min-width: 10px;
    border: none;
    height: 26px;
    line-height: 16px;
    display: inline-flex;
    > a {
      align-self: center;
      font-family: Catamaran, sans-serif;
      font-size: 18px;
      font-weight: 800;
      line-height: 18px;
      min-width: 10px;
      padding: 0;
      color: ${Colors.MIDNIGHT_50};
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 16px;
    height: 27px;
    display: inline-flex;
    > svg {
      align-self: center;
      color: ${Colors.VIOLET};
    }
  }
  .ant-pagination-prev,
  .ant-pagination-item {
    margin-right: 20px;
  }
  .ant-pagination-item-active {
    border-radius: 120px;
    background-color: ${Colors.MIDNIGHT};
    height: 26px;
    width: 26px;
    justify-content: center;
    > a {
      color: ${Colors.WHITE_0};
    }
  }
  .ant-pagination-disabled > svg {
    color: ${Colors.MIDNIGHT_50};
  }
`;

const itemRender = (_, type, originalElement) => {
  if (type === 'prev') {
    return <ChevronLeft size={16} strokeWidth={2.5} />;
  }
  if (type === 'next') {
    return <ChevronRight size={16} strokeWidth={2.5} />;
  }
  return originalElement;
};

const Pagination = ({ ...rest }) => {
  return <StyledPagination {...rest} itemRender={itemRender} />;
};

export default Pagination;
