import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Checkbox, Form, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField } from '@components/commonComponents';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { API_URL, getApiWithAuth, getCountryIdByName, getStateIdByName, patchApiWithAuth } from '@utils';
import { useCountries, useSchools, useStates } from '@hooks';

import { BillingInfo } from './BillingInfo';
import { PastInvoices } from './PastInvoices';
import { DeleteAccount } from './DeleteAccount';

import './SettingStyle.scss';
import useSWR from 'swr';
import { Card } from '@teacher-app/design-system';
import { Membership } from '@pages/Settings/Membership';

export const Settings = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'common' });
  const [showSpinnerEditClass, setShowSpinnerEditClass] = useState(false);
  const [showSpinnerEditUser, setShowSpinnerEditUser] = useState(false);
  const [buttonSpinnerPassword, setButtonSpinnerPassword] = useState(false);
  const [data, setData] = useState({ old_password: '', new_password: '', confirm_password: '' });
  const [userInformation, setUserInformation] = useState({});
  const [emailPreferences, setEmailPreferences] = useState({});

  const [checkUser, setCheckUser] = useState(false);
  const [checkGoogle, setCheckGoogle] = useState(false);
  const [isUpdateInfoDisabled, setIsUpdateInfoDisabled] = useState(false);
  const [schoolName, setSchoolName] = useState('');

  const { data: countries, loading: countryLoading } = useCountries();
  const { data: provinces, loading: provinceLoading } = useStates(userInformation.country);

  const { country, state } = userInformation;
  const { loading } = useSchools(
    getCountryIdByName(countries, country),
    getStateIdByName(provinces, state),
    schoolName
  );

  const editEmailPreferences = async () => {
    setShowSpinnerEditClass(true);
    const { success } = await patchApiWithAuth(API_URL.GET_EMAIL_PREFERENCES, emailPreferences);
    if (success) {
      setShowSpinnerEditClass(false);
      message.success(t('user_info_updated_successfully'));
    } else {
      setShowSpinnerEditClass(false);
      message.error(t('error_try_again'));
    }
  };

  const editUser = async () => {
    if (userInformation.state === '') {
      message.error(t('select_province'));
    } else if (userInformation.schoolid === '') {
      message.error(t('select_school'));
    } else {
      setShowSpinnerEditUser(true);
      const { success } = await patchApiWithAuth(API_URL.UPDATE_USER, userInformation);
      if (success) {
        setShowSpinnerEditUser(false);
        message.success(t('user_info_updated_successfully'));
        getUserInformation();
      } else {
        message.error(t('error_try_again'));
        setShowSpinnerEditUser(false);
      }
    }
  };

  const updatePasswordFunction = async () => {
    setButtonSpinnerPassword(true);
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      try {
        const changePassword = await Auth.changePassword(currentUser, data.old_password, data.new_password);
        if (changePassword === 'SUCCESS') {
          message.success(`${changePassword}`);
          setButtonSpinnerPassword(false);
          setData({ old_password: '', new_password: '', confirm_password: '' });
        } else {
          message.error(`${changePassword}`);
          setButtonSpinnerPassword(false);
        }
      } catch (err) {
        message.error(`${err}`);
        setButtonSpinnerPassword(false);
      }
    } else {
      setButtonSpinnerPassword(false);
    }
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const inputChangeHandler = e => {
    setIsUpdateInfoDisabled(true);
    const { name, value } = e.target;
    setUserInformation({ ...userInformation, [name]: value });
  };
  const selectCountry = countryName => {
    setIsUpdateInfoDisabled(true);
    setUserInformation({ ...userInformation, country: countryName, state: '', schoolid: '', school: {} });
  };
  const selectProvince = provinceId => {
    setIsUpdateInfoDisabled(true);
    setUserInformation({ ...userInformation, state: provinceId, schoolid: '', school: {} });
  };
  const selectSchool = ({ value, key }) => {
    setIsUpdateInfoDisabled(true);
    setUserInformation({ ...userInformation, schoolid: key, school: { id: key, school_name: value } });
  };

  const handleSearch = value => {
    setSchoolName(value);
  };

  const getUserInformation = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      const isGoogle = data.username?.includes('Google') || data.username?.includes('google');
      setCheckGoogle(isGoogle);
      setUserInformation(data);

      setCheckUser(true);

      const schoolId = data.school?.id;
      setUserInformation({ ...userInformation, schoolid: schoolId });
    }
  };

  const getEmailPreferences = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_EMAIL_PREFERENCES);
    if (success) {
      setEmailPreferences(data);
    }
  };

  useEffect(() => {
    getUserInformation();
    getEmailPreferences();
  }, []);

  useEffect(() => {
    if (checkUser) {
      setUserInformation({ ...userInformation, schoolid: userInformation.school?.id });
    }
  }, [checkUser]);

  const onChangedCheck = event => {
    const { name, checked } = event.target;
    setEmailPreferences({ ...emailPreferences, [name]: checked });
  };

  const { data: licenceInfo } = useSWR(API_URL.PAYMENTS.GET_LICENCE);

  return (
    <div className="settings__container container-fluid w-100 studentMianDivHeight">
      <AuthenticatedNavbar title={t('account_settings')} />
      <div className="row">
        <div className="row mt-4 pb-4">
          <div className="col col-7 col-sm-6 col-xl-8">
            <Card title={t('user_information')} className="mb-4">
              <Form onFinish={editUser}>
                <div className="pt-5 row">
                  <div className="col-6">
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          pattern: new RegExp(/^[A-Za-z]+$/),
                          message: commonT('must_contain_alphabets')
                        }
                      ]}>
                      <EducatorInputField
                        label={t('first_name')}
                        inputValue={userInformation.first_name}
                        type="text"
                        name="first_name"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item>
                      <EducatorInputField
                        label={t('last_name')}
                        inputValue={userInformation.last_name}
                        type="text"
                        name="last_name"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <div className="pt-3">
                    <div>
                      <label className="labelStyleCard">{t('country')}</label>
                      <div className="mt-3">
                        <Select
                          className="fixCardInputField"
                          onChange={selectCountry}
                          value={userInformation.country}
                          showArrow={false}
                          disabled
                          name="country"
                          loading={countryLoading}>
                          {/*{countries.map(item => (
                            <Option key={item.name} value={item.name}>
                              {item.name}
                            </Option>
                          ))}*/}
                        </Select>
                      </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div className="pt-3">
                    <div>
                      <label className="labelStyleCard">{t('province')}</label>
                      <div className="mt-3">
                        <Select
                          className="fixCardInputField"
                          showArrow={false}
                          onChange={selectProvince}
                          value={userInformation.state}
                          disabled
                          name="province"
                          loading={provinceLoading}>
                          {/*{provinces?.map(item => (
                            <Option key={item.name} value={item.name}>
                              {item.name}
                            </Option>
                          ))}*/}
                        </Select>
                      </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div className="pt-3">
                    <label className="labelStyleCard">{t('school')}</label>
                    <div className="mt-3">
                      <Select
                        loading={loading}
                        showArrow={false}
                        filterOption={false}
                        onSelect={selectSchool}
                        onSearch={handleSearch}
                        disabled
                        labelInValue
                        value={{
                          value: userInformation.school?.id,
                          label: userInformation.school?.school_name
                        }}
                        name="school"
                        notFoundContent={null}
                        className="fixCardInputField"
                        allowClear
                        popupClassName="school-name__search">
                        {/*{schools?.map(item => (
                          <Option key={item.id} value={item.name}>
                            <div className="d-flex flex-column">
                              <div className="school-name">{item.name}</div>
                              <div className="school-details">
                                {item.district}, {item.city}
                              </div>
                            </div>
                          </Option>
                        ))}*/}
                      </Select>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item className="pt-2">
                  <EducatorButton
                    key="submit"
                    label={t('update_information')}
                    type="submit"
                    width="100%"
                    showSpinner={showSpinnerEditUser}
                    disabled={!isUpdateInfoDisabled}
                  />
                </Form.Item>
              </Form>
            </Card>
            {(licenceInfo?.data?.organization?.type === 'HOUSE' || licenceInfo?.data?.organization === null) && (
              <div className="mb-4">
                <BillingInfo />
              </div>
            )}

            <div className="mb-4">
              <Membership licenceInfo={licenceInfo} />
            </div>

            {(licenceInfo?.data?.organization?.type === 'HOUSE' || licenceInfo?.data?.organization === null) && (
              <div className="mb-4">
                <PastInvoices />
              </div>
            )}
            <DeleteAccount />
          </div>
          <div className="col col-5 col-sm-6 col-xl-4">
            <div>
              <Card bordered={false} className="settingSecondCard cardsStyling p-4 px-sm-3 px-lg-4 px-xxl-5">
                <div className="settingHeadingStyle">{t('update_password')}</div>
                <Form onFinish={updatePasswordFunction} className="pt-4">
                  <Form.Item name="old_password" rules={[{ required: true, message: t('input_old_password') }]}>
                    <EducatorInputField
                      label={t('old_password')}
                      type="password"
                      passwordValue={data.old_password}
                      disabled={checkGoogle}
                      name="old_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="new_password"
                    rules={[
                      { required: true, message: t('input_new_password') },
                      {
                        required: true,
                        pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                        message: t('password_requirements')
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('old_password') !== value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('current_new_password_different')));
                        }
                      })
                    ]}>
                    <EducatorInputField
                      label={t('new_password')}
                      passwordValue={data.new_password}
                      disabled={checkGoogle}
                      type="password"
                      name="new_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="confirm_password"
                    dependencies={['new_password']}
                    rules={[
                      {
                        required: true,
                        message: t('confirm_password_message')
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('password_no_match')));
                        }
                      })
                    ]}>
                    <EducatorInputField
                      label={t('confirm_password')}
                      type="password"
                      name="confirm_password"
                      className="fixCardInputField"
                      disabled={checkGoogle}
                      dependencies={data.new_password}
                      passwordValue={data.confirm_password}
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item className="pt-2">
                    <EducatorButton
                      key="submit"
                      label={t('update_password')}
                      type="submit"
                      width="100%"
                      disabled={checkGoogle}
                      showSpinner={buttonSpinnerPassword}
                    />
                  </Form.Item>
                </Form>
              </Card>
              <Card bordered={false} className="settingThirdCard cardsStyling p-3 px-sm-3  px-lg-4 px-xxl-5 mt-4">
                <Form>
                  <div className="settingHeadingStyle">{t('email_preferences')}</div>
                  <div className="textStyleSetting mt-1 ">{t('choose_email_notifications')}</div>
                  <div className="displayInline">
                    <Checkbox
                      name="marketing_updates"
                      checked={emailPreferences.marketing_updates}
                      onChange={onChangedCheck}
                    />
                    &nbsp;
                    <span>{t('marketing')}</span>
                  </div>
                  <div className="displayInline">
                    <Checkbox
                      name="student_updates"
                      checked={emailPreferences.student_updates}
                      onChange={onChangedCheck}
                    />
                    &nbsp;
                    <span>{t('student_updates')}</span>
                  </div>
                  <div className="displayInline">
                    <Checkbox
                      name="planner_updates"
                      checked={emailPreferences.planner_updates}
                      onChange={onChangedCheck}
                    />
                    &nbsp;
                    <span>{t('planner_updates')}</span>
                  </div>
                  <Form.Item className="pt-2">
                    <EducatorButton
                      key="button"
                      label={t('update_preferences')}
                      type="button"
                      width="100%"
                      onClick={editEmailPreferences}
                      showSpinner={showSpinnerEditClass}
                    />
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
