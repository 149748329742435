/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { EducatorButton } from '../../commonComponents';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getApiWithAuth } from '../../../utils/api.js';
import { API_URL } from '../../../utils/constants';
import { CopyOutlined } from '@ant-design/icons';
const ChildConfirm = () => {
  const [showCopyMessage, setShowCopyMessage] = useState({ value: false });
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const childCreate = () => {
    navigate('/child-where-play');
  };

  const getLoginDetails = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CHILD_DETAILS);
    if (success) {
      setData(data);
    }
  };

  useEffect(() => {
    getLoginDetails();
  }, []);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          {showCopyMessage.value ? (
            <div className="yellowTitle container copyMessage">
              <p className="copyMessage__text">{showCopyMessage.text} Copied to Clipboard!</p>
            </div>
          ) : null}
          <div className="childHaddingStyle">You’re almost there!</div>
          <div className="childTextStyle ">
            This new account has been successfully created! Below are the login details. Write them down or store them
            in a secure password manager
          </div>
          <div className="mt-1">
            <Form onFinish={childCreate}>
              <div className="mt-3 childSecondText">Your Username: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{data?.username ? data?.username : 'Your UserName'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(data?.username);
                    setShowCopyMessage({ value: true, text: 'Username' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="mt-3 childSecondText">Your Password: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{data?.password ? data?.password : 'Your Password'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(data?.password);
                    setShowCopyMessage({ value: true, text: 'Password' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="py-3">
                <EducatorButton label="Start Playing" type="submit" width="100%" />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildConfirm;
