/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { patchApiWithAuth, getApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import { useLocation } from 'react-router';

const ChildParentConnect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { student_id } = location.state || {};
  const [userData, setUserData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const checkUserInfo = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      const check = data.username?.includes('Google') || data.username?.includes('google');
      if (check) {
        updateFlow();
      } else {
        navigate('/child-create-account/confirm-account');
      }
    }
  };
  const updateFlow = async () => {
    const { success } = await patchApiWithAuth(API_URL.UPDATE_USER_AUTH, { is_auth: true });
    if (success) {
      setButtonSpinner(false);
      navigate('/child-where-play');
    }
  };
  const parentConnect = async () => {
    setButtonSpinner(true);

    const { success, data } = await patchApiWithAuth(API_URL.USER_LINK_PARENT, userData);
    if (success) {
      if (data.parent_linked) {
        message.success(`${data.message}`);
      } else {
        message.info('Invite link has been sent to your parent.');
      }
      setButtonSpinner(false);
      checkUserInfo();
    } else {
      setButtonSpinner(false);
      message.error(`${data.message}`);
    }
  };
  useEffect(() => {
    setUserData({ ...userData, student_id: student_id });
  }, [student_id]);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childHaddingStyle">Add Parent</div>
          <div className="childTextStyle">Before you can play, we’ll need you to add a parent to your account.</div>
          <div className="mt-3">
            <Form onFinish={parentConnect}>
              <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                <EducatorInputField
                  label="Parent's Email:"
                  type="email"
                  inputValue={userData.email}
                  headerColor="#000000"
                  name="email"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="py-3">
                <EducatorButton label="Create Account" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildParentConnect;
