import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Upload } from 'antd';
import { Button, Modal, Stack, Text, Title } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import { useAppState } from '@context';
import { API_URL, postApiWithAuth } from '@utils';

const UploadStudentsModal = ({ isOpen, onClose, handleOnFinish }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { state } = useAppState();

  const [readyToUpload, setReadyToUpload] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleUpload = useCallback(async file => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await postApiWithAuth(`${API_URL.ADD_STUDENTS_V2}${state.classId}/students/create`, formData);
    setUploading(false);

    if (data.success) {
      message.success(t('students_added_success'));
      handleOnFinish();
    } else {
      message.error(data.message);
    }
  }, []);

  const props = {
    beforeUpload: file => {
      setReadyToUpload(false);
      const isCsv = file.type === 'text/csv';
      if (!isCsv) {
        message.error(t('only_csv_upload'));
      } else {
        setReadyToUpload(true);
      }
      return false;
    },
    onChange: async info => {
      if (readyToUpload) {
        await handleUpload(info.file);
      }
    },
    maxCount: 1,
    showUploadList: false
  };

  return (
    <Modal centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" spacing={22}>
        <Stack alignItems='flex-start' direction="column" spacing={12}>
          <Title level={3}>{t('import_students')}</Title>
          <Text size='small'>{t('import_students_step1')}</Text>
          <Text size='small'>{t('import_students_step2')}</Text>
          <Text size='small'>{t('import_students_step3')}</Text>
        </Stack>
        <Stack justifyContent="center">
          <Button
            style={{ width: '50%'}}
            type="secondary"
            onClick={() => {
              window.location.href =
                'https://s3.amazonaws.com/resources.cyberlegends.com/Dashboard+Static+Pdf+files/students_upload_template.csv';
            }}>
            {t('download_template')}
          </Button>
          <Upload {...props}>
            <Button style={{ minWidth: 225 }} loading={uploading}>{t('upload_csv')}</Button>
          </Upload>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default UploadStudentsModal;

UploadStudentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOnFinish: PropTypes.func.isRequired
};
