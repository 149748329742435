import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@teacher-app/design-system';
import { Modal } from 'antd';

import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import catamaranRegular from '@assets/fonts/Catamaran-Regular.ttf';
import catamaranBold from '@assets/fonts/Catamaran-Bold.ttf';
import lexendRegular from '@assets/fonts/Lexend-Regular.ttf';
import lexendBold from '@assets/fonts/Lexend-Bold.ttf';

import logo from '@assets/images/new_logo.png';
import assessmentArt from '@assets/images/assessment_pdf_art.png';

Font.register({
  family: 'Catamaran',
  fonts: [
    {
      src: catamaranRegular,
      fontWeight: 'normal'
    },
    {
      src: catamaranBold,
      fontWeight: 'bold'
    }
  ]
});

Font.register({
  family: 'Lexend',
  fonts: [
    {
      src: lexendRegular,
      fontWeight: 'normal'
    },
    {
      src: lexendBold,
      fontWeight: 'bold'
    }
  ]
});

const answerListItemStyles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    marginBottom: 3
  },
  bulletPoint: {
    width: 20,
    marginLeft: 20,
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%'
  },
  itemContent: {
    flex: 1,
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    color: '#3B3D3C'
  },
  isCorrect: {
    color: '#AC2216',
    fontWeight: '800'
  },
  isWrong: {
    color: '#3B3D3C'
  }
});

export const AnswerListItem = ({ children, index, isCorrect }) => (
  <View style={answerListItemStyles.listItem}>
    <Text
      style={[
        answerListItemStyles.bulletPoint,
        isCorrect ? answerListItemStyles.isCorrect : answerListItemStyles.isWrong
      ]}>
      {index + 1}.
    </Text>
    <Text
      style={[
        answerListItemStyles.itemContent,
        isCorrect ? answerListItemStyles.isCorrect : answerListItemStyles.isWrong
      ]}>
      {children}
    </Text>
  </View>
);

AnswerListItem.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool.isRequired
};

const goalListItemStyles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    marginBottom: 3
  },
  bulletPoint: {
    width: 20,
    marginLeft: 5,
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    color: '#49501A'
  },
  itemContent: {
    flex: 1,
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    color: '#49501A'
  }
});

export const GoalListItem = ({ children, index }) => (
  <View style={answerListItemStyles.listItem}>
    <Text style={goalListItemStyles.bulletPoint}>{index + 1}.</Text>
    <Text style={goalListItemStyles.itemContent}>{children}</Text>
  </View>
);

GoalListItem.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired
};

export const styles = StyleSheet.create({
  page: {
    paddingTop: 70,
    paddingBottom: 70,
    paddingLeft: 50,
    paddingRight: 50
  },
  header: {
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
    alignItems: 'center',
    columnGap: 10
  },
  logo: {
    height: 52,
    width: 100
  },
  logoLine: {
    width: 1,
    height: 52,
    backgroundColor: '#CCC8C7'
  },
  textHeader: {
    flexDirection: 'column',
    columnGap: 5,
    height: 52
  },
  cyberAcademy: {
    fontSize: '14px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    color: '#222'
  },
  topicName: {
    fontSize: '24px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#222'
  },
  learningGoalContainer: {
    marginTop: 8,
    marginBottom: 8,
    padding: 20,
    borderRadius: 10,
    backgroundColor: '#FFFFDD',
    borderWidth: 1,
    borderColor: '#768451',
    borderStyle: 'solid'
  },
  learningGoalText: {
    fontSize: '16px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#33390D',
    marginBottom: 5
  },
  questionContainer: {
    marginTop: 8,
    marginBottom: 8
  },
  question: {
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  questionText: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#3B3D3C'
  },
  questionGoal: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    paddingLeft: 10,
    color: '#3B3D3C'
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 5,
    fontSize: 12,
    width: '100%',
    bottom: 50,
    paddingLeft: 50,
    paddingRight: 50,
    left: 0
  },
  footerHelp: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    color: '#3B3D3C'
  },
  footerEmail: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '150%',
    color: '#674EFF'
  },
  footerPage: {
    position: 'absolute',
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    bottom: 50,
    right: 50,
    color: '#8E8E8E'
  },
  assessmentArt: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 270,
    zIndex: 10
  }
});

export const AssignmentQuestionsPdfModal = ({ isOpen, onClose, topic, grade, assessmentId }) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  const assessment = topic.assessments.find(assessment => assessment.id === assessmentId);
  const learningGoals = [...new Set(assessment.questions.map(item => item.lesson.learning_goal))];
  const questions = assessment.questions.map(item => {
    return {
      text: item.question.question,
      choices: item.choices.map(choice => ({
        isCorrect: item.answers.some(answer => answer.text === choice.text),
        ...choice
      })),
      learningGoalIndex: learningGoals.findIndex(goal => goal === item.lesson.learning_goal)
    };
  });

  return (
    <Modal
      width="80vw"
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      open={isOpen}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}>
      <Stack direction="column" alignItems="flex-start" spacing={12}>
        <PDFViewer style={{ width: '100%', height: '90vh' }}>
          <Document title={`${topic?.name} - ${formattedDate}`}>
            <Page size="A4" style={styles.page}>
              <Image style={styles.assessmentArt} src={assessmentArt} />
              <View style={styles.header}>
                <View>
                  <Image style={styles.logo} src={logo} />
                </View>
                <Text style={styles.logoLine} />
                <View style={styles.textHeader}>
                  <Text style={styles.cyberAcademy}>Cyber Academy Assessment</Text>
                  <Text style={styles.topicName}>
                    {topic?.name} - {grade?.name}
                  </Text>
                </View>
              </View>
              <View style={styles.learningGoalContainer}>
                <Text style={styles.learningGoalText}>Learning Goals</Text>
                {learningGoals.map((learningGoal, index) => (
                  <GoalListItem key={index} index={index}>
                    {learningGoal}
                  </GoalListItem>
                ))}
              </View>
              {questions.map((question, index) => (
                <View key={index} style={styles.questionContainer} wrap={false}>
                  <Text style={styles.question}>
                    <Text style={styles.questionText}>{question.text}</Text>
                    <Text style={styles.questionGoal}>(Learning Goal {question.learningGoalIndex + 1})</Text>
                  </Text>

                  {question.choices.map((choice, i) => (
                    <AnswerListItem key={i} index={i} isCorrect={choice.isCorrect}>
                      {choice.text}
                    </AnswerListItem>
                  ))}
                </View>
              ))}
              <View fixed style={styles.footer}>
                <Text style={styles.footerHelp}>Need help? Email us at</Text>
                <Text style={styles.footerEmail}>support@cyberlegends.com</Text>
              </View>
              <Text
                fixed
                style={styles.footerPage}
                render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
              />
            </Page>
          </Document>
        </PDFViewer>
      </Stack>
    </Modal>
  );
};

AssignmentQuestionsPdfModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  topic: PropTypes.object.isRequired,
  grade: PropTypes.object.isRequired,
  assessmentId: PropTypes.number.isRequired
};
