import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Colors from '../../theme/colors';

const StyledDiv = styled.div`
  border: 3px solid ${Colors.GOLD_100};
  width: ${props => props.width};
  border-radius: 40px;
  padding: 7px;
  overflow: hidden;
  background-color: ${Colors.GOLD_50};
`;

const InnerDiv = styled.div`
  width: 100%;
  border: 2.5px solid ${Colors.GOLD_100};
  border-radius: 30px;
  overflow: hidden;
  background: linear-gradient(180deg, #fffbf4 31.86%, #fff2e7 100%);
  box-shadow: 8px 8px 0 0 #0000001a inset;
  padding: ${props => props.frameWithShadow ? '50px 30px 30px 30px': '0'} ;
`;

const Frame = ({ children, width, ...rest }) => {
  return (
    <StyledDiv width={width}>
      <InnerDiv {...rest}>{children}</InnerDiv>
    </StyledDiv>
  );
};

export default Frame;

Frame.defaultProps = {
  width: '100%',
  frameWithShadow: true
};

Frame.propTypes = {
  children: PropTypes.node.isRequired,
  /** Width of the Frame */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Enable to show Frame with shadow and padding */
  frameWithShadow: PropTypes.bool,
};
