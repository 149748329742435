import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Stack from '../Stack/Stack';
import Text from '../Typography/Text';
import colors from '../../theme/colors';
import { getColor, EventTypes } from './utils';

const StyledColor = styled.div`
  padding-left: 10px;
  min-height: 73px;
  height: 100%;
  background-color: ${props => props.backgroundcolor};
`;

const StyledCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  display: block;
  min-height: 73px;
  width: 100%;
  align-items: flex-start;
  cursor: pointer;
  padding: 0;
  padding-right: 22px;
  box-shadow: 0 0 10px 0 #0000001a;
  background-color: ${colors.WHITE_0};
`;

const AgendaEventComponent = React.memo(({ event }) => {
  const { lesson_name, topic_name, duration, start, isEvent, title } = event;
  const backgroundColor = getColor(isEvent, event?.sub_heading?.name);
  return (
    <StyledCard>
      <Stack spacing={20}>
        <StyledColor backgroundcolor={backgroundColor} />
        <Stack justifyContent="space-between">
          <Stack spacing={0} direction="column" alignItems="flex-start" style={{ padding: '14px 0' }}>
            <Text size="small" bold ellipsis>
              {isEvent === EventTypes.HOLIDAY ? title : topic_name}
            </Text>
            <Text size="x-small" ellipsis>
              {lesson_name}
            </Text>
          </Stack>
          <Stack spacing={0} direction="column" alignItems="flex-end" style={{ padding: '14px 0' }}>
            <Text size="small" bold ellipsis>
              {dayjs(start).format('dddd, MMMM D, YYYY')}
            </Text>
            <Text size="x-small" ellipsis>
              {duration}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </StyledCard>
  );
});

export default AgendaEventComponent;

AgendaEventComponent.propTypes = {
  event: PropTypes.object.isRequired
};
