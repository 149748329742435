import React from 'react';
import { Empty, Spin } from 'antd';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { API_URL } from '@utils';
import { EducatorButton } from '@components/commonComponents';
import { AssignmentCard, StatusNotification } from '@components/studentDashboardComponent';

const isDueSoon = dueDate => dayjs(dueDate).diff(dayjs(), 'day') <= 3;

export const AssignmentModal = ({ onDone, assignment }) => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  const { data: assignmentDetailData, error: assignmentDetailError } = useSWR(
    assignment ? `${API_URL.GET_STUDENT_ASSIGNMENTS_LIST}/${assignment.assignment_id}` : null
  );
  const { data: assignmentProgressData, error: assignmentProgressError } = useSWR(
    assignment ? `${API_URL.GET_STUDENT_ASSIGNMENTS_LIST}/${assignment.assignment_id}/progress` : null
  );

  const assignmentDetail = assignmentDetailData?.data;
  const assignmentProgress = assignmentProgressData?.data;

  const isLoading = !assignmentDetail || !assignmentProgress || assignmentDetailError || assignmentProgressError;

  const status = isDueSoon(assignmentDetail?.end_date) ? 'Due Soon' : assignmentDetail?.status?.name;

  return (
    <div className="container-fluid modal__container">
      {isLoading ? (
        <div className="emptyMessageStudent">
          <Spin size="large" />
        </div>
      ) : !assignmentDetail || !assignmentProgress ? (
        <div className="emptyMessageStudent">
          <Empty description={false} />
        </div>
      ) : (
        <div className="childWhiteBackgroung py-4">
          <div className="childSecondHaddingStyle">{assignmentDetail.name}</div>
          <div className="d-flex gap-3 mt-5">
            <div>
              <AssignmentCard
                title=""
                isProgress={false}
                isCompleted={false}
                topicName=""
                onClick={() => {}}
                status={status}
              />
            </div>
            <div>
              <div>
                <StatusNotification
                  endDate={status === 'Due Soon' ? assignmentDetail?.end_date : null}
                  status={assignment.status.name}
                  text={`${
                    assignmentProgress.missions.filter(mission => mission.status && mission.status.name === 'Completed')
                      .length +
                    assignmentProgress.assessments.filter(
                      assessment => assessment.status && assessment.status.name === 'Completed'
                    ).length
                  }/${assignmentDetail.missions.length + assignmentDetail.assessments.length} ${t('tasks_completed')}`}
                />
              </div>
              <p className="description">{t('mission_assigned')}</p>
            </div>
          </div>
          <div className="mt-3">
            <div className="py-3 d-flex justify-content-center">
              <EducatorButton label={t('play_assignment')} onClick={onDone} type="submit" width={'auto'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AssignmentModal.defaultProps = {
  assignment: null
};

AssignmentModal.propTypes = {
  onDone: PropTypes.func.isRequired,
  assignment: PropTypes.object
};
