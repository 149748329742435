import useSWR from 'swr';
import { API_URL, postApiWithAuth } from '@utils';

export const useReferrals = () => {
  const { data, mutate, error, isLoading } = useSWR(API_URL.REFERRALS.GET_REFERRALS);
  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  const createReferral = async email => {
    return await postApiWithAuth(API_URL.REFERRALS.CREATE_REFERRAL, { email });
  };

  return {
    loading: isLoading,
    error: error,
    data: response ?? [],
    revalidate,
    createReferral
  };
};
