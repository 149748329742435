import { Form as AntForm } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../../theme/colors';
import PropTypes from 'prop-types';

const StyledForm = styled(AntForm)`
  width: 100%;
  .ant-form-item {
    width: 100%;
  }
  input[type='text']:not(.addParentInputStyle).ant-input-lg {
    font-size: 40px !important;
    font-weight: 600 !important;
    height: 90px;
    text-align: center;
    border: 4px solid #c7af9b;
    box-shadow: 5px 5px 0 0 #dedede inset;
  }
  input[type='text']:not(.addParentInputStyle) {
    padding: 15px 30px !important;
    color: ${colors.GREY_100};
    font-family: Lexend, sans-serif;
    font-style: normal;
    font-weight: 400 !important;
  }
  .ant-input-password {
    height: 57px;
    border-radius: 30px;
    padding: 12px 30px !important;
    border: 3px solid #c7af9b;
    box-shadow: 2px 2px 0 0 #dedede inset;
    input[type='text']:not(.addParentInputStyle),
    .ant-input {
      border: none;
      padding: 0 !important;
      padding-left: 5px !important;
      box-shadow: none;
      height: 27px;
      min-height: 27px;
    }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 3px solid #c7af9b;
  }
  .ant-input-suffix > span {
    padding-right: 0 !important;
  }
  .ant-form-item-label > label {
    font-family: Catamaran, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    color: ${colors.GREY_200};
  }
  .ant-input,
  .ant-picker {
    width: 100%;
    color: ${colors.GREY_100};
    font-family: Lexend, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    height: 57px;
    padding: 15px 30px !important;
    min-height: 40px;
    border-radius: 30px;
    border: 3px solid #c7af9b;
    box-shadow: 2px 2px 0 0 #dedede inset;
  }
  .ant-input-disabled &::placeholder {
    color: ${colors.GREY_100};
  }
  .ant-input[disabled] {
    color: ${colors.GREY_100};
    background-color: ${colors.WHITE_200};
    border-color: ${colors.GREY_0};
  }
  input.ant-input-disabled::-webkit-input-placeholder {
    color: ${colors.GREY_100} !important;
  }
`;

const Form = ({ children, ...rest }) => {
  return <StyledForm {...rest}>{children}</StyledForm>;
};

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.ErrorList = AntForm.ErrorList;
Form.useForm = AntForm.useForm;
Form.useFormInstance = AntForm.useFormInstance;
Form.useWatch = AntForm.useWatch;
Form.Provider = AntForm.Provider;

export default Form;

Form.defaultProps = {
  layout: 'vertical'
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  /** Form layout */
  layout: PropTypes.oneOf(['horizontal', 'vertical', 'inline'])
};
