import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Image, Input, message } from 'antd';
import { Auth } from 'aws-amplify';
import {
  API_URL,
  clearStorage,
  getApiWithAuth,
  getLocalStorageItem,
  setFlowComplete,
  setToken,
  setUserID
} from '@utils';
import { Button, Colors, Form, Frame, Stack, Text, Title } from '@student-app/design-system';
import LoginNavBar from '../../components/LoginNavBar';
import HelperBotLogo from '@assets/images/cyber-academy/helperbot.png';
import { useTranslation } from 'react-i18next';

const StudentSignIn = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();

  const [data, setData] = useState({ username: '', password: '' });
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const signInStudent = () => {
    setButtonSpinner(true);
    Auth.signIn({
      username: data.username,
      password: data.password
    })
      .then(() => {
        saveStudentToken();
      })
      .catch(err => {
        message.error(`${err.message}`);
        setButtonSpinner(false);
      });
  };
  const getCurrentUser = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      return data;
    }
  };

  const logoutUser = async () => {
    await Auth.signOut()
      .then(() => {
        setButtonSpinner(false);
        clearStorage();
        message.error('This account does not exist as a student');
      })
      .catch(err => {
        setButtonSpinner(false);
        message.error(`${err.message}`);
      });
  };

  const saveStudentToken = async () => {
    await Auth.currentSession()
      .then(async data => {
        setToken(data.accessToken.jwtToken);
        const responseData = await getCurrentUser();
        if (responseData.type !== getLocalStorageItem('user_role')) {
          logoutUser();
        } else {
          setUserID(responseData.id);
          if (responseData.is_auth) {
            setFlowComplete(true);
            setButtonSpinner(false);
          } else {
            setFlowComplete(false);
            setButtonSpinner(false);
            message.error('Something Went Wrong!');
          }
        }
      })
      .catch(() => {
        navigate('/student-signin');
      });
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <Stack
      direction="column"
      spacing={5}
      style={{
        minHeight: '100vh',
        overflowY: 'auto',
        background: 'radial-gradient(38.22% 59.56% at 62.73% 50%, #D2AE41 0%, #31247C 95.08%)'
      }}>
      <LoginNavBar />
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{ maxWidth: 1000, paddingBottom: 20, minHeight: 'calc(100vh - 97px)' }}
        spacing={0}>
        <Col xs={0} sm={0} md={0} lg={8} style={{ maxWidth: 401, marginRight: -15 }}>
          <Stack direction="column" alignItems="flex-end">
            <Image className="hidden-sm-down" src={HelperBotLogo} preview={false} width={356} height={500} />
          </Stack>
        </Col>
        <Stack style={{ minWidth: 350, maxWidth: 620 }}>
          <Frame>
            <Stack direction="column" style={{ padding: '30px 20px' }} spacing={40}>
              <Stack direction="column">
                <Title level={2}>{t('student_login_title')}</Title>
                <Text style={{ color: Colors.GREY_100, textAlign: 'center' }}>{t('student_login_description')}</Text>
              </Stack>
              <Form onFinish={signInStudent}>
                <Stack direction="column" spacing={40}>
                  <Form.Item label={t('username')} rules={[{ required: true, message: 'Please input your Username!' }]}>
                    <Input name="username" value={data.username} onChange={onChangeHandle} />
                  </Form.Item>
                  <Form.Item
                    label={t('password')}
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input.Password type="password" value={data.password} name="password" onChange={onChangeHandle} />
                  </Form.Item>
                  <Stack direction="column">
                    <Button style={{ minWidth: 101 }} htmlType="submit" loading={buttonSpinner}>
                      {t('sign_in')}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            </Stack>
          </Frame>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default StudentSignIn;
