import React from 'react';
import PropTypes from 'prop-types';
import BackgroundColor from '../BackgroundColor';

import premium from '../../../assets/images/Premium.svg';

import './parentResourceCard.scss';

const ParentResourceCard = ({ image, grade, title, description, onClick, topic, disabled }) => {
  return (
    <div className="parent-resources__card" onClick={onClick}>
      <div className="parentResourcesMainStyle pb-3">
        <div className="parentResourcesStyle">
          <div className="">
            <img src={image} alt="resourcesImage" className="parentWhiteColorTextStyle" />
          </div>
        </div>
        <div className={'px-3 pt-4'}>
          <div className="premiumMainDivStyle pb-3">
            <div className="parentResoucesMainHadding">{title}</div>
            {disabled ? (
              <div className="premiumOuterDivStyle">
                <div className="premiumInnerDivStyle">
                  <img src={premium} alt="premium" className="premiumImageStyle" />
                  <div className="assignmentInnerCardDate">Premium</div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="parentResoucesTextHadding">{description}</div>
          <div className="spaceBetweenResouces ">
            <div className="me-2 pt-3">
              <BackgroundColor title={grade} background="#D6E0F9" textColor="#222222" />
            </div>
            <div className="pt-3">
              <BackgroundColor title={topic.text} background={topic.color} textColor="#222222" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentResourceCard;
ParentResourceCard.defaultProps = {
  disabled: false
};
ParentResourceCard.propTypes = {
  image: PropTypes.any.isRequired,
  grade: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  topic: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};
