import useSWR from 'swr';
import { API_URL } from '@utils';

export const useSchools = (countryId, stateId, schoolName) => {
  const isSchoolSearchable = schoolName && countryId && stateId;
  const { data, isLoading, error, mutate } = useSWR(
    isSchoolSearchable ? `${API_URL.GET_SCHOOLS}?q=${schoolName}&country_id=${countryId}&state_id=${stateId}` : null
  );

  const response = data?.data || [];

  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error,
    data: response,
    revalidate
  };
};
