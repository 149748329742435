import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const titleMap = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/teacher-signin',
    title: 'Teacher: Sign In'
  },
  {
    path: '/auth',
    title: 'Teacher: Sign In'
  },
  {
    path: '/teacher-create-account',
    title: 'Teacher: Create Account'
  },
  {
    path: '/create-account-landing',
    title: 'Create Account'
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password'
  },
  {
    path: '/reset-password',
    title: 'Reset Password'
  },
  {
    path: '/teacher-create-account/email-confirmation',
    title: 'Teacher: Email Confirmation'
  },
  {
    path: '/teacher-create-account/curriculum-setup',
    title: 'Teacher: Curriculum Setup'
  },
  {
    path: '/teacher-create-account/school-setup',
    title: 'Teacher: School Setup'
  },
  {
    path: '/teacher-create-account/create-school',
    title: 'Teacher: Create School'
  },
  {
    path: '/dashboard',
    title: 'Dashboard'
  },
  {
    path: '/parent-dashboard',
    title: 'Parent: Dashboard'
  },
  {
    path: '/teacher-dashboard',
    title: 'Teacher: Dashboard'
  },
  {
    path: '/reporting',
    title: 'Teacher: Reporting'
  },
  {
    path: '/students',
    title: 'Teacher: Students'
  },
  {
    path: '/resources',
    title: 'Teacher: Resources'
  },
  {
    path: '/settings',
    title: 'Teacher: Settings'
  },
  {
    path: '/assignments',
    title: 'Teacher: Assignments'
  },
  {
    path: '/student-comprehension',
    title: 'Teacher: Student Comprehension'
  },
  {
    path: '/referrals-credits',
    title: 'Teacher: Referrals'
  },
  {
    path: '/parent-signin',
    title: 'Parent: Sign In'
  },
  {
    path: '/parent-create-account',
    title: 'Parent: Create Account'
  },
  {
    path: '/parent-create-account/email-confirmation',
    title: 'Parent: Email Confirmation'
  },
  {
    path: '/parent-create-account/child-setup',
    title: 'Parent: Child Setup'
  },
  {
    path: '/parent-create-account/add-child',
    title: 'Parent: Add Child'
  },
  {
    path: '/parent-create-account/connect-child',
    title: 'Parent: Connect Child'
  },
  {
    path: '/parent-create-account/curriculum-setup',
    title: 'Parent: Curriculum Setup'
  },
  {
    path: '/parent-create-account/additional-child',
    title: 'Parent: Additional Child'
  },
  {
    path: '/parent-create-account/select-plan',
    title: 'Parent: Select Plan'
  },
  {
    path: '/parent-create-account/payment-setup',
    title: 'Parent: Payment Setup'
  },
  {
    path: '/parent-create-account/payment',
    title: 'Parent: Payment'
  },
  {
    path: '/parent-create-account/payment-confirm',
    title: 'Parent: Payment Confirm'
  },
  {
    path: '/referrals',
    title: 'Parent: Referrals'
  },
  {
    path: '/activities',
    title: 'Parent: Activities'
  },
  {
    path: '/learning',
    title: 'Parent: Learning'
  },
  {
    path: '/parent-resource',
    title: 'Parent: Resource'
  },
  {
    path: '/parent-child-comprehension-report',
    title: 'Parent: Child Comprehension Report'
  },
  {
    path: '/parent-child-weekly-usage-report',
    title: 'Parent: Child Weekly Usage Report'
  },
  {
    path: '/parent-child-customize-learning-path',
    title: 'Parent: Child Customize Learning Path'
  },
  {
    path: '/parent-setting',
    title: 'Parent: Setting'
  },
  {
    path: '/memberships-and-billing',
    title: 'Parent: Membership and Billing'
  },
  {
    path: '/manage-subscription',
    title: 'Parent: Manage Subscription'
  },
  {
    path: '/make-payment',
    title: 'Parent: Make Payment'
  },
  {
    path: '/update-card',
    title: 'Parent: Update Card'
  },
  {
    path: '/edit-account',
    title: 'Parent: Edit Account'
  },
  {
    path: '/parent-cancel-membership',
    title: 'Parent: Cancel Membership'
  },
  {
    path: '/downgrade-subscription-confirm',
    title: 'Parent: Downgrade Subscription Confirm'
  },
  {
    path: '/child-account',
    title: 'Child Account'
  },
  {
    path: '/child-signin',
    title: 'Child Sign In'
  },
  {
    path: '/child-create-account',
    title: 'Create Child Account'
  },
  {
    path: '/child-create-account/parent-connect',
    title: 'Parent Connect'
  },
  {
    path: '/child-create-account/confirm-account',
    title: 'Confirm Account'
  },
  {
    path: '/child-where-play',
    title: 'Where Play'
  },
  {
    path: '/child-create-account/parent-connect',
    title: 'Parent Connect'
  },
  {
    path: '/child-create-account/link-parent',
    title: 'Link Parent'
  },
  {
    path: '/child-create-account/class-not-exist',
    title: 'Class Not Exist'
  },
  {
    path: '/child-create-account/info-update',
    title: 'Info Update'
  },
  {
    path: '/child-create-account/update-class',
    title: 'Update Class'
  }
];

export const useDocumentTitle = () => {
  const location = useLocation();
  const [title, setTitle] = useState('');

  useEffect(() => {
    const item = titleMap.find(item => item.path === location.pathname);

    setTitle(item ? item.title : '');
  }, [location]);

  return { title };
};
