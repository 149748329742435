import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Lock as LockIcon,
  Unlock as UnlockIcon,
  Folder as FolderIcon,
  Paperclip as PaperclipIcon,
  Check as CheckIcon,
  Video as VideoIcon,
  VideoOff as VideoOffIcon,
  HelpCircle as HelpCircleIcon
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { message, Image } from 'antd';
import { Title, Stack, Text, Button, Badge } from '@teacher-app/design-system';

import { getDataTestId, sendGaEvent } from '@utils';
import { useModal } from '@context/modalContext';
import { UnlockModal } from '@pages/TeachingResources/UnlockModal';
import { ReferTeacherModal } from '@pages/TeachingResources/ReferTeacherModal';
import AlignmentDetailsModal from '../AlignmentDetailsModal';
import TeachingResourcesModal from '../TeachingResourceModal';
import { useCredits } from '@hooks';

import CreditIcon from '@assets/images/credit.svg';
import styled from 'styled-components';
import colors from '../../design-system/theme/colors';
import { getTopic } from '../../utils/getTopic';
import { useAppState } from '@context';

const StyledCard = styled.div`
  border-radius: 15px;
  overflow: hidden;
  display: block;
  min-width: 100px;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ${colors.WHITE_0};
  & .resource-thumbnail:hover {
    .lock-icon {
      display: none;
    }
    .unlock-button {
      display: block;
    }
  }
  & .resource-thumbnail {
    .unlock-button {
      display: none;
    }
  }
`;

const ResourceCard = React.memo(({ resourceItem }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const {
    id,
    name,
    lesson_count,
    thumbnail,
    sub_heading,
    is_locked,
    product_id,
    has_videos,
    resources_overview,
    curriculum_details,
    is_sensitive
  } = resourceItem;
  const { setModalContent } = useModal();
  const [searchParams] = useSearchParams();
  const { state } = useAppState();
  const { data: credits, redeemProducts } = useCredits();
  const navigate = useNavigate();
  const [showTeachingResources, setShowTeachingResources] = useState(false);
  const [showAlignmentDetails, setShowAlignmentDetails] = useState(false);
  const currentClass = state.classes.find(item => item.id === state.classId);

  const dataGrade = searchParams.get('grade') ?? currentClass?.grade ?? 'Grade 1';

  const handleResourceCardClick = () => {
    if (!is_locked) {
      sendGaEvent('resource_card_click', 'Resource Card Click', name);

      navigate(`/resources/${id}?grade=${dataGrade}`, {
        state: {
          itemId: id,
          title: name
        }
      });
    }
  };

  const handleUnlock = () => {
    if (is_locked) {
      if (credits.available >= 1) {
        setModalContent(
          <UnlockModal
            show
            handleOnCancel={() => setModalContent(null)}
            handleOnClick={async () => {
              const { success, data } = await redeemProducts([product_id]);
              if (success) {
                setModalContent(null);
                navigate(`/resources/${id}?grade=${dataGrade}`, {
                  state: {
                    itemId: id,
                    title: name
                  }
                });
              } else {
                message.error(data.message);
              }
            }}
          />
        );
        return;
      }

      setModalContent(<ReferTeacherModal show handleOnCancel={() => setModalContent(null)} handleOnClick={() => {}} />);
    }
  };

  return (
    <>
      <StyledCard onClick={handleResourceCardClick} data-testid={getDataTestId(name)}>
        <Stack direction="row" className="position-relative resource-thumbnail">
          <Image width={'100%'} height={150} src={thumbnail} alt="resourcesImage" preview={false} />
          <Stack
            display={is_locked ? 'flex' : 'none'}
            justifyContent="center"
            style={{ background: 'rgba(38, 38, 41, 0.70)' }}
            className="position-absolute h-100">
            <LockIcon className="lock-icon" color={'#CCC8C7'} size={82} />
            <Button data-testid="unlockButton" className="unlock-button" onClick={handleUnlock}>
              {t('unlock_with')} <img src={CreditIcon} alt="credit" width="19" height="18" />
            </Button>
          </Stack>
        </Stack>
        <Stack direction="column" style={{ padding: '24px 16px' }}>
          <Stack direction="row" spacing={4}>
            {is_locked ? (
              <LockIcon
                size={16}
                style={{ minWidth: '16px', alignSelf: 'flex-start' }}
                strokeWidth={3}
                color={colors.GREY_50}
              />
            ) : (
              <UnlockIcon
                size={18}
                style={{ minWidth: '18px', alignSelf: 'flex-start' }}
                strokeWidth={3}
                color={colors.GOLD}
              />
            )}
            <Title ellipsis>{name}</Title>
          </Stack>
          <Stack direction="row" spacing={4}>
            <FolderIcon size={18} style={{ minWidth: '18px' }} color={colors.GREY_50} />
            <Text size="small" ellipsis>
              {lesson_count} {t('lessons')}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            {has_videos ? (
              <VideoIcon size={18} style={{ minWidth: '18px' }} color={colors.GREY_50} />
            ) : (
              <VideoOffIcon size={18} style={{ minWidth: '18px' }} color={colors.GREY_50} />
            )}
            <Text size="small" ellipsis>
              {t('video_lesson')}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <PaperclipIcon size={18} style={{ minWidth: '18px' }} color={colors.GREY_50} />
            <Text size="small" ellipsis>
              {t('teaching_resources')}
            </Text>
            <HelpCircleIcon
              size={18}
              className="cursorPointer"
              style={{ minWidth: '18px' }}
              color={colors.WHITE_0}
              fill={colors.WHITE_200}
              onClick={event => {
                event.stopPropagation();
                setShowTeachingResources(true);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={4}>
            <CheckIcon size={18} style={{ minWidth: '18px' }} color={colors.GREY_50} />
            <Text size="small" ellipsis>
              {t('curriculum_alignment')}
            </Text>
            <HelpCircleIcon
              size={18}
              className="cursorPointer"
              style={{ minWidth: '18px' }}
              color={colors.WHITE_0}
              fill={colors.WHITE_200}
              onClick={event => {
                event.stopPropagation();
                setShowAlignmentDetails(true);
              }}
            />
          </Stack>
          <Stack style={{ paddingTop: '10px' }}>
            <Badge type={getTopic(sub_heading.name)}>{t(getTopic(sub_heading.name))}</Badge>
          </Stack>
        </Stack>
      </StyledCard>
      <TeachingResourcesModal
        resources={resources_overview}
        isOpen={showTeachingResources}
        hasSensitiveContent={is_sensitive}
        onClose={() => setShowTeachingResources(false)}
      />
      <AlignmentDetailsModal
        curriculums={curriculum_details}
        isOpen={showAlignmentDetails}
        onClose={() => setShowAlignmentDetails(false)}
      />
    </>
  );
});

export default ResourceCard;

ResourceCard.propTypes = {
  resourceItem: PropTypes.object.isRequired
};
