import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Title, Text } from '../../design-system';

import { Image } from 'antd';
import notFoundImage from '@assets/images/icon_disappearingChat.png';

const NoAssignmentReport = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  return (
      <Card width="100%">
        <Stack direction="column" spacing={30}>
            <Image preview={false} src={notFoundImage} alt="error" />
            <Title level={3}>{t('report_not_found')}</Title>
            <Text size="small">{t('report_not_found_detail')}</Text>
        </Stack>
      </Card>
  );
};

export default NoAssignmentReport;
