import React, { useCallback, useState } from 'react';
import { Card } from 'antd';
import dayjs from 'dayjs';

import { API_URL, deleteApiWithAuth } from '../../../utils';
import * as Modal from './modals';
import { useCards } from '../../../hooks';

import visa from '../../../assets/images/Visa.svg';
import masterCard from '../../../assets/images/masterCard.svg';
import discover from '../../../assets/images/Discover.svg';
import americanExpress from '../../../assets/images/AmericanExpress.svg';
import jcb from '../../../assets/images/Jcb.svg';
import unionPay from '../../../assets/images/UnionPay.svg';
import rightArrow from '../../../assets/images/rightArrow.svg';
import deleteImage from '../../../assets/images/deleteImage.svg';

import './CardOnFile.scss';

export const CardOnFile = () => {
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);

  const { data: cardDetail, revalidate } = useCards();

  const showDeleteCard = useCallback(item => {
    setSingleUser(item);
    setShowDeleteCardModal(true);
  }, []);

  const showAddCard = useCallback(() => {
    setShowAddCardModal(true);
  }, []);

  const handleAddCard = async () => {
    setShowAddCardModal(false);
    revalidate();
  };
  const showEditCard = useCallback(item => {
    setSingleUser(item);
    setShowEditCardModal(true);
  }, []);

  const handleEditCard = async () => {
    setShowEditCardModal(false);
    revalidate();
  };

  const handleDeleteCard = useCallback(() => {
    setShowDeleteCardModal(false);
  }, []);

  const cardDelete = async id => {
    setShowDeleteSpinner(true);
    const { status } = await deleteApiWithAuth(`${API_URL.DELETE_CARD}/${id}`);
    if (status === 204) {
      setShowDeleteSpinner(false);
      handleDeleteCard();

      revalidate();
    } else {
      setShowDeleteSpinner(false);
    }
  };

  return (
    <>
      <div className="card-on-file__container">
        <div>
          <div className="pb-1">
            <div className="fixCardHeadingStyle pb-2">Card on File</div>
            {cardDetail?.map(item => {
              return (
                <div className="pb-3" key={item.id}>
                  <Card bordered={false} className="cardsStyling cardHeight">
                    <div className="cardBillingStyle">
                      {item.card_type === 'visa' ? (
                        <img src={visa} alt="visa" className="cardImageStyle" />
                      ) : item.card_type === 'masterCard' ? (
                        <img src={masterCard} alt="masterCard" className="cardImageStyle" />
                      ) : item.card_type === 'discover' ? (
                        <img src={discover} alt="discover" className="cardImageStyle" />
                      ) : item.card_type === 'americanExpress' ? (
                        <img src={americanExpress} alt="americanExpress" className="cardImageStyle" />
                      ) : item.card_type === 'jcb' ? (
                        <img src={jcb} alt="jcb" className="cardImageStyle" />
                      ) : (
                        <img src={unionPay} alt="unionPay" className="cardImageStyle" />
                      )}
                      <div className="cardNumberStyle">**** {item.last_digits}</div>
                      <div className="expStyle">EXP {dayjs(item.expiry_date).format('MM/YY')}</div>
                      <div className="editDetailStyle">
                        <div
                          className="assignmentCardSecondInnerDiv me-2 cursorPointer"
                          onClick={() => showEditCard(item)}>
                          Edit &nbsp;
                          <img src={rightArrow} alt="deleteImage" className="cursorPointer" />
                        </div>
                        <div onClick={() => showDeleteCard(item)}>
                          &nbsp;
                          <img src={deleteImage} alt="deleteImage" className="cursorPointer" />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
          <div className="addNewCardText">
            Get a new Credit Card?
            <span className="parentOverviewHadding cursorPointer addLineBottom" onClick={showAddCard}>
              &nbsp;Add a Card &gt;
            </span>
          </div>
        </div>
      </div>

      <Modal.AddCardModal show={showAddCardModal} handleOnCancel={handleAddCard} handleAddCard={handleAddCard} />

      <Modal.EditCardModal
        show={showEditCardModal}
        handleOnCancel={handleEditCard}
        singleUser={singleUser}
        setSingleUser={setSingleUser}
        handleEditCard={handleEditCard}
      />

      <Modal.DeleteCardModal
        show={showDeleteCardModal}
        handleOnCancel={handleDeleteCard}
        singleUser={singleUser}
        handleDelete={cardDelete}
        showSpinner={showDeleteSpinner}
      />
    </>
  );
};
