/* eslint-disable no-console */
import React from 'react';
import { Navbar } from '../../layoutComponents';
import { EducatorButton } from '../../commonComponents';
import { useNavigate } from 'react-router-dom';
import award from '../../../assets/images/award.svg';
import { useLocation } from 'react-router';

const ReturnDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { date } = location.state || {};
  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin pt-5">
        <div className="mt-2">
          <div className="d-flex justify-content-center">
            <img src={award} alt="award" width="110px" height="101px" />
          </div>
          <div className="textStyleHeading mt-3">We’re sad to see you go!</div>
          <div className="mt-2 textStyle400Cabin18 widthFix fixCradCenter">
            You’ve unsubscribed from your premium membership. Your premium features will expire on {date}.
          </div>
          <div className="pt-4 d-flex justify-content-center">
            <EducatorButton
              label="Return to Dashboard"
              onClick={() => navigate('/dashboard')}
              type="button"
              width="276px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReturnDashboard;
