import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EducatorButton, EducatorModal } from '@components/commonComponents';
import { useModal } from '@context/modalContext';

import KeyLock from '@assets/images/keyLock.svg';
import './ReferTeacherModal.scss';

export const ReferTeacherModal = ({ show, handleOnCancel }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });
  const navigate = useNavigate();
  const { setModalContent } = useModal();

  const handleOnClick = () => {
    setModalContent(null);
    navigate('/referrals-credits');
  };

  return (
    <EducatorModal visible={show} onCancel={handleOnCancel}>
      <div className="refer_teacher_modal__container">
        <div className="my-4 modal_image">
          <img src={KeyLock} alt="Gift icon" width="90" height="90" />
        </div>
        <h3>{t('refer_teacher_modal_title')}</h3>
        <p className="mb-5 px-5">{t('refer_teacher_modal_text', { credits: '1 ' })}</p>
        <EducatorButton label={t('refer_teacher_modal_button')} onClick={handleOnClick} />
      </div>
    </EducatorModal>
  );
};

ReferTeacherModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired
};
