import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Spin } from 'antd';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { API_URL, getApiWithAuth } from '../../../utils';
import { EducatorButton, ParentResourceCard } from '../../commonComponents';
import { useGrades } from '../../../hooks';

import './activitiesStyle.scss';

const { Option } = Select;

export const Activities = () => {
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const [resourcesData, setResourcesData] = useState([]);
  const [dataGrade, setDataGrade] = useState('All');
  const [dataTag, setDataTag] = useState('All');
  const [dataSkill, setDataSkill] = useState('All');
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [isPaid, setIsPaid] = useState(null);

  const selectGrade = gradeName => {
    setDataGrade(gradeName);
  };

  const getResources = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_PARENT_RESOURCES);
    if (success) {
      setResourcesData(data);
      setButtonSpinner(false);
      setDataGrade('All');
      setDataTag('All');
    }
  };
  useEffect(() => {
    getParentOverView();
  }, []);

  useEffect(() => {
    if (isPaid !== null) {
      getResources();
    }
  }, [isPaid]);
  const getParentOverView = async () => {
    setButtonSpinner(true);
    const { success, data } = await getApiWithAuth(API_URL.PARENT_OVERVIEW);
    if (success) {
      setIsPaid(data.MemeberShip_Type);
    }
  };

  const selectTag = tagName => {
    setDataTag(tagName);
  };

  const handleOnChange = skill => {
    setDataSkill(skill);
  };

  const getFilterData = async () => {
    if (dataGrade === 'All' && dataTag === 'All') {
      getResources();
    } else if (dataGrade === 'All') {
      setButtonSpinner(true);
      const { success, data } = await getApiWithAuth(`${API_URL.GET_PARENT_RESOURCES}?topic=${dataTag}`);
      if (success) {
        setResourcesData(data);
      }
      setButtonSpinner(false);
    } else if (dataTag === 'All') {
      const { success, data } = await getApiWithAuth(`${API_URL.GET_PARENT_RESOURCES}?grade=${dataGrade}`);
      if (success) {
        setResourcesData(data);
      }
      setButtonSpinner(false);
    } else {
      setButtonSpinner(true);
      const { success, data } = await getApiWithAuth(
        `${API_URL.GET_PARENT_RESOURCES}?grade=${dataGrade}&topic=${dataTag}`
      );
      if (success) {
        setResourcesData(data);
      }
      setButtonSpinner(false);
    }
  };

  return (
    <>
      <div className="activities__container container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar title="Activity Kits" playButton />
        <div className="unitPlanTextStyle row">
          <div className="col-9">
            <div>
              We’ve created a set of age specific tools and resources to help keep your kids entertained and learning.
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-lg-10 cardActivityMainDiv">
            <div className="p-3 w-50">
              <Select onChange={selectTag} value={dataTag} className="fixCardInputField" name="grade">
                <Option key={-1} value={'All'}>
                  Select Subject
                </Option>
                <Option key="Be Cyber Safe" value="Be Cyber Safe">
                  Be Cyber Safe
                </Option>
                <Option key="Be Cyber Positive " value="Be Cyber Positive ">
                  Be Cyber Positive
                </Option>
                <Option key="Be Cyber Secure" value="Be Cyber Secure">
                  Be Cyber Secure
                </Option>
                <Option key="Be Cyber Informed" value="Be Cyber Informed">
                  Be Cyber Informed
                </Option>
                <Option key="Be Cyber Kind" value="Be Cyber Kind">
                  Be Cyber Kind
                </Option>
                <Option key="Be Cyber Healthy" value="Be Cyber Healthy">
                  Be Cyber Healthy
                </Option>
              </Select>
            </div>
            <div className="p-3 w-50">
              <Select onChange={selectGrade} value={dataGrade} className="fixCardInputField" name="grade">
                <Option key={-1} value={'All'}>
                  Select Grade
                </Option>
                {grades?.map(item => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="p-3 w-50 d-none">
              <Select onChange={handleOnChange} value={dataSkill} className="fixCardInputField" name="grade">
                <Option key={-1} value={'All'}>
                  Select Skill
                </Option>
              </Select>
            </div>
            <div className="py-3 pe-2">
              <EducatorButton
                key="button"
                label="Filter"
                type="button"
                width="95px"
                height="50px"
                onClick={getFilterData}
              />
            </div>
            <div className="py-3 pe-2">
              <EducatorButton
                key="button"
                label="Reset"
                type="button"
                width="95px"
                height="50px"
                onClick={getResources}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-sm-12">
            <div className="row">
              {buttonSpinner ? (
                <div className="emptyMessageStudent">
                  <Spin size="large" />
                </div>
              ) : resourcesData?.length === 0 ? (
                <div className="emptyMessageStudent">{buttonSpinner ? <Spin size="large" /> : 'No Data Found'}</div>
              ) : (
                resourcesData.map(item => {
                  return (
                    <div className="col-sm-6 col-xl-3 mt-3" key={item.id}>
                      <ParentResourceCard
                        image={item.thumbnail}
                        title={item.title}
                        description={item.description}
                        grade={item.grade}
                        topic={item.topic}
                        disabled={isPaid === 'Free' && !item.allow_fremium}
                        onClick={() =>
                          navigate(`/parent-resource/${item.id}`, {
                            state: {
                              itemId: item.id,
                              isAllowFreemium: item.allow_fremium,
                              isMemberShipType: isPaid,
                              title: item.title
                            }
                          })
                        }
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activities;
