import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { stripeOptions } from '@utils';

export const BillingDetails = ({ cardValidation, handleOnChangeCardElement, handleOnChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'payment' });
  const useOptions = () => stripeOptions;
  const options = useOptions();

  return (
    <div className="billing-details__container form__container">
      <div>
        <Form layout="vertical">
          <Form.Item name="name" className="pt-3" label={t('cardholders_name')}>
            <Input className="fixCardInputField" name="name" onChange={handleOnChange} />
          </Form.Item>

          <Form.Item name="zip_or_postalCode" className="pt-3" label={t('cardholders_zip_code')}>
            <Input className="fixCardInputField" name="zip_or_postalCode" onChange={handleOnChange} />
          </Form.Item>

          <Form.Item className="pt-4">
            <div>
              <label className="labelCurriculumStyle">{t('card_number')}</label>
              <div className="fixCardInputField mt-2">
                <CardNumberElement onChange={handleOnChangeCardElement} options={options} />
              </div>
              <div className="mt-2 text-danger">
                {cardValidation.isDirty && !cardValidation.isValidNumber ? 'Please enter a valid card number' : ''}
              </div>
            </div>
          </Form.Item>

          <div className="pt-3 row">
            <div className="col-6">
              <Form.Item>
                <div>
                  <label className="labelCurriculumStyle">{t('expiration_date')}</label>
                  <div className="fixCardInputField mt-2">
                    <CardExpiryElement onChange={handleOnChangeCardElement} options={options} />
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item>
                <div>
                  <label className="labelCurriculumStyle">{t('cvc')}</label>
                  <div className="fixCardInputField mt-2">
                    <CardCvcElement onChange={handleOnChangeCardElement} options={options} />
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

BillingDetails.propTypes = {
  cardValidation: PropTypes.object.isRequired,
  handleOnChangeCardElement: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired
};
