import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Divider, Dropdown, Menu, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { CreditBadge, EducatorButton, LanguageSwitcher } from '@components/commonComponents';

import {
  API_URL,
  clearStorage,
  getIsGoogle,
  getLocalStorageItem,
  GOOGLE_SSO_LOGOUT_URL,
  setLogoutUrl,
  USER_ROLE
} from '@utils';

import Notification from '../../../assets/images/Notification.svg';
import notification_icon from '../../../assets/images/notification_icon.svg';

import './AuthenticatedNavbarStyle.scss';
import useSWR from 'swr';

const PlayButton = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'nav' });
  return (
    <div className="pe-3">
      <EducatorButton
        label={t('play_game')}
        type="button"
        onClick={() => window.open('/child-account', '_blank', 'noreferrer')}
      />
    </div>
  );
};

export const AuthenticatedNavbar = ({ title, bodyButton, smallHeading1, smallHeading2, smallHeading3, playButton }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const location = useLocation();

  const { child_info } = location.state || {};
  const { data: licenceInfo } = useSWR(API_URL.PAYMENTS.GET_LICENCE);

  const savedUserRole = getLocalStorageItem('user_role');
  const isTeacher = savedUserRole === USER_ROLE.TEACHER;
  const isParent = savedUserRole === USER_ROLE.PARENT;

  const isOrganizationTeacher = licenceInfo?.data?.organization?.type === 'SCHOOL' || licenceInfo?.data?.organization?.type === 'DISTRICT';

  const logoutUser = async () => {
    const isGoogle = getIsGoogle();
    if (isGoogle === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
          setLogoutUrl('/');
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };

  const handleOnClick = (smallHeading1, smallHeading3) => {
    if (smallHeading3) {
      navigate(-2);
      return;
    }

    if (smallHeading1 === 'Activities >') {
      navigate('/activities');
    } else if (smallHeading1 === 'Learning >') {
      navigate('/learning', { state: { item: child_info } });
    } else {
      navigate(-1);
    }
  };

  const AccountMenu = (
    <Menu className="menuStyleAuthenticated">
      <Menu.Item
        className="menuItemStyleAuthenticated mt-3"
        key="0"
        onClick={() => (isTeacher ? navigate('/settings') : navigate('/parent-setting'))}
        data-testid="settingsButton">
        {t('settings')}
      </Menu.Item>
      <Divider className="dividerAuthenticated" />
      {isParent ? (
        <>
          <Menu.Item className="menuItemStyleAuthenticated mt-1" key="1" onClick={() => navigate('/referrals')}>
            Referrals
          </Menu.Item>
          <Divider className="dividerAuthenticated" />
        </>
      ) : (
        ''
      )}
      <Menu.Item className="menuItemStyleAuthenticated mt-1" key="2" onClick={logoutUser} data-testid="logoutButton">
        {t('logout')}
      </Menu.Item>
      <Divider className="dividerAuthenticated" />
    </Menu>
  );

  return (
    <div className="authenticatedNavbarMainDiv">
      {title ? (
        <div className="authenticatedNavbarHeadingStyle capital mt-4">{title}</div>
      ) : (
        <div className="small-heading__container capital">
          {smallHeading1 ? (
            <h5 className="smallHeading1Style" onClick={() => handleOnClick(smallHeading1, smallHeading3)}>
              {smallHeading1}
              {' > '}
            </h5>
          ) : null}
          {smallHeading2 ? (
            <h5
              onClick={() => navigate(-1)}
              className={smallHeading3 ? 'smallHeading1Style capital' : 'smallHeading2Style capital'}>
              {smallHeading2}
              {' > '}
            </h5>
          ) : null}
          {smallHeading3 ? (
            <h5 className="smallHeading2Style capital">
              {smallHeading3}
              {' > '}
            </h5>
          ) : null}
        </div>
      )}

      <div className="authenticatedNavbarMainDiv">
        {playButton ? <PlayButton /> : null}
        <div className="pe-3">{bodyButton}</div>
        <div className="pe-3 cursorPointer d-none">
          <img src={notification_icon} alt="Notification Icon" />
        </div>
        {(isTeacher && !isOrganizationTeacher) && <CreditBadge />}
        {isTeacher && <LanguageSwitcher />}
        {!isTeacher && (
          <div className="pe-3 cursorPointer">
            <Dropdown overlay={AccountMenu} trigger={['click']}>
              <img src={Notification} alt="Notification" />
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(AuthenticatedNavbar);

AuthenticatedNavbar.defaultProps = {
  title: '',
  bodyButton: '',
  smallHeading1: '',
  smallHeading2: '',
  smallHeading3: '',
  playButton: false
};

AuthenticatedNavbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bodyButton: PropTypes.any,
  smallHeading1: PropTypes.string,
  smallHeading2: PropTypes.string,
  smallHeading3: PropTypes.string,
  playButton: PropTypes.bool
};
