import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { EducatorButton, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import './parentChildSetup.css';
import { useFlag } from '@unleash/proxy-client-react';

const ParentChildSetup = () => {
  const navigate = useNavigate();
  const skipSignupEnabled = useFlag('parent.skip-signup');

  if (skipSignupEnabled) {
    navigate('/parent-create-account/confirmed');
  }

  const newAccountChild = () => {
    navigate('/parent-create-account/add-child');
  };
  const existingAccountChild = () => {
    navigate('/parent-create-account/connect-child');
  };
  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row  py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Child Setup" check={[1, 0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">Does your child already play Cyber Legends?</div>
            <Form onFinish={newAccountChild}>
              <Form.Item className="pt-5">
                <EducatorButton label="No! I’d like to create a new account for my child." type="submit" />
              </Form.Item>
              <Form.Item className="pt-3">
                <EducatorButton
                  label="Yes! I’d like to connect to my child’s existing account."
                  type="button"
                  onClick={existingAccountChild}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          <HelperBot />
        </div>
      </div>
    </div>
  );
};
export default ParentChildSetup;
