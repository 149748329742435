import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';

import { Send, UserCheck, Gift } from 'react-feather';

import { CardBlock, EducatorButton, EducatorInputField } from '@components/commonComponents';
import { useReferrals } from '@hooks';

import GiftIcon from '@assets/images/gift_big.svg';

import './ReferFriends.scss';

export const ReferFriends = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });
  const { createReferral, revalidate } = useReferrals();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInvite = async () => {
    setIsLoading(true);
    const { success, data } = await createReferral(email);
    setIsLoading(false);
    if (success) {
      setEmail('');
      message.success(t('invite_sent'));
      revalidate();
    } else {
      message.error(data.detail);
    }
  };

  const handleOnChange = e => {
    setEmail(e.target.value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleInvite();
    }
  };

  return (
    <CardBlock className="refer_friends__container">
      <div className="row">
        <div className="col-12 col-lg-7">
          <h3>{t('refer_friends')}</h3>
          <p>{t('love_cyber_legends')}</p>

          <Form onFinish={handleInvite}>
            <div className="row">
              <div className="col-12 col-lg-9">
                <label>{t('your_friends_email')}</label>
                <EducatorInputField
                  type="email"
                  name="email"
                  inputValue={email}
                  onChange={handleOnChange}
                  onKeyDown={handleKeyDown}
                  required
                />
              </div>
              <div className="col-12 col-lg-3 d-flex align-items-end">
                <EducatorButton
                  key="button"
                  label={t('invite')}
                  type="submit"
                  disabled={!email || isLoading}
                  dataTestId="inviteButton"
                />
              </div>
            </div>
          </Form>
        </div>
        <div className="col-12 col-lg-5 d-flex justify-content-center">
          <img src={GiftIcon} alt="Gift icon" />
        </div>
      </div>
      <div className="bottom_box__container">
        <div className="row">
          <div className="col-12 col-lg-4 box_item">
            <Send color={'#FFA828'} />
            <h4>{t('send_invite_email')}</h4>
            <p>{t('send_invite_email_text')}</p>
          </div>
          <div className="col-12 col-lg-4 box_item">
            <UserCheck color={'#FFA828'} />
            <h4>{t('your_friends_join_us')}</h4>
            <p>{t('your_friends_join_us_text')}</p>
          </div>
          <div className="col-12 col-lg-4 box_item">
            <Gift color={'#FFA828'} />
            <h4>{t('earn_credits')}</h4>
            <p>{t('earn_credits_text')}</p>
          </div>
        </div>
      </div>
    </CardBlock>
  );
};
