import React from 'react';
import { Result } from 'antd';
import { Navigate } from 'react-router-dom';

import babyImage from '@assets/images/educatorTeacherImage.png';

export const NotFound = () => {
  return (
    <div className="error-page">
      <Result
        icon={<img src={babyImage} alt="error" />}
        status="error"
        title="Can't find the page you're looking for."
        subTitle="Sorry, the page you're looking for doesn't exist. Please check the URL in the address bar and try again."
        extra={<Navigate to="/">Back Home</Navigate>}
      />
    </div>
  );
};
