/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Form, message, Select, Checkbox } from 'antd';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { EducatorInputField, EducatorButton } from '../../commonComponents';
import { API_URL, patchApiWithAuth } from '../../../utils';
import { useGrades } from '../../../hooks';

const { Option } = Select;

const EditAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const { userInfo } = location.state || {};
  const [passwordData, setPasswordData] = useState({ new_password: '', re_type_password: '' });
  const [buttonSpinnerPassword, setButtonSpinnerPassword] = useState(false);
  const [userData, setUserData] = useState({});
  const [userNewData, setUserNewData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);

  useEffect(() => {
    setPasswordData({
      user_name: userInfo.student?.student_username,
      student_id: userInfo.student?.student_id
    });
    setUserData(userInfo);
  }, [userInfo]);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const updatePasswordFunction = async () => {
    setButtonSpinnerPassword(true);
    const { success, data } = await patchApiWithAuth(API_URL.PARENT_RESET_CHILD_PASSWORD, passwordData);
    if (success) {
      message.success(data.message);
      setButtonSpinnerPassword(false);
    } else {
      message.error(data.message);
      setButtonSpinnerPassword(false);
    }
  };
  const selectGrade = gradeName => {
    setUserData({ ...userData, student: { ...userData.student, student_grade: gradeName } });
    setUserNewData({ ...userNewData, grade: gradeName });
  };

  const postDataChange = async () => {
    setButtonSpinner(true);
    const { success } = await patchApiWithAuth(
      `${API_URL.PARENT_UPDATE_USER}/${userData.student?.student_id}`,
      userNewData
    );

    if (success) {
      setButtonSpinner(false);
      navigate('/memberships-and-billing');
    } else {
      setButtonSpinner(false);
    }
  };

  const onChangedCheck = event => {
    const { checked } = event.target;
    setUserData({ ...userData, student: { ...userData.student, student_is_consent: checked } });
    setUserNewData({ ...userNewData, is_consent: checked });
  };

  const inputChangeHandler = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, student: { ...userData.student, [name]: value } });
    setUserNewData({ ...userNewData, [name]: value });
  };
  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar smallHeading1="Account Management" smallHeading2="Edit Account" />
        <div className="unitPlanTextStyle row">
          <div className="col-9">
            <div className="authenticatedNavbarHeadingStyle capital mt-4">
              Edit {userData.student?.first_name} {userData.student?.last_name} Account
            </div>
            <div>
              This is where you can update all of your billing details and details for your children’s accounts -
              including resetting their password.
            </div>
          </div>
        </div>
        <div className="row mt-4 assignmentSrolling">
          <div className="col col-sm-7  pb-3 ">
            <Card bordered={false} className="cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 ">
              <div className="settingHaddingStyle">Users Details</div>
              <Form onFinish={postDataChange} className="pt-4">
                <div className="row pt-3">
                  <div className="col-6">
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          pattern: new RegExp(/^[A-Za-z]+$/),
                          message: 'Must contain Alphabets'
                        }
                      ]}>
                      <EducatorInputField
                        label="Child’s First Name:"
                        inputValue={userData.student?.first_name}
                        type="text"
                        name="first_name"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: false,
                          pattern: /^[a-zA-Z]{1}$/,
                          message: 'First Letter of Last Name'
                        }
                      ]}>
                      <EducatorInputField
                        label="Child’s Last Initial:"
                        inputValue={userData.student?.last_name}
                        type="text"
                        name="last_name"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                        disabled
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <div className="pt-4">
                    <div>
                      <label className="labelStyleCard">Child’s Grade</label>
                      <div className="mt-3">
                        <Select
                          onChange={selectGrade}
                          value={userData.student?.student_grade}
                          className="fixCardInputField"
                          name="child_grade">
                          {grades.map(item => (
                            <Option key={item.name} value={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </Form.Item>
                <div className="settingHaddingStyle">Digital Health & Safety Access</div>
                <div className="unitPlanTextStyle mt-3">
                  Cyber Legends has created digital health and safety curriculum which introduces sensitive and complex
                  topics. For a child to have these lessons included in their gameplay, a parent is required to provide
                  consent. The topics and detailed lesson plans can be found{' '}
                  <a href="https://www.cyberlegends.com/digital-health-consent" target="_blank" rel="noreferrer">
                    here
                  </a>
                  .
                </div>

                <div className="displayInline pt-2">
                  <Checkbox
                    name="student_is_consent"
                    checked={userData.student?.student_is_consent}
                    onChange={onChangedCheck}
                  />
                  &nbsp;
                  <span style={{ alignSelf: 'center' }}>I consent to my child learning digital health & safety.</span>
                </div>

                <Form.Item className="pt-2 ">
                  <EducatorButton
                    key="submit"
                    label="Update User"
                    type="submit"
                    width="100%"
                    showSpinner={buttonSpinner}
                  />
                </Form.Item>
              </Form>
            </Card>
          </div>
          <div className="col col-5 col-sm-5 pb-3">
            <div>
              <Card bordered={false} className="settingSecondCard cardsStyling p-4 px-sm-3 px-lg-4 px-xxl-5">
                <div className="settingHaddingStyle">Update Password</div>
                <Form onFinish={updatePasswordFunction} className="pt-4">
                  <Form.Item
                    className="pt-3"
                    name="new_password"
                    rules={[
                      { required: true, message: 'Please input your New Password!' },
                      {
                        required: true,
                        pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                        message:
                          'Must contain Number , Special Character , upper case letter, lower case letter, min length 8'
                      }
                    ]}>
                    <EducatorInputField
                      label="New Password:"
                      passwordValue={passwordData.new_password}
                      type="password"
                      name="new_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="re_type_password"
                    dependencies={['new_password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords do not match'));
                        }
                      })
                    ]}>
                    <EducatorInputField
                      label="Confirm Password:"
                      type="password"
                      name="re_type_password"
                      className="fixCardInputField"
                      dependencies={passwordData.new_password}
                      passwordValue={passwordData.re_type_password}
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item className="pt-2">
                    <EducatorButton
                      key="submit"
                      label="Update Password"
                      type="submit"
                      width="100%"
                      showSpinner={buttonSpinnerPassword}
                    />
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditAccount;
