import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { USER_ROLE } from '@utils';
import './MultistepStyle.scss';

const Multistep = ({ select, check, userType }) => {
  const { t } = useTranslation('common');

  const stepsConfig = {
    [USER_ROLE.TEACHER]: [
      { step: 'Personal Details', tKey: 'teacher_account_setup' },
      { step: 'Curriculum Setup', tKey: 'curriculum_setup' },
      { step: 'License', tKey: 'license' },
      { step: 'Payment', tKey: 'payment' }
    ],
    [USER_ROLE.SCHOOL_ADMIN]: [
      { step: 'School Account Setup', tKey: 'admin_account_setup' },
      { step: 'School Setup', tKey: 'school_setup' },
      { step: 'Payment', tKey: 'payment' }
    ]
  };

  const setupSteps = stepsConfig[userType];

  return (
    <div className="multistep__container mt-2">
      {setupSteps.map((item, index) => (
        <div className="multistep__item d-flex align-items-center" key={item.step}>
          <div className={`circleRadio ${check[index] === 0 && select === item.step ? 'selected' : 'notSelected'}`} />
          <div className={`textStyle ${check[index] === 0 && select === item.step ? 'selected' : 'notSelected'}`}>
            {t(item.tKey)}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Multistep;

Multistep.propTypes = {
  select: PropTypes.string.isRequired,
  check: PropTypes.array.isRequired,
  userType: PropTypes.oneOf([USER_ROLE.TEACHER, USER_ROLE.SCHOOL_ADMIN])
};

Multistep.defaultProps = {
  userType: USER_ROLE.TEACHER
};
