import useSWR from 'swr';
import { API_URL, fetcher } from '../utils';

export const useReferralCoupon = () => {
  const url = API_URL.GET_REFERRAL_COUPONS;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  };

  const { data, mutate, error, isLoading } = useSWR(url, fetcher, options);
  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error: error,
    data: response,
    revalidate
  };
};
