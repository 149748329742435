/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { EducatorButton } from '../../commonComponents';
import { Form, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearStorage, getIsGoogle, getRefereshToken, getToken } from '../../../utils/sessionStorage';
import { Auth } from 'aws-amplify';
import { getApiWithAuth } from '../../../utils/api';
import { API_URL, GOOGLE_SSO_LOGOUT_URL } from '../../../utils/constants';

const ChildUpdateClass = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { classNamee } = location.state || {};
  const logoutUser = async () => {
    const Google = getIsGoogle();
    const token = getToken();
    const refreshToken = getRefereshToken();

    if (Google === '1') {
      clearStorage();
      window.location.href = GOOGLE_SSO_LOGOUT_URL;
      window.open(`${import.meta.env.REACT_APP_LINK_GAME_SERVER}?token=${token}&mode=school`, '_self');
    } else {
      await Auth.signOut()
        .then(() => {
          clearStorage();
          window.open(
            `${import.meta.env.REACT_APP_LINK_GAME_SERVER}?token=${token}&refresh=${refreshToken}&mode=school`,
            '_self'
          );
        })
        .catch(err => {
          message.error(`${err.message}`);
        });
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { status } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
      if (status === 401) {
        navigate('/');
      }
    };
    fetchUser();
  }, [location.pathname === '/child-create-account/update-class']);

  return (
    <div className="childBackgroundDStyle container-fluid">
      <div className="childInnerDiv">
        <div className="childWhiteBackgroung py-4">
          <div className="childSecondHaddingStyle">You’re currently playing as part of {classNamee}</div>
          <div className="mt-3">
            <Form>
              <div className="py-3">
                <EducatorButton label={`Play Game as part of ${classNamee}`} type="button" onClick={logoutUser} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChildUpdateClass;
