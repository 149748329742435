/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Card, Divider, Form, message, Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import {
  EducatorButton,
  EducatorInputField,
  EducatorModal,
  HelpfulLinks,
  LoginDetails,
  ParentResourceCard
} from '@components/commonComponents';
import {
  API_URL,
  clearStorage,
  getApiWithAuth,
  getApiWithoutAuth,
  getMemberShipType,
  patchApiWithAuth,
  postApiWithAuth,
  setMemberShipType
} from '@utils';
import { useGrades } from '@hooks';

import rightArrow from '@assets/images/rightArrow.svg';

import './ParentDashboardStyle.scss';

const { Option } = Select;

export const ParentDashboard = () => {
  const navigate = useNavigate();
  const { data: grades, error: gradesError } = useGrades();

  const [showBanner, setShowBanner] = useState(false);
  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const [showCreateChildModal, setShowCreateChildModal] = useState(false);
  const [showAddCurriculumModal, setShowAddCurriculumModal] = useState(false);
  const parentTotalActivities = '';

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [childData, setChildData] = useState({});
  const [childCurriculum, setChildCurriculum] = useState({});

  const [detailDta, setDetailData] = useState({});
  const [linkExistingModal, setLinkExistingModal] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [dataCountry, setDataCountry] = useState(null);
  const [parentOverView, setParentOverView] = useState({});
  const [showBannerResource, setShowBannerResource] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState({ value: false });

  const logoutUser = async () => {
    await Auth.signOut()
      .then(() => {
        clearStorage();
        navigate('/teacher-signin');
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  if (gradesError && gradesError.status === 401) {
    logoutUser();
  }

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setChildData({ ...childData, [name]: value });
  };

  const getCountries = async () => {
    const { success, data } = await getApiWithoutAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
      setDataCountry(data[0]?.name);
    }
  };

  const getProvinces = async country => {
    const { success, data } = await getApiWithoutAuth(`${API_URL.GET_PROVINCES}?name=${country}`);
    if (success) {
      setProvinces(data);
      setSelectedProvince(data[0]?.name);
      setSelectedProvinceId(data[0]?.id);
    }
  };

  const selectCountry = countryName => {
    setChildCurriculum({ ...childCurriculum, country: countryName });
  };
  const selectProvince = provinceId => {
    const temp = provinces.filter(item => item.id === provinceId);
    setSelectedProvince(temp[0].name);
    setChildCurriculum({ ...childCurriculum, province_id: provinceId });
  };

  const selectGrade = gradeName => {
    setChildCurriculum({ ...childCurriculum, child_grade: gradeName });
  };

  const createChild = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_CREATE_CHILD_ACCOUNT, childData);
    if (success) {
      setDetailData(data);
      setChildCurriculum({ ...childCurriculum, student_id: data.student_link_id });
      showCurriculumChild();
    }
    setButtonSpinner(false);
  };
  const curriculumChild = async () => {
    setButtonSpinner(true);
    const { success } = await patchApiWithAuth(API_URL.PARENT_CURRICULUM_SETUP, childCurriculum);
    if (success) {
      setChildData({ first_name: '', last_name: '' });
      getParentOverView();
      showDetail();
    }
    setButtonSpinner(false);
  };

  const showAddChild = () => {
    setShowAddChildModal(true);
  };
  const hideAddChild = () => {
    setShowAddChildModal(false);
  };
  const showCreateChild = () => {
    setShowAddChildModal(false);
    setShowCreateChildModal(true);
  };
  const hideCreateChild = () => {
    setShowCreateChildModal(false);
  };
  const showCurriculumChild = () => {
    setShowCreateChildModal(false);
    setShowAddCurriculumModal(true);
  };
  const hideCurriculumChild = () => {
    setShowAddCurriculumModal(false);
  };

  const showDetail = () => {
    setShowAddCurriculumModal(false);
    setShowDetailModal(true);
  };
  const hideDetail = () => {
    setShowDetailModal(false);
    getParentOverView();
  };
  const showExistingCode = () => {
    setShowAddChildModal(false);
    setLinkExistingModal(true);
  };
  const hideExistingCode = () => {
    setLinkExistingModal(false);
  };

  const getParentOverView = async () => {
    const { success, data } = await getApiWithAuth(API_URL.PARENT_OVERVIEW);
    if (success) {
      setParentOverView(data);
      setMemberShipType(data.MemeberShip_Type);
      data.Free_Children?.length > 0 ? setShowBanner(true) : setShowBanner(false);
    }
  };

  const printMessage = () => {
    setShowBanner(false);
    setShowBannerResource(true);
    setTimeout(() => {
      setShowBannerResource(false);
      setShowBanner(true);
    }, 3000);
  };

  useEffect(() => {
    getCountries();
    getParentOverView();
  }, []);

  useEffect(() => {
    getProvinces(childCurriculum.country);
  }, [childCurriculum.country]);

  useEffect(() => {
    if (dataCountry !== null) {
      setChildCurriculum({ ...childCurriculum, country: dataCountry });
    }
  }, [dataCountry]);

  useEffect(() => {
    if (selectedProvinceId !== null) {
      setChildCurriculum({ ...childCurriculum, province_id: selectedProvinceId });
    }
  }, [selectedProvinceId]);

  useEffect(() => {
    if (grades.length > 0) {
      setChildCurriculum({ ...childCurriculum, child_grade: grades[0]?.name });
    }
  }, [grades]);

  return (
    <>
      {showBannerResource ? (
        <div className="blueTitle px-3">You should have any one child of premium subscription to access it.</div>
      ) : null}

      {showBanner ? (
        <div className="yellowTitle px-3">
          {parentOverView.Free_Children?.map((item, index) => {
            if (index < 2) {
              return `${item.student_link__first_name.toUpperCase()}. ${item.student_link__last_name.toUpperCase()} ${
                index < 1 ? ', ' : ' '
              }`;
            }
            if (index === 2) {
              return '... ';
            }
          })}
          is currently playing for free. To unlock custom game skins and activity kits for parents,&nbsp;&nbsp;
          <span className="parentOverviewHadding cursorPointer" onClick={() => navigate('/memberships-and-billing')}>
            UPGRADE NOW!
          </span>
        </div>
      ) : null}

      <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar title="Welcome!" playButton />
        <div className="row mt-3 pb-4 ">
          <div className="col col-7">
            <Card bordered={false} className="cardStyleOverView p-3">
              <div className="row">
                <div className="col-4">
                  <div className="cardHeadingStyle">Questions Answered</div>
                  <div className="pt-2 cardMiddleStyle">
                    {parentTotalActivities && parentTotalActivities.completed_activities
                      ? parentTotalActivities.completed_activities
                      : 'N/A'}
                  </div>
                  <div className="pt-2 cardTextStyle">Since your children started playing.</div>
                </div>
                <div className="col-4 d-flex ps-1">
                  <Divider type="vertical" className="cardLineStyle" />
                  <div className="somePadding">
                    <div className="cardHeadingStyle">Membership Type</div>
                    <div className="pt-2 cardMiddleStyle">{parentOverView.MemeberShip_Type}</div>
                    <div className="pt-2 cardTextStyle">
                      <span>
                        <Link to="/memberships-and-billing" className="addLineBottom me-1 updateStyle cursorPointer">
                          Upgrade
                        </Link>
                      </span>
                      to unlock more features.
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex ps-1">
                  <Divider type="vertical" className="cardLineStyle" />
                  <div className="somePadding">
                    <div className="cardHeadingStyle">Household Code</div>
                    <div className="pt-2 cardMiddleStyle">{parentOverView.Household_code}</div>
                    <div className="pt-2 cardTextStyle">Share this with your children!</div>
                  </div>
                </div>
              </div>
            </Card>

            <div className="pt-5 teachingResourseMainDivSecond">
              <div className="teachingResourcesHeadingStyle">Parent Resources </div>
              <div className="seeMoreTextStyle" onClick={() => navigate('/activities')}>
                See More&nbsp;&nbsp;
                <img src={rightArrow} alt="rightArrow" />
              </div>
            </div>
            <div className="row">
              {parentOverView.Parent_Resources?.map((item, index) => {
                if (index < 2) {
                  return (
                    <div className="col-6" key={`parent-resources-${index + 1}`}>
                      <ParentResourceCard
                        image={item.thumbnail}
                        title={item.title}
                        description={item.description}
                        grade={item.grade}
                        topic={item.topic}
                        disabled={getMemberShipType() === 'Free' && !item.allow_fremium}
                        onClick={() =>
                          getMemberShipType() === 'Free' && !item.allow_fremium
                            ? printMessage()
                            : navigate(`/parent-resource/${item.id}`, {
                                state: {
                                  itemId: item.id,
                                  isAllowFremium: item.allow_fremium,
                                  isMemberShipType: getMemberShipType(),
                                  title: item.title
                                }
                              })
                        }
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="col col-5">
            <LoginDetails />
            <div className="pt-3 textStyle400Cabin18Black">
              Don&apos;t see your child listed?
              <span className="parentOverviewHadding cursorPointer addLineBottom" onClick={showAddChild}>
                &nbsp;Add a Child &gt;
              </span>
            </div>
            <div className={'mb-4'} />
            <HelpfulLinks />
          </div>
        </div>
      </div>
      <EducatorModal
        visible={showAddChildModal}
        onCancel={hideAddChild}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">Add a Child</div>
            <div className="fixCardtextStyle mt-2">
              Would you like to create a new account for the child you’re adding or are you looking to link an account
              that already exists?
            </div>

            <div className="mt-3">
              <EducatorButton
                key="button"
                label="I’d like to create a new account"
                type="button"
                width="100%"
                onClick={showCreateChild}
              />
            </div>
            <div className="mt-3">
              <EducatorButton
                key="button"
                label="I’d like to connect an existing account"
                type="button"
                width="100%"
                onClick={showExistingCode}
              />
            </div>
          </div>
        }
      />

      <EducatorModal
        visible={showCreateChildModal}
        onCancel={hideCreateChild}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">Create Your Child’s Account</div>
            <div className="fixCardtextStyle ">
              Fill out the form below to sign your child up for Cyber Legends. Educational details allows us to ensure
              the content they’re seeing reflects that they learn about in school.
            </div>
            <Form onFinish={createChild}>
              <Form.Item
                name="first_name"
                rules={[
                  { required: true, message: 'Please input your First Name!' },
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message: 'Must contain Alphabets'
                  }
                ]}>
                <EducatorInputField
                  label="First Name:"
                  type="text"
                  inputValue={childData.first_name}
                  headerColor="#000000"
                  name="first_name"
                  onChange={onChangeHandle}
                  width="100%"
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z]{1}$/,
                    message: 'First Letter of Last Name'
                  }
                ]}>
                <EducatorInputField
                  label="First Letter of Last Name:"
                  headerColor="#000000"
                  inputValue={childData.last_name}
                  type="text"
                  name="last_name"
                  onChange={onChangeHandle}
                  width="100%"
                />
              </Form.Item>
              <div className="pt-3">
                <EducatorButton label="Create Account" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        }
      />

      <EducatorModal
        visible={showAddCurriculumModal}
        onCancel={hideCurriculumChild}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle">Add Education Details</div>
            <div className="fixCardtextStyle mt-2">
              Entering your child’s education details allows us to match our content with what they learn about in
              school.
            </div>
            <Form onFinish={curriculumChild}>
              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">
                      What country does
                      {childData ? ` ${childData?.first_name} ${childData?.last_name} ` : ' ChildName '}
                      go to school in?
                    </label>
                    <div className="mt-3">
                      <Select
                        className="fixCardInputField"
                        value={childCurriculum?.country}
                        onChange={selectCountry}
                        name="country">
                        {countries.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">
                      What province does{' '}
                      {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} go to
                      school in?
                    </label>
                    <div className="mt-3">
                      <Select
                        className="fixCardInputField"
                        value={selectedProvince}
                        onChange={selectProvince}
                        name="country">
                        {provinces.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="pt-4">
                  <div>
                    <label className="labelStyleCard">
                      What grade is{' '}
                      {childData.first_name ? `${childData.first_name} ${childData.last_name}` : 'ChildName'} in?
                    </label>
                    <div className="mt-3">
                      <Select
                        onChange={selectGrade}
                        value={childCurriculum?.child_grade}
                        className="fixCardInputField"
                        name="child_grade">
                        {grades.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div className="pt-3">
                <EducatorButton label="Add Curriculum" type="submit" width="100%" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        }
      />
      <EducatorModal
        visible={showDetailModal}
        onCancel={hideDetail}
        bodyContent={
          <div>
            {showCopyMessage.value ? (
              <div className="yellowTitle container copyMessage">
                <p className="copyMessage__text">{showCopyMessage.text} Copied to Clipboard!</p>
              </div>
            ) : null}
            <div className="fixCardHeadingStyle">Your child’s account has been created!</div>
            <div className="fixCardtextStyle mt-2">
              This new account has been successfully created! Below are the login details. Write them down or store them
              in a secure password manager
            </div>

            <Form onFinish={hideDetail}>
              <div className="mt-3 childSecondText">Your Username: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{detailDta?.user_name ? detailDta?.user_name : 'Your UserName'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(detailDta?.user_name);
                    setShowCopyMessage({ value: true, text: 'Username' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="mt-3 childSecondText">Your Password: </div>
              <div className="mt-3 nameAndButtonContainer">
                <div className="childHaddingStyle">{detailDta?.password ? detailDta?.password : 'Your Password'}</div>
                <CopyOutlined
                  style={{ color: 'blue', fontSize: '18px' }}
                  className="ms-4"
                  onClick={() => {
                    navigator.clipboard.writeText(detailDta?.password);
                    setShowCopyMessage({ value: true, text: 'Password' });
                    setTimeout(() => {
                      setShowCopyMessage({ value: false });
                    }, [1500]);
                  }}
                />
              </div>
              <div className="py-3">
                <EducatorButton label="That’s it for now!" type="submit" width="100%" />
              </div>
            </Form>
          </div>
        }
      />
      <EducatorModal
        visible={linkExistingModal}
        onCancel={hideExistingCode}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle fixCradCenter">Link an Existing Account</div>
            <div className="linkExistingModalTextStyling fixCradCenter pt-2">
              You’ve indicated that your child already has a Cyber Legends account. To link them with your account.
              Share the family code below and have them enter it in the game.
            </div>
            <div className="linkExistingModalCodeStyling pt-3">{parentOverView.Household_code}</div>
            <div className="linkExistingModalTextStyling">Your Family Code</div>
          </div>
        }
      />
    </>
  );
};
