import React from 'react';
import { Card } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import './DeleteAccountStyle.scss';

export const DeleteAccount = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  return (
    <Card bordered={false} className="delete-account__container cardsStyling">
      <h3 className="settingHeadingStyle">{t('delete_account')}</h3>
      <p>
        <Trans i18nKey="settings.account_deletion_warning" ns="translation">
          Deleting your account is a permanent action that will cancel all your current subscriptions (which are non
          refundable). Email us at <a href="mailto:legal@cyberlegends.com">legal@cyberlegends.com</a> if you’d like to
          delete your account.
        </Trans>
      </p>
    </Card>
  );
};
