import useSWR from 'swr';
import { fetcher, getUserID } from '../utils';

export const useSubscriptionDetails = () => {
  const parentId = getUserID();
  const url = `payments/parent/${parentId}/subscription-details`;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  };

  const { data, error, isLoading, isValidating, mutate } = useSWR(parentId ? url : null, fetcher, options);

  const revalidate = () => {
    mutate();
  };

  return {
    isLoading,
    error: error,
    data: data?.data,
    isValidating,
    revalidate
  };
};
