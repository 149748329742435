import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Empty, message, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { LoginDetails } from '@components/commonComponents';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { API_URL, clearStorage, getApiWithAuth } from '@utils';
import { useAppState } from '@context';
import { SET_CLASSES, SET_CURRENT_CLASS_ID } from '@context/context-actions';

import './TeacherDashboardStyle.scss';
import Overview from '@teacher-app/pages/Overview';
import { Badge, Colors, Stack, Title } from '@teacher-app/design-system';
import EditClassModal from '@teacher-app/components/EditClassModal';

export const TeacherDashboard = ({ showAddClass, isGetClassesLoading, setShowOnboardingFlow }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'overview' });

  const { state, dispatch } = useAppState();

  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [classOverview, setClassOverview] = useState(null);
  const flag = false;

  const isEmptyClass = state.classes.length === 0 && !isGetClassesLoading;
  const currentClass = state.classes.find(item => item.id === state.classId);

  const classGrades = currentClass?.grades.length > 0 ? currentClass?.grades : [{ id: 1, name: currentClass?.grade }];

  const logoutUser = async () => {
    await Auth.signOut()
      .then(() => {
        clearStorage();
        navigate('/teacher-signin');
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  const getOverviewOfClass = async () => {
    if (state.classId) {
      const { success, data, status } = await getApiWithAuth(`${API_URL.GET_OVERVIEW_OF_CLASS}${state.classId}`);
      if (status === 401) {
        logoutUser();
        return;
      }

      if (success) {
        setClassOverview(data);
      }
    }
  };

  const handleEditModalClose = async (fetchAgain, isDeleted) => {
    if (fetchAgain) {
      await getTeachersClasses(isDeleted);
      await getOverviewOfClass();
    }
    setShowEditClassModal(false);
  };

  useEffect(() => {
    if (isEmptyClass) {
      setShowOnboardingFlow(true);
    }
  }, [isGetClassesLoading]);

  const getTeachersClasses = async isDelete => {
    const { success, data, status } = await getApiWithAuth(API_URL.GET_TEACHERS_CLASSES);

    if (status === 401) {
      logoutUser();
      return;
    }

    if (success) {
      dispatch({
        type: SET_CLASSES,
        payload: data
      });

      if (isDelete) {
        if (data.length > 0) {
          dispatch({
            type: SET_CURRENT_CLASS_ID,
            payload: data[0].id
          });
        } else {
          dispatch({
            type: SET_CURRENT_CLASS_ID,
            payload: null
          });
        }
      }
    }
  };

  if (isEmptyClass) {
    return (
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMainDivHeight">
        <AuthenticatedNavbar title={t('welcome')} />
        <div className="row mt-3 pb-4 dashboardPageScorling">
          <div className="col col-7">
            <Card bordered={false} className="cardStyleOverView p-3">
              <div className="pt-2 cardTextStyle welcome-text">{t('welcome_text')}</div>
              <button className="create-class__button" onClick={showAddClass} data-testid="createClassButton">
                {t('create_class')}
              </button>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMainDivHeight">
        <AuthenticatedNavbar
          title={
            <Stack spacing={20}>
              <Title level={2}>{currentClass?.name}</Title>
              <Edit
                className="cursorPointer"
                onClick={() => setShowEditClassModal(true)}
                size={24}
                color={Colors.VIOLET}
              />
            </Stack>
          }
        />
        <div className="row mt-3 pb-4 dashboardPageScorling">
          <div className={flag ? 'col col-7' : 'col col-12'}>
            <Row>
              {flag && (
                <Card bordered={false} className="cardStyleOverView p-3">
                  <div className="row">
                    <div className="col-4">
                      <div className="cardHeadingStyle">{t('assignments')}</div>
                      <div className="pt-2 cardMiddleStyle">{classOverview?.total_assignments ?? 'N/A'}</div>
                      <div className="pt-2 cardTextStyle">{t('created_since_joined')}</div>
                    </div>
                    <div className="col-4 d-flex ps-1">
                      <Divider type="vertical" className="cardLineStyle" />
                      <div className="somePadding">
                        <div className="cardHeadingStyle">{t('parents_linked')}</div>
                        <div className="pt-2 cardMiddleStyle">
                          {classOverview?.parents_linked
                            ? `${parseFloat(classOverview.parents_linked).toFixed(2)} %`
                            : 'N/A'}
                        </div>
                        <div className="pt-2 cardTextStyle">
                          {t('waiting_to_connect_parents', { count: classOverview?.count })}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex ps-1">
                      <Divider type="vertical" className="cardLineStyle" />
                      <div className="somePadding">
                        <div className="cardHeadingStyle">{t('classcode')}</div>
                        <div className="pt-2 cardMiddleStyle">{classOverview?.class_code ?? 'N/A'}</div>
                        <div className="pt-2 cardTextStyle">{t('share_with_students')}</div>
                      </div>
                    </div>
                  </div>
                </Card>
              )}
              <ErrorBoundary
                fallbackRender={({ error }) => (
                  <div className="row">
                    <div className="col p-4">
                      <Empty description={error.detail} />
                    </div>
                  </div>
                )}>
                <Stack direction="column" spacing={30}>
                  <Stack>
                    {classGrades.map(grade => (
                      <Badge type="invalid" key={grade.id}>
                        {grade.name}{' '}
                      </Badge>
                    ))}
                  </Stack>
                </Stack>
                <Overview />
              </ErrorBoundary>
            </Row>
          </div>
          <div className="col col-5">{flag && <LoginDetails />}</div>
        </div>
      </div>
      <EditClassModal isOpen={showEditClassModal} onClose={handleEditModalClose} currentClass={currentClass} />
    </>
  );
};

TeacherDashboard.propTypes = {
  showAddClass: PropTypes.func.isRequired,
  setShowOnboardingFlow: PropTypes.func.isRequired,
  isGetClassesLoading: PropTypes.bool.isRequired
};
