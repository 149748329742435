/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { EducatorButton, EducatorInputField } from '../../commonComponents';
import { Card, Divider, Form, message } from 'antd';
import { API_URL } from '../../../utils/constants';
import { subTotalFunction, subTotalFunctionWithTax, totalFunction } from '../../../utils/Helper';
import { getUserID } from '../../../utils/sessionStorage';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const UpadteBillingForm = ({ data, premiumChildDetail, freeChildDetail, userInfo }) => {
  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: '18px',
            color: '#222222',
            fontFamily: 'Cabin, sans-serif',
            '::placeholder': {
              color: '#222222'
            }
          }
        }
      }),
      []
    );

    return options;
  };
  const stripe = useStripe();
  const options = useOptions();

  const elements = useElements();
  const navigate = useNavigate();

  const [cardDetail, setCardDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sendData, setSendData] = useState({
    new_payment_id: undefined,
    user_id: getUserID()
  });
  const [sendNewData, setSendNewData] = useState({});
  const getCardDetails = async () => {
    const { success, data } = await getApiWithAuth(`${API_URL.FETCH_CARD}?payment_id=${userInfo.payment_id}`);
    if (success) {
      setCardDetail(data);
    }
  };
  const [checkCard, setCheckCard] = useState({
    isValidNumber: false,
    isValidExpiry: false,
    isValidCvc: false
  });
  useEffect(() => {
    setSendData({
      ...sendData,
      old_payment_id: userInfo.payment_id,
      name: userInfo.name,
      zip_or_postalCode: userInfo.zip_or_postalCode
    });
    getCardDetails();
  }, [userInfo.payment_id]);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setSendData({ ...sendData, [name]: value });
    setCardDetail({ ...cardDetail, [name]: value });
  };

  const handleSubmit = async event => {
    setIsLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    let cardElement = null;
    cardElement = {
      ...cardElement,
      ...elements.getElement(CardCvcElement),
      ...elements.getElement(CardExpiryElement),
      ...elements.getElement(CardNumberElement)
    };
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      metadata: {
        name: sendData.name,
        zip_or_postalCode: sendData.zip_or_postalCode
      }
    });

    if (error) {
      setIsLoading(false);
      message.error(error.message);
    } else {
      setSendData({
        ...sendData,
        new_payment_id: paymentMethod?.id
      });
      setSendNewData({
        ...sendNewData,
        payment_id: paymentMethod?.id
      });
    }
  };

  useEffect(() => {
    if (sendData.new_payment_id != undefined) PostPaymentId();
  }, [sendData.new_payment_id]);

  const onChangeHandleCard = event => {
    if (event.elementType === 'cardNumber' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidNumber: true
      });
    } else if (event.elementType === 'cardExpiry' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidExpiry: true
      });
    } else if (event.elementType === 'cardCvc' && event.complete) {
      setCheckCard({
        ...checkCard,
        isValidCvc: true
      });
    }
  };
  const PostPaymentId = async () => {
    const newData = sendData;
    delete newData.name;
    delete newData.zip_or_postalCode;
    const { success, data } = await patchApiWithAuth(`payments/${userInfo.id}/update-card`, newData);
    if (success) {
      updatePayment(data);
    } else {
      setIsLoading(false);
    }
  };

  const updatePayment = async userData => {
    sendNewData.payment_id = userData.payment_id;
    const { success } = await patchApiWithAuth(API_URL.UPDATE_PAYMENT, sendNewData);
    if (success) {
      setIsLoading(false);
      navigate('/memberships-and-billing');
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setSendNewData(data);
  }, [data]);
  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar
          smallHeading1="Account Management"
          smallHeading2="Manage Subscriptions"
          smallHeading3="Make Paymnet"
        />
        <div className="unitPlanTextStyle row">
          <div className="col-6">
            <div className="authenticatedNavbarHeadingStyle capital mt-4">Make Payment</div>
            <div>You’ve chosen to upgrade your subscriptions! Make your payment to unlock premium features.</div>
          </div>
        </div>
        <div className="row mt-4 assignmentSrolling">
          <div className="col col-sm-7 pb-3 ">
            {' '}
            <Card bordered={false} className="cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 memberStyle">
              <Form>
                <div className="fixCardHeadingStyle">Add Payment Details</div>

                <Form.Item name="name" className="pt-3">
                  <EducatorInputField
                    label="Cardholder’s Name:"
                    className="fixCardInputField"
                    inputValue={cardDetail?.name}
                    type="name"
                    name="name"
                    width="100%"
                    onChange={onChangeHandle}
                  />
                </Form.Item>
                <Form.Item name="zip_or_postalCode" className="pt-3">
                  <EducatorInputField
                    className="fixCardInputField"
                    label="ZIP/ Postal Code:"
                    type="text"
                    name="zip_or_postalCode"
                    width="100%"
                    inputValue={cardDetail?.zip_or_postalCode}
                    onChange={onChangeHandle}
                  />
                </Form.Item>

                <Form.Item className="pt-4">
                  <div>
                    <label className="parentOverviewHadding">Card Number:</label>
                    <div className="mt-3">
                      <div className="fixCardInputField">
                        <CardNumberElement onChange={onChangeHandleCard} options={options} />
                      </div>
                    </div>
                  </div>
                </Form.Item>

                <div className="pt-3 row">
                  <div className="col-6">
                    <Form.Item>
                      <div>
                        <label className="parentOverviewHadding">Expiration Date:</label>
                        <div className="fixCardInputField mt-3">
                          <CardExpiryElement onChange={onChangeHandleCard} options={options} />
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item>
                      <div>
                        <label className="parentOverviewHadding ">CVC:</label>
                        <div className="fixCardInputField mt-3">
                          <CardCvcElement onChange={onChangeHandleCard} options={options} />
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
          <div className="col col-sm-5 pb-3 ">
            <Card bordered={false} className="cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 memberStyle">
              <div className="pt-2  spaceBetweenDiv">
                <div>Premium Membership</div>
                <div className="pt-2  spaceBetweenDiv">
                  <div>{premiumChildDetail.length}&nbsp;x&nbsp;</div>
                  <div> ${premiumChildDetail.payment} </div>
                </div>
              </div>
              <div className="pt-2  spaceBetweenDiv">
                <div>Free Membership</div>
                <div className="pt-2  spaceBetweenDiv">
                  <div>{freeChildDetail.length}&nbsp;x&nbsp;</div>
                  <div>${freeChildDetail.payment}</div>
                </div>
              </div>
              <Divider className="managedividerStyleOne" />
              <div className="pt-2  spaceBetweenDiv">
                <div>Subtotal </div>
                <div>${subTotalFunction(premiumChildDetail.length, premiumChildDetail.payment)}</div>
              </div>
              <div className="pt-2  spaceBetweenDiv">
                <div>Estimated Tax </div>
                <div>${subTotalFunctionWithTax(premiumChildDetail.length, premiumChildDetail.payment)}</div>
              </div>
              <Divider className="managedividerStyleTwo" />
              <div className="pt-2 secondCardHadding spaceBetweenDiv">
                <div>Total </div>
                <div>${totalFunction(premiumChildDetail.length, premiumChildDetail.payment)}</div>
              </div>
              <Divider className="managedividerStyleTwo" />

              <div className="pt-3">
                <EducatorButton
                  label="Make Payment"
                  type="button"
                  width="100%"
                  showSpinner={isLoading}
                  onClick={handleSubmit}
                  disabled={!checkCard.isValidNumber || !checkCard.isValidExpiry || !checkCard.isValidCvc}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpadteBillingForm;

UpadteBillingForm.propTypes = {
  data: PropTypes.object.isRequired,
  premiumChildDetail: PropTypes.object.isRequired,
  freeChildDetail: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired
};
