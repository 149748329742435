import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { message, Select } from 'antd';
import { EducatorButton, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import { API_URL, getApiWithAuth, patchApiWithAuth } from '../../../utils';
import { useGrades } from '../../../hooks';

import './parentCurriculumSetup.css';

const { Option } = Select;
export const ParentCurriculumSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: grades } = useGrades();

  const { student_id, userName } = location.state || {};

  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState([]);

  const getCountries = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
      setSelectedCountry(data[0]?.name);
    }
  };

  const getProvinces = async country => {
    const { success, data } = await getApiWithAuth(`${API_URL.GET_PROVINCES}?name=${country}`);
    if (success) {
      setProvinces(data);
      setSelectedProvince(data[0]?.name);
      setSelectedProvinceId(data[0]?.id);
    }
  };

  const selectCountry = event => {
    getProvinces(event);
    setSelectedCountry(event);
  };

  const selectProvince = event => {
    setSelectedProvince(event);
    setSelectedProvinceId(event);
  };

  const addCurriculum = async () => {
    setButtonSpinner(true);
    const { success, data } = await patchApiWithAuth(`${API_URL.PARENT_CURRICULUM_SETUP}`, {
      province_id: selectedProvinceId,
      country: selectedCountry,
      child_grade: selectedGrade,
      student_id: student_id
    });
    if (success) {
      setButtonSpinner(false);
      message.success('Curriculum is added');
      navigate('/parent-create-account/additional-child');
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };

  const onChangeHandle = event => {
    setSelectedGrade(event);
  };

  useEffect(() => {
    if (grades.length > 0) {
      setSelectedGrade(grades[0]?.name);
    }
  }, [grades]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getProvinces(countries[0]?.name);
  }, [countries]);

  return (
    <div className="mainDivSignUpStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Curriculum Setup" check={[1, 1, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex flex-column">
            <div className="CurriculumSetupStyleHeading">Curriculum Setup</div>
            <div className="pt-2 textStyle">
              Letting us know about the education system your child is apart of helps us ensure the content they’re
              seeing reflects what they learn in school.
            </div>

            <div className="pt-3 mt-3">
              <div>
                <label className="labelCurriculumStyle">
                  What country does {userName.first_name.charAt(0).toUpperCase() + userName.first_name.slice(1)}{' '}
                  {userName.last_name} go to school in?
                </label>
                <div className="mt-2">
                  <Select className="inputFieldStyle" onChange={selectCountry} value={selectedCountry}>
                    {countries.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div>
                <label className="labelCurriculumStyle">
                  What {selectedCountry === 'Canada' ? 'province' : 'state'} does{' '}
                  {userName.first_name.charAt(0).toUpperCase() + userName.first_name.slice(1)} {userName.last_name} go
                  to school in?
                </label>
                <div className="mt-2">
                  <Select className="inputFieldStyle" onChange={selectProvince} value={selectedProvince}>
                    {provinces.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <div>
                <label className="labelCurriculumStyle">
                  What grade is {userName.first_name.charAt(0).toUpperCase() + userName.first_name.slice(1)}{' '}
                  {userName.last_name} in?
                </label>
                <div className="mt-2">
                  <Select
                    className="inputFieldStyle"
                    onChange={onChangeHandle}
                    value={selectedGrade}
                    name="child_grade">
                    {grades.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="pt-3">
              <EducatorButton label="Add Curriculum" onClick={addCurriculum} showSpinner={buttonSpinner} />
            </div>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end">
          <HelperBot>More curriculum alignments coming soon!</HelperBot>
        </div>
      </div>
    </div>
  );
};
export default ParentCurriculumSetup;
