import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Stack } from '@student-app/design-system';
import StudentNavbar from '@student-app/components/StudentNavbar/StudentNavbar';
import BackgroundImage from '@assets/images/cyber-academy/background.png';

const StyledContainer = styled.div`
  background-color: #f7e7da;
  background-size: cover;
  height: 100%;
  @media screen and (min-width: 650px) {
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: 100%;
  }
`;

const StyledInnerDiv = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f7e7da 53.34%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  bottom: -100px;
`;

const PageLayout = ({ children, pageTitle }) => (
  <StyledContainer>
    <StyledInnerDiv />
    <Stack direction={'column'} spacing={50}>
      <StudentNavbar title={pageTitle} />
      {children}
    </Stack>
  </StyledContainer>
);

export default PageLayout;

PageLayout.defaultProps = {
  pageTitle: ''
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string
};
