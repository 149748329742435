import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Empty, Skeleton } from 'antd';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import './PastInvoicesStyle.scss';

const InvoiceList = ({ data, currentPage, itemsPerPage }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <ul>
      {currentItems?.map(invoice => (
        <li key={invoice.id} className="invoice">
          <p>
            {t('invoice_number')} {invoice.number}
          </p>
          <a href={invoice.hosted_invoice_url} target="_blank" rel="noreferrer">
            {t('view_invoice')}
          </a>
        </li>
      ))}
    </ul>
  );
};

InvoiceList.propTypes = {
  data: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired
};

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const isPrevPageAvailable = currentPage > 1;
  const isNextPageAvailable = currentPage < totalPages;

  return (
    <div className="controller">
      <button disabled={!isPrevPageAvailable} onClick={() => onPageChange(currentPage - 1)}>
        {t('prev')}
      </button>
      <p>{t('viewing_results', { currentPage, totalPages })}</p>
      <button disabled={!isNextPageAvailable} onClick={() => onPageChange(currentPage + 1)}>
        {t('next')}
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export const PastInvoices = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' });

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, error } = useSWR('/payments/invoices');

  if (error) {
    return (
      <Card bordered={false} className="past-invoices__container cardsStyling">
        <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={t('no_invoices_found')} />
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card bordered={false} className="past-invoices__container cardsStyling">
        <Skeleton active />
      </Card>
    );
  }

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  let pastInvoices = [];

  if (Array.isArray(data?.data?.data)) {
    pastInvoices = data.data.data.filter(invoice => invoice.status === 'paid');
  }

  const totalPages = pastInvoices.length > 0 ? Math.ceil(pastInvoices.length / itemsPerPage) : 0;

  return (
    <Card bordered={false} className="past-invoices__container cardsStyling">
      <h3 className="settingHeadingStyle">{t('past_invoices')}</h3>
      <div className="invoices">
        {pastInvoices.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={t('no_invoices_found')} />
        ) : (
          <>
            <InvoiceList data={pastInvoices} currentPage={currentPage} itemsPerPage={6} />
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </>
        )}
      </div>
    </Card>
  );
};
