/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Card, Form, Checkbox, Select, message } from 'antd';
import { EducatorInputField, EducatorButton } from '../../commonComponents';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { Auth } from 'aws-amplify';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './parentSettingsStyle.css';

const ParentSettings = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const [showSpinnerEditClass, setShowSpinnerEditClass] = useState(false);
  const [showSpinnerEditUser, setShowSpinnerEditUser] = useState(false);
  const [checkGoogle, setCheckGoogle] = useState(false);
  const [buttonSpinnerPassword, setButtonSpinnerPassword] = useState(false);
  const [data, setData] = useState({ old_password: '', new_password: '', confirm_password: '' });
  const [userInformation, setUserInformation] = useState({});
  const [countries, setCountries] = useState([]);
  const [userInformationNew, setUserInformationNew] = useState({});

  const editEmailPreferences = async () => {
    setShowSpinnerEditClass(true);
    const { success, data } = await patchApiWithAuth(API_URL.UPDATE_USER, userInformationNew);
    if (success) {
      message.success(data.message);
      setShowSpinnerEditClass(false);
    } else {
      setShowSpinnerEditClass(false);
    }
  };

  const editUser = async () => {
    setShowSpinnerEditUser(true);
    const { success } = await patchApiWithAuth(API_URL.UPDATE_USER, userInformationNew);
    if (success) {
      setShowSpinnerEditUser(false);
      message.success('User information has been updated successfully');
    } else {
      message.success('User information has been updated successfully');
      setShowSpinnerEditUser(false);
    }
  };

  const updatePasswordFunction = async () => {
    setButtonSpinnerPassword(true);
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      try {
        const changePassword = await Auth.changePassword(currentUser, data.old_password, data.new_password);
        if (changePassword === 'SUCCESS') {
          message.success(`${changePassword}`);
          setButtonSpinnerPassword(false);
          setData({ old_password: '', new_password: '', confirm_password: '' });
        } else {
          message.error(`${changePassword}`);
          setButtonSpinnerPassword(false);
        }
      } catch (err) {
        message.error(`${err}`);
        setButtonSpinnerPassword(false);
      }
    } else {
      setButtonSpinnerPassword(false);
    }
  };
  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    setUserInformation({ ...userInformation, [name]: value });
    setUserInformationNew({ ...userInformationNew, [name]: value });
  };
  const selectCountry = countryName => {
    setUserInformationNew({ ...userInformationNew, country: countryName });
  };
  const getCountries = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_COUNTRIES);
    if (success) {
      setCountries(data);
    }
  };
  const getUserInformation = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      const isGoogle = data.username?.includes('Google') || data.username?.includes('google');
      setCheckGoogle(isGoogle);
      setUserInformation(data);
    }
  };
  useEffect(() => {
    getUserInformation();
    getCountries();
  }, []);

  const onChangedCheck = event => {
    const { name, checked } = event.target;
    setUserInformation({ ...userInformation, [name]: checked });
    setUserInformationNew({ ...userInformationNew, [name]: checked });
  };
  return (
    <div className="container-fluid w-100 p-3  studentMianDivHeight">
      <AuthenticatedNavbar title="Account Settings" playButton />
      <div className="row settingPageScorling">
        <div className="row mt-4 pb-4 ">
          <div className="col col-7 col-sm-6 col-xl-8">
            <Card bordered={false} className="parentSettingFirstCard cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 ">
              <div className="settingHaddingStyle">User Information</div>
              <Form onFinish={editUser}>
                <div className="pt-5 row">
                  <div className="col-6">
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          pattern: new RegExp(/^[A-Za-z]+$/),
                          message: 'Must contain Alphabets'
                        }
                      ]}>
                      <EducatorInputField
                        label="First Name:"
                        inputValue={userInformation.first_name}
                        type="text"
                        name="first_name"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item>
                      <EducatorInputField
                        label="Last Name:"
                        inputValue={userInformation.last_name}
                        type="text"
                        name="last_name"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item className="pt-3">
                  <EducatorInputField
                    label="Email Address:"
                    inputValue={userInformation.email}
                    disabled
                    type="text"
                    name="email"
                    className="fixCardInputFieldTwo"
                    onChange={inputChangeHandler}
                    width="100%"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="pt-3">
                    <div>
                      <label className="labelStyleCard">Country:</label>
                      <div className="mt-3">
                        <Select
                          className="fixCardInputField"
                          onChange={selectCountry}
                          disabled
                          value={userInformation.country}
                          name="country">
                          {countries.map(item => (
                            <Option key={item.name} value={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item className="pt-2">
                  <EducatorButton
                    key="submit"
                    label="Update Information"
                    type="submit"
                    width="100%"
                    showSpinner={showSpinnerEditUser}
                  />
                </Form.Item>
              </Form>
            </Card>

            <Card bordered={false} className="parentBillingInfo cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 mt-4">
              <div className="settingHaddingStyle">Billing Info</div>
              <div>
                <span>
                  <Link to="/memberships-and-billing" className="parentSettingLinkStyle mt-1 cursorPointer">
                    Click here
                  </Link>
                </span>
                <span className="textStyleSetting mt-1 ms-2">to update your billing info through Stripe.</span>
              </div>
            </Card>
          </div>
          <div className="col col-5 col-sm-6 col-xl-4">
            <div>
              <Card bordered={false} className="settingSecondCard cardsStyling p-4 px-sm-3 px-lg-4 px-xxl-5">
                <div className="settingHaddingStyle">Update Password</div>
                <Form onFinish={updatePasswordFunction} className="pt-4">
                  <Form.Item
                    name="old_password"
                    rules={[{ required: true, message: 'Please input your Old Password!' }]}>
                    <EducatorInputField
                      label="Old Password:"
                      type="password"
                      disabled={checkGoogle}
                      passwordValue={data.old_password}
                      name="old_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="new_password"
                    rules={[
                      { required: true, message: 'Please input your New Password!' },
                      {
                        required: true,
                        pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                        message:
                          'Must contain Number , Special Character , upper case letter, lower case letter, min length 8'
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('old_password') !== value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Current And new Password should be different!'));
                        }
                      })
                    ]}>
                    <EducatorInputField
                      label="New Password:"
                      passwordValue={data.new_password}
                      disabled={checkGoogle}
                      type="password"
                      name="new_password"
                      className="fixCardInputField"
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item
                    className="pt-3"
                    name="confirm_password"
                    dependencies={['new_password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('new_password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords do not match'));
                        }
                      })
                    ]}>
                    <EducatorInputField
                      label="Confirm Password:"
                      type="password"
                      name="confirm_password"
                      className="fixCardInputField"
                      dependencies={data.new_password}
                      passwordValue={data.confirm_password}
                      disabled={checkGoogle}
                      onChange={onChangeHandle}
                    />
                  </Form.Item>
                  <Form.Item className="pt-2">
                    <EducatorButton
                      key="submit"
                      label="Update Password"
                      type="submit"
                      width="100%"
                      disabled={checkGoogle}
                      showSpinner={buttonSpinnerPassword}
                    />
                  </Form.Item>
                </Form>
              </Card>
              <Card bordered={false} className="settingThirdCard cardsStyling p-3 px-sm-3  px-lg-4 px-xxl-5 mt-4">
                <Form>
                  <div className="settingHaddingStyle">Email Preferences</div>
                  <div className="textStyleSetting mt-1 ">
                    Choose which email messages and notifications you receive:
                  </div>
                  <div className="displayInline pt-2">
                    <Checkbox
                      name="account_emails"
                      checked={userInformation.account_emails}
                      onChange={onChangedCheck}
                    />
                    &nbsp;
                    <span style={{ alignSelf: 'center' }}>Account Emails</span>
                  </div>
                  <div className="displayInline">
                    <Checkbox name="marketing" checked={userInformation.marketing} onChange={onChangedCheck} />
                    &nbsp;
                    <span>Marketing</span>
                  </div>
                  <div className="displayInline">
                    <Checkbox
                      name="student_updates"
                      checked={userInformation.student_updates}
                      onChange={onChangedCheck}
                    />
                    &nbsp;
                    <span>Student Updates</span>
                  </div>
                  <Form.Item className="pt-2">
                    <EducatorButton
                      key="button"
                      label="Update Prefrences"
                      type="button"
                      width="100%"
                      onClick={editEmailPreferences}
                      showSpinner={showSpinnerEditClass}
                    />
                  </Form.Item>
                </Form>
              </Card>
              <Card
                bordered={false}
                className="settingThirdCard cardsStyling p-3 px-sm-3  px-lg-4 px-xxl-5 mt-4 d-none">
                <Form>
                  <div className="settingHaddingStyle">Cancel Membership</div>
                  <Form.Item className="pt-3">
                    <EducatorButton
                      key="button"
                      label="Update Account Settings"
                      type="button"
                      width="100%"
                      onClick={() => {
                        navigate('/parent-cancel-membership');
                      }}
                    />
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ParentSettings;
