import React from 'react';
import PropTypes from 'prop-types';
import { EducatorButton, EducatorModal } from '../../../commonComponents';
import deleteFrame from '../../../../assets/images/Frame.svg';

export const DeleteAccountModal = ({ show, handleOnCancel, singleUser, handleDeleteUser, showSpinner }) => {
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div className="fixCradCenter mt-4 p-3">
          <div>
            <img src={deleteFrame} alt="deleteFrame" className="cursorPointer" />

            <div className="fixCardHeadingStyle mt-3 px-5">
              Are you sure you want to delete {singleUser?.student?.first_name}{' '}
              {singleUser?.student?.last_name.substring(0, 1).toUpperCase()} Account?
            </div>
            <div className="unitPlanTextStyle px-2">
              When you delete your account you will lose all progress that your child has made in the game. If you want
              to keep your progress, you can always downgrade to a freemium membership.
            </div>
            <div className="pt-3">
              <EducatorButton
                key="button"
                label="Delete Account"
                type="button"
                width="100%"
                onClick={() => handleDeleteUser(singleUser)}
                showSpinner={showSpinner}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DeleteAccountModal;

DeleteAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  singleUser: PropTypes.object.isRequired,
  handleDeleteUser: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired
};
