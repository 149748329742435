import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { Navbar } from '@components/layoutComponents';
import { EducatorButton, EducatorInputField } from '@components/commonComponents';
import {
  API_URL,
  clearCognitoStorage,
  clearStorage,
  getApiWithAuth,
  getIsGoogle,
  getLocalStorageItem,
  GOOGLE_SSO_AUTH_URL,
  GOOGLE_SSO_LOGOUT_URL,
  setFlowComplete,
  setLocalStorageItem,
  setToken,
  setUserID,
  setValue,
  USER_ROLE
} from '@utils';

import googleImage from '@assets/images/googleImage.png';

import './SignIn.scss';

export const SignIn = () => {
  const { t: teacherSignInT } = useTranslation('translation', { keyPrefix: 'teacher_sign_in' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();

  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [googleSpinner, setGoogleSpinner] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const isRostered = searchParams.get('rostered') === 'true';
  const email = searchParams.get('email');
  const [data, setData] = useState({ email: email ? email : '', password: '' });

  const getCurrentUser = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_CURRENT_USER);
    if (success) {
      return data;
    }
  };

  const isHomeschoolTeacherFlow = getLocalStorageItem('isHomeschoolTeacher');

  const signInUser = () => {
    clearCognitoStorage();

    const userRole = getLocalStorageItem('user_role');
    if (!userRole) {
      setLocalStorageItem('user_role', USER_ROLE.TEACHER);
    }

    setButtonSpinner(true);

    Auth.signIn({
      username: data.email,
      password: data.password
    })
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setValue('NEW_PASSWORD_REQUIRED', JSON.stringify(user));
          navigate('/teacher-signup/new-password', {
            state: { userData: data }
          });
        } else {
          saveToken();
        }
      })
      .catch(err => {
        message.error(`${err.message}`);
      })
      .finally(() => {
        setButtonSpinner(false);
      });
  };

  const logoutUser = async () => {
    const Google = getIsGoogle();
    if (Google === '1') {
      clearStorage();

      window.location.href = GOOGLE_SSO_LOGOUT_URL;
    } else {
      await Auth.signOut()
        .then(() => {
          setButtonSpinner(false);
          clearStorage();
          message.error(teacherSignInT('account_not_exist_teacher'));
        })
        .catch(err => {
          setButtonSpinner(false);
          message.error(`${err.message}`);
        });
    }
  };

  const saveToken = async () => {
    await Auth.currentSession()
      .then(async data => {
        setToken(data.accessToken.jwtToken);
        const responseData = await getCurrentUser();
        const currentUserType = responseData.type;
        const userRole = getLocalStorageItem('user_role');
        if (currentUserType !== userRole) {
          logoutUser();
        } else {
          setUserID(responseData.id);
          if (responseData.is_auth) {
            setButtonSpinner(false);
            setFlowComplete(true);
          } else {
            setButtonSpinner(false);
            setFlowComplete(false);
            navigate('/teacher-create-account/curriculum-setup');
          }
        }
      })
      .catch(() => {
        navigate('/teacher-signin');
      });
  };

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const googleSignIn = () => {
    clearCognitoStorage();
    setGoogleSpinner(true);
    window.location.href = GOOGLE_SSO_AUTH_URL;
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5">
        <div className="mt-2">
          <div className="textStyleHeading mb-1">
            {teacherSignInT(isHomeschoolTeacherFlow ? 'homeschool_sign_in' : 'sign_in')}
          </div>
          <Form onFinish={signInUser}>
            <Form.Item name="email" rules={[{ required: !(isRostered && email), message: commonT('input_email') }]}>
              <EducatorInputField
                label={teacherSignInT('email_address')}
                type="email"
                inputValue={data.email}
                name="email"
                onChange={onChangeHandle}
                disabled={isRostered && email}
              />
            </Form.Item>
            <Form.Item
              className="pt-3"
              name="password"
              rules={[{ required: true, message: commonT('input_password') }]}>
              <EducatorInputField
                label={teacherSignInT('password')}
                type="password"
                passwordValue={data.password}
                name="password"
                onChange={onChangeHandle}
              />
            </Form.Item>
            <div className="pt-3">
              <EducatorButton
                label={teacherSignInT('sign_in')}
                type="submit"
                showSpinner={buttonSpinner}
                dataTestId="signInButton"
              />
            </div>
          </Form>
          <div className="pt-3 textStyle400Cabin18">{commonT('or')}</div>
          <div className="pt-3">
            <EducatorButton
              label={teacherSignInT('sign_in_google')}
              className="googleStyle"
              onClick={googleSignIn}
              showSpinner={googleSpinner}
              type="button"
              icon={<img src={googleImage} alt="googleLogo" width={40} height={40} />}
              dataTestId="googleSignInButton"
            />
          </div>
          {!isRostered && (
            <div>
              <div className="mt-3 textStyle400Cabin18">
                {teacherSignInT('no_account')}
                <span
                  className="labelStyle cursorPointer"
                  onClick={() => {
                    navigate('/teacher-create-account');
                  }}>
                  {' '}
                  {teacherSignInT('sign_up')}
                </span>
              </div>
              <div
                className=" mt-3 textStyleForgetPassword cursorPointer"
                onClick={() => {
                  navigate('/forgot-password');
                }}>
                {teacherSignInT('forgot_password')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignIn;
