import React, { useEffect, useState } from 'react';
import { Input, message, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { postApiWithAuth, API_URL, getApiWithAuth } from '@utils';
import { Button, Modal, Form, Text, Title, Colors, Stack } from '@student-app/design-system';

const LinkClassModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('student', { keyPrefix: 'dashboard' });
  const [form] = Form.useForm();
  const [classCode, setClassCode] = useState({});
  const [currentClass, setCurrentClass] = useState(null);
  const [updatingClass, setUpdatingClass] = useState(false);
  const [fetchingClassDetails, setFetchingClassDetails] = useState(false);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setClassCode({ ...classCode, [name]: value });
  };

  const sendCodeParent = async () => {
    setUpdatingClass(true);
    const { data, success } = await postApiWithAuth(API_URL.POST_STUDENTS_LINK_CLASS, classCode);
    if (success) {
      message.success(t('link_success_message'));
      setUpdatingClass(false);
      onClose();
    } else {
      message.error(data.message ?? 'Something went wrong!');
      setUpdatingClass(false);
    }
  };

  const getClassDetails = async () => {
    setFetchingClassDetails(true);
    const { success, data } = await getApiWithAuth(API_URL.GET_STUDENT_CLASS);
    if (success) {
      setCurrentClass(data);
      form.setFieldValue('class_code',  data?.class_code);
      setClassCode({class_code: data?.class_code });
      setFetchingClassDetails(false);
    }
    else {
      setFetchingClassDetails(false);
    }
  };

  useEffect(() => {
    getClassDetails();
  }, []);

  return (
    <Modal destroyOnClose width={517} onCancel={onClose} open={isOpen}>
      <Stack direction="column" spacing={10}>
        <Title style={{ color: Colors.SKY_200 }} level={2}>
          {t('my_class_code')}
        </Title>
        {fetchingClassDetails ? (
          <Stack justifyContent="center" style={{ minHeight: 250 }}>
            <Spin size="large" />
          </Stack>
        ) : (
          <Form form={form} onFinish={sendCodeParent}>
            <Stack direction="column" spacing={30}>
              <Text style={{ textAlign: 'center' }}>
                <Trans>
                  {currentClass ? t('my_class_code_description', { className:  currentClass?.name }) : t('my_class_no_class_description')}
                </Trans>
              </Text>
              <Form.Item name="class_code" rules={[{ required: true, message: t('insert_class_code') }]}>
                <Input
                  size="large"
                  type="text"
                  name="class_code"
                  placeholder={t('class_input_placeholder')}
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Button htmlType="submit" loading={updatingClass}>
                {currentClass ? t('update') : t('join_class')}
              </Button>
            </Stack>
          </Form>
        )}
      </Stack>
    </Modal>
  );
};

export default LinkClassModal;

LinkClassModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
