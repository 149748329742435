import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Spin } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import useSWR from 'swr';
import Slider from 'react-slick';

import { AuthenticatedNavbar } from '../../../layoutComponents';
import { AssignmentCardWithColor, BackgroundColor } from '../../../commonComponents';
import { API_URL } from '@utils';
import { OtherPlaylists } from './OtherPlaylists';

import PremiumIcon from '../../../../assets/images/Premium.svg';
import PrevArrow from '../../../../assets/images/prev.svg';
import NextArrow from '../../../../assets/images/next.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './ParentResourcesStyle.scss';

const VideoThumbnail = ({ item }) => {
  return (
    <div
      className="video-thumbnail__container"
      style={{
        backgroundImage: `url(${item.video_thumbnail})`
      }}>
      <div className="video-info">
        <div className="video-info__text">
          <p>{item.video_title}</p>
          <span>Lesson Video</span>
        </div>
        <button>Start Series &gt;</button>
      </div>
    </div>
  );
};

VideoThumbnail.propTypes = {
  item: PropTypes.object.isRequired
};

export const ParentResources = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { isAllowFreemium, isMemberShipType, title } = location.state || {};
  const [thumbnailSlider, setThumbnailSlider] = useState(null);
  const videoRef = useRef(null);
  const [viewedVideos, setViewedVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [autoplay, setAutoplay] = useState(true);

  const isVideoLocked = isMemberShipType === 'Free' && !isAllowFreemium;
  const { data: userActivitiesResponse, isLoading } = useSWR(`${API_URL.GET_USER_RESOURCES_BY_ID}/${id}`, {
    revalidateOnFocus: false
  });
  const userActivities = userActivitiesResponse?.data || [];

  let videos = [];
  if (userActivities.video) {
    videos = [
      {
        id: 0,
        video: userActivities.video,
        video_thumbnail: userActivities.thumbnail,
        video_title: userActivities.title
      },
      ...(userActivities.activity_videos ?? [])
    ];
  }

  return (
    <>
      <Helmet>
        <title>{title ? `Parent Resource: ${title} - ` : ''}Cyber Legends</title>
      </Helmet>
      {isLoading ? (
        <div className="spineerCenter">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="parent-resources__container container">
            <div className="parent-resources__main">
              <AuthenticatedNavbar smallHeading1="Activities" smallHeading2={`${userActivities.title}`} />
              <div className="row mt-4">
                <div className="video-player__container">
                  <ReactPlayer
                    playing={autoplay}
                    width="100%"
                    height="100%"
                    ref={videoRef}
                    url={currentVideoUrl || videos[0].video}
                    className="imageThumbnailStyle"
                    controls
                    light={<VideoThumbnail item={videos[currentIndex]} />}
                    onStart={() => {
                      setAutoplay(true);
                    }}
                    onEnded={() => {
                      setViewedVideos([...viewedVideos, currentIndex]);
                      thumbnailSlider.slickNext();
                    }}
                  />
                  {isVideoLocked ? (
                    <div className="premiumVideoText">
                      <img src={PremiumIcon} alt="premium-icon" width="77" height="74" className="premium-icon mb-3" />
                      <h3>This resource is for premium users only - upgrade to unlock it.</h3>
                      <Link to="/subscriptions">Upgrade Your Membership &nbsp; &gt;</Link>
                    </div>
                  ) : null}
                </div>
                {videos.length > 1 ? (
                  <div className="thumbnail-slider">
                    <Slider
                      arrows={false}
                      afterChange={current => {
                        setCurrentIndex(current);
                        setCurrentVideoUrl(videos[current]?.video);
                      }}
                      ref={slider => setThumbnailSlider(slider)}
                      slidesToShow={videos.length >= 4 ? 4 : videos.length}
                      swipe={false}
                      className={'thumbnail-slides__container '}>
                      {videos.map((item, index) => {
                        return (
                          <div key={`${index}__${item.video_thumbnail}`}>
                            <div className="slide-inner">
                              <div className="thumbnail__wrapper">
                                {viewedVideos.includes(item.id) ? (
                                  <div className="viewed-video">
                                    <CheckOutlined />
                                    &nbsp; Viewed
                                  </div>
                                ) : null}
                                <img src={item.video_thumbnail} alt={item.video_title} />
                                {isVideoLocked ? (
                                  <div className="premiumVideoText video--small">
                                    <img
                                      src={PremiumIcon}
                                      alt="premium-icon"
                                      width="37"
                                      height="35"
                                      className="premium-icon mb-1"
                                    />
                                    <p>This resource is for premium users only - upgrade to unlock it.</p>
                                  </div>
                                ) : null}
                              </div>
                              <p>{item.video_title}</p>
                              <span>Lesson Video</span>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                    <div>
                      <button
                        aria-label="Previous"
                        className="prev-arrow"
                        onClick={() => {
                          thumbnailSlider.slickPrev();
                          setAutoplay(false);
                        }}>
                        <img src={PrevArrow} alt="Prev slide" />
                      </button>
                      <button
                        aria-label="Next"
                        className="next-arrow"
                        onClick={() => {
                          thumbnailSlider.slickNext();
                          setAutoplay(false);
                        }}>
                        <img src={NextArrow} alt="Next slide" />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row mt-5">
                <div className="col-12 col-md-8">
                  <div className="overview__container">
                    <h3 className="parent-resources__heading--sub mt-3">Overview</h3>
                    <div className="parent-resources__text--sub">{userActivities.description}</div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <h3 className="parent-resources__heading--sub mt-3">Categories</h3>
                  <div className="d-flex flex-wrap">
                    {userActivities.categories.length === 0 ? (
                      <div className="me-2">
                        <BackgroundColor title="No Category" background="#E5E5E5" textColor="#000000" />
                      </div>
                    ) : null}
                    {userActivities.categories?.map(item => {
                      return (
                        <div className="me-2" key={item.id}>
                          <BackgroundColor
                            title={item.name}
                            background={item.background_color}
                            textColor={item.text_color}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-8 mr-auto">
                  <div className="related-resources__container mt-5">
                    <h3 className="parent-resources__heading--sub">Practice What You’ve Learned:</h3>
                    {userActivities.related_resources?.map(item => {
                      return (
                        <div key={item.id}>
                          <AssignmentCardWithColor
                            title={item.title}
                            past
                            resources={!(isMemberShipType === 'Free' && !isAllowFreemium)}
                            resourcesTwo={isMemberShipType === 'Free' && !isAllowFreemium}
                            downloadResources={() => {
                              isMemberShipType === 'Free' && !isAllowFreemium
                                ? navigate('/memberships-and-billing')
                                : window.open(item.file_or_url, '_blank');
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OtherPlaylists itemId={id} isMemberShipType={isMemberShipType} />
        </>
      )}
    </>
  );
};
export default ParentResources;
