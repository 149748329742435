import useSWR from 'swr';
import { API_URL, fetcher } from '../utils';

export const useStudentSubscriptionDetails = studentId => {
  const url = `${API_URL.GET_STUDENT_SUBSCRIPTION_DETAILS}/${studentId}`;

  const options = {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  };

  const { data, error, isLoading, isValidating, mutate } = useSWR(studentId ? url : null, fetcher, options);
  return {
    isLoading,
    error: error,
    data: data?.data,
    isValidating,
    mutate
  };
};
