import { EducatorModal } from '@components/commonComponents';
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import './AlignmentDetailsModal.scss';

const columns = t => [
  {
    title: t('curriculum'),
    dataIndex: 'curriculum',
    key: 'curriculum'
  },
  {
    title: t('standard'),
    dataIndex: 'standard',
    key: 'standard'
  },
  {
    title: t('description'),
    dataIndex: 'description',
    key: 'description'
  }
];

export const AlignmentDetailsModal = ({ show, handleOnCancel, curriculums }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const data = [];

  curriculums.forEach((curriculum, i) => {
    curriculum.alignments.forEach((alignment, j) => {
      data.push({
        key: `${i}.${j}`,
        curriculum: curriculum.name,
        standard: alignment.standard,
        description: alignment.description
      });
    });
  });
  return (
    <EducatorModal height={'620px'} visible={show} onCancel={handleOnCancel}>
      <div className="alignment-modal__container">
        <div className="alignment-modal__header">
          <h3 className="alignment-modal__heading--sub mt-3">{t('curriculum_alignments_details')}</h3>
          <div className="alignment-modal__text--sub">{t('curriculum_alignments_details_text')}</div>
        </div>
        <div>
          <Table columns={columns(t)} dataSource={data} />
        </div>
      </div>
    </EducatorModal>
  );
};

AlignmentDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  curriculums: PropTypes.array.isRequired
};
