import React from 'react';
import PropTypes from 'prop-types';
import { EducatorModal } from '../../../commonComponents';

export const LinkExistingModal = ({ show, handleOnCancel, parentOverView }) => {
  return (
    <EducatorModal
      visible={show}
      onCancel={handleOnCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle fixCradCenter">Link an Existing Account</div>
          <div className="linkExistingModalTextStyling fixCradCenter pt-2">
            You’ve indicated that your child already has a Cyber Legends account. To add them to your class, share the
            classcode below and have them enter it in the game.
          </div>
          <div className="linkExistingModalCodeStyling pt-3">{parentOverView.Household_code}</div>
          <div className="linkExistingModalTextStyling">Your Family Code</div>
        </div>
      }
    />
  );
};

export default LinkExistingModal;

LinkExistingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  parentOverView: PropTypes.object.isRequired
};
