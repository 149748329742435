import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField } from '@components/commonComponents';
import { Navbar, Multistep } from '@components/layoutComponents';
import { API_URL, postApiWithAuth } from '@utils';
import { Colors, Title } from '@teacher-app/design-system';

import babyImage from '@assets/images/educatorTeacherImage.png';

const CreateSchoolSetup = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'school_setup' });
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const addSchool = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.CREATE_SCHOOL, newData);
    if (success) {
      setButtonSpinner(false);
      navigate('/teacher-create-account/select-licence');
    } else {
      message.error(data.message);
      setButtonSpinner(false);
    }
  };
  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row  py-5">
        <div className="col-3  d-flex justify-content-center">
          <Multistep select="School Setup" check={[1, 1, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <Title level={2} style={{ color: Colors.WHITE_0 }}>{t('school_setup')}</Title>
            <Form onFinish={addSchool}>
              <Form.Item className="pt-3" name="name" rules={[{ required: true, message: t('input_school_name') }]}>
                <EducatorInputField
                  label={t('type_school_name')}
                  type="text"
                  name="name"
                  inputValue={newData?.name}
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item
                className="pt-3"
                name="school_board"
                rules={[{ required: true, message: t('input_school_board') }]}>
                <EducatorInputField
                  label={t('school_board_question')}
                  inputValue={newData?.school_board}
                  type="text"
                  name="school_board"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="pt-3">
                <EducatorButton
                  label={t('add_school')}
                  type="submit"
                  showSpinner={buttonSpinner}
                  dataTestId="addSChoolButton"
                />
              </div>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end">
          <img src={babyImage} alt="Logo" width="235" height="255.43" />
        </div>
      </div>
    </div>
  );
};
export default CreateSchoolSetup;
