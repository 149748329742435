import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '@components/commonComponents';

export const AssignmentCurriculum = ({ show, onCancel, onFinish, checkCurriculum, onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  return (
    <EducatorModal
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyle">{t('select_curriculum')}</div>
          <div className="fixCardtextStyle mt-2">{t('view_topics_from_school_curriculum')}</div>
          <Form onFinish={onFinish} className="pt-4">
            {checkCurriculum?.available ? (
              <Form.Item>
                <EducatorButton
                  key="submit"
                  label={`${checkCurriculum?.name} ${t('state_curriculum')}`}
                  type="submit"
                  width="100%"
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <EducatorButton
                key="button"
                label={t('cyber_legends_curriculum')}
                type="button"
                width="100%"
                onClick={onClick}
              />
            </Form.Item>
          </Form>
        </div>
      }
    />
  );
};

AssignmentCurriculum.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  checkCurriculum: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};
