/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Card, Form, Select, Divider, Collapse, message } from 'antd';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { EducatorInputField, EducatorButton, BackgroundColor } from '../../commonComponents';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { postApiWithAuth, getApiWithAuth } from '../../../utils/api';
import { API_URL } from '../../../utils/constants';
import './referralStyle.css';
const Referrals = () => {
  const { Option } = Select;
  const { Panel } = Collapse;

  const [parentFriendData, setParentFriendData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [parentData, setParentData] = useState({});

  const inputChangeHandler = e => {
    const { name, value } = e.target;
    setParentFriendData({ ...parentFriendData, [name]: value });
  };
  const setDataFriend = async () => {
    setButtonSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_SEND_INVITE, parentFriendData);
    if (success) {
      setButtonSpinner(false);
      message.success(data.message);
      setParentFriendData({ first_name: '', email: '', country: '', desc: '' });
      getLoginDetails();
    } else {
      message.error(data.messsage);
      setButtonSpinner(false);
    }
  };
  const selectUserType = selectedType => {
    setParentFriendData({ ...parentFriendData, user_type: parseInt(selectedType) });
  };

  const getLoginDetails = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_EARN_RECORD);
    if (success) {
      setParentData(data);
    }
  };

  useEffect(() => {
    getLoginDetails();
  }, []);

  return (
    <>
      <div className="container-fluid p-2 p-md-2 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar title="Referrals" playButton />
        <div className="row pb-3">
          <div className="unitPlanTextStyle row">
            <div className="col-7 col-sm-11 col-xxl-7">
              <div>
                Using the form below, invite your parent and teacher friends to sign up for Cyber Legends.For every
                successful signup, you’ll receive one free month of access to our premium solution.
              </div>
              <div className="referalHadding pt-2">1. Refer a Friend</div>
              <div>
                Enter your friend’s info below, and let them know what you love about Cyber Legends – so they can join
                too! And so you can get your free month of Cyber Legends premium solution!”
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-10 col-sm-12 col-xxl-10">
              <Card bordered={false} className="cardWidth cardsStyling p-3 ">
                <Form onFinish={setDataFriend}>
                  <div className="pt-5 row">
                    <div className="col-6">
                      <Form.Item
                        name="first_name"
                        rules={[
                          { required: true, message: 'Please input your Frient Name!' },
                          {
                            pattern: new RegExp(/^[A-Za-z]+$/),
                            message: 'Must contain Alphabets'
                          }
                        ]}>
                        <EducatorInputField
                          label="Your Friend’s Name:"
                          inputValue={parentFriendData.first_name}
                          type="text"
                          name="first_name"
                          className="fixCardInputFieldTwo"
                          onChange={inputChangeHandler}
                          width="100%"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ),
                            message: 'Please input your Friend Email!'
                          }
                        ]}>
                        <EducatorInputField
                          label="Your Friend’s Email:"
                          inputValue={parentFriendData.email}
                          type="email"
                          name="email"
                          className="fixCardInputFieldTwo"
                          onChange={inputChangeHandler}
                          width="100%"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item>
                    <div className="pt-3">
                      <div>
                        <label className="labelStyleCard">User Type:</label>
                        <div className="mt-3">
                          <Select
                            className="fixCardInputField"
                            onChange={selectUserType}
                            value={parentFriendData.country}
                            name="user_type">
                            <Option key="1" value="1">
                              Teacher
                            </Option>
                            <Option key="2" value="2">
                              Parent
                            </Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                  <div className="col-12 pt-3">
                    <Form.Item name="desc">
                      <EducatorInputField
                        label="What I love about Cyber Legends? (Optional)"
                        inputValue={parentFriendData.desc}
                        type="text"
                        name="desc"
                        className="fixCardInputFieldTwo"
                        onChange={inputChangeHandler}
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item className="pt-2 ">
                    <div className="d-flex justify-content-end">
                      <EducatorButton
                        key="submit"
                        label="Send Invites"
                        type="submit"
                        width="143px"
                        showSpinner={buttonSpinner}
                      />
                    </div>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>

          <div className="unitPlanTextStyle row mt-3">
            <div className="col-7 col-sm-11 col-xxl-7">
              <div className="referalHadding pt-2">2. Progress of People You’ve Referred</div>
              <div>
                Track the progress of the people you’ve referred below.Once they’ve registered, the status will say
                “Reward Sent” and we’ll add your reward to your account.
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-10 col-sm-12 col-xxl-10">
              <Card bordered={false} className="cardWidth cardsStyling p-3">
                <div className="row assignmentInnerCardHadding pb-2">
                  <div className="col-2 wordBreak">Name</div>
                  <div className="col-4 wordBreak">Email Address</div>
                  <div className="col-2 wordBreak">User Type</div>
                  <div className="col-3 wordBreak">Reward Status</div>
                </div>
                {parentData.api_invites?.map((item, index) => {
                  return (
                    <div
                      className={
                        index % 2 === 0 ? 'row referalFirstBackgroundChange' : 'row referalSecondBackgroundChange'
                      }
                      key={item.id}>
                      <div className="col-2 wordBreak">{item.first_name}</div>
                      <div className="col-4 wordBreak">{item.email}</div>
                      <div className="col-2 wordBreak">{item.user_type == 1 ? 'Teacher' : 'Parent'}</div>
                      <div className="col-xxl-2 col-sm-3 wordBreak centerText">
                        <BackgroundColor
                          title={
                            item.referral_status === 'REFERRAL_SENT'
                              ? 'Referral Sent'
                              : item.referral_status === 'REGISTERED_USER'
                              ? 'User Registered'
                              : 'Reward Sent'
                          }
                          background={
                            item.referral_status === 'REFERRAL_SENT'
                              ? '#6BD3E8'
                              : item.referral_status === 'REGISTERED_USER'
                              ? '#F8CA42'
                              : '#7ED46A'
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </Card>
            </div>
          </div>

          <div className="unitPlanTextStyle row mt-3">
            <div className="col-7 col-sm-11 col-xxl-7">
              <div className="referalHadding pt-2">3. Earn Rewards</div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-10 col-sm-12 col-xxl-10">
              <Card bordered={false} className="cardWidth cardsStyling p-3">
                <div className="row">
                  <div className="col-4">
                    <div className="cardHeadingStyle">Total Referrals Sent</div>
                    <div className="pt-2 cardMiddleStyle">{parentData.total_invite_sent}</div>
                    <div className="pt-2 cardTextStyle">Created since you joined Cyber Legends.</div>
                  </div>
                  <div className="col-4 d-flex ps-1">
                    <Divider type="vertical" className="cardLineStyle" />
                    <div className="somePadding">
                      <div className="cardHeadingStyle">Successful Referrals</div>
                      <div className="pt-2 cardMiddleStyle">{parentData.total_invite_registered}</div>
                      <div className="pt-2 cardTextStyle">You’re still waiting to connect 5 parents.</div>
                    </div>
                  </div>
                  <div className="col-4 d-flex ps-1">
                    <Divider type="vertical" className="cardLineStyle" />
                    <div className="somePadding">
                      <div className="cardHeadingStyle">Total Reward Earned</div>
                      <div className="pt-2 cardMiddleStyle">{parentData.total_reward_earned}</div>
                      <div className="pt-2 cardTextStyle">Months of free premium membership</div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="referalHadding mt-4">Frequently Asked Questions</div>
          <div className="row mt-3">
            <div className="col-10 col-sm-12 col-xxl-10">
              <Card bordered={false} className="cardWidth cardsStyling p-3">
                <Collapse
                  className="collapseStyle"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="referalIconStyle" />
                    ) : (
                      <PlusOutlined className="referalIconStyle" />
                    )
                  }
                  expandIconPosition={'end'}>
                  <Panel className="panelHeadingsStyleReferal mt-2" header="Who can I refer?" key="1">
                    <p className="faqAnswer">
                      Refer parents and teachers you know who will benefit from Cyber Legends. For every successful
                      parent or teacher referral, you will be credited one month of premium subscription.
                    </p>
                  </Panel>
                  <Panel
                    className="panelHeadingsStyleReferal mt-2"
                    header="How do I get credit for my referrals?"
                    key="2">
                    <p className="faqAnswer">
                      Once the referred person signs up, your account will automatically get credited the next day for
                      one month of premium subscription for all your children.
                    </p>
                  </Panel>
                  <Panel className="panelHeadingsStyleReferal mt-2" header="How many referrals can I make?" key="3">
                    <p className="faqAnswer">You can make unlimited referrals.</p>
                  </Panel>
                  <Panel className="panelHeadingsStyleReferal mt-2" header="Does the referral expire?" key="4">
                    <p className="faqAnswer">Referrals do not expire.</p>
                  </Panel>
                  <Panel
                    className="panelHeadingsStyleReferal mt-2"
                    header="What is included in the premium subscription?"
                    key="5">
                    <p className="faqAnswer">
                      Our premium VIP monthly subscription for parents includes: teaching videos + activity kits for
                      families and bonus in-game goodies for the kids. Also included: the ability to generate reports
                      based on a child’s competency as well as customize a child’s learning path, and exclusive access
                      to coding lessons.
                    </p>
                  </Panel>
                </Collapse>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Referrals;
