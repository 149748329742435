import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Colors, Card, Link, Stack, Text, Title } from '@teacher-app/design-system';

export const GlossaryTermCard = ({ cardData }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'support' });
  return (
    <Card width='100%'>
      <Stack direction='column' alignItems='flex-start'>
      <Title level={3}>{cardData.Term}</Title>
      <Stack justifyContent='space-between'>
        <Title level={5} style={{ color: Colors.VIOLET_100 }}>{cardData.Topic.toUpperCase()} - GRADE {cardData.Grade}</Title>
        <Link size='small' href={cardData.Reference} target="_blank" rel="noopener noreferrer">{t('source')}</Link>
      </Stack>
      <Text size='small'>{cardData.Definition}</Text>
      </Stack>
    </Card>
  );
};
export default GlossaryTermCard;

GlossaryTermCard.propTypes = {
  cardData: propTypes.shape({
    Term: propTypes.string,
    Definition: propTypes.string,
    Grade: propTypes.string,
    Topic: propTypes.string,
    Reference: propTypes.string
  }).isRequired
};
