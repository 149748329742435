import React, { useState } from 'react';
import { Form, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar } from '@components/layoutComponents';
import { EducatorButton, EducatorInputField } from '@components/commonComponents';
import { setToken, completeFlow } from '@utils';

export const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state;

  const userData = state.userData ?? null;
  const [data, setData] = useState({ new_password: '', confirm_password: '' });
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const handleOnSubmit = async () => {
    setButtonSpinner(true);
    if (userData) {
      try {
        const cognitoUser = await Auth.signIn({
          username: userData.email,
          password: userData.password
        });

        await Auth.completeNewPassword(cognitoUser, data.new_password, {
          given_name: 'Test'
        });
        message.success('Password updated successfully');

        await completeFlow();
        await saveUserToken();

        navigate('/teacher-dashboard');
      } catch (err) {
        message.error(`${err}`);
      }
    }

    setButtonSpinner(false);
  };

  const saveUserToken = async () => {
    await Auth.currentSession()
      .then(async data => {
        setToken(data.accessToken.jwtToken);
        setButtonSpinner(false);
      })
      .catch(e => {
        message.error(e.message);
        navigate('/teacher-signin');
      });
  };

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="innerDivSignin py-5 w-100">
        <div className="mt-2">
          <div className="textStyleHeading">Update your password</div>
          <div className="mt-2 forgetPasswordTextStyle">
            You need to update your password to continue using the platform. Please enter your new password below.
          </div>
          <div className="mt-4">
            <Form onFinish={handleOnSubmit}>
              <Form.Item
                className="pt-2"
                name="new_password"
                rules={[
                  { required: true, message: 'Please input your Password!' },
                  {
                    required: true,
                    pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                    message:
                      'Must contain Number , Special Character , upper case letter, lower case letter, min length 8'
                  }
                ]}>
                <EducatorInputField
                  label="New Password:"
                  passwordValue={data.new_password}
                  type="password"
                  name="new_password"
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item
                className="pt-2"
                name="confirm_password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    }
                  })
                ]}>
                <EducatorInputField
                  label="Confirm Password:"
                  type="password"
                  name="confirm_password"
                  passwordValue={data.confirm_password}
                  dependencies={data.new_password}
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <div className="pt-3">
                <EducatorButton label="Update Password" type="submit" showSpinner={buttonSpinner} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
