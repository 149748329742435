import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { CardBlock, EducatorButton } from '@components/commonComponents';
import { useCredits } from '@hooks';

import CreditIcon from '@assets/images/credit.svg';
import './RewardStats.scss';

export const RewardStats = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });
  const navigate = useNavigate();
  const { data: credits } = useCredits();

  const handleClick = () => {
    if (credits.available > 0) {
      navigate('/resources');
    } else {
      message.info(t('earn_credits_message'));
    }
  };

  return (
    <CardBlock className="reward_stats__container">
      <h3>{t('reward_stats')}</h3>
      <div className="reward_stats__content mt-4">
        <div className="stats__item row">
          <span className="col-8">{t('credits_earned')}</span>
          <span className="col-4 credit-count">
            {credits.earned}
            <img src={CreditIcon} alt="credit" />
          </span>
        </div>
        <div className="stats__item row">
          <span className="col-8">{t('credits_used')}</span>
          <span className="col-4 credit-count">
            {credits.used}
            <img src={CreditIcon} alt="credit" />
          </span>
        </div>
        <div className="stats__item row">
          <span className="col-8">{t('credits_available')}</span>
          <span className="col-4 credit-count">
            {credits.available}
            <img src={CreditIcon} alt="credit" />
          </span>
        </div>
      </div>
      <EducatorButton
        label={credits.available > 0 ? t('use_credit') : t('earn_credits_button')}
        type="button"
        dataTestId="useCreditButton"
        variant="outline"
        onClick={handleClick}
      />
    </CardBlock>
  );
};
