import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { CopyOutlined } from '@ant-design/icons';
import { useAppState } from '@context';
import { EducatorModal } from '../../index';

export const ExistingModal = ({ show, onCancel }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);

  return (
    <>
      <EducatorModal
        visible={show}
        onCancel={onCancel}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle fixCradCenter">{t('add_existing_accounts')}</div>
            <div className="linkExistingModalTextStyling fixCradCenter pt-2">{t('existing_accounts_note')}</div>

            <div className="clipboard-title">{t('your_class_code')}</div>
            <div className="clipboard-code pt-3">
              {currentClass?.class_code}
              <CopyOutlined
                style={{ color: 'blue', fontSize: '18px' }}
                className="ms-4"
                onClick={() => {
                  navigator.clipboard.writeText(currentClass?.class_code);
                  message.info(t('class_code_copied'));
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

ExistingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};
