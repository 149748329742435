import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { ChevronRight } from 'react-feather';
import colors from '../../theme/colors';

const AntLink = Typography.Link;

const getTypographySizes = size => {
  if (size === 'medium') {
    return {
      fontSize: '18px !important',
      lineHeight: '120% !important'
    };
  }
  return {
    fontSize: '16px !important',
    lineHeight: '120% !important'
  };
};

const StyledLink = styled(AntLink)`
  font-size: ${props => getTypographySizes(props.size).fontSize};
  line-height: ${props => getTypographySizes(props.size).lineHeight};
  white-space: nowrap;
  display: flex;
  align-items: center;
  &.ant-typography {
    font-family: Lexend, sans-serif;
    color: ${colors.VIOLET_100};
    text-align: left;
    font-weight: 800;
    > svg {
      height: ${props => (props.size === 'small' ? '14px' : '20px')};
      width: ${props => (props.size === 'small' ? '14px' : '20px')};
      margin-left: 4px;
      stroke-width: 2.5px;
    }
    &:hover {
      color: ${colors.VIOLET_200};
    }
  }
`;

const Link = ({ children, size, hideIcon, icon, ...rest }) => {
  return (
    <StyledLink size={size} {...rest}>
      {children}
      {!hideIcon && (icon || <ChevronRight />)}
    </StyledLink>
  );
};

export default Link;

Link.defaultProps = {
  children: '',
  size: 'small',
  className: '',
  hideIcon: false,
  icon: null
};

Link.propTypes = {
  children: PropTypes.node,
  /** Custom link icon */
  icon: PropTypes.node,
  /** Can be set to hide icon */
  hideIcon: PropTypes.bool,
  /**  Can be set to 'small' and 'medium'. 'medium' is default */
  size: PropTypes.oneOf(['small', 'medium']),
  /** Can be set to pass custom classes */
  className: PropTypes.string
};
