import { SET_CURRENT_CLASS_ID, SET_CLASSES } from '@context';
import { setLocalStorageItem } from '@utils';

export const classReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_CLASS_ID:
      setLocalStorageItem('class_id', action.payload);
      return { ...state, classId: action.payload };

    case SET_CLASSES:
      return { ...state, classes: action.payload };

    default:
      return state;
  }
};
