import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { EducatorButton, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';

import './parentAdditionalChild.css';

export const ParentAdditionalChild = () => {
  const navigate = useNavigate();

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row  py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Curriculum Setup" check={[1, 1, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">Before we continue on, would you like to add another child?</div>
            <Form>
              <Form.Item className="pt-5">
                <EducatorButton
                  label="No, thank you! "
                  type="button"
                  onClick={() => navigate('/parent-create-account/select-plan')}
                />
              </Form.Item>
              <Form.Item className="pt-3">
                <EducatorButton
                  label="Yes, please!"
                  type="button"
                  onClick={() => navigate('/parent-create-account/child-setup')}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          <HelperBot>Almost there...</HelperBot>
        </div>
      </div>
    </div>
  );
};
export default ParentAdditionalChild;
