import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './educatorInputFielsStyle.scss';

const EducatorInputField = ({
  label,
  type,
  name,
  inputValue,
  passwordValue,
  placeholder,
  onChange,
  required,
  small,
  className,
  suffix,
  width,
  onKeyDown,
  headerColor,
  disabled
}) => {
  return (
    <div>
      {label && (
        <label className={className ? 'labelStyleCard capital' : 'labelStyle capital'} style={{ color: headerColor }}>
          {label}
        </label>
      )}
      <div className={headerColor ? 'mt-2' : 'mt-3'}>
        {type === 'password' ? (
          <Input.Password
            placeholder={placeholder}
            value={passwordValue}
            type={type}
            className={`${className} ${small ? 'inputFieldSmallStyle' : 'inputFieldStyle'}`}
            onChange={onChange}
            name={name}
            style={{ width: width }}
            required={required}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            disabled={disabled}
            data-testid="passwordInput"
          />
        ) : (
          <Input
            placeholder={placeholder}
            value={inputValue}
            type={type}
            className={`${className} ${small ? 'inputFieldSmallStyle' : 'inputFieldStyle'}`}
            onChange={onChange}
            name={name}
            required={required}
            suffix={suffix}
            style={{ width: width }}
            onKeyDown={onKeyDown}
            disabled={disabled}
            data-testid={`${name}Input`}
          />
        )}
      </div>
    </div>
  );
};
export default EducatorInputField;

EducatorInputField.defaultProps = {
  label: '',
  name: '',
  inputValue: '',
  placeholder: '',
  onChange: () => {},
  required: false,
  small: false,
  className: '',
  suffix: '',
  width: '',
  onKeyDown: () => {},
  passwordValue: '',
  headerColor: '',
  disabled: false
};
EducatorInputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  suffix: PropTypes.any,
  width: PropTypes.string,
  onKeyDown: PropTypes.func,
  passwordValue: PropTypes.string,
  headerColor: PropTypes.string,
  disabled: PropTypes.bool
};
