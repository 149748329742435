import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import deleteImage from '../../../assets/images/deleteImage.svg';
import rightArrow from '../../../assets/images/rightArrow.svg';
import editIcon from '../../../assets/images/editIcon.svg';
import freemium from '../../../assets/images/Freemium.svg';
import premium from '../../../assets/images/Premium.svg';

import './assignmentCardWithColorStyle.scss';

export const AssignmentCardWithColor = ({
  color,
  date,
  title,
  viewReports,
  editAssignment,
  deleteAssignment,
  past,
  resources,
  downloadResources,
  membership,
  account,
  isFree,
  resourcesTwo,
  userInfo,
  loading
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  return (
    <div className="mt-3">
      {membership || account ? (
        <Card bordered={false} className="assignmentCardStyle" loading={loading}>
          <div className="noPadding">
            <Divider type="vertical" className={'cardLinePast'} style={{ background: color }} />
            <div className="px-sm-2 px-xl-4 assignmentCardMainDiv">
              <div className="d-flex">
                <div className="memberShipImage">
                  {isFree ? <img src={freemium} alt="Freemium" /> : <img src={premium} alt="Premium" />}
                  <div className="assignmentInnerCardDate">{isFree ? 'Freemium' : 'Premium'}</div>
                </div>
                <div className="mx-sm-2 mx-lg-3">
                  <div className="assignmentInnerCardGrade">{userInfo.grade}</div>
                  <div className="assignmentInnerCardHeading">{title}</div>
                  <div className="assignmentInnerCardDate">{userInfo.topic}</div>
                </div>
              </div>
              <div className="editAccountDetail">
                <div className="assignmentCardSecondInnerDiv me-2 cursorPointer" onClick={viewReports}>
                  {membership ? 'Edit Membership' : 'Edit Account Details'} &nbsp;
                  <img src={rightArrow} alt="right arrow" className="cursorPointer" />
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card bordered={false} className="assignmentCardStyle" loading={loading}>
          <div className="noPadding">
            <Divider
              type="vertical"
              className={past ? 'cardLinePast' : 'cardLineAssignment'}
              style={{ background: color }}
            />
            <div className=" px-sm-2 px-lg-3 px-xl-4 assignmentCardMainDiv">
              <div>
                <div className="assignmentInnerCardHeading">{title}</div>
                <div className="assignmentInnerCardDate">{date}</div>
              </div>
              <div
                className={
                  past && !resources
                    ? 'assignmentCardSecondMainDivSecond'
                    : resources
                    ? ''
                    : 'assignmentCardSecondMainDiv'
                }>
                {resources ? (
                  <div className="assignmentCardSecondInnerDiv cursorPointer" onClick={downloadResources}>
                    {t('download')} &nbsp;
                    <img src={rightArrow} alt="deleteImage" className="cursorPointer" />
                  </div>
                ) : resourcesTwo ? (
                  <div className="assignmentCardSecondInnerDiv cursorPointer" onClick={downloadResources}>
                    <img src={premium} alt="premium_star_icon" height="23px" width="24px" />
                    &nbsp; {t('upgrade_to_view')} &nbsp;
                    <img src={rightArrow} alt="deleteImage" className="cursorPointer" />
                  </div>
                ) : (
                  <>
                    <div className="assignmentCardSecondInnerDiv cursorPointer" onClick={viewReports}>
                      {t('view_report')} &nbsp;
                      <img src={rightArrow} alt="deleteImage" className="cursorPointer" />
                    </div>
                    {past ? null : (
                      <div onClick={editAssignment}>
                        <img src={editIcon} alt="editIcon" className="cursorPointer" />
                      </div>
                    )}

                    <div onClick={deleteAssignment}>
                      <img src={deleteImage} alt="deleteImage" className="cursorPointer" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};
export default AssignmentCardWithColor;
AssignmentCardWithColor.defaultProps = {
  viewReports: () => {},
  editAssignment: () => {},
  deleteAssignment: () => {},
  downloadResources: () => {},
  date: '',
  past: false,
  color: '',
  resources: false,
  membership: false,
  account: false,
  isFree: false,
  resourcesTwo: false,
  userInfo: {},
  loading: false
};
AssignmentCardWithColor.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  viewReports: PropTypes.func,
  editAssignment: PropTypes.func,
  deleteAssignment: PropTypes.func,
  past: PropTypes.bool,
  resources: PropTypes.bool,
  downloadResources: PropTypes.func,
  membership: PropTypes.bool,
  account: PropTypes.bool,
  userInfo: PropTypes.object,
  isFree: PropTypes.bool,
  resourcesTwo: PropTypes.bool,
  loading: PropTypes.bool
};
