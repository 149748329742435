import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import './OnboardingVideo.scss';
import { API_URL, getApiWithAuth } from '@utils';
import { message, Spin } from 'antd';

export const OnboardingVideoV2 = ({
  playing,
  type,
  title,
  messageText,
  videoFirst,
  link,
  playerHeight,
  playerWidth
}) => {
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  useEffect(async () => {
    setLoading(true);
    const URL = `${API_URL.TUTORIAL_VIDEOS}/${type}`;
    const { success, data } = await getApiWithAuth(URL);

    if (success) {
      if (data && data['data']) {
        setVideoUrl(data['data']['video_url']);
        setThumbnail(data['data']['video_thumbnail']);
      }
      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  }, [type]);

  return (
    <div className="onboarding-video__container">
      {loading ? (
        <div className="col-md-8 col-12 pb-3 ">
          <div>
            <div className="scorlingHeight">
              <div className="emptyMessagePlanner mt-4">
                <Spin size="large" />
              </div>
            </div>
          </div>
        </div>
      ) : videoFirst ? (
        <>
          <div className="video__container mt-0">
            <ReactPlayer
              playing={playing}
              url={videoUrl}
              width={playerWidth}
              height={playerHeight}
              controls
              light={thumbnail}
            />
          </div>
          <h3 className="fixCardHeadingStyle">{title}</h3>
          <p>{messageText}</p>
          <div className="d-flex justify-content-center mt-3">{link}</div>
        </>
      ) : (
        <>
          <h3 className="fixCardHeadingStyle">{title}</h3>
          <p>{messageText}</p>
          <div className="video__container">
            <ReactPlayer
              playing={playing}
              url={videoUrl}
              width={playerWidth}
              height={playerHeight}
              controls
              light={thumbnail}
            />
          </div>
          <div className="d-flex justify-content-center mt-3">{link}</div>
        </>
      )}
    </div>
  );
};

OnboardingVideoV2.defaultProps = {
  title: '',
  messageText: '',
  videoFirst: true,
  link: null,
  playerHeight: '161px',
  playerWidth: '252px',
  playing: true
};

OnboardingVideoV2.propTypes = {
  type: PropTypes.oneOf([
    'AddingStudents',
    'CreatingAssignments',
    'DashboardOverview',
    'TeachingResources',
    'SetupPlannerDetail'
  ]).isRequired,
  title: PropTypes.string,
  messageText: PropTypes.string,
  videoFirst: PropTypes.bool,
  link: PropTypes.element,
  playerHeight: PropTypes.string,
  playerWidth: PropTypes.string,
  playing: PropTypes.bool
};
