import useSWR from 'swr';
import { API_URL, getLocalStorageItem, USER_ROLE } from '@utils';

export const useSchoolTutorial = (slug) => {
   const userRole = getLocalStorageItem('user_role');
  if (!userRole || userRole !== USER_ROLE.TEACHER) return {
    loading: false,
    data: []
  };

  const url = `${API_URL.GET_SCHOOL_TUTORIALS}${slug}`;

  const { data, error, isLoading, mutate, isValidating } = useSWR(url);

  const response = data?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error,
    data: response ?? [],
    revalidate,
    isValidating
  };
};