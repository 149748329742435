import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tag, Button, Pagination, Skeleton, message } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CardBlock, EducatorButton } from '@components/commonComponents';

import { useReferrals } from '@hooks';
import './ReferralProgress.scss';

dayjs.extend(relativeTime);

export const ReferralProgress = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'referrals' });
  const { data, loading, createReferral } = useReferrals();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const dotPagination = (total, current) => {
    const dots = [];
    for (let i = 1; i <= Math.ceil(total / pageSize); i++) {
      dots.push(
        <span
          key={i}
          style={{
            height: '8px',
            width: '8px',
            backgroundColor: i === current ? '#1890ff' : '#d9d9d9',
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0 4px',
            cursor: 'pointer'
          }}
          onClick={() => setCurrentPage(i)}
        />
      );
    }
    return <div style={{ textAlign: 'center', padding: '10px 0' }}>{dots}</div>;
  };

  const referralStatus = data.reduce(
    (acc, curr) => {
      acc.sent += curr.referral_status === 'REFERRAL_SENT' ? 1 : 0;
      acc.accepted += curr.referral_status === 'REGISTERED_USER' ? 1 : 0;
      return acc;
    },
    { sent: 0, accepted: 0 }
  );

  const handleResend = async record => {
    await createReferral(record.email);
    message.success(t('invite_sent'));
  };

  const columns = [
    {
      title: t('column_email'),
      dataIndex: 'email',
      key: 'email',
      render: email => email
    },
    {
      title: t('column_date'),
      dataIndex: 'invitation_date',
      key: 'invitation_date',
      render: date => dayjs(date).fromNow(),
      sorter: (a, b) => dayjs(a.invitation_date).unix() - dayjs(b.invitation_date).unix()
    },
    {
      title: t('column_status'),
      key: 'referral_status',
      dataIndex: 'referral_status',
      render: (status, record) => {
        const statusMapping = {
          REGISTERED_USER: { color: 'green', label: t('credit_earned') },
          REFERRAL_SENT: { color: 'gold', label: t('invited') }
        };

        const { color, label } = statusMapping[status];

        const isResendAvailable = dayjs().diff(dayjs(record.invitation_date), 'day') > 7;

        return (
          <span>
            <Tag color={color}>{label}</Tag>
            {status === 'REFERRAL_SENT' && isResendAvailable && (
              <Button size="small" className="resend__button" onClick={() => handleResend(record)}>
                {t('resend_invite')} &gt;
              </Button>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.referral_status.localeCompare(b.referral_status)
    }
  ];

  if (loading) return <Skeleton active />;

  return (
    <CardBlock className="referral_progress__container">
      <h3>{t('referral_progress')}</h3>
      <p>
        {t('referral_progress_description', {
          sent: referralStatus.sent,
          accepted: referralStatus.accepted,
          pending: referralStatus.sent - referralStatus.accepted
        })}
      </p>
      {data?.length > 0 ? (
        <>
          <Table
            className="invitation__table"
            columns={columns}
            dataSource={data}
            pagination={false}
            footer={() => dotPagination(data?.length, currentPage)}
            loading={loading}
          />
          <Pagination
            simple
            current={currentPage}
            pageSize={pageSize}
            total={data?.length}
            onChange={page => setCurrentPage(page)}
            style={{ visibility: 'hidden' }}
          />
        </>
      ) : (
        <div>
          <p className="mb-5">{t('referral_progress_empty_text')}</p>
          <EducatorButton label={t('refer_now')} type="button" dataTestId="referNowButton" variant="outline" />
        </div>
      )}
    </CardBlock>
  );
};
