import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import './educatorModal.css';

const EducatorModal = ({
  children,
  visible,
  onOk,
  onCancel,
  bodyContent,
  width,
  height,
  wide,
  className,
  closeIcon,
  ...rest
}) => {
  return (
    <Modal
      className={`p-2 modalStyleClass ${wide ? 'wide' : ''} ${className}`}
      width={width}
      height={height}
      bodyStyle={{ background: 'none' }}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={[]}
      closeIcon={closeIcon}
      {...rest}>
      {children || bodyContent}
    </Modal>
  );
};

export default EducatorModal;

EducatorModal.defaultProps = {
  onOk: () => {},
  width: '',
  height: '',
  wide: false,
  children: null,
  bodyContent: null,
  className: '',
  closeIcon: null
};
EducatorModal.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  bodyContent: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  wide: PropTypes.bool,
  className: PropTypes.string,
  closeIcon: PropTypes.node
};
