import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { EducatorButton, EducatorModal, ModalOptions } from '@components/commonComponents';

const { Panel } = Collapse;

export const AssignmentSkills = ({
  show,
  onCancel,
  classAssignmentTopics,
  handleTopics,
  onPreviousClick,
  onNextClick
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  return (
    <EducatorModal
      visible={show}
      onCancel={onCancel}
      bodyContent={
        <div>
          <div className="fixCardHeadingStyleWidth">{t('select_skills')}</div>
          <div className="fixCardtext mt-2 mb-3">{t('choose_topic_assignment')}</div>
          <Collapse
            accordion
            className="collapseStyle"
            defaultActiveKey={[classAssignmentTopics[0]?.heading]}
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined className="iconStyle" /> : <PlusOutlined className="iconStyle" />
            }
            expandIconPosition={'end'}>
            {classAssignmentTopics &&
              classAssignmentTopics?.map(item => {
                return (
                  <Panel className="panelHeadingsOrIconStyle mt-2" header={item.heading} key={item.heading}>
                    {item.topics?.map(topic => (
                      <ModalOptions
                        key={topic.id}
                        id={topic.id}
                        title={topic.name}
                        background={topic.sub_heading.background_color}
                        subTitle={topic.sub_heading.name}
                        textColor={topic.sub_heading.text_color}
                        onChange={e => {
                          handleTopics(e.target.value);
                        }}
                      />
                    ))}
                  </Panel>
                );
              })}
          </Collapse>
          <div className="d-flex justify-content-between mt-3">
            <EducatorButton
              label={t('previous')}
              className="previousButton"
              type="button"
              width="49%"
              onClick={onPreviousClick}
            />
            <EducatorButton
              label={t('next')}
              type="button"
              width="50%"
              onClick={onNextClick}
              disabled={classAssignmentTopics.length === 0}
            />
          </div>
        </div>
      }
    />
  );
};

AssignmentSkills.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  classAssignmentTopics: PropTypes.array.isRequired,
  handleTopics: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired
};
