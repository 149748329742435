import Color from '../../theme/colors';

export const EventTypes = {
  EDITABLE: 'EDITABLE',
  HOLIDAY: 'HOLIDAY',
  NON_EDITABLE: 'NON_EDITABLE'
};

export const topicColor = topicName => {
  switch (topicName) {
    case 'Be Cyber Kind':
      return { color: Color.GREY_100, backgroundColor: Color.SKY };
    case 'Be Cyber Safe':
      return { color: Color.GREY_100, backgroundColor: Color.DAFFODIL };
    case 'Be Cyber Healthy':
      return { color: Color.WHITE_0, backgroundColor: Color.GOLD };
    case 'Be Cyber Positive':
      return { color: Color.WHITE_0, backgroundColor: Color.LILAC };
    case 'Be Cyber Informed':
      return { color: Color.WHITE_0, backgroundColor: Color.SPRING };
    case 'Be Cyber Secure':
      return { color: Color.WHITE_0, backgroundColor: Color.LAVENDER_100 };
    default:
      return { color: Color.GREY_100, backgroundColor: Color.SKY };
  }
};

export const getColor = (isEvent, topic) => {
  switch (isEvent) {
    case EventTypes.HOLIDAY:
      return { color: Color.GREY_100, backgroundColor: Color.SEA_FOAM_50 };
    case EventTypes.NON_EDITABLE:
      return { color: Color.GREY_50, backgroundColor: '#dddddd' };
    case EventTypes.EDITABLE:
      return topicColor(topic);
    default:
      return { color: Color.GREY_100, backgroundColor: Color.SKY };
  }
};
