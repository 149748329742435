import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ArrowUpRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Title } from '@parent-app/design-system';
import { LanguageSwitcher } from '@components/commonComponents';

const CyberAcademyButton = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  return (
      <Button
        style={{ direction: 'rtl' }}
        icon={<ArrowUpRight style={{ marginLeft: 8 }} />}
        onClick={() => window.open('/child-account', '_blank', 'noreferrer')}>
        {t('cyber_academy')}
      </Button>
  );
};

export const Navbar = ({ title, cyberAcademyButton }) => (
    <Stack justifyContent='space-between' width='100%'>
      <Title level={2} style={{ wordBreak: 'keep-all' }}>{title}</Title>
      <Stack spacing={20} justifyContent='flex-end' style={{ width: 'auto'}}>
        {cyberAcademyButton && <CyberAcademyButton />}
        <LanguageSwitcher />
      </Stack>
    </Stack>
);
export default memo(Navbar);

Navbar.defaultProps = {
  title: '',
  cyberAcademyButton: false
};

Navbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cyberAcademyButton: PropTypes.bool
};
