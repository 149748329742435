import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorModal } from '../../index';
import { setIsFromStudent } from '@utils';

export const DoneSetupModal = ({ show, onCancel, handleDownloadPdf, handlePrintLoginInstruction }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const navigate = useNavigate();

  return (
    <>
      <EducatorModal
        visible={show}
        onCancel={onCancel}
        bodyContent={
          <div>
            <div className="fixCardHeadingStyle d-flex justify-content-center">{t('all_set_up')}</div>
            <div className="fixCardtextStyle mt-2 text-center">{t('all_set_up_info')}</div>
            <Form>
              <Form.Item className="pt-4">
                <EducatorButton
                  key="button"
                  label={t('download_pdf')}
                  type="button"
                  width="100%"
                  onClick={handleDownloadPdf}
                />
              </Form.Item>
              <Form.Item>
                <EducatorButton
                  key="button"
                  label={t('print_instructions')}
                  type="button"
                  width="100%"
                  onClick={handlePrintLoginInstruction}
                />
              </Form.Item>
              <Form.Item className="d-none">
                <EducatorButton key="button" label={t('view_account_details')} type="button" width="100%" />
              </Form.Item>
              <Form.Item className="d-none">
                <EducatorButton
                  key="button"
                  label={t('create_assignment')}
                  type="button"
                  width="100%"
                  onClick={() => {
                    setIsFromStudent(true);
                    navigate('/assignments');
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        }
      />
    </>
  );
};

DoneSetupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleDownloadPdf: PropTypes.func.isRequired,
  handlePrintLoginInstruction: PropTypes.func.isRequired
};
