import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppState } from '@context';
import { getFlowComplete } from '@utils';

const PublicRoute = ({ children, restricted }) => {
  const { state } = useAppState();
  const { isAuthenticated } = state;

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated && getFlowComplete() && restricted) {
    return <Navigate to="/dashboard" />;
  }

  if (!isAuthenticated && !restricted) {
    return <Navigate to="/" />;
  }

  return children ?? <Outlet />;
};

export default PublicRoute;

PublicRoute.defaultProps = {
  restricted: false,
  children: null
};

PublicRoute.propTypes = {
  restricted: PropTypes.bool,
  children: PropTypes.element
};
