import React, { useReducer, createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth, Hub } from 'aws-amplify';

import {
  SET_AUTHENTICATION,
  SET_CLASSES,
  SET_CURRENT_CLASS_ID,
  SET_CURRENT_USER,
  SET_IS_GOOGLE_AUTH,
  SET_TOKEN
} from './context-actions';
import { auth } from './reducers/auth';
import { classReducer } from './reducers/class';
import { setFlowComplete, removeLocalStorageItem, getLocalStorageItem, getCognitoUserId } from '@utils';
import { useCurrentUser } from '@hooks';

// initial state
const initialState = {
  currentUser: null,
  isAuthenticated: false,
  isGoogleAuth: false,
  authToken: null,
  classId: getLocalStorageItem('class_id') ?? null,
  classes: []
};

// create context
const Context = createContext(initialState);
export const useAppState = () => useContext(Context);
// combine reducer function
const combineReducers =
  (...reducers) =>
  (state, action) => {
    for (let i = 0; i < reducers.length; i++) state = reducers[i](state, action);
    return state;
  };

// context provider
export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combineReducers(auth, classReducer), initialState);

  const { data, revalidate } = useCurrentUser();

  const checkAuthState = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        dispatch({ type: SET_AUTHENTICATION, payload: true });

        const currentSession = await Auth.currentSession();
        const { accessToken } = currentSession;

        dispatch({ type: SET_TOKEN, payload: accessToken.jwtToken });
      } else {
        dispatch({ type: SET_AUTHENTICATION, payload: false });
        dispatch({ type: SET_CURRENT_USER, payload: null });
        dispatch({ type: SET_TOKEN, payload: null });
        dispatch({ type: SET_CLASSES, payload: [] });
        dispatch({ type: SET_CURRENT_CLASS_ID, payload: '' });
      }
    } catch (err) {
      dispatch({ type: SET_AUTHENTICATION, payload: false });
      dispatch({ type: SET_CURRENT_USER, payload: null });
      dispatch({ type: SET_TOKEN, payload: null });
      dispatch({ type: SET_CLASSES, payload: [] });
      dispatch({ type: SET_CURRENT_CLASS_ID, payload: '' });
    }
  };

  const resetLocalStorage = () => {
    removeLocalStorageItem('user_role');
    removeLocalStorageItem('class_id');
  };

  const setGaUserProperties = async () => {
    const cognitoUserId = await getCognitoUserId();

    if (cognitoUserId) {
      gtag('set', 'user_properties', { cognito_user_id: cognitoUserId });
    } else {
      gtag('set', 'user_properties', { cognito_user_id: null });
    }
  };

  useEffect(() => {
    const handleAuthEvents = data => {
      switch (data.payload.event) {
        case 'signIn':
          checkAuthState();
          setGaUserProperties();
          break;
        case 'signOut':
          checkAuthState();
          resetLocalStorage();
          setGaUserProperties();
          break;
        default:
          break;
      }
    };

    const listener = Hub.listen('auth', handleAuthEvents);

    checkAuthState();
    setGaUserProperties();

    return () => listener();
  }, []);

  useEffect(() => {
    if (state.isAuthenticated) {
      revalidate();
    }
  }, [state.isAuthenticated]);

  useEffect(() => {
    if (data) {
      dispatch({ type: SET_CURRENT_USER, payload: data });

      if (data.username?.toLowerCase().startsWith('google_')) {
        dispatch({ type: SET_IS_GOOGLE_AUTH, payload: true });
      }

      setFlowComplete(data.is_auth);
    }
  }, [data]);

  const value = { state, dispatch, fetchCurrentUser: revalidate };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

AppStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};
