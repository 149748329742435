import PropTypes from 'prop-types';
import React from 'react';

const BackgroundColor = ({ title, background, textColor }) => {
  return (
    <div className="backgroundColorWithText" style={{ background: background, color: textColor }}>
      {title}
    </div>
  );
};
export default BackgroundColor;
BackgroundColor.defaultProps = {
  textColor: '#222222',
  background: '#E5E5E5'
};
BackgroundColor.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string.isRequired,
  textColor: PropTypes.string
};
