import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Collapse, Radio, Divider, message, Spin } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { AuthenticatedNavbar } from '../../layoutComponents';
import { EducatorButton } from '../../commonComponents';
import { API_URL, getApiWithAuth, patchApiWithAuth } from '../../../utils';

import './parentChildCustomizePath.scss';
const ParentChildCustomizePath = () => {
  const location = useLocation();

  const [learningPath, setLearningPath] = useState([]);
  const [showDummyData, setShowDummyData] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const { child_info, isFreePlan } = location.state || {};
  const navigate = useNavigate();

  const { Panel } = Collapse;

  const onChange = e => {
    const { name, value } = e.target;
    updatePath(name, value);
  };

  const getLearningPath = async () => {
    setShowSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.CUSTOMIZE_LEANING_PATH}/${child_info?.student_id}`);
    if (success) {
      setLearningPath(data);
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (!isFreePlan) {
      getLearningPath();
    } else {
      setShowDummyData(true);
      setLearningPath([
        {
          heading: 'Safety Topics',
          id: 0,
          topics: [
            {
              child_topic__name: 'Password Protection',
              child_topic__sub_heading__background_color: 'black',
              child_topic__sub_heading__name: 'Cyber',
              child_topic__sub_heading__text_color: 'yellow',
              id: 0,
              topic_level: 'NORMAL'
            },
            {
              child_topic__name: 'Identify a Trusted Adult',
              child_topic__sub_heading__background_color: 'black',
              child_topic__sub_heading__name: 'legends',
              child_topic__sub_heading__text_color: 'yellow',
              id: 1,
              topic_level: 'NORMAL'
            },
            {
              child_topic__name: 'Password Protection',
              child_topic__sub_heading__background_color: 'black',
              child_topic__sub_heading__name: 'legends',
              child_topic__sub_heading__text_color: 'yellow',
              id: 1,
              topic_level: 'NORMAL'
            },
            {
              child_topic__name: 'Identify a Trusted Adult',
              child_topic__sub_heading__background_color: 'black',
              child_topic__sub_heading__name: 'legends',
              child_topic__sub_heading__text_color: 'yellow',
              id: 1,
              topic_level: 'NORMAL'
            }
          ]
        },
        {
          heading: 'Coding Topics',
          id: 1,
          topics: [
            {
              child_topic__name: 'Password Protection',
              id: 0,
              topic_level: 'NORMAL'
            },
            {
              child_topic__name: 'Identify a Trusted Adult',
              id: 1,
              topic_level: 'NORMAL'
            }
          ]
        }
      ]);
    }
  }, [isFreePlan]);

  const updatePath = async (topic_id, value) => {
    const { data } = await patchApiWithAuth(
      `${API_URL.UPDATE_LEARNING_PATH}/${topic_id}/child/${child_info.student_id}`,
      {
        topic_level: value
      }
    );
    if (data) {
      message.success('Updated');
    }
  };

  return (
    <>
      {showDummyData ? (
        <div className="yellowTitle px-3">
          {`${child_info.first_name.toUpperCase()}. ${child_info.last_name.toUpperCase()}`} is currently playing for
          free. To unlock custom game skins and activity kits for parents,&nbsp;&nbsp;
          <span className="parentOverviewHadding cursorPointer" onClick={() => navigate('/memberships-and-billing')}>
            UPGRADE NOW
          </span>
        </div>
      ) : null}
      {!showDummyData ? (
        <div className="container-fluid p-2 p-md-3 p-lg-4 weeklyMianDivHeight">
          <AuthenticatedNavbar
            smallHeading1="Learning"
            smallHeading2=" Customize Learning Paths"
            bodyButton={<EducatorButton label="Print Report" type="button" width="152px" />}
            playButton
          />
          <div className="row mt-3 cardResourceMainDiv">
            <div className="authenticatedNavbarHeadingStyle">
              Customize {`${child_info?.first_name.toUpperCase()} ${child_info?.last_name.toUpperCase()}'s`}
            </div>
            <div className="col col-12 authenticatedNavbarHeadingStyle">Learning Path</div>
            <div className="pt-1 textGradedAssignmentsStyle">
              Below is a list of topics covered in our game. Set a priority for each topic to help shape your child’s
              learning.{' '}
            </div>
            <Card bordered={false} className="cardStyleSecondStudentComprehension cardsStyling p-md-3 p-3 mt-3">
              {!showSpinner ? (
                <Collapse
                  accordion
                  className="collapseStyle"
                  expandIcon={({ isActive }) =>
                    isActive ? <MinusOutlined className="iconStyle" /> : <PlusOutlined className="iconStyle" />
                  }
                  expandIconPosition={'end'}>
                  {learningPath?.length !== 0 ? (
                    learningPath?.map(item => {
                      return (
                        <Panel className="panelHeadingsStyleStudentReport mt-2" header={item.heading} key={item.id}>
                          {item.topics?.map(panelOptions => {
                            return (
                              <div key={panelOptions.id}>
                                <div className="optionsStyle">
                                  <div className="d-flex justify-content-between">
                                    {panelOptions.child_topic__name}
                                    <Radio.Group
                                      onChange={onChange}
                                      defaultValue={panelOptions.topic_level}
                                      name={panelOptions.id}>
                                      <Radio value="HIGH">High</Radio>
                                      <Radio value="NORMAL">Normal</Radio>
                                      <Radio value="LOW">Low</Radio>
                                      <Radio value="DONOTSHOW">Do Not Show</Radio>
                                    </Radio.Group>
                                  </div>
                                  <Divider className="dividerStyle" />
                                </div>
                              </div>
                            );
                          })}
                        </Panel>
                      );
                    })
                  ) : (
                    <div className="emptyMessage">No Data to show</div>
                  )}
                </Collapse>
              ) : (
                <Spin className="spinnerCenter" />
              )}
            </Card>
          </div>
        </div>
      ) : (
        <div className="container-fluid p-2 p-md-3 p-lg-4 weeklyMianDivHeight">
          <AuthenticatedNavbar
            smallHeading1="Learning"
            smallHeading2=" Customize Learning Paths"
            bodyButton={<EducatorButton label="Print Report" type="button" width="152px" />}
          />
          <div className="row mt-3 cardResourceMainDiv">
            <div className="col col-12 authenticatedNavbarHeadingStyle">Sample Learning Path</div>
            <div className="pt-1 textGradedAssignmentsStyle">
              Below is a list of topics covered in our game. Set a priority for each topic to help shape your child’s
              learning.{' '}
            </div>
            <div className="blueTileComprehensionReport px-3">
              This is a sample report, to unlock reporting on your child’s progress,
              <span
                className="parentOverviewHadding cursorPointer mx-2"
                onClick={() => navigate('/memberships-and-billing')}>
                Upgrade Now
              </span>
            </div>
            <Card bordered={false} className=" col-8 cardStyleSecondStudentComprehension cardsStyling p-md-3 p-3 mt-3">
              <Collapse
                accordion
                className="collapseStyle"
                defaultActiveKey={[`${learningPath[0]?.id}`]}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined className="iconStyle" /> : <PlusOutlined className="iconStyle" />
                }
                expandIconPosition={'end'}>
                {learningPath?.map(item => {
                  return (
                    <Panel className="panelHeadingsStyleStudentReport mt-2" header={item.heading} key={item.id}>
                      {item.topics?.map(panelOptions => {
                        return (
                          <div key={panelOptions.id}>
                            <div className="optionsStyle">
                              <div className="d-flex justify-content-between">
                                {panelOptions.child_topic__name}
                                <Radio.Group defaultValue={panelOptions.topic_level} name={panelOptions.id}>
                                  <Radio value="HIGH">High</Radio>
                                  <Radio value="NORMAL">Normal</Radio>
                                  <Radio value="LOW">Low</Radio>
                                  <Radio value="DONOTSHOW">Do Not Show</Radio>
                                </Radio.Group>
                              </div>
                              <Divider className="dividerStyle" />
                            </div>
                          </div>
                        );
                      })}
                    </Panel>
                  );
                })}
              </Collapse>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
export default ParentChildCustomizePath;
