import useSWRImmutable from 'swr';
import { API_URL } from '../utils';

export const useLoginDetails = id => {
  const url = `${API_URL.GET_CHILD_DETAILS_BY_ID}/${id}`;

  const { data: loginData, mutate, error, isLoading } = useSWRImmutable(id ? url : null);
  const response = loginData?.data;
  const revalidate = () => {
    mutate();
  };

  return {
    loading: isLoading,
    error: error,
    data: response,
    revalidate
  };
};
