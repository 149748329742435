import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Radio, Divider, message } from 'antd';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { EducatorButton, EducatorModal, EducatorInputField } from '@components/commonComponents';
import { AddCard } from '@pages/MembershipAndBilling';
import {
  API_URL,
  getApiWithAuth,
  patchApiWithAuth,
  subTotalFunction,
  subTotalFunctionWithTax,
  totalFunction
} from '@utils';

import './makePayment.css';

const MakePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, premiumChildDetail, freeChildDetail, selectedPlanId } = location.state || {};

  const [cardDetail, setCardDetail] = useState(null);
  const [sendData, setSendData] = useState({ payment_id: null });
  const [isLoading, setisLoading] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const [isCouponApply, setIsCouponApply] = useState(false);

  const getCardDetails = async () => {
    const { success, data } = await getApiWithAuth(API_URL.FETCH_CARDS);
    if (success) {
      setCardDetail(data);
    }
  };
  useEffect(() => {
    getCardDetails();
  }, []);

  const onChange = e => {
    setSendData({ ...sendData, payment_id: e.target.value });
  };

  useEffect(() => {
    setSendData(data);
  }, [data]);

  const updatePayment = async () => {
    setisLoading(true);
    const { success, data } = await patchApiWithAuth(API_URL.UPDATE_PAYMENT, sendData);
    if (success) {
      setisLoading(false);
      navigate('/memberships-and-billing');
    } else {
      setisLoading(false);
      message.error(`${data.message}`);
    }
  };
  const showAddCard = () => {
    setShowAddCardModal(true);
  };
  const handleAddCard = () => {
    setShowAddCardModal(false);
    getCardDetails();
  };
  const onChangeHandleCoupon = e => {
    const { value } = e.target;
    setCouponCode(value);
  };
  const applyCoupon = async () => {
    const { success, data } = await getApiWithAuth(`coupons/${couponCode}/${selectedPlanId?.id}`);
    if (success) {
      if (data.data.remaining_redemptions === 0) {
        message.error('Coupon is expired');
      } else {
        message.success('Coupon added successfully');
        setIsCouponApply(true);
      }
    } else {
      message.error(data.detail);
      setIsCouponApply(false);
    }
  };
  const onKeyDown = e => {
    if (e.key === 'Enter') {
      applyCoupon();
    }
  };

  const removeCouponCode = async () => {
    setIsCouponApply(false);
    setCouponCode('');
    message.success('Coupon code remove Successfully');
  };
  const paymentFunction = async () => {
    const newSendData = sendData;
    newSendData.coupon_code = couponCode;
    setisLoading(true);

    const { success, data } = await patchApiWithAuth(API_URL.UPDATE_PAYMENT, newSendData);
    if (success) {
      setisLoading(false);
      navigate('/memberships-and-billing');
    } else {
      setisLoading(false);
      message.error(`${data.message}`);
    }
  };
  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 mainDivParentResourcesStyle">
        <AuthenticatedNavbar
          smallHeading1="Account Management"
          smallHeading2="Manage Subscriptions"
          smallHeading3="Make Paymnet"
        />
        <div className="unitPlanTextStyle row">
          <div className="col-6">
            <div className="authenticatedNavbarHeadingStyle capital mt-4">Make Payment</div>
            <div>You’ve chosen to upgrade your subscriptions! Make your payment to unlock premium features.</div>
          </div>
        </div>
        <div className="row mt-4 assignmentSrolling">
          <div className="col col-sm-8 pb-3 ">
            <Radio.Group onChange={onChange} defaultValue={sendData?.payment_id} className="col-12">
              {cardDetail?.map(item => {
                return (
                  <Card key={item.id} bordered={false} className="cardsStyling mt-3 px-sm-3 p-4 px-5 px-lg-4 px-xl-5 ">
                    <div className="secondCardHadding">Pay with a card on file</div>
                    <div className="spaceBetweenDiv mt-2">
                      <Radio value={item.payment_id}>
                        <div className="textStyle400Cabin18Black">
                          {item.card_type} ****{item.last_digits}
                        </div>
                      </Radio>
                      <div
                        className="childLinkStyle cursorPointer"
                        onClick={() =>
                          navigate('/update-card', {
                            state: {
                              data: data,
                              premiumChildDetail: premiumChildDetail,
                              freeChildDetail: freeChildDetail,
                              userInfo: item
                            }
                          })
                        }>
                        Update Card
                      </div>
                    </div>
                  </Card>
                );
              })}
            </Radio.Group>
            <div className="addNewCardText mt-4">
              Get a new Credit Card?
              <span className="parentOverviewHadding cursorPointer addLineBottom" onClick={showAddCard}>
                &nbsp;Add a Card &gt;
              </span>
            </div>
          </div>
          <div className="col col-sm-4 pb-3 ">
            <Card bordered={false} className="cardsStyling px-sm-3 p-4 px-5 px-lg-4 px-xl-5 memberStyle">
              <div className="pt-2  spaceBetweenDiv">
                <div>Premium Membership</div>
                <div className="pt-2  spaceBetweenDiv">
                  <div>{premiumChildDetail.length}&nbsp;x&nbsp;</div>
                  <div> ${premiumChildDetail.payment} </div>
                </div>
              </div>
              <div className="pt-2  spaceBetweenDiv">
                <div>Free Membership</div>
                <div className="pt-2  spaceBetweenDiv">
                  <div>{freeChildDetail.length}&nbsp;x&nbsp;</div>
                  <div>${freeChildDetail.payment}</div>
                </div>
              </div>
              <Divider className="managedividerStyleOne" />
              <div className="mt-3">
                <EducatorInputField
                  headerColor="#000000"
                  label="Redeem Coupon Code:"
                  type="text"
                  name="coupon"
                  width="100%"
                  inputValue={couponCode}
                  onChange={onChangeHandleCoupon}
                  onKeyDown={onKeyDown}
                  suffix={
                    <EducatorButton
                      width="96px"
                      key="button"
                      label="Apply"
                      type="button"
                      className="applyCouponButton"
                      onClick={applyCoupon}
                      isCoupon
                    />
                  }
                />
              </div>
              {isCouponApply ? (
                <div className="pt-1 memberStyle">
                  Removed coupon &nbsp;
                  <span onClick={removeCouponCode} className="parentOverviewHadding cursorPointer">
                    {couponCode}&nbsp;
                  </span>
                  ?
                </div>
              ) : null}
              <Divider className="managedividerStyleOne" />

              <div className="pt-2  spaceBetweenDiv">
                <div>Subtotal </div>
                <div>${subTotalFunction(premiumChildDetail.length, premiumChildDetail.payment)}</div>
              </div>
              <div className="pt-2  spaceBetweenDiv">
                <div>Estimated Tax </div>
                <div>${subTotalFunctionWithTax(premiumChildDetail.length, premiumChildDetail.payment)}</div>
              </div>
              {isCouponApply ? (
                <div className="pt-2  spaceBetweenDiv">
                  <div>Coupon Code </div>
                  <div>${totalFunction(premiumChildDetail.length, premiumChildDetail.payment)}</div>
                </div>
              ) : null}
              <Divider className="managedividerStyleTwo" />
              <div className="pt-2 secondCardHadding spaceBetweenDiv">
                <div>Total </div>
                <div>
                  ${isCouponApply ? '0.00' : totalFunction(premiumChildDetail.length, premiumChildDetail.payment)}{' '}
                </div>
              </div>
              <Divider className="managedividerStyleTwo" />

              <div className="pt-3">
                <EducatorButton
                  label="Make Payment"
                  type="button"
                  width="100%"
                  showSpinner={isLoading}
                  disabled={isCouponApply ? null : !sendData.payment_id}
                  onClick={isCouponApply ? paymentFunction : updatePayment}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
      <EducatorModal
        visible={showAddCardModal}
        onCancel={handleAddCard}
        bodyContent={
          <div className="mt-4 p-3 ">
            <AddCard closeModal={handleAddCard} />
          </div>
        }
      />
    </>
  );
};
export default MakePayment;
