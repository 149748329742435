import useSWR from 'swr';
import { API_URL } from '@utils';

export const useTopics = grade => {
  const url = grade ? `${API_URL.SCHOOL_TOPICS}?grade=${grade}` : null;

  const { data, mutate, error, isLoading } = useSWR(url);
  const response = data?.data ?? [];

  const revalidate = async () => {
    await mutate();
  };

  return {
    loading: isLoading,
    error: error?.response?.data ?? null,
    data: response,
    revalidate
  };
};
